import { createSlice } from '@reduxjs/toolkit';
import { Timing } from 'lib/util';
import moment from 'moment';
import Card from '../../models/Card';
import authenticationAsyncActions from '../actions/authentication.action';
import cardsAsyncActions from '../actions/cards.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';
import { CPA, SliceState } from '../types';

export interface CardsState extends SliceState<Card> {}

const initialState: CardsState = {
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'cards',
	initialState,
	reducers: {},
	extraReducers: {
		[cardsAsyncActions.index.fulfilled.type]: (state, action: CPA<Card[]>) => {
			state.list = action.payload;
			state.updatedAt = moment().valueOf();

			postRequest(action);
		},
		[cardsAsyncActions.index.rejected.type]: (state, action) => postErrorRequest(state, action, initialState),
		[cardsAsyncActions.show.fulfilled.type]: (state, action: CPA<Card>) => {
			const findIndex = state.list.findIndex((element) => element.uuid === action.payload.uuid);
			if (findIndex === -1) {
				state.list.push(action.payload);
			} else {
				state.list.splice(findIndex, 1, action.payload);
			}

			state.updatedAt = moment().valueOf();

			postRequest(action);
		},
		[cardsAsyncActions.show.rejected.type]: (state, action) => postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
	},
});

export const cardsActions = slice.actions;

export default slice.reducer;
