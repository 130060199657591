import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'lib/util';
import { requestActions } from '../slices/request.slice';

const refreshUser = createAsyncThunk('user/refresh', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(refreshUser.typePrefix));
	try {
		const response = await API.get('/user');

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const userAsyncActions = {
	refreshUser,
};

export default userAsyncActions;
