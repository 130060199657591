import React from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import InvitationBox from '../../lib/components/InvitationBox';
import Textarea from '../../lib/components/Textarea';
import Typography from '../../lib/components/Typography';
import Contributor from '../../models/Contributor';
import styles from './index.module.scss';

interface Props {
	isLoading: boolean;
	recipientFirstName: string;
	reminderMessage: string;
	contributors: Contributor[];
	handleSave: () => void;
	setContributors: React.Dispatch<React.SetStateAction<Contributor[]>>;
	setReminderMessage: React.Dispatch<React.SetStateAction<string>>;
}

const ContributorsTab: React.FC<Props> = (props: Props) => {
	const { width } = useWindowDimensions();

	return (
		<div className={styles.formContainer}>
			<Typography variant="h5">Contributors</Typography>
			<Box height={18} />
			<Typography variant="body2" style={{ fontStyle: 'italic' }}>
				Below you’ll find a list if people that have contributed to {props.recipientFirstName}’s card.
			</Typography>
			<Box height={18} />
			<Typography variant="body2" style={{ fontStyle: 'italic' }}>
				<span style={{ fontWeight: 'bold' }}>Share</span> {props.recipientFirstName}’s card to get more people
				onboard to Sign The Card.
			</Typography>
			<Box height={18} />
			<InvitationBox
				viewOnly
				height={width < 992 ? 120 : 200}
				list={props.contributors}
				setList={props.setContributors}
			/>
			<Box height={18} />
			<Typography variant="h5">Edit message sent to the contributors</Typography>
			<Box height={18} />
			<Textarea value={props.reminderMessage} onChange={props.setReminderMessage} />
			<Button
				onClick={props.handleSave}
				disabled={props.isLoading}
				loading={props.isLoading}
				type="text"
				style={{ width: 100 }}
			>
				Update
			</Button>
		</div>
	);
};

export default ContributorsTab;
