import React from 'react';
import styles from './index.module.scss';

interface Props {
	className?: string;
	children: React.ReactNode;
	variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2' | 'button' | 'caption' | 'overline';
	style?: React.CSSProperties;
}

const Typography: React.FC<Props> = ({ variant = 'body1', ...props }: Props) => {
	return (
		<div style={props.style} className={`${styles[variant]} ${props.className ? props.className : ''}`}>
			{props.children}
		</div>
	);
};

export default Typography;
