import { GiphyFetch } from '@giphy/js-fetch-api';
import { Grid } from '@giphy/react-components';
import moment from 'moment';
import React from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Box from '../Box';
import Input from '../Input';
import Typography from '../Typography';
import styles from './index.module.scss';

const gf = new GiphyFetch(process.env.REACT_APP_GIPHY_API_KEY!);

interface Props {
	onSelect: (value: string, width: number, height: number) => void;
}

const Giphy: React.FC<Props> = (props: Props) => {
	const { width } = useWindowDimensions();

	const [query, setQuery] = React.useState<string>('');
	const [searchKey, setSearchKey] = React.useState<number>(moment().valueOf());
	const [list, setList] = React.useState<any>(gf.trending());

	const handleGifClick = React.useCallback((gif) => {
		props.onSelect(
			gif.images.downsized_large.url,
			gif.images.downsized_large.width,
			gif.images.downsized_large.height
		);
	}, []);

	React.useEffect(() => {
		if (!query) {
			setList(gf.trending());
			setSearchKey(moment().valueOf());
			return;
		}

		const timeoutHandle = setTimeout(async () => {
			setList(gf.search(query));
			setSearchKey(moment().valueOf());
		}, 500);

		return () => {
			if (timeoutHandle) {
				clearTimeout(timeoutHandle);
			}
		};
	}, [query]);

	return (
		<div className={styles.container}>
			<Typography variant="h3">Add a GIF from Giphy</Typography>
			<Box height={width < 992 ? 16 : 32} />
			<Input
				containerStyle={{ width: width < 992 ? '100%' : '60%' }}
				name="query"
				label={'Search'}
				value={query}
				onChange={setQuery}
			/>
			<Box height={width < 992 ? 16 : 32} />
			<div style={{ height: '100%', overflow: 'hidden', overflowY: 'auto' }}>
				<Grid
					key={searchKey}
					noLink
					hideAttribution
					onGifClick={handleGifClick}
					width={(width < 992 ? width * 0.75 : width * 0.6) - 32 - 32}
					columns={3}
					fetchGifs={() => list}
				/>
			</div>
		</div>
	);
};

export default Giphy;
