import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'lib/util';
import Card from '../../models/Card';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('cards/index', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<never, Card[]>('/card');

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const show = createAsyncThunk('cards/show', async (payload: Pick<Card, 'id'>, thunkApi) => {
	thunkApi.dispatch(requestActions.started(show.typePrefix));
	try {
		const response = await API.get<never, Card>(`/card/${payload.id}`);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const cardsAsyncActions = {
	index,
	show,
};

export default cardsAsyncActions;
