import React from 'react';
import { io, Socket } from 'socket.io-client';
import SocketContext from '../../../context/SocketContext';

interface Props {
	children: React.ReactNode;
}

const API_HOST = process.env.REACT_APP_API_URL!;

const SocketManager: React.FC<Props> = ({ children }: Props) => {
	const [socket, setSocket] = React.useState<Socket | null>(null);
	const [socketConnected, setSocketConnected] = React.useState(false);

	React.useEffect(() => {
		const newSocket = io(API_HOST, {
			reconnection: true,
			reconnectionAttempts: Infinity,
			reconnectionDelay: 10000,
			transports: ['websocket'],
		});

		newSocket.on('connect', () => {
			setSocketConnected(true);
			console.log(`SocketState: Connected to the server at ${API_HOST}`);
		});
		newSocket.on('connect_error', (error: Error) => {
			console.error('SocketState: connect_error', error);
		});
		newSocket.on('disconnect', () => {
			setSocketConnected(false);
			console.log('SocketState: Disconnected from the server.');
		});

		setSocket(newSocket);

		return () => {
			if (socket && socketConnected) {
				socket.disconnect();
				socket.close();
				setSocket(null);
			}
		};
	}, []);

	return <SocketContext.Provider value={{ socket, socketConnected }}>{children}</SocketContext.Provider>;
};

export default SocketManager;
