import React from 'react';
import { useDispatch } from 'react-redux';
import authenticationAsyncActions from '../../../store/actions/authentication.action';
import { useAuthenticationState, useUIState } from '../../../store/selectors';
import { uiActions } from '../../../store/slices/ui.slice';
import Button from '../Button';
import Input from '../Input';
import Typography from '../Typography';
import styles from './index.module.scss';

const ResetPassword = () => {
	const dispatch = useDispatch();

	const authenticationState = useAuthenticationState();
	const uiState = useUIState();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [code, setCode] = React.useState<string>(uiState.code || '');
	const [password, setPassword] = React.useState<string>('');
	const [passwordConfirmation, setPasswordConfirmation] = React.useState<string>('');
	const [codeError, setCodeError] = React.useState<string>('');
	const [passwordError, setPasswordError] = React.useState<string>('');
	const [passwordConfirmationError, setPasswordConfirmationError] = React.useState<string>('');

	const canProceed = React.useMemo<boolean>(() => {
		return (
			code !== '' &&
			code.length === 6 &&
			password !== '' &&
			password.length >= 6 &&
			password === passwordConfirmation
		);
	}, [code, password, passwordConfirmation]);

	const handleResetPassword = React.useCallback(async () => {
		if (!canProceed || isLoading) {
			return;
		}

		setIsLoading(true);
		await dispatch(authenticationAsyncActions.resetPassword({ code, password, passwordConfirmation }));
		setIsLoading(false);
	}, [dispatch, canProceed, isLoading, code, password, passwordConfirmation]);

	React.useEffect(() => {
		if (code === '') {
			setCode(uiState.code);
		}
	}, [uiState.code]);

	React.useEffect(() => {
		if (code && code.length < 6) {
			setCodeError('Please enter 6 digits.');
		} else {
			setCodeError('');
		}
	}, [code]);

	React.useEffect(() => {
		if (password && password.length < 6) {
			setPasswordError('Too weak password.');
		} else {
			setPasswordError('');
		}
	}, [password]);

	React.useEffect(() => {
		if (passwordConfirmation && password !== passwordConfirmation) {
			setPasswordConfirmationError('Passwords does not match.');
		} else {
			setPasswordConfirmationError('');
		}
	}, [password, passwordConfirmation]);

	React.useEffect(() => {
		setCodeError('');
		setPasswordError(authenticationState.passwordError || '');
		setPasswordConfirmationError('');
	}, [authenticationState.updatedAt]);

	return (
		<div className={styles.container}>
			<div className={styles.leftContainer}>
				<Typography variant="h3" style={{ color: '#ffffff', textAlign: 'center' }}>
					Forgot
					<br />
					<span style={{ color: '#ee584b' }}>password</span>
				</Typography>
			</div>
			<div className={styles.rightContainer}>
				<Typography variant="h5" style={{ textAlign: 'center' }}>
					Forgotten your password?
				</Typography>
				<div style={{ height: 24 }} />
				<Typography variant="body1" style={{ textAlign: 'center' }}>
					Just enter your email address you used to create an account and we'll send you instructions to
					change your password.
				</Typography>
				<div style={{ height: 36 }} />
				<Input
					error={codeError}
					style={{ width: '100%' }}
					name="code"
					label={'Code'}
					value={code}
					onChange={setCode}
				/>
				<div style={{ height: 16 }} />
				<Input
					error={passwordError}
					style={{ width: '100%' }}
					name="password"
					label={'Password'}
					value={password}
					onChange={setPassword}
					type="password"
				/>
				<div style={{ height: 16 }} />
				<Input
					error={passwordConfirmationError}
					style={{ width: '100%' }}
					name="password-confirmation"
					label={'Password Confirmation'}
					value={passwordConfirmation}
					onChange={setPasswordConfirmation}
					type="password"
				/>
				<div style={{ height: 36 }} />
				<Button loading={isLoading} disabled={!canProceed || isLoading} onClick={handleResetPassword}>
					Update Password
				</Button>
				<div style={{ height: 8 }} />
				<Button
					onClick={() => dispatch(uiActions.openForgotPasswordModal())}
					style={{ padding: '16px 0', textAlign: 'center' }}
					type="text"
				>
					Don't have code? Click here
				</Button>
				<Button
					onClick={() => dispatch(uiActions.openSignInModal({ redirectUrl: uiState.redirectUrl }))}
					style={{ padding: '16px 0' }}
					type="text"
				>
					I want to Sign In
				</Button>
			</div>
		</div>
	);
};

export default ResetPassword;
