import React from 'react';
import { useDispatch } from 'react-redux';
import { IResolveParams, LoginSocialFacebook } from 'reactjs-social-login';
import authenticationAsyncActions from '../../../store/actions/authentication.action';
import { useAuthenticationState, useUIState } from '../../../store/selectors';
import { uiActions } from '../../../store/slices/ui.slice';
import { SocialTypeId } from '../../../utils/SocialTypeId';
import validateEmail from '../../../utils/validateEmail';
import Box from '../Box';
import Button from '../Button';
import Input from '../Input';
import LoginSocialGoogle from '../LoginSocialGoogle';
import LoginSocialLinkedIn from '../LoginSocialLinkedIn';
import FacebookIcon from './../../../img/ic-social-facebook.svg';
import GoogleIcon from './../../../img/ic-social-google.svg';
import LinkedInIcon from './../../../img/ic-social-linkedin.svg';
import styles from './index.module.scss';

const SignIn: React.FC = () => {
	const dispatch = useDispatch();

	const authenticationState = useAuthenticationState();
	const uiState = useUIState();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [email, setEmail] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');
	const [emailError, setEmailError] = React.useState<string>('');
	const [passwordError, setPasswordError] = React.useState<string>('');

	const canProceed = React.useMemo<boolean>(() => {
		return email !== '' && validateEmail(email) && password !== '' && password.length >= 6;
	}, [email, password]);

	const handleSignIn = React.useCallback(async () => {
		if (!canProceed || isLoading) {
			return;
		}

		setIsLoading(true);
		await dispatch(authenticationAsyncActions.signIn({ email, password }));
		setIsLoading(false);
	}, [dispatch, canProceed, isLoading, email, password]);

	const handleSocialSignIn = React.useCallback(
		(token: string, typeId: SocialTypeId) => {
			dispatch(authenticationAsyncActions.signInWithSocial({ token, typeId }));
		},
		[dispatch]
	);

	React.useEffect(() => {
		if (email && !validateEmail(email)) {
			setEmailError('Invalid Email address.');
		} else {
			setEmailError('');
		}
	}, [email]);

	React.useEffect(() => {
		if (password && password.length < 6) {
			setPasswordError('Too weak password.');
		} else {
			setPasswordError('');
		}
	}, [password]);

	React.useEffect(() => {
		if (!authenticationState.isAuthenticated) {
			setIsLoading(false);
		}

		setEmailError(authenticationState.emailError || '');
		setPasswordError(authenticationState.passwordError || '');
	}, [authenticationState]);

	return (
		<div className={styles.container}>
			<div className={styles.leftContainer}>
				<h3 className={styles.h3}>Log in to your account</h3>
				<Box height={36} />
				<div style={{ cursor: 'pointer' }}>
					<LoginSocialLinkedIn
						scope={'r_liteprofile,r_emailaddress'}
						redirect_uri={process.env.REACT_APP_BASE_URL!}
						client_id={process.env.REACT_APP_LINKEDIN_CLIENT_ID!}
						onResolve={async (response: IResolveParams) => {
							if (!response.data) {
								return;
							}

							handleSocialSignIn(response.data.accessToken, SocialTypeId.LINKEDIN);
						}}
						onReject={(error: any) => {
							console.error(error);
						}}
					>
						<Button style={{ pointerEvents: 'none', width: 230, padding: '0 4px' }} type={'outlined'}>
							<img src={LinkedInIcon} alt={'LinkedIn'} />
							<div style={{ width: 8 }} />
							Continue with LinkedIn
						</Button>
					</LoginSocialLinkedIn>
				</div>
				<Box height={24} />
				<div style={{ cursor: 'pointer' }}>
					<LoginSocialGoogle
						scope="openid email profile"
						access_type="offline"
						redirect_uri={process.env.REACT_APP_BASE_URL!}
						client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID!}
						onResolve={(response: IResolveParams) =>
							handleSocialSignIn(response.data!.idToken, SocialTypeId.GOOGLE)
						}
						onReject={(error: any) => {
							console.error(error);
						}}
					>
						<Button style={{ pointerEvents: 'none', width: 230, padding: '0 4px' }} type={'outlined'}>
							<img src={GoogleIcon} alt={'Google'} />
							<div style={{ width: 8 }} />
							Continue with Google
						</Button>
					</LoginSocialGoogle>
				</div>
				<Box height={24} />
				<div style={{ cursor: 'pointer' }}>
					<LoginSocialFacebook
						fieldsProfile={'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email'}
						redirect_uri={process.env.REACT_APP_BASE_URL!}
						appId={process.env.REACT_APP_FACEBOOK_APP_ID!}
						onResolve={(response: IResolveParams) =>
							handleSocialSignIn(response.data!.accessToken, SocialTypeId.FACEBOOK)
						}
						onReject={(error: any) => {
							console.error(error);
						}}
					>
						<Button style={{ pointerEvents: 'none', width: 230, padding: '0 4px' }} type={'outlined'}>
							<img src={FacebookIcon} alt={'Facebook'} />
							<div style={{ width: 8 }} />
							Continue with Facebook
						</Button>
					</LoginSocialFacebook>
				</div>
			</div>
			<div className={styles.rightContainer}>
				<h5 className={styles.h5}>Log in with email</h5>
				<Box height={36} />
				<Input
					error={emailError}
					style={{ width: '100%' }}
					name="email"
					label={'Email address'}
					value={email}
					onChange={setEmail}
					type="email"
				/>
				<Box height={16} />
				<Input
					error={passwordError}
					style={{ width: '100%' }}
					name="password"
					label={'Password'}
					value={password}
					onChange={setPassword}
					type="password"
				/>
				<Box height={16} />
				<Button
					type="text"
					style={{ padding: '0 12px', color: '#000000' }}
					onClick={() => dispatch(uiActions.openForgotPasswordModal())}
				>
					Forgot Password?
				</Button>
				<Box height={12} />
				<Button loading={isLoading} disabled={!canProceed || isLoading} onClick={handleSignIn}>
					Sign In
				</Button>
				<Box height={8} />
				<Button
					onClick={() => dispatch(uiActions.openSignUpModal({ redirectUrl: uiState.redirectUrl }))}
					style={{ padding: '16px 0' }}
					type="text"
				>
					Are you new? Register today
				</Button>
			</div>
		</div>
	);
};

export default SignIn;
