import React from 'react';
import styles from './index.module.scss';

interface Props {
	disabled?: boolean;
	value?: string;
	name?: string;
	style?: React.CSSProperties;
	label?: string;
	error?: string;
	noError?: boolean;
	rows?: number;
	onChange?: (value: string) => void;
	onClick?: () => void;
	onFocus?: () => void;
	onBlur?: () => void;
}

interface State {
	isActive: boolean;
}

export default class Textarea extends React.Component<Props, State> {
	static defaultProps = {
		disabled: false,
		value: '',
		noError: false,
		rows: 6,
		onChange: () => {},
	};
	private ref;
	private focusIn: () => void;
	private focusOut: () => void;

	constructor(props: Props) {
		super(props);

		this.ref = React.createRef<HTMLTextAreaElement>();
		this.focusIn = () => {
			this.setState({
				isActive: true,
			});
			if (this.props.onFocus) {
				this.props.onFocus();
			}
		};
		this.focusOut = () => {
			if (!this.props.value) {
				this.setState({
					isActive: false,
				});
			}
			if (this.props.onBlur) {
				this.props.onBlur();
			}
		};

		this.state = {
			isActive: false,
		};
	}

	componentDidMount() {
		if (this.ref.current) {
			this.ref.current.addEventListener('focusin', this.focusIn);
			this.ref.current.addEventListener('focusout', this.focusOut);
		}

		if (this.props.value && this.props.label) {
			this.setState({
				isActive: true,
			});
		}
	}

	componentWillUnmount() {
		if (this.ref.current) {
			this.ref.current.removeEventListener('focusin', this.focusIn);
			this.ref.current.removeEventListener('focusout', this.focusOut);
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		if (this.props.value && this.props.value !== '' && this.props.label && !prevState.isActive) {
			this.setState({
				isActive: true,
			});
		}

		if ((!this.props.value || this.props.value === '') && this.props.label && prevState.isActive) {
			this.setState({
				isActive: false,
			});
		}
	}

	render() {
		return (
			<div
				onClick={this.props.onClick}
				className={`${styles.container} ${this.props.disabled ? styles.disabled : ''}`}
			>
				<div
					style={this.props.style}
					className={`${`${styles.inputContainer} ${this.props.disabled ? styles.disabled : ''}`} ${
						this.props.error ? styles.error : ''
					}`}
				>
					{this.props.label ? (
						<label
							className={`${styles.label} ${this.state.isActive ? styles.activeLabel : ''} ${
								this.props.error ? styles.errorLabel : ''
							}`}
						>
							{this.props.label}
						</label>
					) : null}
					<textarea
						rows={this.props.rows}
						disabled={this.props.disabled}
						name={this.props.name}
						onChange={(e) => this.props.onChange!(e.target.value)}
						className={`${styles.input} ${this.props.disabled ? styles.disabled : ''}`}
						ref={this.ref}
						value={this.props.value}
					/>
				</div>
				{!this.props.noError && <span className={styles.errorText}>{this.props.error}&nbsp;</span>}
			</div>
		);
	}
}
