import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import Card from '../../models/Card';
import User from '../../models/User';
import { Roles } from '../../utils/Roles';
import authenticationAsyncActions from '../actions/authentication.action';
import manageCardAsyncActions from '../actions/manageCard.action';
import transactionsAsyncActions from '../actions/transactions.action';
import userAsyncActions from '../actions/user.action';
import postRequest from '../postRequest';
import { CPA } from '../types';
import { requestActions } from './request.slice';
import { uiActions } from './ui.slice';

export interface UserState extends User {
	updatedAt: number;
}

const initialState: UserState = {
	id: '-100',
	firstName: '',
	lastName: '',
	email: '',
	roleId: Roles.GUEST,
	cards: [],
	unusedPayment: false,
	availableCards: 0,
	updatedAt: moment().valueOf(),
};

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: {
		[manageCardAsyncActions.update.fulfilled.type]: (state, action: CPA<Card>) => {
			const findIndex = state.cards.findIndex((element) => element.uuid === action.payload.uuid);
			if (findIndex !== -1) {
				state.cards.splice(findIndex, 1, action.payload);
			}
		},
		[userAsyncActions.refreshUser.fulfilled.type]: (state, action) => {
			state.id = action.payload.id;
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.email = action.payload.email;
			state.roleId = action.payload.roleId;
			state.cards = action.payload.cards;
			state.unusedPayment = action.payload.unusedPayment;
			state.availableCards = action.payload.availableCards;
			state.updatedAt = moment().valueOf();

			postRequest(action);
		},
		[userAsyncActions.refreshUser.rejected.type]: (state, action) => {
			if (action.payload.error.status === 401) {
				action.dispatch(uiActions.openSignInModal({}));
			}

			action.dispatch(
				requestActions.rejected({
					name: action.type,
					message: '',
					payload: {},
				})
			);

			return state;
		},
		[authenticationAsyncActions.signIn.fulfilled.type]: (state, action) => {
			const { user } = action.payload;

			state.id = user.id;
			state.firstName = user.firstName;
			state.lastName = user.lastName;
			state.email = user.email;
			state.roleId = user.roleId;
			state.cards = user.cards;
			state.unusedPayment = user.unusedPayment;
			state.availableCards = user.availableCards;
			state.updatedAt = moment().valueOf();
		},
		[authenticationAsyncActions.signUp.fulfilled.type]: (state, action) => {
			const { user } = action.payload;

			state.id = user.id;
			state.firstName = user.firstName;
			state.lastName = user.lastName;
			state.email = user.email;
			state.roleId = user.roleId;
			state.cards = user.cards;
			state.unusedPayment = user.unusedPayment;
			state.availableCards = user.availableCards;
			state.updatedAt = moment().valueOf();
		},
		[authenticationAsyncActions.resetPassword.fulfilled.type]: (state, action) => {
			const { user } = action.payload;

			state.id = user.id;
			state.firstName = user.firstName;
			state.lastName = user.lastName;
			state.email = user.email;
			state.roleId = user.roleId;
			state.cards = user.cards;
			state.unusedPayment = user.unusedPayment;
			state.availableCards = user.availableCards;
			state.updatedAt = moment().valueOf();
		},
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
		[transactionsAsyncActions.finalize.fulfilled.type]: (state, action) => {
			state.availableCards = action.payload.availableCards;
		},
	},
});

export const userActions = slice.actions;

export default slice.reducer;
