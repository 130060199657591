import React from 'react';
import Input from '../Input';
import LinkIcon from './../../../img/ic-link-black.svg';

interface Props {
	url: string;
}

const CopyLink: React.FC<Props> = (props: Props) => {
	const ref = React.useRef<HTMLTextAreaElement>(null);
	const [showAnimation, setShowAnimation] = React.useState<boolean>(false);

	const handleClick = React.useCallback(() => {
		setShowAnimation(true);
		setTimeout(() => {
			setShowAnimation(false);
		}, 750);

		ref.current!.select();
		document.execCommand('copy');

		// navigator.clipboard.writeText(props.url);
	}, [props.url]);

	return (
		<>
			<textarea
				value={props.url}
				ref={ref}
				style={{ pointerEvents: 'none', position: 'absolute', left: '-200vw' }}
			></textarea>
			<Input
				onClick={handleClick}
				style={{
					animationDuration: '750ms',
					animationTimingFunction: 'linear',
					animationName: showAnimation ? 'copied-fade-out' : '',
				}}
				labelStyle={{
					animationDuration: '1800ms',
					animationTimingFunction: 'linear',
					animationName: showAnimation ? 'copied-label-fade-out' : '',
				}}
				noError
				label="Copy link"
				value={props.url}
				editable={false}
				icon={LinkIcon}
			/>
		</>
	);
};

export default CopyLink;
