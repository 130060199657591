import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'lib/util';
import { requestActions } from '../slices/request.slice';

const getSettings = createAsyncThunk('settings/get', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(getSettings.typePrefix));
	try {
		const response = await API.get('/settings');

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const settingsAsyncActions = {
	getSettings,
};

export default settingsAsyncActions;
