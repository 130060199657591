import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import Header from '../../lib/components/Header';
import { PayPalButton } from '../../lib/components/PayPalButton';
import Typography from '../../lib/components/Typography';
import CardPack from '../../models/CardPack';
import cardPackAsyncActions from '../../store/actions/cardPack.action';
import transactionsAsyncActions from '../../store/actions/transactions.action';
import { useCardPackState } from '../../store/selectors';
import PayPalTransactionTypes from '../../utils/PayPalTransactionTypes';
import CheckIcon from './../../img/ic-check.svg';
import styles from './index.module.scss';

const BuyScreen: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { width } = useWindowDimensions();

	const cardPackState = useCardPackState();

	const [packId, setPackId] = React.useState<string>('-1');
	const [showRightPanel, setShowRightPanel] = React.useState<boolean>(false);

	const cardPack = React.useMemo<CardPack | undefined>(
		() => cardPackState.list.find((element) => parseInt(element.id, 10) === parseInt(packId, 10)),
		[cardPackState.list, packId]
	);

	const createOrder = React.useCallback(
		(data: any, actions: any) => {
			if (!cardPack) {
				return null;
			}

			const order = actions.order.create({
				purchase_units: [
					{
						amount: {
							currency_code: 'AUD',
							value: cardPack.pricePerCard * cardPack.cardsAmount,
						},
						description: `Pack of ${cardPack.cardsAmount} cards.`,
					},
				],
			});

			order.then((transactionId: string) => {
				dispatch(
					transactionsAsyncActions.initialize({
						transactionId,
						objectId: cardPack.id,
						typeId: PayPalTransactionTypes.BULK_BUY,
						amount: cardPack.cardsAmount * cardPack.pricePerCard,
					})
				);
			});

			return order;
		},
		[dispatch, cardPackState.list, packId, cardPack]
	);

	const onApprove = React.useCallback(
		(data: any, actions: any) => {
			return actions.order.capture().then(() => {
				dispatch(
					transactionsAsyncActions.finalize({
						transactionId: data.orderID,
					})
				);
			});
		},
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(cardPackAsyncActions.index());
	}, [dispatch]);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.leftContainer}>
					{(width >= 992 || !showRightPanel) && (
						<Header
							style={{ zIndex: width < 992 ? 25 : 10 }}
							relative={width >= 992 || (width < 992 && !showRightPanel)}
						/>
					)}
					<div>
						{width < 992 && <Box height={32} />}
						<Typography variant={width < 992 ? 'h5' : 'h3'}>Select a card pack to purchase</Typography>
						<Box height={32} />
						<div className={styles.contentContainer}>
							<div>
								{cardPackState.list.map((element) => (
									<div
										key={element.id}
										className={`${styles.cardPackContainer} ${
											parseInt(packId, 10) === parseInt(element.id, 10)
												? styles.cardPackActive
												: ''
										}`}
									>
										{parseInt(packId, 10) === parseInt(element.id, 10) && (
											<div className={styles.activeTick}>
												<img src={CheckIcon} alt="" />
											</div>
										)}
										<div className={styles.cardPackAmount}>{element.cardsAmount}</div>
										<Box height={12} />
										<Typography variant="button">cards</Typography>
										<Box height={12} />
										<Typography variant="button">
											${element.pricePerCard.toFixed(2)} per card
										</Typography>
										<Box height={12} />
										<div className={styles.cardPackSaveContainer}>
											{element.savingPercentageDescription}
										</div>
										<Box height={12} />
										<Button
											onClick={() => {
												setShowRightPanel(true);
												setPackId(element.id);
											}}
										>
											Select
										</Button>
									</div>
								))}
								{width < 992 && <Box width={8} />}
							</div>
						</div>
						<Box height={16} />
						<div className={styles.redContainer}>
							<Typography variant="caption">
								For more cards or if interested in a corporate/group plan.
							</Typography>
							<Button
								style={{ height: 32, margin: '0 8px', padding: 0, textTransform: 'none' }}
								className={styles.button}
								onClick={() => (window.location.href = 'mailto:support@signthecard.com')}
								type={'text'}
							>
								Get in touch via email.
							</Button>
						</div>
						<Box height={32} />
						<div
							style={{
								padding: '0 8px',
								fontSize: '1.5rem',
								display: 'flex',
								flexDirection: 'row',
								alignSelf: 'flex-start',
								alignItems: 'center',
							}}
						>
							<span>&lt;</span>
							<Button
								style={{ height: 32, margin: '3px 8px 0', padding: 0 }}
								onClick={() => history.replace('/?tab=1')}
								type={'text'}
							>
								Return to my profile
							</Button>
						</div>
						<Box height={32} />
					</div>
				</div>
				<div className={`${styles.rightContainer} ${showRightPanel ? styles.rightContainerActive : ''}`}>
					<div style={{ width: '100%' }}>
						{width < 992 && <Box height={16} />}
						{width < 992 && (
							<>
								<div
									style={{
										padding: '0 8px',
										fontSize: '1.5rem',
										display: 'flex',
										flexDirection: 'row',
										alignSelf: 'flex-start',
										alignItems: 'center',
									}}
								>
									<span>&lt;</span>
									<Button
										style={{ height: 32, margin: '3px 8px 0', padding: 0 }}
										onClick={() => setShowRightPanel(false)}
										type={'text'}
									>
										Return to card packs
									</Button>
								</div>
								<Box height={16} />
							</>
						)}
						<Typography variant="h3">Order Summary</Typography>
						<Box height={48} />
						<Box width={'100%'} height={1} style={{ background: '#D0D4DB' }} />
						{cardPack !== undefined && (
							<>
								<div className={styles.summaryEntry}>
									<Typography variant="button" style={{ textTransform: 'none' }}>
										{cardPack.cardsAmount} cards (${cardPack.pricePerCard.toFixed(2)} each)
									</Typography>
									<Typography variant="button" style={{ fontWeight: 300 }}>
										${(cardPack.cardsAmount * cardPack.pricePerCard).toFixed(2)}
									</Typography>
								</div>
								{/* <Box width={'100%'} height={1} style={{ background: '#D0D4DB' }} />
								<div className={styles.summaryEntry}>
									<Typography variant="button" style={{ textTransform: 'none' }}>
										Subtotal
									</Typography>
									<Typography variant="button" style={{ fontWeight: 300 }}>
										${(cardPack.cardsAmount * cardPack.pricePerCard).toFixed(2)}
									</Typography>
								</div> */}
								<Box width={'100%'} height={1} style={{ background: '#D0D4DB' }} />
								<div className={styles.summaryEntry}>
									<Typography variant="button">Total</Typography>
									<Typography variant="button" style={{ fontWeight: 300 }}>
										${(cardPack.cardsAmount * cardPack.pricePerCard).toFixed(2)}
									</Typography>
								</div>
								<Box height={32} />
								<Typography variant="h4">Select your payment method</Typography>
								<Box height={32} />
								<PayPalButton
									createOrder={createOrder}
									onApprove={onApprove}
									options={{
										clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID_CREATION,
										currency: 'AUD',
									}}
								/>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default BuyScreen;
