import React from 'react';
import Typography from '../Typography';
import styles from './index.module.scss';

interface Props {
	tabs: Array<string>;
	index: number;
	full?: boolean;
	onClick?: (index: number) => void;
	style?: React.CSSProperties;
	buttonStyle?: React.CSSProperties;
	buttonVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2' | 'button' | 'caption' | 'overline';
}

const HomeTabbar: React.FC<Props> = ({
	onClick = () => {},
	buttonStyle = {},
	buttonVariant = 'overline',
	...props
}: Props) => {
	return (
		<div style={props.style} className={styles.container}>
			{props.tabs.map((element, index) => (
				<div
					onClick={() => onClick(index)}
					key={index}
					className={`${styles[props.full ? 'tab-full' : 'tab']} ${
						props.index === index ? styles.active : ''
					} ${styles[`active-${props.full ? 'full-' : ''}${props.index}`]}`}
				>
					<Typography
						style={Object.assign(
							{ fontWeight: 600, color: 'rgba(0, 0, 0, .87)', textTransform: 'uppercase' },
							buttonStyle
						)}
						variant={buttonVariant}
					>
						{element}
					</Typography>
				</div>
			))}
		</div>
	);
};

export default HomeTabbar;
