import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import Controls from '../../lib/components/Controls';
import Header from '../../lib/components/Header';
import TopTimer from '../../lib/components/TopTimer';
import Typography from '../../lib/components/Typography';
import manageCardAsyncActions from '../../store/actions/manageCard.action';
import RequestManager from '../../store/request-manager';
import { useManageCardState, useRequestState } from '../../store/selectors';
import { manageCardActions } from '../../store/slices/manageCard.slice';
import { uiActions } from '../../store/slices/ui.slice';
import styles from './index.module.scss';
import RightPanel from './RightPanel';

const ManageCardScreen: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();

	const manageCardState = useManageCardState();
	const requestState = useRequestState();

	const [requestUpdatedAt] = React.useState<number>(requestState.updatedAt);

	// const [card, setCard] = React.useState<Card | undefined>(cardsState.list.find((element) => element.uuid === id));

	const [zoom, setZoom] = React.useState<number>(1);
	const [showWithdrawModal, setShowWithdrawModal] = React.useState<boolean>(false);
	// const [recipientFirstName, setRecipientFirstName] = React.useState<string>('');
	// const [recipientLastName, setRecipientLastName] = React.useState<string>('');

	const [showRightPanel] = React.useState<boolean>(true);

	const canWithdraw = React.useMemo<boolean>(() => {
		const cardDate = moment.utc(`${manageCardState.deliveryAt}`, 'DD/MM/YYYY hh:mm A');
		cardDate.utcOffset(manageCardState.deliveryTimezone.split(' ')[0].replace(/UTC|/, ''), true);

		const now = moment.utc();
		return cardDate.diff(now, 'hours') <= 48;
	}, [manageCardState.updatedAt]);

	React.useEffect(() => {
		if (requestUpdatedAt === requestState.updatedAt) return;
		const RM = new RequestManager(requestState);

		if (RM.isFulfilled(manageCardAsyncActions.show.typePrefix)) {
			RM.consume(manageCardAsyncActions.show.typePrefix);

			// const findCard = cardsState.list.find((element) => element.uuid === id);
			// if (findCard) {
			// 	setCard(findCard);

			// 	setRecipientFirstName(findCard.recipientFirstName);
			// 	setRecipientLastName(findCard.recipientLastName);
			// }
		}
	}, [requestUpdatedAt, requestState.updatedAt]);

	React.useEffect(() => {
		dispatch(manageCardAsyncActions.show({ id }));
	}, [dispatch, id]);

	React.useEffect(() => {
		return () => {
			dispatch(manageCardActions.reset());
		};
	}, []);

	if (manageCardState.uuid !== id) {
		return null;
	}

	return (
		<>
			<div className={styles.container}>
				<TopTimer
					deliveryAt={manageCardState.deliveryAt}
					deliveryTimezone={manageCardState.deliveryTimezone}
					deliveredAt={manageCardState.deliveredAt}
				/>
				<div className={styles.leftContainer}>
					<Header
						right={
							manageCardState.isContributionEnabled && canWithdraw ? (
								<Button className={styles.headerButton} onClick={() => setShowWithdrawModal(true)}>
									Withdraw
								</Button>
							) : (
								<Button
									className={styles.headerButton}
									onClick={() => dispatch(uiActions.openShareModal({ noContributors: true }))}
								>
									Share
								</Button>
							)
						}
						title={<Typography variant="body2">Sign the Card for</Typography>}
						subTitle={
							<Typography variant="h5">
								{manageCardState.recipientFirstName} {manageCardState.recipientLastName}&nbsp;
							</Typography>
						}
						relative
					/>
					<div style={{ marginTop: 16, display: 'flex', alignItems: 'center', height: '100%' }}>
						<div className={styles.cardContainer} style={{ transform: `scale(${zoom})` }}>
							<img src={manageCardState.image} alt={'Card Preview'} />
						</div>
					</div>
					{manageCardState.deliveredAt === null && (
						<Button
							onClick={() => history.push(`/?action=replace&redirectUrl=/manage-card/${id}`)}
							type="text"
						>
							Change Design
						</Button>
					)}
					<Box height={12} />
					<Controls noPage zoom={zoom} setZoom={setZoom} />
					<Box height={32} />
				</div>
				<div className={`${styles.rightContainer} ${showRightPanel ? styles.rightContainerActive : ''}`}>
					<RightPanel
						showWithdrawModal={showWithdrawModal}
						setShowWithdrawModal={setShowWithdrawModal}
						uuid={id}
					/>
				</div>
			</div>
		</>
	);
};

export default ManageCardScreen;
