import React from 'react';
import Box from '../Box';
import CustomCircularProgress from '../CustomCircularProgress';
import styles from './index.module.scss';

interface Props {
	onClick?: () => void;
	className?: string;
	style?: React.CSSProperties;
	type?: 'raised' | 'outlined' | 'text';
	children?: React.ReactNode | string;
	disabled?: boolean;
	loading?: boolean;
}

const Button: React.FC<Props> = ({ onClick = () => {}, type = 'raised', ...props }: Props) => {
	return (
		<div
			onClick={() => {
				if (props.disabled) {
					return;
				}

				onClick();
			}}
			style={props.style}
			className={`${
				type === 'raised'
					? styles.container
					: type === 'outlined'
					? styles.containerOutlined
					: styles.containerText
			} ${props.disabled ? (type === 'text' ? styles.disabledText : styles.disabled) : ''} ${
				props.className ? props.className : ''
			}`}
		>
			{props.loading && (
				<>
					<CustomCircularProgress />
					<Box width={12} />
				</>
			)}
			{props.children}
		</div>
	);
};

export default Button;
