import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { API } from 'lib/util';
import { RootState } from '..';
import Card from '../../models/Card';
import Signature from '../../models/Signature';
import CreateSignatureRequest from '../../network/requests/CreateSignatureRequest';
import MoveSignatureRequest from '../../network/requests/MoveSignatureRequest';
import MoveSignaturesToNewPageRequest from '../../network/requests/MoveSignaturesToNewPageRequest';
import PublishSignaturesRequest from '../../network/requests/PublishSignaturesRequest';
import RemoveSignatureRequest from '../../network/requests/RemoveSignatureRequest';
import ShowCardRequest from '../../network/requests/ShowCardRequest';
import UpdateSignatureRequest from '../../network/requests/UpdateSignatureRequest';
import OkResponse from '../../network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';
import { SessionState } from '../slices/session.slice';

const show = createAsyncThunk('sign-a-card/show', async ({ id }: ShowCardRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(show.typePrefix));
	try {
		const sessionState: SessionState = (thunkApi.getState() as RootState).session;

		const response = await API.post<Omit<ShowCardRequest, 'id'>, AxiosResponse<Card>>(`/card/${id}`, {
			sessionId: sessionState.sessionId,
		});

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ error });
	}
});

const createSignature = createAsyncThunk(
	'sign-a-card/create-signature',
	async (payload: CreateSignatureRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(createSignature.typePrefix));
		try {
			const sessionState: SessionState = (thunkApi.getState() as RootState).session;

			const response = await API.post<CreateSignatureRequest & { sessionId: string }, AxiosResponse<Signature>>(
				'/signatures',
				{
					sessionId: sessionState.sessionId,
					...payload,
				}
			);

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const updateSignatures = createAsyncThunk(
	'sign-a-card/update-signatures',
	async (payload: UpdateSignatureRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(updateSignatures.typePrefix));
		try {
			const sessionState: SessionState = (thunkApi.getState() as RootState).session;

			const response = await API.put<UpdateSignatureRequest & { sessionId: string }, AxiosResponse<Signature>>(
				`/signatures`,
				{
					sessionId: sessionState.sessionId,
					...payload,
				}
			);

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const moveSignaturesToNewPage = createAsyncThunk(
	'sign-a-card/move-signatures-to-new-page',
	async (payload: MoveSignaturesToNewPageRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(moveSignaturesToNewPage.typePrefix));
		try {
			const sessionState: SessionState = (thunkApi.getState() as RootState).session;

			const response = await API.put<
				MoveSignaturesToNewPageRequest & { sessionId: string },
				AxiosResponse<Signature>
			>(`/signatures/move`, {
				sessionId: sessionState.sessionId,
				...payload,
			});

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const publishSignatures = createAsyncThunk(
	'sign-a-card/publish-signatures',
	async ({ id, ...payload }: PublishSignaturesRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(publishSignatures.typePrefix));
		try {
			const sessionState: SessionState = (thunkApi.getState() as RootState).session;

			const response = await API.post<
				Omit<PublishSignaturesRequest & { sessionId: string }, 'id'>,
				AxiosResponse<OkResponse>
			>(`/signatures/${id}/publish`, {
				sessionId: sessionState.sessionId,
				...payload,
			});

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const removeSignature = createAsyncThunk(
	'sign-a-card/remove-signature',
	async ({ id, ...payload }: RemoveSignatureRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(removeSignature.typePrefix));
		try {
			const sessionState: SessionState = (thunkApi.getState() as RootState).session;

			const response = await API.post<
				Omit<RemoveSignatureRequest & { sessionId: string }, 'id'>,
				AxiosResponse<OkResponse>
			>(`/signatures/${id}/remove`, {
				sessionId: sessionState.sessionId,
				...payload,
			});

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const moderateRemoveSignature = createAsyncThunk(
	'sign-a-card/moderate-remove-signature',
	async ({ id, ...payload }: RemoveSignatureRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(moderateRemoveSignature.typePrefix));
		try {
			const sessionState: SessionState = (thunkApi.getState() as RootState).session;

			const response = await API.post<
				Omit<RemoveSignatureRequest & { sessionId: string }, 'id'>,
				AxiosResponse<OkResponse>
			>(`/signatures/${id}/moderate-remove`, {
				sessionId: sessionState.sessionId,
				...payload,
			});

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const moderateMoveSignature = createAsyncThunk(
	'sign-a-card/moderate-move-signature',
	async ({ id, ...payload }: MoveSignatureRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(moderateMoveSignature.typePrefix));
		try {
			const sessionState: SessionState = (thunkApi.getState() as RootState).session;

			const response = await API.post<
				Omit<MoveSignatureRequest & { sessionId: string }, 'id'>,
				AxiosResponse<OkResponse>
			>(`/signatures/${id}/moderate-move`, {
				sessionId: sessionState.sessionId,
				...payload,
			});

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const signACardAsyncActions = {
	show,
	createSignature,
	updateSignatures,
	moveSignaturesToNewPage,
	publishSignatures,
	removeSignature,
	moderateRemoveSignature,
	moderateMoveSignature,
};

export default signACardAsyncActions;
