import React from 'react';
import './index.scss';

interface Props {
	value: number;
}

const ProgressBar: React.FC<Props> = (props: Props) => {
	return (
		<div className={`progress-bar progress-circle ${props.value >= 50 ? 'over50' : ''} p${props.value}`}>
			<span>{props.value}%</span>
			<div className="progress-bar left-half-clipper">
				<div className="progress-bar first50-bar"></div>
				<div className="progress-bar value-bar"></div>
			</div>
		</div>
	);
};

export default ProgressBar;
