import moment from 'moment';
import React from 'react';
import Box from '../../lib/components/Box';
import Checkbox from '../../lib/components/Checkbox';
import Textarea from '../../lib/components/Textarea';
import Typography from '../../lib/components/Typography';
import styles from './index.module.scss';

interface Props {
	recipientFirstName: string;
	recipientEmail: string;
	date: string;
	time: string;
	deliveryTimezone: string;
	contribution: number;
	reminderMessage: string;
	isReminderEnabled: boolean;
	setReminderMessage: (value: string) => void;
	setIsReminderEnabled: (value: boolean) => void;
}

const ConfirmTab: React.FC<Props> = (props: Props) => {
	return (
		<div className={styles.formContainer}>
			<Typography variant="h4">
				Almost there!
				<br />
				Confirm the details below.
			</Typography>
			<Box height={32} />
			<Typography variant="body1" style={{ fontWeight: 'bold' }}>
				{props.recipientFirstName}
			</Typography>
			{props.recipientEmail !== '' && <Typography variant="body1">({props.recipientEmail})</Typography>}
			<Typography variant="body1">
				Will receive card on{' '}
				<span style={{ fontWeight: 'bold' }}>{moment(props.date, 'DD/MM/YYYY').format('DD MMM YYYY')}</span> at{' '}
				<span style={{ fontWeight: 'bold' }}>
					{props.time} {props.deliveryTimezone !== '' ? `(${props.deliveryTimezone})` : ''}
				</span>
			</Typography>
			{props.contribution !== 0 && (
				<Typography variant="body1">
					Your gift contribution target is{' '}
					<span style={{ fontWeight: 'bold' }}>
						{props.contribution === -1 ? 'Unlimited' : `\$${props.contribution.toFixed(2)}`}
					</span>
				</Typography>
			)}
			<Box height={24} />
			<div className={styles.separator} />
			<Box height={24} />
			<Typography variant="h6">Do you have a message for those signing?</Typography>
			<Box height={12} />
			<Typography variant="body2" style={{ fontStyle: 'italic' }}>
				This message will be sent to the people you have invited to Sign the Card.
			</Typography>
			<Box height={24} />
			<Textarea value={props.reminderMessage} onChange={props.setReminderMessage} />
			<Box height={24} />
			<Checkbox value={props.isReminderEnabled} onChange={props.setIsReminderEnabled}>
				<Typography variant="body1">
					Remind contributors to sign the card 24 hours before {props.recipientFirstName} receives the card.
				</Typography>
			</Checkbox>
			<Box height={24} />
		</div>
	);
};

export default ConfirmTab;
