import React from 'react';
import { useDispatch } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import Checkbox from '../../lib/components/Checkbox';
import Input from '../../lib/components/Input';
import Modal from '../../lib/components/Modal';
import Separator from '../../lib/components/Separator';
import Typography from '../../lib/components/Typography';
import { uiActions } from '../../store/slices/ui.slice';
import ContributeIcon from './../../img/ic-contribute.svg';
import styles from './index.module.scss';

interface Props {
	recipientFirstName: string;
	recipientLastName: string;
	isContributionEnabled: boolean;
	contribution: number;
	deliveredAt: string | null;
	setIsContributionEnabled: (value: boolean) => void;
	setContribution: (value: number) => void;
	viewOnly?: boolean;
	collectedAmount?: number;
	yourContribution?: number;
}

const GiftDetailsTab: React.FC<Props> = ({ collectedAmount = 0, yourContribution = 0, ...props }: Props) => {
	const dispatch = useDispatch();

	const { width } = useWindowDimensions();
	const [showAmountModal, setShowAmountModal] = React.useState<boolean>(false);
	const [tempAmount, setTempAmount] = React.useState<number>(props.contribution);
	const [progress, setProgress] = React.useState<number>(0);
	const [contributionError, setContributionError] = React.useState<string>('');

	const handlePillClick = React.useCallback(
		(value: number) => {
			if (!props.isContributionEnabled) {
				return;
			}

			props.setContribution(value);
			setTempAmount(value);
		},
		[props]
	);

	const handleContributionChange = React.useCallback((value: string) => {
		props.setContribution(parseInt(value));
	}, []);

	const handleFeesClick = React.useCallback(() => {
		dispatch(uiActions.openNavigationModal({ index: 6 }));
	}, [dispatch]);

	React.useEffect(() => {
		if (props.contribution !== -1 && props.contribution < 5 && props.isContributionEnabled) {
			setContributionError('Minimum contribution amount is $5');
		} else {
			setContributionError('');
		}
	}, [props.contribution, props.isContributionEnabled]);

	React.useEffect(() => {
		const p = Math.round((100 * collectedAmount) / props.contribution);
		if (p > 100) {
			setProgress(100);
		} else {
			setProgress(p);
		}
	}, [props.contribution, collectedAmount]);

	if (props.viewOnly) {
		return (
			<>
				<Modal style={{ height: 'initial' }} isVisible={showAmountModal} setIsVisible={setShowAmountModal}>
					<div className={styles.modalContainer}>
						<Typography style={{ textAlign: 'center' }} variant="h3">
							Increase Amount
						</Typography>
						<Box height={24} />
						<Input
							containerStyle={{ width: width < 992 ? '100%' : 200 }}
							type="number"
							label="$ Enter amount"
							value={tempAmount.toString()}
							onChange={(value) => setTempAmount(parseInt(value))}
						/>
						<Button
							style={{ padding: '0 16px', width: width < 992 ? '100%' : 200 }}
							onClick={() => {
								props.setContribution(tempAmount);
								setShowAmountModal(false);
							}}
						>
							Save
						</Button>
					</div>
				</Modal>
				<div className={styles.formContainer}>
					<img
						style={{ width: 134, height: 128, alignSelf: 'center' }}
						src={ContributeIcon}
						alt={'Contribute'}
					/>
					<Box height={12} />
					<Typography style={{ textAlign: 'center' }} variant="h3">
						You currently have ${yourContribution.toFixed(2)} contributed to {props.recipientFirstName}{' '}
						{props.recipientLastName}’s card
					</Typography>
					<Box height={12} />
					<Typography style={{ textAlign: 'center', fontStyle: 'italic' }} variant="body1">
						<span style={{ fontWeight: 'bold' }}>Share</span> {props.recipientFirstName}{' '}
						{props.recipientLastName}’s card with more people to meet the
						{props.contribution === -1 ? '' : ` \$${props.contribution}`} target.
					</Typography>
					<Box height={24} />
					<div
						className={`${styles.progress} ${
							props.contribution === -1
								? styles.progressUnlimited
								: collectedAmount >= props.contribution
								? styles.progressSuccess
								: styles.progressPending
						}`}
					>
						<div
							className={`${styles.progressInner} ${styles[`progressStep${progress}`]} ${
								props.contribution === -1
									? styles.progressInnerUnlimited
									: collectedAmount >= props.contribution
									? styles.progressInnerSuccess
									: styles.progressInnerPending
							}`}
						/>
						<Typography
							style={{ position: 'relative', zIndex: 1, textAlign: 'center', fontWeight: 'bold' }}
							variant="body1"
						>
							${collectedAmount.toFixed(2)}
						</Typography>
					</div>
					<Box height={24} />
					<Typography style={{ textAlign: 'center', fontWeight: 'bold' }} variant="body1">
						Target: {props.contribution === -1 ? 'Unlimited' : `\$${props.contribution.toFixed(2)}`}
					</Typography>
					{props.contribution !== -1 && props.deliveredAt === null && (
						<div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<Button
								type="text"
								style={{ padding: '0 16px', width: width < 992 ? '70%' : '50%' }}
								onClick={() => setShowAmountModal(true)}
							>
								Increase Amount
							</Button>
						</div>
					)}
					<Typography style={{ textAlign: 'center', fontStyle: 'italic' }} variant="caption">
						<span style={{ fontWeight: 'bold' }}>Note:</span> You are unable to withdraw the funds until 48
						hours before the card is due to be sent. Please see the{' '}
						<div
							style={{
								textDecoration: 'underline',
								cursor: 'pointer',
								display: 'inline-block',
								color: '#ee584b',
							}}
							onClick={handleFeesClick}
						>
							fees
						</div>{' '}
						page for further details
					</Typography>
				</div>
			</>
		);
	}

	return (
		<div className={styles.formContainer}>
			<img style={{ width: 134, height: 128, alignSelf: 'center' }} src={ContributeIcon} alt={'Contribute'} />
			<Box height={12} />
			<Typography style={{ textAlign: 'center' }} variant="h3">
				Ask people to contribute to this card
			</Typography>
			<Box height={12} />
			<Typography style={{ textAlign: 'center', fontStyle: 'italic' }} variant="body1">
				Select or enter an amount below that you’re aiming to collect. This amount will not be shown to
				contributors.
			</Typography>
			<Box height={24} />
			<div className={styles.pills}>
				<div
					onClick={() => handlePillClick(50)}
					className={`${styles.pill} ${props.contribution === 50 ? styles.pillActive : ''} ${
						!props.isContributionEnabled ? styles.pillDisabled : ''
					}`}
				>
					<Typography style={{ fontWeight: 800 }} variant="body1">
						$50
					</Typography>
				</div>
				<div
					onClick={() => handlePillClick(100)}
					className={`${styles.pill} ${props.contribution === 100 ? styles.pillActive : ''} ${
						!props.isContributionEnabled ? styles.pillDisabled : ''
					}`}
				>
					<Typography style={{ fontWeight: 800 }} variant="body1">
						$100
					</Typography>
				</div>
				<div
					onClick={() => handlePillClick(150)}
					className={`${styles.pill} ${props.contribution === 150 ? styles.pillActive : ''} ${
						!props.isContributionEnabled ? styles.pillDisabled : ''
					}`}
				>
					<Typography style={{ fontWeight: 800 }} variant="body1">
						$150
					</Typography>
				</div>
				<div
					onClick={() => handlePillClick(-1)}
					className={`${styles.pill} ${props.contribution === -1 ? styles.pillActive : ''} ${
						!props.isContributionEnabled ? styles.pillDisabled : ''
					}`}
				>
					<Typography style={{ fontWeight: 800 }} variant="body1">
						Unlimited
					</Typography>
				</div>
			</div>
			<>
				<Box height={24} />
				<Separator style={{ alignSelf: 'center' }} />
				<Box height={24} />
				<Input
					error={contributionError}
					prefix="$"
					disabled={!props.isContributionEnabled}
					value={props.contribution === -1 ? '' : props.contribution.toFixed(0)}
					onChange={handleContributionChange}
					label="Enter amount"
					type="number"
				/>
			</>
			<Box height={24} />
			<Checkbox
				disabled={props.deliveredAt !== null}
				value={!props.isContributionEnabled}
				onChange={(value) => {
					if (!value === false) {
						props.setContribution(0);
					}

					props.setIsContributionEnabled(!value);
				}}
			>
				<Typography style={{ color: 'rgba(0, 0, 0, .38)' }} variant="body1">
					No thanks, I only want people to sign the card.
				</Typography>
			</Checkbox>
			<Box height={24} />
		</div>
	);
};

export default GiftDetailsTab;
