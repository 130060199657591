import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import CustomCircularProgress from '../../lib/components/CustomCircularProgress';
import Modal from '../../lib/components/Modal';
import Tabbar from '../../lib/components/Tabbar';
import Typography from '../../lib/components/Typography';
import Contributor from '../../models/Contributor';
import cardAsyncActions from '../../store/actions/card.action';
import manageCardAsyncActions from '../../store/actions/manageCard.action';
import RequestManager from '../../store/request-manager';
import {
	useAuthenticationState,
	useManageCardState,
	useRequestState,
	useSettingsState,
	useUserState,
} from '../../store/selectors';
import { manageCardActions } from '../../store/slices/manageCard.slice';
import { uiActions } from '../../store/slices/ui.slice';
import getTimezones from '../../utils/getTimezones';
import validateEmail from '../../utils/validateEmail';
import GiftDetailsTab from '../CreateCardScreen/GiftDetailsTab';
import RecipientTab from '../CreateCardScreen/RecipientTab';
import ShareIcon from './../../img/ic-share.svg';
import ContributorsTab from './ContributorsTab';
import styles from './index.module.scss';

interface Props {
	uuid: string;
	showWithdrawModal: boolean;
	setShowWithdrawModal: React.Dispatch<React.SetStateAction<boolean>>;
	setShowRightPanel?: React.Dispatch<React.SetStateAction<boolean>>;
}

const RightPanel: React.FC<Props> = (props: Props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const authenticationState = useAuthenticationState();
	const userState = useUserState();
	const manageCardState = useManageCardState();
	const settingsState = useSettingsState();
	const requestState = useRequestState();

	const [requestUpdatedAt] = React.useState<number>(requestState.updatedAt);

	const { width } = useWindowDimensions();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [index, setIndex] = React.useState<number>(0);
	const [recipientFirstName, setRecipientFirstName] = React.useState<string>(
		`${manageCardState.recipientFirstName} ${manageCardState.recipientLastName}`.trim()
	);
	const [recipientLastName, setRecipientLastName] = React.useState<string>(manageCardState.recipientLastName);
	const [recipientEmail, setRecipientEmail] = React.useState<string>(manageCardState.recipientEmail);
	const [reminderMessage, setReminderMessage] = React.useState<string>(manageCardState.reminderMessage);
	const [deliveryAt, setDeliveryAt] = React.useState<string>(manageCardState.deliveryAt);
	const [deliveryTimezone, setDeliveryTimezone] = React.useState<string>(manageCardState.deliveryTimezone);
	const [isContributionEnabled, setIsContributionEnabled] = React.useState<boolean>(
		manageCardState.isContributionEnabled
	);
	const [contribution, setContribution] = React.useState<number>(manageCardState.contribution);
	const [contributors, setContributors] = React.useState<Array<Contributor>>(manageCardState.contributors);
	const [timezones] = React.useState<Array<string>>(getTimezones());

	const [date, setDate] = React.useState<string>(
		moment(manageCardState.deliveryAt, 'DD/MM/YYYY hh:mm A').format('DD/MM/YYYY')
	);
	const [time, setTime] = React.useState<string>(
		moment(manageCardState.deliveryAt, 'DD/MM/YYYY hh:mm A').format('hh:mm A')
	);

	const canProceed = React.useMemo<boolean>(() => {
		if (manageCardState.deliveredAt !== null) {
			return false;
		}

		if (index === 0) {
			return (
				recipientFirstName !== '' &&
				recipientEmail !== '' &&
				validateEmail(recipientEmail) &&
				deliveryAt !== '' &&
				timezones.some((element) => element === deliveryTimezone)
			);
		} else if (index === 1) {
			return (isContributionEnabled && contribution !== 0) || !isContributionEnabled;
		} else if (index === 2) {
			return true;
		}

		return false;
	}, [
		manageCardState.deliveredAt,
		index,
		timezones,
		recipientFirstName,
		recipientEmail,
		deliveryAt,
		deliveryTimezone,
		isContributionEnabled,
		contribution,
		contributors,
	]);

	const alreadySigned = React.useMemo<boolean>(() => {
		return (
			manageCardState.signatures.find((element) => element.user && element.user.id === userState.id) !== undefined
		);
	}, [userState.id, manageCardState.signatures]);

	const handleNext = React.useCallback(async () => {
		if (index >= 3 || !canProceed) {
			return;
		}

		dispatch(
			manageCardActions.updateRecipient({
				recipientFirstName,
				recipientLastName,
				recipientEmail,
				deliveryAt,
				deliveryTimezone,
			})
		);

		if (index === 0) {
			setIsLoading(true);
			dispatch(manageCardAsyncActions.update());

			return;
		} else if (index === 1) {
			if (!manageCardState.isContributionEnabled || manageCardState.contribution === 0) {
				dispatch(manageCardActions.updateContribution({ isContributionEnabled, contribution }));
				setIsLoading(true);

				dispatch(manageCardAsyncActions.update());
			} else {
				dispatch(uiActions.openShareModal({ noContributors: true }));
				return;
			}
		} else if (index === 2) {
			dispatch(uiActions.openShareModal({ noContributors: true }));
		}

		if (index < 2) {
			setIndex(index + 1);
		}
	}, [
		dispatch,
		index,
		props.uuid,
		canProceed,
		recipientFirstName,
		recipientLastName,
		recipientEmail,
		deliveryAt,
		deliveryTimezone,
		isContributionEnabled,
		contribution,
	]);

	const handleSave = React.useCallback(() => {
		if (index !== 2 || !canProceed) {
			return;
		}

		dispatch(
			manageCardActions.updateRecipient({
				recipientFirstName,
				recipientLastName,
				recipientEmail,
				deliveryAt,
				deliveryTimezone,
			})
		);

		setIsLoading(true);
		dispatch(manageCardAsyncActions.update());
	}, [dispatch, reminderMessage, index]);

	const handleExtraButton = React.useCallback(() => {
		history.replace(`/sign-a-card/${props.uuid}`);
	}, [history, props.uuid]);

	const handleViewMyProfile = React.useCallback(() => {
		if (props.setShowRightPanel) {
			props.setShowRightPanel((prev) => !prev);
			return;
		}

		history.replace(`/?tab=1`);
	}, [history, props.setShowRightPanel]);

	const handleWithdraw = React.useCallback(async () => {
		if (isLoading) {
			return;
		}

		setIsLoading(true);
		await dispatch(cardAsyncActions.withdrawRequest());
		setIsLoading(false);

		props.setShowWithdrawModal(false);
	}, [dispatch, isLoading]);

	const canWithdraw = React.useMemo<boolean>(() => {
		const cardDate = moment.utc(`${manageCardState.deliveryAt}`, 'DD/MM/YYYY hh:mm A');
		cardDate.utcOffset(manageCardState.deliveryTimezone.split(' ')[0].replace(/UTC|/, ''), true);

		const now = moment.utc();
		const diff = cardDate.diff(now, 'hours');

		return diff <= 48 && diff >= -(24 * 14);
	}, [manageCardState.deliveryAt, manageCardState.deliveryTimezone]);

	const handleFeesClick = React.useCallback(() => {
		dispatch(uiActions.openNavigationModal({ index: 6 }));
	}, [dispatch]);

	const availableWithdrawalAmount = React.useMemo<string>(() => {
		let percentageWithdrawalFee = manageCardState.collectedAmount * (settingsState.withdrawalFee / 100);

		const amount = manageCardState.collectedAmount - percentageWithdrawalFee;
		if (amount < 0) {
			return `-\$${amount.toFixed(2)}`;
		}

		return `\$${amount.toFixed(2)}`;
	}, [manageCardState.updatedAt, settingsState.updatedAt]);

	React.useEffect(() => {
		if (requestUpdatedAt === requestState.updatedAt) return;
		const RM = new RequestManager(requestState);

		if (RM.isFinished(manageCardAsyncActions.update.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(manageCardAsyncActions.update.typePrefix)) {
			RM.consume(manageCardAsyncActions.update.typePrefix);
		}
	}, [requestUpdatedAt, requestState.updatedAt]);

	React.useEffect(() => {
		setDeliveryAt(`${date} ${time}`);
	}, [date, time]);

	React.useEffect(() => {
		dispatch(
			manageCardActions.updateReminder({
				isReminderEnabled: true,
				reminderMessage,
			})
		);
	}, [reminderMessage]);

	React.useEffect(() => {
		const parts = recipientFirstName.trim().split(' ');
		if (parts.length === 1) {
			setRecipientLastName('');
		} else {
			setRecipientLastName(parts.slice(1).join(' '));
		}
	}, [reminderMessage, recipientFirstName]);

	React.useEffect(() => {
		if (manageCardState.id !== '' && manageCardState.id !== 'temporary') {
			setDate(moment(manageCardState.deliveryAt, 'DD/MM/YYYY hh:mm A').format('DD/MM/YYYY'));
			setTime(moment(manageCardState.deliveryAt, 'DD/MM/YYYY hh:mm A').format('hh:mm A'));
		}
	}, [manageCardState.id]);

	React.useEffect(() => {
		if (!authenticationState.isAuthenticated) {
			dispatch(uiActions.openSignInModal({}));
		}
	}, [dispatch, authenticationState]);

	React.useEffect(() => {
		if (manageCardState.id !== '' && manageCardState.id !== 'temporary') {
			setRecipientFirstName(`${manageCardState.recipientFirstName} ${manageCardState.recipientLastName}`.trim());
			setRecipientLastName(manageCardState.recipientLastName);
			setRecipientEmail(manageCardState.recipientEmail);
			setReminderMessage(manageCardState.reminderMessage);
			setDate(moment(manageCardState.deliveryAt, 'DD/MM/YYYY hh:mm A').format('DD/MM/YYYY'));
			setTime(moment(manageCardState.deliveryAt, 'DD/MM/YYYY hh:mm A').format('hh:mm A'));
			setDeliveryTimezone(manageCardState.deliveryTimezone);
			setIsContributionEnabled(manageCardState.isContributionEnabled);
			setContribution(manageCardState.contribution);
			setContributors(manageCardState.contributors);
		}
	}, [manageCardState.updatedAt]);

	if (manageCardState.uuid !== props.uuid) {
		return (
			<div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
				<CustomCircularProgress size={48} color={'#EE584B'} />
			</div>
		);
	}

	return (
		<>
			<Modal
				style={{ height: 'initial' }}
				isVisible={props.showWithdrawModal}
				setIsVisible={props.setShowWithdrawModal}
			>
				<div className={styles.modalContainer}>
					<Typography style={{ textAlign: 'center' }} variant="h3">
						Withdraw contributions
					</Typography>
					<Box height={24} />
					<Typography style={{ textAlign: 'center' }} variant="body2">
						Well done! You are now able to withdraw the total contribtions collected for{' '}
						{manageCardState.recipientFirstName}`s card, less STC withdrawal fees. The total withdrawal
						amount is :
					</Typography>
					<Box height={16} />
					<Typography style={{ textAlign: 'center' }} variant="h3">
						{availableWithdrawalAmount} AUD
					</Typography>
					<Box height={16} />
					<Typography style={{ textAlign: 'center' }} variant="body2">
						No further collections will be processed once the withdrawal has been approved. Requests can
						take up to 24 hours to process to your nominated PayPal account.
					</Typography>
					<Box height={16} />
					<Button
						loading={isLoading}
						disabled={isLoading}
						style={{ padding: '0 16px', width: width < 992 ? '100%' : 200 }}
						onClick={handleWithdraw}
					>
						Submit
					</Button>
					<Box height={16} />
					<Typography style={{ textAlign: 'center' }} variant="body2">
						Please visit the{' '}
						<div
							style={{
								textDecoration: 'underline',
								cursor: 'pointer',
								display: 'inline-block',
								color: '#ee584b',
							}}
							onClick={handleFeesClick}
						>
							Fees
						</div>{' '}
						section for further information.
					</Typography>
				</div>
			</Modal>
			<Tabbar onClick={setIndex} tabs={['1. Recipient', '2. Gift Details', '3. Contributors']} index={index} />
			{index === 0 && (
				<>
					<Box height={24} />
					<div className={styles.cardContainer}>
						<img src={manageCardState.image} alt={'Card Preview'} />
					</div>
					{manageCardState.deliveredAt === null && (
						<Button
							style={{ fontSize: '1.325rem', lineHeight: '1.325rem' }}
							onClick={() => history.push(`/?action=update&tab=0&redirectUri=/?tab=1`)}
							type="text"
						>
							Change Cover Design
						</Button>
					)}
					<RecipientTab
						timezones={timezones}
						recipientFirstName={recipientFirstName}
						recipientEmail={recipientEmail}
						date={date}
						time={time}
						deliveryTimezone={deliveryTimezone}
						deliveredAt={manageCardState.deliveredAt}
						setRecipientFirstName={setRecipientFirstName}
						setRecipientEmail={setRecipientEmail}
						setDate={setDate}
						setTime={setTime}
						setDeliveryTimezone={setDeliveryTimezone}
					/>
				</>
			)}
			{index === 1 && (
				<GiftDetailsTab
					viewOnly={manageCardState.isContributionEnabled && manageCardState.contribution !== 0}
					recipientFirstName={recipientFirstName.trim().split(' ')[0]}
					recipientLastName={recipientLastName}
					isContributionEnabled={isContributionEnabled}
					contribution={contribution}
					collectedAmount={manageCardState.collectedAmount}
					yourContribution={manageCardState.yourContribution}
					deliveredAt={manageCardState.deliveredAt}
					setIsContributionEnabled={setIsContributionEnabled}
					setContribution={setContribution}
				/>
			)}
			{index === 2 && (
				<ContributorsTab
					isLoading={isLoading}
					recipientFirstName={recipientFirstName}
					reminderMessage={reminderMessage}
					contributors={contributors}
					handleSave={handleSave}
					setContributors={setContributors}
					setReminderMessage={setReminderMessage}
				/>
			)}
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					flexShrink: 0,
				}}
			>
				{index < 2 && (
					<>
						{index === 1 && width < 992 && manageCardState.isContributionEnabled && canWithdraw && (
							<Button
								loading={isLoading}
								disabled={!canWithdraw || isLoading || manageCardState.withdrawalLocked}
								style={{ width: width < 992 ? '70%' : '50%' }}
								onClick={() => props.setShowWithdrawModal(true)}
							>
								Withdraw
							</Button>
						)}
						{index === 1 &&
							width < 992 &&
							!manageCardState.isContributionEnabled &&
							manageCardState.deliveredAt === null && (
								<Button
									loading={isLoading}
									disabled={!canProceed || isLoading}
									style={{ width: width < 992 ? '70%' : '50%' }}
									onClick={handleNext}
								>
									Update
								</Button>
							)}
						{index === 1 && width < 992 && !canWithdraw && !manageCardState.isContributionEnabled && (
							<Button
								loading={isLoading}
								style={{
									position: 'relative',
									marginTop: width < 992 ? 16 : undefined,
									width: width < 992 ? '70%' : '50%',
								}}
								onClick={() => dispatch(uiActions.openShareModal({ noContributors: true }))}
							>
								<img
									style={{ position: 'absolute', left: 16, width: 24, height: 24 }}
									src={ShareIcon}
									alt="Share"
								/>
								Share this Card
							</Button>
						)}
						{(width >= 992 || index !== 1) && (
							<Button
								loading={isLoading}
								disabled={!canProceed || isLoading}
								style={{ width: width < 992 ? '70%' : '50%' }}
								onClick={handleNext}
							>
								{index === 1 &&
								manageCardState.isContributionEnabled &&
								manageCardState.contribution !== 0
									? 'Share this Card'
									: 'Update'}
							</Button>
						)}
						{width < 992 && (
							<>
								<Box height={8} />
								<div
									style={{
										padding: '0 8px',
										fontSize: '1.5rem',
										display: 'flex',
										flexDirection: 'row',
										alignSelf: 'center',
										alignItems: 'center',
									}}
								>
									<span>&lt;</span>
									<Button
										style={{ height: 32, margin: '3px 8px 0', padding: 0 }}
										onClick={handleViewMyProfile}
										type={'text'}
									>
										Return to my profile
									</Button>
								</div>
							</>
						)}
					</>
				)}
				{index === 2 && (
					<>
						<Button
							style={{ position: 'relative', width: width < 992 ? '70%' : '50%' }}
							onClick={handleNext}
						>
							<img
								style={{ position: 'absolute', left: 16, width: 24, height: 24 }}
								src={ShareIcon}
								alt="Share"
							/>
							Share this Card
						</Button>
						{width < 992 && (
							<>
								<Box height={8} />
								<div
									style={{
										padding: '0 8px',
										fontSize: '1.5rem',
										display: 'flex',
										flexDirection: 'row',
										alignSelf: 'center',
										alignItems: 'center',
									}}
								>
									<span>&lt;</span>
									<Button
										style={{ height: 32, margin: '3px 8px 0', padding: 0 }}
										onClick={handleViewMyProfile}
										type={'text'}
									>
										Return to my profile
									</Button>
								</div>
							</>
						)}
					</>
				)}
			</div>
		</>
	);
};

export default RightPanel;
