import React from 'react';
import SocketContext from '../../../context/SocketContext';
import { Font } from '../../../declarations';
import SignaturePosition from '../../../models/SignaturePosition';
import { useManageCardState, useSessionState, useSignACardState } from '../../../store/selectors';
import getFonts from '../../../utils/getFonts';
import SignatureType from '../../../utils/SignatureType';
import Box from '../Box';
import styles from './index.module.scss';

interface Props {
	cardId: string;
	mode: 'sign' | 'view';
	signatureId: string;
	style?: React.CSSProperties;
	name: string | null;
	zoom: number;
	inEditingMode: boolean;
	moderate?: boolean;
	setPosition: (id: string, position: SignaturePosition) => void;
}

export const SIGNATURE_MINIMUM_WIDTH = 150;
export const SIGNATURE_EMOJI_MINIMUM_WIDTH = 40;
export const SIGNATURE_MINIMUM_HEIGHT = 100;
export const SIGNATURE_TEXT_MINIMUM_HEIGHT = 60;

const ComputeSignature: React.FC<Props> = (props: Props) => {
	const signACardState = useSignACardState();
	const manageCardState = useManageCardState();
	const sessionState = useSessionState();

	const { socket } = React.useContext(SocketContext);

	const signatureRef = React.useRef<HTMLDivElement>(null);

	const [fonts] = React.useState<Array<Font>>(getFonts());
	const [fontFamily, setFontFamily] = React.useState<Font>(getFonts()[0]);

	const [emojiFontSize, setEmojiFontSize] = React.useState<number>(150);

	const signature = React.useMemo(() => {
		if (props.mode === 'sign') {
			return signACardState.signatures.find((element) => element.id === props.signatureId);
		}

		return manageCardState.signatures.find((element) => element.id === props.signatureId);
	}, [signACardState.signatures, manageCardState.signatures]);

	const mediaType = React.useMemo<string>(() => {
		if (!signature) {
			return 'unknown';
		}

		if (signature.media) {
			if (
				signature.media.url.split('.').pop()!.toLowerCase().includes('jpg') ||
				signature.media.url.split('.').pop()!.toLowerCase().includes('png') ||
				signature.media.url.split('.').pop()!.toLowerCase().includes('jpeg') ||
				signature.media.url.split('.').pop()!.toLowerCase().includes('gif')
			) {
				return 'image';
			} else if (
				signature.media.url.split('.').pop()!.toLowerCase().includes('mp4') ||
				signature.media.url.split('.').pop()!.toLowerCase().includes('mov') ||
				signature.media.url.split('.').pop()!.toLowerCase().includes('3gp')
			) {
				return 'video';
			}
		}

		return 'unknown';
	}, [signature?.media]);

	React.useEffect(() => {
		if (!signature) {
			return;
		}

		setEmojiFontSize(signature.position.width);
	}, [signatureRef.current, signature?.position]);

	React.useEffect(() => {
		if (!signatureRef.current || !signature) {
			return;
		}

		console.log(signatureRef.current.clientHeight);
		// setTimeout(() => {
		// if (!signatureRef.current || !signature) {
		// return;
		// }
		//
		props.setPosition(signature.id, {
			x: signature.position.x,
			y: signature.position.y,
			width: signatureRef.current.clientWidth,
			height: signatureRef.current.clientHeight,
			rotation: 0,
		});

		if (socket !== null && socket !== undefined) {
			socket.emit(
				'signature:move',
				props.cardId,
				sessionState.sessionId,
				signature.id,
				{
					x: signature.position.x,
					y: signature.position.y,
					width: signatureRef.current.clientWidth,
					height: signatureRef.current.clientHeight,
					rotation: 0,
				} as SignaturePosition,
				props.mode === 'view'
			);
		}

		setEmojiFontSize(signatureRef.current.clientWidth);
		// }, 50);
	}, [signatureRef.current?.clientWidth, signature?.meta?.message]);

	React.useEffect(() => {
		if (!signature) {
			return;
		}

		if (signature.meta) {
			setFontFamily(fonts.find((element) => element.name === signature.meta!.fontFamily)!);
		}
	}, [fonts, signature?.meta]);

	const message = React.useMemo(() => {
		if (!signature) {
			return '';
		}

		let text = '';
		if (signature.typeId === SignatureType.TEXT) {
			text = `${signature.meta ? signature.meta.message : ''}\n\n${
				props.name === null || props.name.trim() === '' ? (props.inEditingMode ? 'Your Name' : '') : props.name
			}`.trim();
		} else if (signature.typeId === SignatureType.EMOJI) {
			text = signature.media ? signature.media.url : '';
		}

		return text;
	}, [props.name, props.inEditingMode, signature?.typeId, signature?.meta, signature?.media]);

	if (!signature) {
		return null;
	}

	return (
		<div
			className={`${styles.container} ${props.moderate ? styles.containerModerate : ''}`}
			style={Object.assign({ width: signature.position.width, height: 'initial' }, props.style)}
			ref={signatureRef}
		>
			{signature.typeId !== SignatureType.EMOJI && signature.user !== null && (
				<div
					style={{ userSelect: 'none' }}
					className={`${styles.header} ${props.moderate ? styles.headerModerate : ''}`}
				>
					{`${signature.user.firstName} ${signature.user.lastName}`}
				</div>
			)}
			{signature.typeId === SignatureType.TEXT && <Box height={8} />}
			<div className={styles.content}>
				{signature.typeId !== SignatureType.TEXT && signature.typeId !== SignatureType.EMOJI && (
					<>
						{mediaType === 'image' && (
							<img
								style={{
									pointerEvents: 'none',
									width: '100%',
									height: '100%',
									objectFit: 'contain',
								}}
								src={signature.media!.url}
								alt={'Media'}
							/>
						)}
						{mediaType === 'video' && (
							<video
								controls
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'contain',
								}}
							>
								<source src={signature.media!.url} />
							</video>
						)}
					</>
				)}
				{(signature.typeId === SignatureType.TEXT || signature.typeId === SignatureType.EMOJI) && (
					<div
						style={{
							alignSelf: undefined,
							pointerEvents: 'none',
							overflow: 'hidden',
							width: '100%',
							whiteSpace: 'pre-wrap',
							fontFamily: fontFamily.fontFamily,
							...(signature.typeId === SignatureType.EMOJI
								? {
										fontSize: `${emojiFontSize}px`,
										lineHeight: `${emojiFontSize}px`,
								  }
								: {}),
							...(signature.meta
								? {
										fontSize: `${signature.meta.fontSize}px`,
										lineHeight: `${signature.meta.fontSize + 4}px`,
										fontWeight: signature.meta.isBold ? 'bold' : 'normal',
										fontStyle: signature.meta.isItalic ? 'italic' : 'normal',
										textDecoration: signature.meta.isUnderline ? 'underline' : 'initial',
										color: signature.meta.color,
										textAlign: signature.meta.alignment,
								  }
								: {}),
						}}
					>
						{message}
					</div>
				)}
			</div>
		</div>
	);
};

export default ComputeSignature;
