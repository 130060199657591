import React from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Box from '../Box';
import Button from '../Button';
import Typography from '../Typography';
import styles from './index.module.scss';

interface Props {
	viewOnly?: boolean;
}

const SizeSpecificationModal: React.FC<Props> = () => {
	const { width } = useWindowDimensions();

	const handleRedirect = React.useCallback(() => {
		const newWindow = window.open(`https://tinyjpg.com`, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = null;
	}, []);

	return (
		<div className={styles.container}>
			<Typography variant="h3" style={{ textAlign: 'center' }}>
				Custom card upload size specfications
			</Typography>
			<Box height={width < 992 ? 16 : 32} />
			<Typography variant="body1" style={{ textAlign: 'center' }}>
				Follow the size guidelines below to ensure your design comes out crisp!
			</Typography>
			<Box height={32} />
			<table style={{ width: '100%' }}>
				<tr>
					<td style={{ textAlign: 'center' }}>
						<Typography style={{ fontWeight: 'bold' }} variant="caption">
							Card ratio
						</Typography>
					</td>
					<td style={{ textAlign: 'center' }}>
						<Typography style={{ fontWeight: 'bold' }} variant="caption">
							Minimum dimensions
						</Typography>
					</td>
				</tr>
				<tr style={{ background: '#f3f3f7', padding: '15px 32px' }}>
					<td style={{ padding: width < 992 ? '15px 16px' : '15px 32px', textAlign: 'center' }}>
						<Typography style={{ fontWeight: 'bold' }} variant="body1">
							3:4
						</Typography>
					</td>
					<td style={{ padding: width < 992 ? '15px 16px' : '15px 32px', textAlign: 'center' }}>
						<Typography variant="body1">600px w x 800px h</Typography>
					</td>
				</tr>
			</table>
			<Box height={32} />
			<Typography variant="h6" style={{ textAlign: 'center' }}>
				Maximum file size: 10MB
			</Typography>
			<Typography variant="body1" style={{ textAlign: 'center' }}>
				Need help reducing your file size?
			</Typography>
			<Button type="text" style={{ flexShrink: 0 }} onClick={handleRedirect}>
				Try tinyjpg
			</Button>
		</div>
	);
};

export default SizeSpecificationModal;
