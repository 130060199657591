import React from 'react';
import Card from '../../../models/Card';
import CardTableEntry from '../CardTableEntry';
import styles from './index.module.scss';

interface Props {
	handleCardClick: (id: string) => void;
	cards: Card[];
	selectedDesign: string;
	setShowWithdrawModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardsTable: React.FC<Props> = (props: Props) => {
	return (
		<table className={styles.cardsTable}>
			<tbody>
				{props.cards.map((element) => (
					<CardTableEntry
						isActive={props.selectedDesign === element.uuid}
						key={element.id}
						card={element}
						onClick={props.handleCardClick}
						setShowWithdrawModal={props.setShowWithdrawModal}
					/>
				))}
			</tbody>
		</table>
	);
};

export default CardsTable;
