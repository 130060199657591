import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import Setting from '../../models/Setting';
import settingsAsyncActions from '../actions/settings.action';
import postRequest from '../postRequest';
import { CPA } from '../types';
import { requestActions } from './request.slice';
import { uiActions } from './ui.slice';

export interface SettingsState {
	creationFee: number;
	withdrawalFee: number;
	updatedAt: number;
}

const initialState: SettingsState = {
	creationFee: 0.0,
	withdrawalFee: 0.0,
	updatedAt: moment().valueOf(),
};

const slice = createSlice({
	name: 'settings',
	initialState,
	reducers: {},
	extraReducers: {
		[settingsAsyncActions.getSettings.fulfilled.type]: (state, action: CPA<Array<Setting>>) => {
			for (const setting of action.payload) {
				if (setting.name === 'creation_fee') {
					state.creationFee = parseFloat(setting.value);
					continue;
				}

				if (setting.name === 'withdrawal_fee') {
					state.withdrawalFee = parseFloat(setting.value);
					continue;
				}
			}
			state.updatedAt = moment().valueOf();

			postRequest(action);
		},
		[settingsAsyncActions.getSettings.rejected.type]: (state, action) => {
			if (action.payload.error.status === 401) {
				action.dispatch(uiActions.openSignInModal({}));
			}

			action.dispatch(
				requestActions.rejected({
					name: action.type,
					message: '',
					payload: {},
				})
			);

			return state;
		},
	},
});

export const settingsActions = slice.actions;

export default slice.reducer;
