import moment from 'moment';
import React from 'react';
import Typography from '../Typography';
import styles from './index.module.scss';

interface Props {
	deliveryAt: string;
	deliveryTimezone: string;
	deliveredAt: string | null;
	style?: React.CSSProperties;
}

const TopTimer: React.FC<Props> = (props: Props) => {
	const [timer, setTimer] = React.useState<string>('');

	React.useEffect(() => {
		const cardDate = moment.utc(`${props.deliveryAt}`, 'DD/MM/YYYY hh:mm A');
		cardDate.utcOffset(props.deliveryTimezone.split(' ')[0].replace(/UTC|/, ''), true);

		const interval = setInterval(() => {
			const now = moment().valueOf();
			const distance = cardDate.valueOf() - now;
			const days = Math.floor(distance / (1000 * 60 * 60 * 24));
			const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

			if (distance < 0) {
				clearTimeout(interval);
			} else {
				setTimer(
					(days < 10 ? `0${days}` : days.toString()) +
						' days ' +
						(hours < 10 ? `0${hours}` : hours.toString()) +
						' hours ' +
						(minutes < 10 ? `0${minutes}` : minutes.toString()) +
						' minutes.'
				);
			}
		}, 1000);

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [props.deliveryAt, props.deliveryTimezone]);

	return (
		<div style={props.style} className={styles.container}>
			<Typography variant="caption" style={{ color: '#FFFFFF', textAlign: 'center', textTransform: 'uppercase' }}>
				{timer !== '' && (
					<>
						Time left to sign: <span style={{ fontWeight: 'bold' }}>{timer}</span>
					</>
				)}
				{props.deliveredAt !== null && (
					<>
						Delivered at: <span style={{ fontWeight: 'bold' }}>{props.deliveredAt}</span>
					</>
				)}
			</Typography>
		</div>
	);
};

export default TopTimer;
