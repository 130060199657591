import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAuthenticationState } from '../../../store/selectors';
import { uiActions } from '../../../store/slices/ui.slice';
import Box from '../Box';
import Hamburger from '../Hamburger';
import Typography from '../Typography';
import Logo from './../../../logo.svg';
import styles from './index.module.scss';

interface Props {
	title?: React.ReactNode | string;
	subTitle?: React.ReactNode | string;
	style?: React.CSSProperties;
	titleStyle?: React.CSSProperties;
	subTitleStyle?: React.CSSProperties;
	relative?: boolean;
	right?: React.ReactNode;
	hideAuthentication?: boolean;
	subTitleClick?: () => void;
}

const Header: React.FC<Props> = ({
	style = {},
	titleStyle = {},
	subTitleStyle = {},
	subTitleClick = () => {},
	hideAuthentication = false,
	...props
}: Props) => {
	const dispatch = useDispatch();
	const authenticationState = useAuthenticationState();

	const handleSignIn = React.useCallback(() => {
		dispatch(uiActions.openSignInModal({}));
	}, [dispatch]);

	const handleSignUp = React.useCallback(() => {
		dispatch(uiActions.openSignUpModal({}));
	}, [dispatch]);

	return (
		<header
			style={Object.assign(props.relative ? { position: 'relative' } : {}, style)}
			className={styles.container}
		>
			<Link to={'/'}>
				<img src={Logo} alt={'Sign the card'} />
			</Link>
			<div style={{ width: 18 }} />
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				{props.title !== undefined && props.title !== '' && typeof props.title === 'string' && (
					<Typography style={titleStyle} variant="h4">
						{props.title}
					</Typography>
				)}
				{props.subTitle !== undefined && props.subTitle !== '' && typeof props.subTitle === 'string' && (
					<div onClick={subTitleClick}>
						<Typography style={subTitleStyle} variant="body1">
							{props.subTitle}
						</Typography>
					</div>
				)}
				{props.title !== undefined && props.title !== '' && typeof props.title !== 'string' && props.title}
				{props.subTitle !== undefined &&
					props.subTitle !== '' &&
					typeof props.subTitle !== 'string' &&
					props.subTitle}
			</div>
			<div style={{ flexGrow: 1 }} />
			{!authenticationState.isAuthenticated && !hideAuthentication && (
				<>
					<button onClick={handleSignIn} className={styles.button}>
						Login
					</button>
					<button onClick={handleSignUp} className={styles.button}>
						Register
					</button>
				</>
			)}
			{props.right}
			{!hideAuthentication && <Box width={18} />}
			<button onClick={() => dispatch(uiActions.openNavigationModal({}))} className={styles.button}>
				<Hamburger />
			</button>
		</header>
	);
};

export default Header;
