import { LS } from '@cyboticx/common';
import { createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { API, Timing } from 'lib/util';
import moment from 'moment';
import AuthenticationResponse from '../../network/responses/AuthenticationResponse';
import ErrorResponse from '../../network/responses/ErrorResponse';
import authenticationAsyncActions from '../actions/authentication.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';
import { CPA } from '../types';
import { requestActions } from './request.slice';
import { uiActions } from './ui.slice';

export interface AuthenticationState {
	isAuthenticated: null | boolean;
	emailError: string | null;
	passwordError: string | null;
	accessToken: string;
	expiryAt: number;
	updatedAt: number;
}

const initialState: AuthenticationState = {
	isAuthenticated: null,
	emailError: null,
	passwordError: null,
	accessToken: '',
	expiryAt: -1,
	updatedAt: moment().valueOf(),
};

const fillState = (state: WritableDraft<typeof initialState>, action: CPA<AuthenticationResponse>) => {
	state.isAuthenticated = true;
	state.accessToken = action.payload.accessToken;
	state.expiryAt = action.payload.expiryAt;
	state.updatedAt = Timing.now();

	LS.addAccessToken(action.payload.accessToken);
	API.addAccessToken(action.payload.accessToken);

	action.dispatch(uiActions.closeSignInModal());
	action.dispatch(uiActions.closeSignUpModal());
	action.dispatch(
		requestActions.fulfilled({
			name: action.type,
			message: '',
			payload: {},
		})
	);
};

const slice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		restoreAccessToken: (state: WritableDraft<typeof initialState>) => {
			API.addAccessToken(state.accessToken === '' ? undefined : state.accessToken);
		},
		removeAuthState: () => {
			API.removeAccessToken();
			return initialState;
		},
	},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: fillState,
		[authenticationAsyncActions.signIn.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signInWithSocial.fulfilled.type]: fillState,
		[authenticationAsyncActions.signInWithSocial.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signUp.fulfilled.type]: fillState,
		[authenticationAsyncActions.signUp.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signUpWithSocial.fulfilled.type]: fillState,
		[authenticationAsyncActions.signUpWithSocial.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.forgotPassword.fulfilled.type]: (_, action) => postRequest(action),
		[authenticationAsyncActions.forgotPassword.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.resetPassword.fulfilled.type]: fillState,
		[authenticationAsyncActions.resetPassword.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signOut.fulfilled.type]: (_, action) => {
			LS.removeAccessToken();
			API.removeAccessToken();

			postRequest(action);

			return initialState;
		},
		[authenticationAsyncActions.signOut.rejected.type]: (state, action) => {
			LS.removeAccessToken();
			API.removeAccessToken();

			return postErrorRequest(state, action, initialState);
		},
	},
});

export const authenticationActions = slice.actions;

export default slice.reducer;
