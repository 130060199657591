import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { API } from 'lib/util';
import { RootState } from '..';
import Card from '../../models/Card';
import CreateCardRequest from '../../network/requests/CreateCardRequest';
import PublishCardRequest from '../../network/requests/PublishCardRequest';
import { CreateCardState } from '../slices/createCard.slice';
import { requestActions } from '../slices/request.slice';
import { SessionState } from '../slices/session.slice';

const store = createAsyncThunk('create-card/store', async (_, thunkApi) => {
	thunkApi.dispatch(requestActions.started(store.typePrefix));
	try {
		const createCardState: CreateCardState = (thunkApi.getState() as RootState).createCard;
		const sessionState: SessionState = (thunkApi.getState() as RootState).session;

		const response = await API.post<CreateCardRequest, AxiosResponse<Card>>('/card', {
			sessionId: sessionState.sessionId,
			templateId: createCardState.template ? createCardState.template.id : null,
			recipientFirstName: createCardState.recipientFirstName,
			recipientLastName: createCardState.recipientLastName,
			recipientEmail: createCardState.recipientEmail,
			image: createCardState.image,
			isContributionEnabled: createCardState.isContributionEnabled,
			contribution: createCardState.contribution,
			contributors: createCardState.contributors.map((element) => element.email),
			deliveryAt: createCardState.deliveryAt,
			deliveryTimezone: createCardState.deliveryTimezone,
			isReminderEnabled: createCardState.isReminderEnabled,
			reminderMessage: createCardState.reminderMessage,
		});

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ error });
	}
});

const publish = createAsyncThunk('create-card/publish', async (_, thunkApi) => {
	thunkApi.dispatch(requestActions.started(publish.typePrefix));
	try {
		const createCard: CreateCardState = (thunkApi.getState() as RootState).createCard;

		const response = await API.post<Omit<PublishCardRequest, 'id'>, AxiosResponse<Card>>(
			`/card/${createCard.id}/publish`,
			{
				isReminderEnabled: createCard.isReminderEnabled,
				reminderMessage: createCard.reminderMessage,
			}
		);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ error });
	}
});

const createCardAsyncActions = {
	store,
	publish,
};

export default createCardAsyncActions;
