import React from 'react';
import { usePopper } from 'react-popper';
import Typography from '../Typography';
import styles from './index.module.scss';

interface Props {
	className?: string;
	name?: string;
	style?: React.CSSProperties;
	withArrows?: boolean;
	width?: number | 'auto' | 'full';
	isBold: boolean;
	isUnderline: boolean;
	isItalic: boolean;
	setIsBold: (value: boolean) => void;
	setIsUnderline: (value: boolean) => void;
	setIsItalic: (value: boolean) => void;
	onClick?: () => void;
	onFocus?: () => void;
	onBlur?: () => void;
}

const FontStyleDropdown: React.FC<Props> = ({ width = 'full', ...props }: Props) => {
	const mainRef = React.useRef<HTMLDivElement | null>(null);
	const ref = React.useRef<HTMLInputElement>(null);
	const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);
	const [popperRef, setPopperRef] = React.useState<HTMLDivElement | null>(null);
	const [arrowRef, setArrowRef] = React.useState<HTMLDivElement | null>(null);
	const { styles: popperStyles, attributes } = usePopper(containerRef, popperRef, {
		modifiers: [{ name: 'arrow', options: { element: arrowRef } }],
		placement: 'auto-start',
	});
	const [showDropdown, setShowDropdown] = React.useState<boolean>(false);

	React.useEffect(() => {
		const mouseUp = (event: any) => {
			if (mainRef.current && !mainRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		};

		document.addEventListener('mouseup', mouseUp);

		return () => {
			document.removeEventListener('mouseup', mouseUp);
		};
	}, [mainRef]);

	React.useEffect(() => {
		const focusIn = () => {
			setShowDropdown(true);
			if (props.onFocus) {
				props.onFocus();
			}
		};
		const focusOut = () => {
			if (props.onBlur) {
				props.onBlur();
			}
		};

		if (ref.current) {
			ref.current.addEventListener('focusin', focusIn);
			ref.current.addEventListener('focusout', focusOut);
		}

		return () => {
			if (ref.current) {
				ref.current.removeEventListener('focusin', focusIn);
				ref.current.removeEventListener('focusout', focusOut);
			}
		};
	}, [ref, props.onFocus, props.onBlur]);

	return (
		<div ref={mainRef} style={{ position: 'relative', width: typeof width === 'number' ? width : 'initial' }}>
			<div ref={setContainerRef} className={styles.container}>
				<div
					style={props.style}
					className={`${styles.inputContainer} ${props.className ? props.className : ''}`}
				>
					<div
						onClick={() => {
							setShowDropdown(true);

							if (props.onClick) {
								props.onClick();
							}
						}}
						style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
					>
						<input
							style={{
								fontWeight: props.isBold ? 'bold' : 400,
								fontStyle: props.isItalic ? 'italic' : 'normal',
								textDecoration: props.isUnderline ? 'underline' : 'initial',
							}}
							disabled={true}
							name={props.name}
							value={'T'}
							className={styles.input}
							ref={ref}
							type="text"
						/>
					</div>
				</div>
			</div>
			<div
				className={`${styles.dropdownContainer} ${!showDropdown ? styles.hidden : ''}`}
				ref={setPopperRef}
				style={popperStyles.popper}
				{...attributes.popper}
			>
				<div
					style={{ width: typeof width === 'number' ? width : 'initial' }}
					onClick={() => props.setIsBold(!props.isBold)}
					className={`${styles.dropdownItem} ${props.isBold ? styles.dropdownItemActive : ''}`}
				>
					<Typography style={{ fontWeight: 'bold' }} variant="body2">
						Bold
					</Typography>
				</div>
				<div
					style={{ width: typeof width === 'number' ? width : 'initial' }}
					onClick={() => props.setIsItalic(!props.isItalic)}
					className={`${styles.dropdownItem} ${props.isItalic ? styles.dropdownItemActive : ''}`}
				>
					<Typography style={{ fontWeight: 'normal', fontStyle: 'italic' }} variant="body2">
						Italic
					</Typography>
				</div>
				<div
					style={{ width: typeof width === 'number' ? width : 'initial' }}
					onClick={() => props.setIsUnderline(!props.isUnderline)}
					className={`${styles.dropdownItem} ${props.isUnderline ? styles.dropdownItemActive : ''}`}
				>
					<Typography style={{ fontWeight: 'normal', textDecoration: 'underline' }} variant="body2">
						Underline
					</Typography>
				</div>
				<div ref={setArrowRef} style={popperStyles.arrow} />
			</div>
		</div>
	);
};

export default FontStyleDropdown;
