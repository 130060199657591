import React from 'react';
import { usePopper } from 'react-popper';
import { TextAlignment } from '../../../declarations';
import CenterAlignmentIcon from './../../../img/ic-align-center.svg';
import JustifyAlignmentIcon from './../../../img/ic-align-justify.svg';
import LeftAlignmentIcon from './../../../img/ic-align-left.svg';
import RightAlignmentIcon from './../../../img/ic-align-right.svg';
import styles from './index.module.scss';

interface Props {
	value: TextAlignment;
	className?: string;
	name?: string;
	style?: React.CSSProperties;
	withArrows?: boolean;
	width?: number | 'auto' | 'full';
	onChange: (value: TextAlignment) => void;
	onClick?: () => void;
	onFocus?: () => void;
	onBlur?: () => void;
}

const AlignmentDropdown: React.FC<Props> = ({ width = 'full', ...props }: Props) => {
	const mainRef = React.useRef<HTMLDivElement | null>(null);
	const ref = React.useRef<HTMLInputElement>(null);
	const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);
	const [popperRef, setPopperRef] = React.useState<HTMLDivElement | null>(null);
	const [arrowRef, setArrowRef] = React.useState<HTMLDivElement | null>(null);
	const { styles: popperStyles, attributes } = usePopper(containerRef, popperRef, {
		modifiers: [{ name: 'arrow', options: { element: arrowRef } }],
		placement: 'auto-start',
	});
	const [showDropdown, setShowDropdown] = React.useState<boolean>(false);

	const handleChange = React.useCallback(
		(value: TextAlignment) => {
			setShowDropdown(false);
			props.onChange(value);
		},
		[props.onChange]
	);

	React.useEffect(() => {
		const mouseUp = (event: any) => {
			if (mainRef.current && !mainRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		};

		document.addEventListener('mouseup', mouseUp);

		return () => {
			document.removeEventListener('mouseup', mouseUp);
		};
	}, [mainRef]);

	React.useEffect(() => {
		const focusIn = () => {
			setShowDropdown(true);
			if (props.onFocus) {
				props.onFocus();
			}
		};
		const focusOut = () => {
			if (props.onBlur) {
				props.onBlur();
			}
		};

		if (ref.current) {
			ref.current.addEventListener('focusin', focusIn);
			ref.current.addEventListener('focusout', focusOut);
		}

		return () => {
			if (ref.current) {
				ref.current.removeEventListener('focusin', focusIn);
				ref.current.removeEventListener('focusout', focusOut);
			}
		};
	}, [ref, props.onFocus, props.onBlur]);

	return (
		<div ref={mainRef} style={{ position: 'relative', width: typeof width === 'number' ? width : 'initial' }}>
			<div ref={setContainerRef} className={styles.container}>
				<div
					style={props.style}
					className={`${styles.inputContainer} ${props.className ? props.className : ''}`}
				>
					<div
						onClick={() => {
							setShowDropdown(true);

							if (props.onClick) {
								props.onClick();
							}
						}}
						style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
					>
						<img
							className={styles.alignmentIcon}
							src={
								props.value === 'left'
									? LeftAlignmentIcon
									: props.value === 'center'
									? CenterAlignmentIcon
									: props.value === 'right'
									? RightAlignmentIcon
									: JustifyAlignmentIcon
							}
							alt=""
						/>
					</div>
				</div>
			</div>
			<div
				className={`${styles.dropdownContainer} ${!showDropdown ? styles.hidden : ''}`}
				ref={setPopperRef}
				style={popperStyles.popper}
				{...attributes.popper}
			>
				<div
					style={{ width: typeof width === 'number' ? width : 'initial' }}
					onClick={() => handleChange('left')}
					className={`${styles.dropdownItem} ${props.value === 'left' ? styles.dropdownItemActive : ''}`}
				>
					<img className={styles.alignmentIcon} src={LeftAlignmentIcon} alt="Left" />
				</div>
				<div
					style={{ width: typeof width === 'number' ? width : 'initial' }}
					onClick={() => handleChange('center')}
					className={`${styles.dropdownItem} ${props.value === 'center' ? styles.dropdownItemActive : ''}`}
				>
					<img className={styles.alignmentIcon} src={CenterAlignmentIcon} alt="Center" />
				</div>
				<div
					style={{ width: typeof width === 'number' ? width : 'initial' }}
					onClick={() => handleChange('right')}
					className={`${styles.dropdownItem} ${props.value === 'right' ? styles.dropdownItemActive : ''}`}
				>
					<img className={styles.alignmentIcon} src={RightAlignmentIcon} alt="Right" />
				</div>
				{false && (
					<div
						style={{ width: typeof width === 'number' ? width : 'initial' }}
						onClick={() => handleChange('justify')}
						className={`${styles.dropdownItem} ${
							props.value === 'justify' ? styles.dropdownItemActive : ''
						}`}
					>
						<img className={styles.alignmentIcon} src={JustifyAlignmentIcon} alt="Justify" />
					</div>
				)}
				<div ref={setArrowRef} style={popperStyles.arrow} />
			</div>
		</div>
	);
};

export default AlignmentDropdown;
