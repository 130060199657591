import React, { ReactNode } from 'react';
import Box from '../../lib/components/Box';
import Typography from '../../lib/components/Typography';
import { useSignACardState } from '../../store/selectors';
import CheckIcon from './../../img/ic-check.svg';
import DollarIcon from './../../img/ic-dollar.svg';
import styles from './index.module.scss';

interface Props {
	recipientFirstName: string;
	recipientLastName: string;
}

const GreetingTab: React.FC<Props> = (props: Props) => {
	const signACardState = useSignACardState();

	const [contribution, setContribution] = React.useState<number>(signACardState.yourContribution);

	const contributionText = React.useMemo<ReactNode>(() => {
		if (contribution > 0) {
			return (
				<>
					, and your <span style={{ fontWeight: 'bold' }}>${contribution}</span> contribution to{' '}
					{props.recipientFirstName} {props.recipientLastName}`s gift
				</>
			);
		}

		return null;
	}, [props.recipientFirstName, contribution]);

	React.useEffect(() => {
		setContribution(signACardState.yourContribution);
	}, [signACardState.yourContribution]);

	return (
		<div style={{ alignItems: 'center', justifyContent: 'center' }} className={styles.formContainer}>
			<div className={styles.greetingCardContainer}>
				<img style={{ right: 12 }} className={styles.greetingCardIcon} src={CheckIcon} alt="" />
				{contribution > 0 && (
					<img style={{ right: 48 }} className={styles.greetingCardIcon} src={DollarIcon} alt="" />
				)}
				<img
					style={{
						borderRadius: '8px',
						boxShadow: '0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
						// width: width < 992 ? 'calc(40vh * .75)' : 'calc(40vh * .75)',
						// height: width < 992 ? '40vh' : '40vh'
						width: '100%',
						height: '100%',
						objectFit: 'cover',
					}}
					src={signACardState.image}
					alt={'Card Preview'}
				/>
			</div>
			<Box height={48} />
			<Typography style={{ textAlign: 'center' }} variant="h3">
				You’ve Signed the Card!
			</Typography>
			<Box height={12} />
			<Typography style={{ textAlign: 'center', fontStyle: 'italic' }} variant="body1">
				Thank you for signing {props.recipientFirstName} {props.recipientLastName}`s card{contributionText}.
			</Typography>
		</div>
	);
};

export default GreetingTab;
