import { createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import authenticationAsyncActions from '../actions/authentication.action';

export interface SessionState {
	sessionId: string;
	updatedAt: number;
}

const initialState: SessionState = {
	sessionId: uuidv4(),
	updatedAt: moment().valueOf(),
};

const slice = createSlice({
	name: 'session',
	initialState,
	reducers: {
		regenerate: (state: WritableDraft<typeof initialState>) => {
			const lastUpdatedAt = localStorage.getItem('sessionUpdatedAt');
			if (lastUpdatedAt) {
				const time = moment.unix(parseInt(lastUpdatedAt) / 1000);

				if (moment.utc().diff(time, 'seconds') < 60 * 60 * 24) {
					return;
				}
			}

			const updatedAt = moment.utc().valueOf();

			localStorage.setItem('sessionUpdatedAt', updatedAt.toString());

			state.sessionId = uuidv4();
			state.updatedAt = updatedAt;
		},
	},
	extraReducers: {
		[authenticationAsyncActions.signOut.fulfilled.type]: (state) => {
			const updatedAt = moment.utc().valueOf();

			localStorage.setItem('sessionUpdatedAt', updatedAt.toString());

			state.sessionId = uuidv4();
			state.updatedAt = updatedAt;
		},
		[authenticationAsyncActions.signOut.rejected.type]: (state) => {
			const updatedAt = moment.utc().valueOf();

			localStorage.setItem('sessionUpdatedAt', updatedAt.toString());

			state.sessionId = uuidv4();
			state.updatedAt = updatedAt;
		},
	},
});

export const sessionActions = slice.actions;

export default slice.reducer;
