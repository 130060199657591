import arrayEquals from './arrayEquals';

const checkPoint = require('robust-point-in-polygon');

interface Point {
	x: number;
	y: number;
}

const checkIntersection = (a: Point[], b: Point[]) => {
	if (arrayEquals(a, b) || arrayEquals(b, a)) {
		return false;
	}

	let polygon = [
		[a[0].x, a[0].y],
		[a[2].x, a[2].y],
		[a[3].x, a[3].y],
		[a[1].x, a[1].y],
	];

	for (const point of b) {
		if (checkPoint(polygon, [point.x, point.y]) !== 1) {
			return true;
		}
	}

	polygon = [
		[b[0].x, b[0].y],
		[b[2].x, b[2].y],
		[b[3].x, b[3].y],
		[b[1].x, b[1].y],
	];

	for (const point of a) {
		if (checkPoint(polygon, [point.x, point.y]) !== 1) {
			return true;
		}
	}

	return false;
};

export default checkIntersection;
