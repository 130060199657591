import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { API } from 'lib/util';
import CardCategory from '../../models/CardCategory';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('card-category/index', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<never, AxiosResponse<CardCategory[]>>(`/card/categories`);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const cardCategoryAsyncActions = {
	index,
};

export default cardCategoryAsyncActions;
