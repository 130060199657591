import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'lib/util';
import { RootState } from '..';
import { SocialTypeId } from '../../utils/SocialTypeId';
import { requestActions } from '../slices/request.slice';
import { SessionState } from '../slices/session.slice';

const signIn = createAsyncThunk(
	'authentication/sign-in',
	async (payload: { email: string; password: string }, thunkApi) => {
		thunkApi.dispatch(requestActions.started(signIn.typePrefix));
		try {
			const sessionState: SessionState = (thunkApi.getState() as RootState).session;

			const response = await API.post('/auth/sign-in', { sessionId: sessionState.sessionId, ...payload });

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const signInWithSocial = createAsyncThunk(
	'authentication/sign-in-social',
	async (payload: { token: string; typeId: SocialTypeId }, thunkApi) => {
		thunkApi.dispatch(requestActions.started(signInWithSocial.typePrefix));
		try {
			const sessionState: SessionState = (thunkApi.getState() as RootState).session;

			const response = await API.post('/auth/sign-in-social', { sessionId: sessionState.sessionId, ...payload });

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const signUp = createAsyncThunk(
	'authentication/sign-up',
	async (payload: { firstName: string; lastName: string; email: string; password: string }, thunkApi) => {
		thunkApi.dispatch(requestActions.started(signUp.typePrefix));
		try {
			const sessionState: SessionState = (thunkApi.getState() as RootState).session;

			const response = await API.post('/auth/sign-up', { sessionId: sessionState.sessionId, ...payload });

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const signUpWithSocial = createAsyncThunk(
	'authentication/sign-up-social',
	async (
		payload: {
			firstName: string;
			lastName: string;
			email: string;
			token: string;
			typeId: SocialTypeId;
			userId: string;
		},
		thunkApi
	) => {
		thunkApi.dispatch(requestActions.started(signUpWithSocial.typePrefix));
		try {
			const sessionState: SessionState = (thunkApi.getState() as RootState).session;

			const response = await API.post('/auth/sign-up-social', { sessionId: sessionState.sessionId, ...payload });

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const forgotPassword = createAsyncThunk(
	'authentication/forgot-password',
	async (payload: { email: string }, thunkApi) => {
		thunkApi.dispatch(requestActions.started(forgotPassword.typePrefix));
		try {
			const response = await API.post('/auth/forgot-password', payload);

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const resetPassword = createAsyncThunk(
	'authentication/reset-password',
	async (payload: { code: string; password: string; passwordConfirmation: string }, thunkApi) => {
		thunkApi.dispatch(requestActions.started(resetPassword.typePrefix));
		try {
			const response = await API.post('/auth/reset-password', payload);

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const signOut = createAsyncThunk('authentication/sign-out', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(signOut.typePrefix));
	try {
		const response = await API.get('/sign-out');

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const authenticationAsyncActions = {
	signIn,
	signInWithSocial,
	signUp,
	signUpWithSocial,
	forgotPassword,
	resetPassword,
	signOut,
};

export default authenticationAsyncActions;
