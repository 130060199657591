import EmojiPicker, { IEmojiData } from 'emoji-picker-react';
import React from 'react';
import { usePopper } from 'react-popper';
import Box from '../Box';
import Button from '../Button';
import EmojiIcon from './../../../img/ic-emoji.png';
import styles from './index.module.scss';

interface Props {
	className?: string;
	style?: React.CSSProperties;
	containerStyle?: React.CSSProperties;
	width?: number | 'auto' | 'full';
	onChange?: (value: string) => void;
}

const EmojiDropdown: React.FC<Props> = ({ width = 'full', onChange = () => {}, containerStyle = {} }: Props) => {
	const mainRef = React.useRef<HTMLDivElement | null>(null);
	const containerRef = React.useRef<HTMLDivElement>(null);
	const popperRef = React.useRef<HTMLDivElement>(null);
	const { styles: popperStyles, attributes } = usePopper(containerRef.current, popperRef.current, {
		placement: 'auto-start',
	});
	const [showDropdown, setShowDropdown] = React.useState<boolean>(false);

	const handleSelect = React.useCallback(
		(_, element: IEmojiData) => {
			setShowDropdown(false);
			onChange(element.emoji);
		},
		[onChange]
	);

	React.useEffect(() => {
		const mouseUp = (event: any) => {
			if (mainRef.current && !mainRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		};

		document.addEventListener('mouseup', mouseUp);

		return () => {
			document.removeEventListener('mouseup', mouseUp);
		};
	}, [mainRef]);

	return (
		<div
			ref={mainRef}
			style={Object.assign(
				{ position: 'relative', width: typeof width === 'number' ? width : 'initial' },
				containerStyle
			)}
		>
			<div ref={containerRef} className={styles.container}>
				<Button
					style={{ padding: '0 16px', alignSelf: 'center', marginBottom: 16 }}
					type="outlined"
					onClick={() => setShowDropdown(true)}
				>
					<img style={{ objectFit: 'contain', width: 24, height: 24 }} src={EmojiIcon} alt="" />
					<Box width={12} />
					Add Emoji
				</Button>
			</div>
			<div
				className={`${styles.dropdownContainer} ${
					width === 'auto' || typeof width === 'number' ? styles.dropdownContainerAuto : ''
				} ${!showDropdown ? styles.hidden : ''}`}
				ref={popperRef}
				style={popperStyles.popper}
				{...attributes.popper}
			>
				<EmojiPicker onEmojiClick={handleSelect} />
			</div>
		</div>
	);
};

export default EmojiDropdown;
