import React from 'react';
import { Rnd } from 'react-rnd';
import SocketContext from '../../../context/SocketContext';
import { Font } from '../../../declarations';
import SignaturePosition from '../../../models/SignaturePosition';
import { OverlappingTableEntry } from '../../../screens/SignACardScreen';
import { useManageCardState, useSessionState, useSignACardState } from '../../../store/selectors';
import getFonts from '../../../utils/getFonts';
import SignatureType from '../../../utils/SignatureType';
import Box from '../Box';
import styles from './index.module.scss';

interface Props {
	cardId: string;
	mode: 'sign' | 'view';
	signatureId: string;
	style?: React.CSSProperties;
	disabled?: boolean;
	name: string | null;
	removeSignature: (id: string) => void;
	setPosition: (id: string, position: SignaturePosition) => void;
	zoom: number;
	inEditingMode: boolean;
	moderate?: boolean;
	hideRemoveButton?: boolean;
	overlappingTable: OverlappingTableEntry[];
	revalidateSignatures: (id?: string, page?: number, x?: number, y?: number, width?: number, height?: number) => void;
}

export const SIGNATURE_MINIMUM_WIDTH = 150;
export const SIGNATURE_EMOJI_MINIMUM_WIDTH = 40;
export const SIGNATURE_MINIMUM_HEIGHT = 100;
export const SIGNATURE_TEXT_MINIMUM_HEIGHT = 60;

let prevX = 0;
let prevY = 0;

const EditableSignature: React.FC<Props> = ({ disabled = false, ...props }: Props) => {
	const signACardState = useSignACardState();
	const manageCardState = useManageCardState();
	const sessionState = useSessionState();

	const { socket } = React.useContext(SocketContext);

	const rndRef = React.useRef<Rnd>(null);

	const [isAdjusting, setIsAdjusting] = React.useState<boolean>(false);

	const [fonts] = React.useState<Array<Font>>(getFonts());
	const [fontFamily, setFontFamily] = React.useState<Font>(
		// props.signature.meta
		// 	? getFonts().find((element) => element.name === props.signature.meta!.fontFamily)!
		// 	: getFonts()[0]
		getFonts()[0]
	);

	// const [emojiFontSize, setEmojiFontSize] = React.useState<number>(props.signature.position.width);
	const [emojiFontSize, setEmojiFontSize] = React.useState<number>(150);
	// const [position, setPosition] = React.useState<SignaturePosition>(props.signature.position);
	const [position, setPosition] = React.useState<SignaturePosition>({
		x: 10,
		y: 10,
		width: 150,
		height: 150,
		rotation: 0,
	});

	const signature = React.useMemo(() => {
		if (props.mode === 'sign') {
			return signACardState.signatures.find((element) => element.id === props.signatureId);
		}

		return manageCardState.signatures.find((element) => element.id === props.signatureId);
	}, [signACardState.signatures, manageCardState.signatures]);

	const mediaType = React.useMemo<string>(() => {
		if (!signature) {
			return 'unknown';
		}

		if (signature.media) {
			if (
				signature.media.url.split('.').pop()!.toLowerCase().includes('jpg') ||
				signature.media.url.split('.').pop()!.toLowerCase().includes('png') ||
				signature.media.url.split('.').pop()!.toLowerCase().includes('jpeg') ||
				signature.media.url.split('.').pop()!.toLowerCase().includes('gif')
			) {
				return 'image';
			} else if (
				signature.media.url.split('.').pop()!.toLowerCase().includes('mp4') ||
				signature.media.url.split('.').pop()!.toLowerCase().includes('mov') ||
				signature.media.url.split('.').pop()!.toLowerCase().includes('3gp')
			) {
				return 'video';
			}
		}

		return 'unknown';
	}, [signature?.media]);

	const isOverlapping = React.useMemo(() => {
		if (!signature) {
			return false;
		}

		return (
			props.overlappingTable.findIndex(
				(element) =>
					element.page === signature.page &&
					(element.signatureOneId === parseInt(signature.id, 10) ||
						element.signatureTwoId === parseInt(signature.id, 10))
			) !== -1
		);
	}, [signature?.id, props.overlappingTable]);

	React.useEffect(() => {
		if (!signature) {
			return;
		}

		if (!isAdjusting) {
			setEmojiFontSize(signature.position.width);
			rndRef.current?.updatePosition({
				x: signature.position.x,
				y: signature.position.y,
			});
			setPosition(signature.position);
		}
	}, [rndRef.current, signature?.position]);

	React.useEffect(() => {
		if (!signature) {
			return;
		}

		if (signature.meta) {
			setFontFamily(fonts.find((element) => element.name === signature.meta!.fontFamily)!);
		}
	}, [fonts, signature?.meta]);

	const message = React.useMemo(() => {
		if (!signature) {
			return '';
		}

		let text = '';
		if (signature.typeId === SignatureType.TEXT) {
			text = `${signature.meta ? signature.meta.message : ''}\n\n${
				props.name === null || props.name.trim() === '' ? (props.inEditingMode ? 'Your Name' : '') : props.name
			}`.trim();
		} else if (signature.typeId === SignatureType.EMOJI) {
			text = signature.media ? signature.media.url : '';
		}

		return text;
	}, [props.name, props.inEditingMode, signature?.typeId, signature?.meta, signature?.media]);

	if (!signature) {
		return null;
	}

	return (
		<Rnd
			ref={rndRef}
			style={Object.assign({ zIndex: 2 }, props.style)}
			bounds="parent"
			disableDragging={disabled}
			enableResizing={!disabled}
			scale={props.zoom}
			size={{ width: position.width, height: position.height }}
			cancel=".remove-button"
			// position={{ x: position.x / props.zoom, y: position.y / props.zoom }}
			lockAspectRatio={signature.typeId !== SignatureType.TEXT}
			onDrag={(e, d) => {
				if (!isAdjusting) {
					setIsAdjusting(true);
				}

				if (Math.abs(prevX - d.x) >= 3 || Math.abs(prevY - d.y) >= 3) {
					prevX = d.x;
					prevY = d.y;

					let { width, height } = position;

					requestAnimationFrame(() => {
						props.revalidateSignatures(signature.id, signature.page, d.x, d.y, width, height);
					});

					if (socket !== null && socket !== undefined) {
						socket.emit(
							'signature:move',
							props.cardId,
							sessionState.sessionId,
							signature.id,
							{
								x: d.x,
								y: d.y,
								width,
								height,
								rotation: 0,
							} as SignaturePosition,
							props.mode === 'view'
						);
					}
				}
			}}
			onDragStop={(e, d) => {
				setIsAdjusting(false);
				setPosition((prev) => ({
					...prev,
					x: d.x,
					y: d.y,
				}));
				props.setPosition(signature.id, {
					...signature.position,
					x: d.x,
					y: d.y,
				});

				if (socket !== null && socket !== undefined) {
					socket.emit(
						'signature:move',
						props.cardId,
						sessionState.sessionId,
						signature.id,
						{
							...position,
							x: d.x,
							y: d.y,
							rotation: 0,
						} as SignaturePosition,
						props.mode === 'view'
					);
				}
			}}
			onResize={(e, dir, elementRef, delta, position) => {
				if (!isAdjusting) {
					setIsAdjusting(true);
				}

				props.revalidateSignatures(
					signature.id,
					signature.page,
					position.x,
					position.y,
					parseFloat(elementRef.style.width.replace('px', '')),
					parseFloat(elementRef.style.height.replace('px', ''))
				);

				if (socket !== null && socket !== undefined) {
					socket.emit(
						'signature:move',
						props.cardId,
						sessionState.sessionId,
						signature.id,
						{
							x: position.x,
							y: position.y,
							width: parseFloat(elementRef.style.width.replace('px', '')),
							height: parseFloat(elementRef.style.height.replace('px', '')),
							rotation: 0,
						} as SignaturePosition,
						props.mode === 'view'
					);
				}

				setEmojiFontSize(parseFloat(elementRef.style.width.replace('px', '')));
			}}
			onResizeStop={(e, dir, elementRef, delta, position) => {
				setIsAdjusting(false);
				setEmojiFontSize(parseFloat(elementRef.style.width.replace('px', '')));
				setPosition((prev) => ({
					...prev,
					x: position.x,
					y: position.y,
					width: parseFloat(elementRef.style.width.replace('px', '')),
					height: parseFloat(elementRef.style.height.replace('px', '')),
				}));
				props.setPosition(signature.id, {
					x: position.x,
					y: position.y,
					width: parseFloat(elementRef.style.width.replace('px', '')),
					height: parseFloat(elementRef.style.height.replace('px', '')),
					rotation: 0,
				});

				if (socket !== null && socket !== undefined) {
					socket.emit(
						'signature:move',
						props.cardId,
						sessionState.sessionId,
						signature.id,
						{
							x: position.x,
							y: position.y,
							width: parseFloat(elementRef.style.width.replace('px', '')),
							height: parseFloat(elementRef.style.height.replace('px', '')),
							rotation: 0,
						} as SignaturePosition,
						props.mode === 'view'
					);
				}
			}}
			minWidth={
				signature.typeId === SignatureType.EMOJI ? SIGNATURE_EMOJI_MINIMUM_WIDTH : SIGNATURE_MINIMUM_WIDTH
			}
			minHeight={
				signature.typeId === SignatureType.EMOJI
					? undefined
					: signature.typeId === SignatureType.TEXT
					? SIGNATURE_TEXT_MINIMUM_HEIGHT
					: SIGNATURE_MINIMUM_HEIGHT
			}
			default={{
				width: position.width,
				height: position.height,
				x: position.x / props.zoom,
				y: position.y / props.zoom,
			}}
		>
			<div
				className={`${styles.container} ${isOverlapping ? styles.containerOverlapping : ''} ${
					props.moderate ? styles.containerModerate : ''
				}`}
			>
				{signature.typeId !== SignatureType.EMOJI && signature.user !== null && (
					<div
						style={{ userSelect: 'none' }}
						className={`${styles.header} ${isOverlapping ? styles.headerOverlapping : ''} ${
							props.moderate ? styles.headerModerate : ''
						}`}
					>
						{`${signature.user.firstName} ${signature.user.lastName}`}
					</div>
				)}
				{!props.hideRemoveButton && (
					<div
						onClick={() => props.removeSignature(signature.id)}
						className={`remove-button ${styles.removeButton} ${
							isOverlapping ? styles.removeButtonOverlapping : ''
						} ${props.moderate ? styles.removeButtonModerate : ''}`}
					>
						X
					</div>
				)}
				<div
					className={`${styles.resizingTopRight} ${isOverlapping ? styles.resizingOverlapping : ''} ${
						disabled ? styles.disabledResizing : ''
					} ${props.moderate ? styles.resizingModerate : ''}`}
				/>
				<div
					className={`${styles.resizingBottomRight} ${isOverlapping ? styles.resizingOverlapping : ''} ${
						disabled ? styles.disabledResizing : ''
					} ${props.moderate ? styles.resizingModerate : ''}`}
				/>
				<div
					className={`${styles.resizingTopLeft} ${isOverlapping ? styles.resizingOverlapping : ''} ${
						disabled ? styles.disabledResizing : ''
					} ${props.moderate ? styles.resizingModerate : ''}`}
				/>
				<div
					className={`${styles.resizingBottomLeft} ${isOverlapping ? styles.resizingOverlapping : ''} ${
						disabled ? styles.disabledResizing : ''
					} ${props.moderate ? styles.resizingModerate : ''}`}
				/>
				{signature.typeId === SignatureType.TEXT && <Box height={8} />}
				<div className={styles.content}>
					{signature.typeId !== SignatureType.TEXT && signature.typeId !== SignatureType.EMOJI && (
						<>
							{mediaType === 'image' && (
								<img
									style={{
										pointerEvents: 'none',
										width: '100%',
										height: '100%',
										objectFit: 'contain',
									}}
									src={signature.media!.url}
									alt={'Media'}
								/>
							)}
							{mediaType === 'video' && (
								<video
									controls
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'contain',
									}}
								>
									<source src={signature.media!.url} />
								</video>
							)}
						</>
					)}
					{(signature.typeId === SignatureType.TEXT || signature.typeId === SignatureType.EMOJI) && (
						<div
							style={{
								alignSelf: undefined,
								pointerEvents: 'none',
								overflow: 'hidden',
								width: '100%',
								whiteSpace: 'pre-wrap',
								fontFamily: fontFamily.fontFamily,
								...(signature.typeId === SignatureType.EMOJI
									? {
											fontSize: `${emojiFontSize}px`,
											lineHeight: `${emojiFontSize}px`,
									  }
									: {}),
								...(signature.meta
									? {
											fontSize: `${signature.meta.fontSize}px`,
											lineHeight: `${signature.meta.fontSize + 4}px`,
											fontWeight: signature.meta.isBold ? 'bold' : 'normal',
											fontStyle: signature.meta.isItalic ? 'italic' : 'normal',
											textDecoration: signature.meta.isUnderline ? 'underline' : 'initial',
											color: signature.meta.color,
											textAlign: signature.meta.alignment,
									  }
									: {}),
							}}
						>
							{message}
						</div>
					)}
				</div>
			</div>
		</Rnd>
	);
};

export default EditableSignature;
