import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import App from './App';
import './index.scss';
import SocketManager from './lib/components/SocketManager';
import reportWebVitals from './reportWebVitals';
import store from './store';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_KEY,
	integrations: [new Integrations.BrowserTracing()],
	environment: process.env.NODE_ENV,
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<SocketManager>
				<Toaster
					toastOptions={{
						className: 'border border-light-100',
					}}
				/>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</SocketManager>
			<ToastContainer />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
