import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import moment from 'moment';
import { Timing } from '../../lib/util';
import Card from '../../models/Card';
import CardTemplate from '../../models/CardTemplate';
import ErrorResponse from '../../network/responses/ErrorResponse';
import getTimezones from '../../utils/getTimezones';
import authenticationAsyncActions from '../actions/authentication.action';
import createCardAsyncActions from '../actions/createCard.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';
import { CPA } from '../types';

export interface CreateCardState
	extends Omit<
		Card,
		| 'id'
		| 'uuid'
		| 'creator'
		| 'collectedAmount'
		| 'signatures'
		| 'deliveredAt'
		| 'yourContribution'
		| 'withdrawError'
		| 'thankYouMessageSent'
	> {
	id: string | null;
	uuid: string;
	updatedAt: number;
}

const initialState: CreateCardState = {
	id: null,
	uuid: '',
	template: null,
	recipientFirstName: '',
	recipientLastName: '',
	recipientEmail: '',
	image: '',
	isContributionEnabled: true,
	contribution: 0,
	deliveryAt: moment().add(1, 'day').format('DD/MM/YYYY') + '12:00 PM',
	deliveryTimezone: '',
	isReminderEnabled: true,
	withdrawalLocked: false,
	reminderMessage: '',
	contributors: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'createCard',
	initialState,
	reducers: {
		initializeCard: (state: WritableDraft<typeof initialState>) => {
			state.id = null;
			state.uuid = '';
			state.template = null;
			state.image = '';
			state.recipientFirstName = '';
			state.recipientLastName = '';
			state.recipientEmail = '';
			state.deliveryAt = moment().add(1, 'day').format('DD/MM/YYYY') + '12:00 PM';

			const timeZones = getTimezones();
			const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const parts = timeZone.split('/');
			let city = parts[parts.length - 1];
			city = city.replace('_', ' ');
			const findIndex = timeZones.findIndex((element) => element.toLowerCase().includes(city.toLowerCase()));
			if (findIndex !== -1) {
				state.deliveryTimezone = timeZones[findIndex];
			} else {
				state.deliveryTimezone = '';
			}
			state.isReminderEnabled = true;
			state.reminderMessage = '';
			state.contribution = 0;
			state.isContributionEnabled = true;
			state.contributors = [];
			state.updatedAt = Timing.now();
		},
		setTemplate: (state: WritableDraft<typeof initialState>, action: PayloadAction<CardTemplate>) => {
			state.template = action.payload;
			state.image = action.payload.picture;
			state.updatedAt = Timing.now();
		},
		changeCover: (state: WritableDraft<typeof initialState>, action: PayloadAction<string>) => {
			state.template = null;
			state.image = action.payload;
			state.updatedAt = Timing.now();
		},
		updateRecipient: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<
				Pick<
					Card,
					'recipientFirstName' | 'recipientLastName' | 'recipientEmail' | 'deliveryAt' | 'deliveryTimezone'
				>
			>
		) => {
			state.recipientFirstName = action.payload.recipientFirstName;
			state.recipientLastName = action.payload.recipientLastName;
			state.recipientEmail = action.payload.recipientEmail;
			state.deliveryAt = action.payload.deliveryAt;
			state.deliveryTimezone = action.payload.deliveryTimezone;

			if (state.reminderMessage === '') {
				state.reminderMessage = `Hi there. You have been invited to Sign the Card for ${action.payload.recipientFirstName}.`;
			}
			state.updatedAt = Timing.now();
		},
		updateContribution: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Card, 'contribution' | 'isContributionEnabled'>>
		) => {
			state.contribution = action.payload.contribution;
			state.isContributionEnabled = action.payload.isContributionEnabled;
			state.updatedAt = Timing.now();
		},
		updateContributors: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Card, 'contributors'>>
		) => {
			state.contributors = action.payload.contributors;
			state.updatedAt = Timing.now();
		},
		updateReminder: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Card, 'isReminderEnabled' | 'reminderMessage'>>
		) => {
			state.isReminderEnabled = action.payload.isReminderEnabled;
			state.reminderMessage = action.payload.reminderMessage;
			state.updatedAt = Timing.now();
		},
	},
	extraReducers: {
		[createCardAsyncActions.store.fulfilled.type]: (state, action: CPA<Card>) => {
			state.id = action.payload.id;
			state.uuid = action.payload.uuid;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[createCardAsyncActions.store.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[createCardAsyncActions.publish.fulfilled.type]: (state, action: CPA<Card>) => postRequest(action),
		[createCardAsyncActions.publish.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
	},
});

export const createCardActions = slice.actions;

export default slice.reducer;
