import React from 'react';
import CardTemplate from '../../../models/CardTemplate';
import Box from '../Box';
import Typography from '../Typography';
import CheckIcon from './../../../img/ic-check.svg';
import styles from './index.module.scss';

interface Props {
	template: CardTemplate;
	isActive?: boolean;
	onClick: () => void;
}

const CardTile: React.FC<Props> = (props: Props) => {
	return (
		<div className={`${styles.container} ${props.isActive ? styles.active : ''}`}>
			<div onClick={props.onClick} className={`${styles.subContainer}`}>
				{props.isActive && (
					<div className={styles.activeTick}>
						<img src={CheckIcon} alt="" />
					</div>
				)}
				<div className={styles.preview}>
					<img style={{ opacity: 1 }} src={props.template.picture} alt={'Card Preview'} />
				</div>
				<Box height={28} />
				<Typography variant="caption" style={{ textAlign: 'center', textTransform: 'uppercase' }}>
					{props.template.caption}
				</Typography>
				<Box height={6} />
				<Typography
					className={props.isActive ? styles.activeText : ''}
					variant="body1"
					style={{ fontWeight: 800, textAlign: 'center' }}
				>
					{props.template.name}
				</Typography>
			</div>
		</div>
	);
};

export default CardTile;
