import React from 'react';
import styles from './index.module.scss';

interface Props {
	disabled?: boolean;
	value?: string;
	name?: string;
	labelStyle?: React.CSSProperties;
	style?: React.CSSProperties;
	containerStyle?: React.CSSProperties;
	label?: string;
	error?: string;
	type?: 'text' | 'email' | 'tel' | 'password' | 'number';
	noError?: boolean;
	icon?: string;
	iconSize?: string | number;
	editable?: boolean;
	prefix?: string;
	min?: number | string;
	className?: string;
	onChange?: (value: string) => void;
	onClick?: () => void;
	onFocus?: () => void;
	onBlur?: () => void;
}

interface State {
	isActive: boolean;
}

export default class Input extends React.Component<Props, State> {
	static defaultProps = {
		disabled: false,
		value: '',
		noError: false,
		type: 'text',
		iconSize: 16,
		editable: true,
		onChange: () => {},
	};
	private ref;
	private focusIn: () => void;
	private focusOut: () => void;

	constructor(props: Props) {
		super(props);

		this.ref = React.createRef<HTMLInputElement>();
		this.focusIn = () => {
			this.setState({
				isActive: true,
			});
			if (this.props.onFocus) {
				this.props.onFocus();
			}
		};
		this.focusOut = () => {
			if (!this.props.value) {
				this.setState({
					isActive: false,
				});
			}
			if (this.props.onBlur) {
				this.props.onBlur();
			}
		};

		this.state = {
			isActive: false,
		};
	}

	componentDidMount() {
		if (this.ref.current) {
			this.ref.current.addEventListener('focusin', this.focusIn);
			this.ref.current.addEventListener('focusout', this.focusOut);
		}

		if (this.props.value && this.props.label) {
			this.setState({
				isActive: true,
			});
		}
	}

	componentWillUnmount() {
		if (this.ref.current) {
			this.ref.current.removeEventListener('focusin', this.focusIn);
			this.ref.current.removeEventListener('focusout', this.focusOut);
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		if (this.props.value && this.props.value !== '' && this.props.label && !prevState.isActive) {
			this.setState({
				isActive: true,
			});
		}

		if ((!this.props.value || this.props.value === '') && this.props.label && prevState.isActive) {
			this.setState({
				isActive: false,
			});
		}
	}

	render() {
		return (
			<div
				style={this.props.containerStyle}
				onClick={this.props.onClick}
				className={`${styles.container} ${this.props.className ? this.props.className : ''} ${
					this.props.disabled ? styles.disabled : ''
				}`}
			>
				<div
					style={this.props.style}
					className={`${`${styles.inputContainer} ${this.props.disabled ? styles.disabled : ''}`} ${
						this.props.error ? styles.error : ''
					}`}
				>
					{this.props.label ? (
						<label
							style={this.props.labelStyle}
							className={`${styles.label} ${this.state.isActive ? styles.activeLabel : ''} ${
								this.props.error ? styles.errorLabel : ''
							}`}
						>
							{this.props.label}
						</label>
					) : null}
					<div style={{ display: 'flex', flexDirection: 'row', height: '100%', alignItems: 'center' }}>
						{this.props.prefix !== undefined && <div className={styles.prefix}>{this.props.prefix}</div>}
						<input
							min={this.props.min}
							style={{ paddingLeft: this.props.prefix !== undefined ? '0' : undefined }}
							disabled={this.props.disabled || !this.props.editable}
							name={this.props.name}
							value={this.props.value}
							onChange={(e) => this.props.onChange!(e.target.value)}
							className={`${styles.input} ${this.props.className ? this.props.className : ''} ${
								this.props.disabled ? styles.disabled : ''
							}`}
							ref={this.ref}
							type={this.props.type}
						/>
						{this.props.icon && (
							<img
								style={{ width: this.props.iconSize, height: this.props.iconSize, marginRight: 12 }}
								src={this.props.icon}
								alt=""
							/>
						)}
					</div>
				</div>
				{!this.props.noError && <span className={styles.errorText}>{this.props.error}&nbsp;</span>}
			</div>
		);
	}
}
