import React from 'react';
import styles from './index.module.scss';

const Hamburger: React.FC = () => {
	return (
		<div className={styles.container}>
			<div className={styles.line} />
			<div className={styles.line} />
			<div className={styles.line} />
			<div className={styles.line} />
		</div>
	);
};

export default Hamburger;
