import React from 'react';
import Box from '../Box';
import Button from '../Button';
import Typography from '../Typography';
import styles from './index.module.scss';

interface Props {
	onSubmit: () => void;
}

const RemoveMessageModal: React.FC<Props> = (props: Props) => {
	return (
		<div className={styles.container}>
			<Typography style={{ textAlign: 'center' }} variant="h3">
				Delete message
			</Typography>
			<Box height={16} />
			<Typography variant="body2" style={{ textAlign: 'center' }}>
				Are you sure you want to delete this message and all connected media? The person who created the message
				will receive an email to notify them that their message has been deleted. If they made a contribution,
				this will remain.
			</Typography>
			<Box height={16} />
			<Button onClick={props.onSubmit} type={'raised'}>
				Delete
			</Button>
			<Box height={16} />
			<Typography variant="body2" style={{ textAlign: 'center' }}>
				Deleting this message is permanent and can not be undone.
			</Typography>
		</div>
	);
};

export default RemoveMessageModal;
