import React from 'react';
import Box from '../Box';
import Typography from '../Typography';
import AddIcon from './../../../img/ic-add.svg';
import LibraryIcon from './../../../img/ic-library.svg';
import CardPlaceholder from './../../../img/il-card-placeholder.png';
import styles from './index.module.scss';

interface Props {
	title: string;
	content: string;
	isActive?: boolean;
	onClick: () => void;
}

const CardTilePlaceholder: React.FC<Props> = (props: Props) => {
	return (
		<div onClick={props.onClick} className={`${styles.container} ${props.isActive ? styles.active : ''}`}>
			<div className={styles.preview}>
				<div className={styles.addButton}>
					<img className={styles.libraryIcon} src={LibraryIcon} alt={'Library'} />
					<img className={styles.addIcon} src={AddIcon} alt={'Add Icon'} />
				</div>
				<img src={CardPlaceholder} alt={'Card Preview'} />
			</div>
			<Box height={28} />
			<Typography variant="caption">{props.title}</Typography>
			<Box height={6} />
			<Typography variant="body1" style={{ fontWeight: 800, textAlign: 'center' }}>
				{props.content}
			</Typography>
		</div>
	);
};

export default CardTilePlaceholder;
