import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import authenticationAsyncActions from '../actions/authentication.action';

export interface UIState {
	navigationIndex: number;
	showNavigationModal: boolean;
	showSignInModal: boolean;
	showSignUpModal: boolean;
	showForgotPasswordModal: boolean;
	showResetPasswordModal: boolean;
	showShareModal: boolean;
	showReceivedShareModal: boolean;
	doRedirect: boolean;
	redirectUrl?: string;
	code: string;
	noContributors: boolean;
}

const initialState: UIState = {
	navigationIndex: 0,
	showNavigationModal: false,
	showSignInModal: false,
	showSignUpModal: false,
	showForgotPasswordModal: false,
	showResetPasswordModal: false,
	showShareModal: false,
	showReceivedShareModal: false,
	doRedirect: false,
	redirectUrl: undefined,
	code: '',
	noContributors: false,
};

const slice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		openNavigationModal: (state: WritableDraft<typeof initialState>, action: PayloadAction<{ index?: number }>) => {
			if (action.payload.index !== undefined) {
				state.navigationIndex = action.payload.index;
			}
			state.showNavigationModal = true;
		},
		openSignInModal: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<{ redirectUrl?: string }>
		) => {
			state.redirectUrl = action.payload.redirectUrl;
			state.showSignUpModal = false;
			state.showForgotPasswordModal = false;
			state.showResetPasswordModal = false;
			state.showShareModal = false;
			state.showReceivedShareModal = false;
			state.showSignInModal = true;
		},
		openSignUpModal: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<{ redirectUrl?: string }>
		) => {
			state.redirectUrl = action.payload.redirectUrl;
			state.showSignInModal = false;
			state.showForgotPasswordModal = false;
			state.showResetPasswordModal = false;
			state.showShareModal = false;
			state.showReceivedShareModal = false;
			state.showSignUpModal = true;
		},
		openForgotPasswordModal: (state: WritableDraft<typeof initialState>) => {
			state.showSignInModal = false;
			state.showSignUpModal = false;
			state.showResetPasswordModal = false;
			state.showShareModal = false;
			state.showReceivedShareModal = false;
			state.showForgotPasswordModal = true;
		},
		openResetPasswordModal: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<{ code?: string }>
		) => {
			state.showSignInModal = false;
			state.showSignUpModal = false;
			state.showForgotPasswordModal = false;
			state.showShareModal = false;
			state.showReceivedShareModal = false;
			state.showResetPasswordModal = true;
			if (!action.payload.code) {
				state.code = '';
			} else {
				state.code = action.payload.code;
			}
		},
		openShareModal: (
			state: WritableDraft<typeof initialState>,
			payload: PayloadAction<{ noContributors?: boolean }>
		) => {
			state.showSignInModal = false;
			state.showSignUpModal = false;
			state.showForgotPasswordModal = false;
			state.showResetPasswordModal = false;
			state.showReceivedShareModal = false;
			state.showShareModal = true;
			state.noContributors = payload.payload.noContributors ?? false;
		},
		openReceivedShareModal: (state: WritableDraft<typeof initialState>) => {
			state.showSignInModal = false;
			state.showSignUpModal = false;
			state.showForgotPasswordModal = false;
			state.showResetPasswordModal = false;
			state.showShareModal = false;
			state.showReceivedShareModal = true;
		},
		closeSignInModal: (state: WritableDraft<typeof initialState>) => {
			state.showSignInModal = false;
		},
		closeSignUpModal: (state: WritableDraft<typeof initialState>) => {
			state.showSignUpModal = false;
		},
		closeModals: (state: WritableDraft<typeof initialState>) => {
			state.navigationIndex = 0;
			state.showNavigationModal = false;
			state.showSignInModal = false;
			state.showSignUpModal = false;
			state.showForgotPasswordModal = false;
			state.showResetPasswordModal = false;
			state.showShareModal = false;
			state.showReceivedShareModal = false;
		},
		finishRedirect: (state: WritableDraft<typeof initialState>) => {
			state.redirectUrl = undefined;
			state.doRedirect = false;
		},
	},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: (state, action) => {
			action.dispatch(uiActions.closeModals());
			state.doRedirect = true;
		},
		[authenticationAsyncActions.signUp.fulfilled.type]: (state, action) => {
			action.dispatch(uiActions.closeModals());
			state.doRedirect = true;
		},
		[authenticationAsyncActions.forgotPassword.fulfilled.type]: (state, action) => {
			action.dispatch(uiActions.closeModals());
			state.showResetPasswordModal = true;
		},
		[authenticationAsyncActions.resetPassword.fulfilled.type]: (state, action) => {
			action.dispatch(uiActions.closeModals());
			state.doRedirect = true;
			state.code = '';
		},
	},
});

export const uiActions = slice.actions;

export default slice.reducer;
