import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { API } from 'lib/util';
import CardPack from '../../models/CardPack';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('card-pack/index', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<never, AxiosResponse<CardPack[]>>(`/card-packs`);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const cardPackAsyncActions = {
	index,
};

export default cardPackAsyncActions;
