import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import CardDesignUpload from '../../lib/components/CardDesignUpload';
import CardsTable from '../../lib/components/CardsTable';
import CardTile from '../../lib/components/CardTile';
import CardTilePlaceholder from '../../lib/components/CardTilePlaceholder';
import CategoryAccordion from '../../lib/components/CategoryAccordion';
import Checkbox from '../../lib/components/Checkbox';
import Header from '../../lib/components/Header';
import HomeTabbar from '../../lib/components/HomeTabbar';
import Input from '../../lib/components/Input';
import Modal from '../../lib/components/Modal';
import SizeSpecificationModal from '../../lib/components/SizeSpecificationModal';
import TitledContainer from '../../lib/components/TitledContainer';
import Typography from '../../lib/components/Typography';
import Card from '../../models/Card';
import CardCategory from '../../models/CardCategory';
import cardCategoryAsyncActions from '../../store/actions/cardCategory.action';
import cardPackAsyncActions from '../../store/actions/cardPack.action';
import manageCardAsyncActions from '../../store/actions/manageCard.action';
import userAsyncActions from '../../store/actions/user.action';
import {
	useAuthenticationState,
	useCardCategoryState,
	useCardPackState,
	useManageCardState,
	useUserState,
} from '../../store/selectors';
import { cardActions } from '../../store/slices/card.slice';
import { createCardActions } from '../../store/slices/createCard.slice';
import { manageCardActions } from '../../store/slices/manageCard.slice';
import { uiActions } from '../../store/slices/ui.slice';
import useQuery from '../../utils/useQuery';
import RightPanel from '../ManageCardScreen/RightPanel';
import LibraryIcon from './../../img/ic-library.svg';
import SearchIcon from './../../img/ic-search.svg';
import CardPlaceholder from './../../img/il-card-placeholder.png';
import CardPreviewPlaceholder from './../../img/il-card-preview-placeholder.png';
import CardUploadPlaceholder from './../../img/il-card-upload-placeholder.png';
import styles from './index.module.scss';

const HomeScreen: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQuery();

	const authenticationState = useAuthenticationState();
	const manageCardState = useManageCardState();
	const userState = useUserState();
	const cardCategoryState = useCardCategoryState();
	const cardPackState = useCardPackState();

	const { width } = useWindowDimensions();
	const location = useLocation();

	const [showWithdrawModal, setShowWithdrawModal] = React.useState<boolean>(false);
	const [index, setIndex] = React.useState<number>(query.has('tab') ? parseInt(query.get('tab')!) : 0);
	const [selectedCategoryIds, setSelectedCategoryIds] = React.useState<string[]>([]);
	const [image, setImage] = React.useState<string>('');
	const [selectedDesign, setSelectedDesign] = React.useState<string>('-1');
	const [showSpecificationModal, setShowSpecificationModal] = React.useState<boolean>(false);

	const [showRightPanel, setShowRightPanel] = React.useState<boolean>(false);

	const [search, setSearch] = React.useState<string>('');
	const [subIndex, setSubIndex] = React.useState<number>(0);

	const findTemplate = React.useCallback(
		(templateId: string) => {
			for (let i = 0; i < cardCategoryState.list.length; i++) {
				const findIndex = cardCategoryState.list[i].templates.findIndex(
					(element) => parseInt(element.id, 10) === parseInt(templateId, 10)
				);
				if (findIndex !== -1) {
					return cardCategoryState.list[i].templates[findIndex];
				}
			}
		},
		[cardCategoryState.list]
	);

	const cheapestCardPack = React.useMemo<number>(() => {
		const list = [...cardPackState.list].sort((a, b) => a.pricePerCard - b.pricePerCard);
		if (list.length > 0) {
			return list[0].pricePerCard;
		}

		return 0;
	}, [cardPackState.list]);

	const filteredCategories = React.useMemo<CardCategory[]>(() => {
		if (search === '') {
			return cardCategoryState.list;
		}

		return cardCategoryState.list.filter((element) => element.name.toLowerCase().includes(search.toLowerCase()));
	}, [cardCategoryState.list, search]);

	const selectedDesignImage = React.useMemo<string>(() => {
		if (selectedDesign === '-1') {
			return CardPreviewPlaceholder;
		}
		if (selectedDesign === '0') {
			return CardUploadPlaceholder;
		}

		if (index === 0) {
			return findTemplate(selectedDesign)!.picture;
		}

		return userState.cards.find((element) => element.uuid === selectedDesign)!.image;
	}, [selectedDesign, index, cardCategoryState.list, userState.cards]);

	const handleConfirmDesign = React.useCallback(() => {
		if (!query.has('action')) {
			dispatch(createCardActions.initializeCard());
		}

		if (!query.has('action') || query.get('action') === 'create') {
			const template = findTemplate(selectedDesign);
			if (template) {
				dispatch(createCardActions.setTemplate(template));
			} else {
				dispatch(createCardActions.changeCover(image));
			}
		} else if (query.get('action') === 'update') {
			const template = findTemplate(selectedDesign);
			if (template) {
				dispatch(manageCardActions.setTemplate(template));
			} else {
				dispatch(manageCardActions.changeCover(image));
			}

			dispatch(manageCardAsyncActions.update());
		}

		if (!query.has('action')) {
			history.push('/create-card');
			return;
		}

		if (query.has('redirectUri')) {
			history.replace(query.get('redirectUri')!);
		} else {
			history.goBack();
		}
	}, [dispatch, findTemplate, selectedDesign, cardCategoryState.list, history, image, manageCardState.id, query]);

	const handleDesignClick = React.useCallback(
		(id: string) => {
			if (id === '0' && index === 1) {
				setIndex(0);
			}

			setSelectedDesign(id);
			setShowRightPanel(true);
			if (id === '0') {
				setImage(CardUploadPlaceholder);
				return;
			}

			if (index === 0) {
				setImage(findTemplate(id)!.picture);
			} else {
				setImage(userState.cards.find((element) => element.uuid === id)!.image);
			}
		},
		[cardCategoryState.list, index, userState]
	);

	const handleCardClick = React.useCallback(
		(id: string) => {
			dispatch(manageCardAsyncActions.show({ id }));
			handleDesignClick(id);
		},
		[handleDesignClick]
	);

	const createdCards = React.useMemo<Card[]>(
		() => userState.cards.filter((element) => parseInt(element.creator.id, 10) === parseInt(userState.id, 10)),
		[userState.id, userState.cards]
	);

	const signedCards = React.useMemo<Card[]>(
		() =>
			userState.cards.filter(
				(element) =>
					element.signatures.findIndex(
						(e) => e.user && parseInt(e.user.id, 10) === parseInt(userState.id, 10)
					) !== -1
			),
		[userState.id, userState.cards]
	);

	const receivedCards = React.useMemo<Card[]>(
		() => userState.cards.filter((element) => element.recipientEmail === userState.email),
		[userState.id, userState.cards]
	);

	const userCards = React.useMemo<Array<Card>>(() => {
		if (!userState.cards) {
			return [];
		}

		let cards = [...userState.cards];

		if (subIndex === 0) {
			cards = createdCards;
		} else if (subIndex === 1) {
			cards = signedCards;
		} else if (subIndex === 2) {
			cards = receivedCards;
		}

		cards.sort((a, b) => {
			const aDeliveryAt = moment.utc(a.deliveryAt, 'DD/MM/YYYY hh:mm A');
			const bDeliveryAt = moment.utc(b.deliveryAt, 'DD/MM/YYYY hh:mm A');

			return bDeliveryAt.valueOf() - aDeliveryAt.valueOf();
		});

		return cards;
	}, [subIndex, userState.id, userState.cards, createdCards, signedCards, receivedCards]);

	const getTemplatesAmount = React.useMemo<number>(() => {
		const getTemplates = (categoryId: string) => {
			const findIndex = cardCategoryState.list.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(categoryId, 10)
			);
			if (findIndex === -1) {
				return [];
			}

			return cardCategoryState.list[findIndex].templates;
		};

		let templates = selectedCategoryIds.map((element) => getTemplates(element)).flat();
		if (selectedCategoryIds.length === 0) {
			templates = cardCategoryState.list.map((element) => getTemplates(element.id)).flat();
		}

		return templates.length;
	}, [cardCategoryState.list, selectedCategoryIds]);

	const getTemplatesTitle = React.useMemo<string>(() => {
		const getTemplates = (categoryId: string) => {
			const findIndex = cardCategoryState.list.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(categoryId, 10)
			);
			if (findIndex === -1) {
				return [];
			}

			return cardCategoryState.list[findIndex];
		};

		let templates = selectedCategoryIds.map((element) => getTemplates(element)).flat();
		if (selectedCategoryIds.length === 0) {
			templates = cardCategoryState.list.map((element) => getTemplates(element.id)).flat();
		}

		if (templates.length === 0) {
			return 'All cards';
		} else if (templates.length === 1) {
			return `${templates[0].name} cards`;
		} else if (templates.length === 2) {
			return `${templates[0].name} and ${templates[1].name} cards`;
		} else if (templates.length === 3) {
			return `${templates[0].name}, ${templates[1].name} and ${templates[2].name} cards`;
		} else {
			return `${templates
				.map((element) => element.name)
				.slice(0, 3)
				.join(', ')} + ${templates.map((element) => element.name).slice(3).length} more cards`;
		}

		return `${templates.length} cards`;
	}, [cardCategoryState.list, selectedCategoryIds]);

	const getTemplates = React.useMemo<React.ReactNode>(() => {
		const getTemplates = (categoryId: string) => {
			const findIndex = cardCategoryState.list.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(categoryId, 10)
			);
			if (findIndex === -1) {
				return [];
			}

			return cardCategoryState.list[findIndex].templates;
		};

		let templates = selectedCategoryIds.map((element) => getTemplates(element)).flat();
		if (selectedCategoryIds.length === 0) {
			templates = cardCategoryState.list.map((element) => getTemplates(element.id)).flat();
		}

		templates.sort((a) => (a.isFeatured ? -1 : 1));
		return templates.map((element) => (
			<CardTile
				key={`sample-${element.id}`}
				template={element}
				isActive={parseInt(selectedDesign, 10) === parseInt(element.id, 10)}
				onClick={() => handleDesignClick(element.id)}
			/>
		));
	}, [cardCategoryState.list, selectedCategoryIds, selectedDesign, index, userState]);

	React.useEffect(() => {
		if (query.has('tab')) {
			if (index !== parseInt(query.get('tab')!)) {
				setSelectedDesign('-1');
			}
			setIndex(parseInt(query.get('tab')!));

			let params = [];
			if (query.has('redirectUri')) {
				params.push(`redirectUri=${query.get('redirectUri')}`);
			}
			if (query.has('action')) {
				params.push(`action=${query.get('action')}`);
			}

			history.replace(`/${params.length > 0 ? `?${params.join('&')}` : ''}`);
			return;
		}
	}, [query]);

	React.useEffect(() => {
		if (selectedDesign !== '-1') {
			setShowRightPanel(true);
		}
	}, [selectedDesign]);

	React.useEffect(() => {
		if (!showRightPanel) {
			setImage('');
		}
	}, [showRightPanel]);

	React.useEffect(() => {
		if (!query.has('action')) {
			dispatch(
				cardActions.initializeCard({
					image: '',
				})
			);
		}
		dispatch(userAsyncActions.refreshUser());
	}, [dispatch]);

	React.useEffect(() => {
		if (authenticationState.isAuthenticated) {
			dispatch(userAsyncActions.refreshUser());
			dispatch(cardCategoryAsyncActions.index());
		}

		dispatch(cardPackAsyncActions.index());
	}, [dispatch, authenticationState]);

	React.useEffect(() => {
		if (location.pathname.includes('/about-us')) {
			dispatch(uiActions.openNavigationModal({ index: 0 }));
		}

		if (location.pathname.includes('/how-it-works')) {
			dispatch(uiActions.openNavigationModal({ index: 1 }));
		}

		if (location.pathname.includes('/faqs')) {
			dispatch(uiActions.openNavigationModal({ index: 2 }));
		}

		if (location.pathname.includes('/website-terms')) {
			dispatch(uiActions.openNavigationModal({ index: 3 }));
		}

		if (location.pathname.includes('/terms-of-use')) {
			dispatch(uiActions.openNavigationModal({ index: 4 }));
		}

		if (location.pathname.includes('/privacy-policy')) {
			dispatch(uiActions.openNavigationModal({ index: 5 }));
		}

		if (location.pathname.includes('/fees')) {
			dispatch(uiActions.openNavigationModal({ index: 6 }));
		}
	}, [dispatch, location]);

	const leftContent = React.useMemo<React.ReactNode>(() => {
		return (
			<>
				<Input
					style={{ backgroundColor: '#FFFFFF' }}
					value={search}
					onChange={(value) => {
						setSelectedCategoryIds([]);
						setSearch(value);
					}}
					label="Search"
					icon={SearchIcon}
				/>
				<Box height={16} />
				{filteredCategories.map((element) => (
					<Box key={element.id} style={{ padding: '4px 0' }}>
						<Checkbox
							style={{ padding: '6px 0' }}
							value={selectedCategoryIds.includes(element.id)}
							onChange={(isChecked) => {
								const newSelectedCategoryIds = [...selectedCategoryIds];
								const findIndex = selectedCategoryIds.findIndex(
									(entry) => parseInt(entry, 10) === parseInt(element.id, 10)
								);
								if (isChecked && findIndex === -1) {
									newSelectedCategoryIds.push(element.id);
								} else {
									newSelectedCategoryIds.splice(findIndex, 1);
								}

								setSelectedCategoryIds(newSelectedCategoryIds);
							}}
						>
							<Typography className={styles.categoryTitle} variant="button">
								{element.name}
							</Typography>{' '}
							<Typography variant="caption">- {element.templatesAmount} cards</Typography>
						</Checkbox>
					</Box>
				))}
			</>
		);
	}, [selectedCategoryIds, cardCategoryState.list, search]);

	const showHeader = React.useMemo<boolean>(() => {
		if (index === 1 && width < 992 && showRightPanel) {
			return false;
		}

		return true;
	}, [width, index, showRightPanel]);

	return (
		<>
			<Modal isVisible={showSpecificationModal} setIsVisible={setShowSpecificationModal}>
				<SizeSpecificationModal />
			</Modal>
			<div className={styles.container}>
				<div className={styles.leftContainer}>
					{showHeader && (
						<Header
							subTitleClick={() => setShowSpecificationModal(true)}
							title={width < 992 && showRightPanel ? 'Card preview' : undefined}
							subTitle={width < 992 && showRightPanel ? 'View size specifications' : undefined}
							subTitleStyle={{ textDecoration: 'underline', cursor: 'pointer' }}
							style={{ zIndex: width < 992 ? 25 : 10 }}
							relative={width >= 992 || (width < 992 && !showRightPanel)}
						/>
					)}
					<HomeTabbar
						style={{ flexShrink: 0, zIndex: 15, position: 'relative', top: width < 992 ? -36 : -40 }}
						onClick={(value) => {
							setSelectedDesign('-1');
							setIndex(value);
						}}
						tabs={['Create a card', 'My profile']}
						index={index}
					/>
					{index === 0 && (
						<div style={{ position: 'relative', top: width < 992 ? -32 : 0 }}>
							{width < 992 && <Box height={32} />}
							<Typography style={{ position: 'relative', top: width >= 992 ? -32 : 0 }} variant="h3">
								Choose a design
							</Typography>
							<div className={styles.contentContainer}>
								<div className={styles.leftContentContainer}>
									{width >= 992 && (
										<>
											<TitledContainer title="Select an occasion" />
											<Box height={16} />
										</>
									)}
									{width < 992 && (
										<CategoryAccordion
											question="Select an occasion"
											questionColor="#000000"
											blackIcon
											noSpacer
										>
											{leftContent}
										</CategoryAccordion>
									)}

									{width >= 992 && leftContent}
								</div>
								<div className={styles.rightContentContainer}>
									<TitledContainer
										title={
											cardCategoryState.list.length === 0
												? 'Loading...'
												: selectedCategoryIds.length === 0
												? `All cards - ${cardCategoryState.list
														.map((element) => element.templatesAmount)
														.reduce((previous, current) => previous + current, 0)} designs`
												: selectedCategoryIds.length === 1
												? `${
														cardCategoryState.list.find(
															(element) =>
																parseInt(element.id, 10) ===
																parseInt(selectedCategoryIds[0], 10)
														)!.name
												  } cards - ${getTemplatesAmount} designs`
												: getTemplatesTitle
										}
										alternate={width < 992}
										// suffix={<Typography variant="caption">Sort: Popular | New</Typography>}
									/>
									<Box height={16} />
									<div className={styles.cardsContainer}>
										<CardTilePlaceholder
											title={index === 0 ? 'Have a design?' : 'Want to send a card?'}
											content={index === 0 ? 'Upload your own!' : 'Create a card'}
											isActive={selectedDesign === '0'}
											onClick={() => handleDesignClick('0')}
										/>
										{index === 0 && getTemplates}
									</div>
								</div>
							</div>
						</div>
					)}
					{index === 1 && (
						<div>
							{width < 992 && <Box height={32} />}
							<Typography style={{ width: '100%', textAlign: 'center' }} variant="h3">
								{userState.firstName}`s{width < 992 ? <br /> : null} Sign the Card profile
							</Typography>
							<Box height={32} />
							<div className={styles.redContainer}>
								<Typography variant="caption">
									Remaining cards to create:{' '}
									<span style={{ fontWeight: 600 }}>{userState.availableCards}</span>
								</Typography>
								<Button
									style={{ height: 32, margin: 0, padding: 0 }}
									className={styles.button}
									onClick={() => history.push('/buy')}
									type={'text'}
								>
									Add more cards from ${cheapestCardPack.toFixed(2)}
								</Button>
							</div>
							<Box height={32} />
							<HomeTabbar
								full
								style={{ width: '100%', flexShrink: 0 }}
								onClick={(value) => {
									setSelectedDesign('-1');
									setSubIndex(value);
								}}
								tabs={[
									`Created cards: ${createdCards.length}`,
									`Signed cards: ${signedCards.length}`,
									`Received cards: ${receivedCards.length}`,
								]}
								index={subIndex}
							/>
							<CardsTable
								handleCardClick={handleCardClick}
								selectedDesign={selectedDesign}
								cards={userCards}
								setShowWithdrawModal={setShowWithdrawModal}
							/>
						</div>
					)}
				</div>
				<div className={`${styles.rightContainer} ${showRightPanel ? styles.rightContainerActive : ''}`}>
					{(index === 0 || (index === 1 && (selectedDesign === '-1' || selectedDesign === '0'))) && (
						<>
							{width < 992 && <Box height={64 + 16 - 32} />}
							<div style={{ textAlign: 'center' }}>
								{width >= 992 && (
									<Typography style={{ marginTop: 28 }} variant="h4">
										{selectedDesign === '0'
											? 'Upload your own!'
											: index === 0
											? 'Card preview'
											: 'Create a new card'}
									</Typography>
								)}
								{selectedDesign === '0' && width >= 992 && (
									<>
										<Box height={12} />
										<div onClick={() => setShowSpecificationModal(true)}>
											<Typography
												style={{ textDecoration: 'underline', cursor: 'pointer' }}
												variant="body1"
											>
												View size specifications
											</Typography>
										</div>
									</>
								)}
							</div>
							{((selectedDesign !== '-1' && selectedDesign !== '0') || selectedDesign === '-1') && (
								<div
									style={{
										width: width < 992 ? 'calc(45vh * .75)' : 'calc(40vh * .75)',
										height: width < 992 ? '45vh' : '40vh',
									}}
								>
									{index === 1 && (
										<div className={styles.preview}>
											<div className={styles.addButton}>
												<img className={styles.libraryIcon} src={LibraryIcon} alt={'Library'} />
											</div>
											<img src={CardPlaceholder} alt={'Card Preview'} />
										</div>
									)}
									{index !== 1 && (
										<img
											style={{
												width: '100%',
												height: '100%',
												maxWidth: '100%',
												objectFit: 'cover',
												borderRadius: 8,
												boxShadow:
													'0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
											}}
											src={selectedDesignImage}
											alt={'Card Preview'}
										/>
									)}
								</div>
							)}
							{selectedDesign === '0' && <CardDesignUpload onUpload={setImage} />}
							<div
								style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
							>
								{index === 0 && (
									<Button
										disabled={selectedDesign === '-1' || image === ''}
										style={{ padding: '0 16px', width: width < 992 ? '90%' : '50%' }}
										onClick={handleConfirmDesign}
									>
										Confirm design
									</Button>
								)}
								{index === 1 && (
									<Button
										style={{ padding: '0 16px', width: width < 992 ? '90%' : '50%' }}
										onClick={() => setIndex(0)}
									>
										Create a card
									</Button>
								)}
								{width < 992 && (
									<>
										<Box height={14} />
										<Button
											type="outlined"
											style={{ padding: '0 16px', width: '90%' }}
											onClick={() => {
												setSelectedDesign('-1');
												setShowRightPanel(false);
											}}
										>
											Choose another design
										</Button>
									</>
								)}
							</div>
						</>
					)}
					{index === 1 && selectedDesign !== '-1' && selectedDesign !== '0' && (
						<RightPanel
							uuid={selectedDesign}
							showWithdrawModal={showWithdrawModal}
							setShowWithdrawModal={setShowWithdrawModal}
							setShowRightPanel={setShowRightPanel}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default HomeScreen;
