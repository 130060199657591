import { Request } from 'lib/util';
import { useTypedSelector } from '.';
import { AuthenticationState } from './slices/authentication.slice';
import { CardState } from './slices/card.slice';
import { CardCategoryState } from './slices/cardCategory.slice';
import { CardPackState } from './slices/cardPack.slice';
import { CardsState } from './slices/cards.slice';
import { CreateCardState } from './slices/createCard.slice';
import { ManageCardState } from './slices/manageCard.slice';
import { SessionState } from './slices/session.slice';
import { SettingsState } from './slices/settings.slice';
import { SignACardState } from './slices/signACard.slice';
import { TransactionsState } from './slices/transactions.slice';
import { UIState } from './slices/ui.slice';
import { UserState } from './slices/user.slice';

export function useAuthenticationState() {
	return useTypedSelector<AuthenticationState>(({ authentication }) => authentication);
}

export function useCardState() {
	return useTypedSelector<CardState>(({ card }) => card);
}

export function useCardCategoryState() {
	return useTypedSelector<CardCategoryState>(({ cardCategory }) => cardCategory);
}

export function useCardPackState() {
	return useTypedSelector<CardPackState>(({ cardPack }) => cardPack);
}

export function useCardsState() {
	return useTypedSelector<CardsState>(({ cards }) => cards);
}

export function useCreateCardState() {
	return useTypedSelector<CreateCardState>(({ createCard }) => createCard);
}

export function useManageCardState() {
	return useTypedSelector<ManageCardState>(({ manageCard }) => manageCard);
}

export function useRequestState() {
	return useTypedSelector<Request.State>(({ request }) => request);
}

export function useSessionState() {
	return useTypedSelector<SessionState>(({ session }) => session);
}

export function useSettingsState() {
	return useTypedSelector<SettingsState>(({ settings }) => settings);
}

export function useSignACardState() {
	return useTypedSelector<SignACardState>(({ signACard }) => signACard);
}

export function useTransactionsState() {
	return useTypedSelector<TransactionsState>(({ transactions }) => transactions);
}

export function useUIState() {
	return useTypedSelector<UIState>(({ ui }) => ui);
}

export function useUserState() {
	return useTypedSelector<UserState>(({ user }) => user);
}
