import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import Controls from '../../lib/components/Controls';
import Header from '../../lib/components/Header';
import StaticSignature from '../../lib/components/StaticSignature';
import Textarea from '../../lib/components/Textarea';
import Typography from '../../lib/components/Typography';
import Signature from '../../models/Signature';
import cardAsyncActions from '../../store/actions/card.action';
import { useAuthenticationState, useCardState } from '../../store/selectors';
import { uiActions } from '../../store/slices/ui.slice';
import ArrowLeftIcon from './../../img/ic-arrow-left.svg';
import ArrowRightIcon from './../../img/ic-arrow-right.svg';
import DownloadIcon from './../../img/ic-download.svg';
import ShareIcon from './../../img/ic-share-primary.svg';
import CardBackPlaceholder from './../../img/il-card-back-placeholder.png';
import Logo from './../../logo.svg';
import styles from './index.module.scss';

const RecipientScreen: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();

	const authenticationState = useAuthenticationState();
	const cardState = useCardState();

	const { width } = useWindowDimensions();
	const [opacity, setOpacity] = React.useState<number>(1);
	const [zoom, setZoom] = React.useState<number>(width < 992 ? 2 : 1.5);
	const [page, setPage] = React.useState<number>(-1);
	const [thankYouMessage, setThankYouMessage] = React.useState<string>('');
	const [atEnd, setAtEnd] = React.useState<boolean>(false);

	const cardContentRef = React.useRef<HTMLDivElement>(null);

	const filteredSignatures = React.useMemo<Array<Signature>>(() => {
		return cardState.signatures.filter((element) => element.isPublished && element.page === page);
	}, [cardState.signatures, page]);

	const lastPage = React.useMemo<number>(() => {
		const newPage = Math.max(...cardState.signatures.map((element) => element.page));
		if (newPage < 1) {
			return 1;
		}

		return newPage + 1;
	}, [cardState.signatures]);

	const handleChangePage = React.useCallback(
		(newPage: number) => {
			if (newPage <= -2 || newPage > lastPage) {
				return;
			}

			if (page !== -1 && page !== lastPage) {
				if (cardContentRef.current && newPage > page && !atEnd && width < 992) {
					const scrollWidth = cardContentRef.current.scrollWidth;
					const width = cardContentRef.current.clientWidth;
					const maxScrollLeft = scrollWidth - width;
					cardContentRef.current.scrollLeft = maxScrollLeft > 0 ? maxScrollLeft : 0;
					return;
				} else if (
					cardContentRef.current &&
					newPage < page &&
					cardContentRef.current.scrollLeft !== 0 &&
					width < 992
				) {
					cardContentRef.current.scrollLeft = 0;
					return;
				}

				if (cardContentRef.current && newPage > page) {
					cardContentRef.current.scrollLeft = 0;
				}
			}

			setOpacity(0);
			setTimeout(() => {
				setPage(newPage);
				setTimeout(() => {
					setOpacity(1);
				}, 50);
			}, 250);
		},
		[page, lastPage, cardContentRef.current, atEnd]
	);

	const handleDownloadClick = React.useCallback(() => {
		const newWindow = window.open(
			`${process.env.REACT_APP_API_URL}/download-pdf/${cardState.id}`,
			'_blank',
			'noopener,noreferrer'
		);
		if (newWindow) newWindow.opener = null;
	}, [cardState]);

	React.useEffect(() => {
		// @ts-ignore
		window.startConfetti();

		return () => {
			// @ts-ignore
			window.removeConfetti();
		};
	}, []);

	React.useEffect(() => {
		if (cardState.id === id) {
			return;
		}

		dispatch(cardAsyncActions.show({ id }));
	}, [dispatch, cardState.id, authenticationState.isAuthenticated]);

	React.useEffect(() => {
		if (width <= 320) {
			setZoom(0.7);
		} else if (width < 576) {
			setZoom(0.8);
		} else {
			setZoom(1);
		}
	}, [width]);

	const handleScroll = React.useCallback(
		(e: any) => {
			const value = e.target.scrollWidth - e.target.scrollLeft === e.target.clientWidth;

			if (atEnd !== value) {
				setAtEnd(value);
			}
		},
		[atEnd]
	);

	if (cardState.id === '' || cardState.id === 'temporary') {
		return null;
	}

	return (
		<div className={styles.container}>
			<div className={styles.leftContainer}>
				<Header
					right={
						<>
							{cardState.deliveredAt === null && (
								<Button
									style={{ padding: '0 8px' }}
									type="text"
									onClick={() => dispatch(uiActions.openReceivedShareModal())}
								>
									<img style={{ width: 18, height: 18 }} src={ShareIcon} alt="" />
									{width > 576 && (
										<>
											<Box width={12} />
											Share
										</>
									)}
								</Button>
							)}
							{cardState.deliveredAt !== null && (
								<Button style={{ padding: '0 16px' }} type="raised" onClick={handleDownloadClick}>
									<img style={{ width: 18, height: 18 }} src={DownloadIcon} alt="" />
									{width > 576 && (
										<>
											<Box width={12} />
											Download
										</>
									)}
								</Button>
							)}
						</>
					}
					style={{ zIndex: 101 }}
					hideAuthentication
					title={<Typography variant="body2">This card was signed for</Typography>}
					subTitle={
						<Typography variant="h5">
							{cardState.recipientFirstName} {cardState.recipientLastName}&nbsp;
						</Typography>
					}
					relative
				/>
				<div
					style={{
						flexShrink: 0,
						marginTop: 16,
						display: 'flex',
						alignItems: 'center',
						height: width < 576 ? 'calc(100% - 142px)' : 'calc(100% - 172px)',
					}}
				>
					<div
						ref={cardContentRef}
						className={`${
							page === -1 || page === lastPage ? styles.cardContainerCover : styles.cardContainer
						}`}
						style={{
							transform:
								width >= 992
									? `scale(${
											page === -1 || page === lastPage ? (width < 992 ? zoom * 1.5 : zoom) : zoom
									  }) translateZ(0)`
									: undefined,
						}}
						onScroll={handleScroll}
					>
						{page === -1 && <img src={cardState.image} alt={'Card Preview'} />}
						{page === lastPage && (
							<div
								style={{
									padding: width < 992 ? '0 16px' : '0 90px',
									boxSizing: 'border-box',
									display: 'flex',
									flexDirection: 'column',
									position: 'relative',
									width: '100%',
									height: '100%',
									alignItems: 'center',
									justifyContent: 'space-around',
								}}
							>
								<img src={CardBackPlaceholder} alt={'Card Preview'} style={{ position: 'absolute' }} />
								<div
									style={{
										height: '100%',
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Typography
										variant={width < 992 ? 'h6' : 'h5'}
										style={{ fontWeight: 'bold', textAlign: 'center', position: 'relative' }}
									>
										We hope you’ve enjoyed using
									</Typography>
									<Box height={width < 992 ? 8 : 16} />
									<img
										style={{
											position: 'relative',
											width: width < 992 ? 42 : 128,
											height: width < 992 ? 58 : 180,
										}}
										src={Logo}
										alt={'Sign the card'}
									/>
									<Box height={width < 992 ? 8 : 16} />
									<Typography
										variant="body2"
										style={{
											fontSize: width < 992 ? '11px' : undefined,
											lineHeight: width < 992 ? '13px' : undefined,
											textAlign: 'center',
											position: 'relative',
										}}
									>
										Why not send someone else this warm fuzzy feeling too?
									</Typography>
									<Box height={width < 992 ? 8 : 16} />
									<Button
										onClick={() => history.replace('/?tab=0')}
										style={{
											fontSize: width < 992 ? '11px' : undefined,
											lineHeight: width < 992 ? '13px' : undefined,
											height: width < 992 ? 28 : undefined,
											position: 'relative',
											width: '80%',
											padding: width < 992 ? '0 3px' : '0 8px',
										}}
									>
										Create a card
									</Button>
								</div>
							</div>
						)}

						{page > -1 && page < lastPage && (
							<div
								style={{
									width: width < 992 ? 650 : undefined,
								}}
							>
								<div
									className={styles.cardContent}
									style={{
										transform:
											width < 992
												? `scale(${
														page === -1 || page === lastPage
															? width < 992
																? zoom * 1.5
																: zoom
															: zoom
												  }) translateZ(0)`
												: undefined,
									}}
								>
									<div className={styles.cardVerticalLine} />
									<div className={`${styles.cardHorizontalLine} ${styles.lineHidden}`} />
									{filteredSignatures.map((element) => (
										<StaticSignature
											style={{ transition: 'opacity ease 250ms', opacity }}
											key={element.id}
											signature={element}
											zoom={zoom}
											// noSignature
										/>
									))}
								</div>
							</div>
						)}
						{width >= 992 && page === -1 && (
							<div
								onClick={() => handleChangePage(page + 1)}
								className={styles.button}
								style={{ right: width < 992 ? -40 : -60 }}
							>
								<img src={ArrowRightIcon} alt="Right" />
							</div>
						)}
						{width >= 992 && page === lastPage && (
							<div
								onClick={() => handleChangePage(page - 1)}
								className={styles.button}
								style={{ left: width < 922 ? -40 : -60 }}
							>
								<img src={ArrowLeftIcon} alt="Left" />
							</div>
						)}
					</div>
				</div>
				<Controls
					noZoom={width < 992}
					disableZoom={width < 992}
					page={page}
					zoom={page === -1 || page === lastPage ? (width < 992 ? zoom * 2.5 : zoom) : zoom}
					setPage={handleChangePage}
					setZoom={(newZoom) => {
						if (width < 992) {
							setZoom(0.5);
						} else {
							setZoom(newZoom);
						}
					}}
				/>
				<Box height={32} />
			</div>
			<div className={styles.rightContainer}>
				<div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Typography variant="h4">Congratulations on your card</Typography>
					<Typography style={{ marginTop: 16 }} variant="body2">
						Hey {cardState.recipientFirstName} {cardState.recipientLastName}, we hope you enjoy the card
						created for you!
					</Typography>
					<Typography style={{ marginTop: 16 }} variant="body2">
						You can now download your card, share it on your social accounts, or create an account with Sign
						the Card to view your card forever.
					</Typography>
					<Box height={32} />
					<Button onClick={() => dispatch(uiActions.openSignUpModal({}))} style={{ width: 240 }}>
						Create an account
					</Button>
				</div>
				<Box width={'100%'} height={1} style={{ background: '#D0D4DB' }} />
				{!cardState.thankYouMessageSent && (
					<div
						style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
					>
						<Typography variant="h6">How about sending a message to all signers?</Typography>
						<Typography style={{ marginTop: 16 }} variant="body2">
							Send a message of thanks to everyone who signed your card. This message will be sent direct
							to their email addresses they used to sign the card.
						</Typography>
						<Box height={24} />
						<Textarea label={'Your message'} value={thankYouMessage} onChange={setThankYouMessage} />
						<Box height={32} />
						<Button
							onClick={() =>
								dispatch(cardAsyncActions.cardThankYou({ id: cardState.id, message: thankYouMessage }))
							}
							style={{ width: 240 }}
						>
							Send message
						</Button>
					</div>
				)}
				{cardState.thankYouMessageSent && (
					<div
						style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
					>
						<Typography variant="h6">Your message has been sent</Typography>
						<Typography style={{ marginTop: 16 }} variant="body2">
							Thanks for sending a message to everyone who signed your card.
						</Typography>
					</div>
				)}
			</div>
		</div>
	);
};

export default RecipientScreen;
