import React from 'react';
import { usePopper } from 'react-popper';
import styles from './index.module.scss';

interface Props {
	options: Array<string>;
	value: string;
	className?: string;
	name?: string;
	style?: React.CSSProperties;
	withArrows?: boolean;
	width?: number | 'auto' | 'full';
	onChange: (value: string) => void;
	onClick?: () => void;
	onFocus?: () => void;
	onBlur?: () => void;
}

const ColorDropdown: React.FC<Props> = ({ width = 'full', ...props }: Props) => {
	const mainRef = React.useRef<HTMLDivElement | null>(null);
	const ref = React.useRef<HTMLInputElement>(null);
	const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);
	const [popperRef, setPopperRef] = React.useState<HTMLDivElement | null>(null);
	const [arrowRef, setArrowRef] = React.useState<HTMLDivElement | null>(null);
	const { styles: popperStyles, attributes } = usePopper(containerRef, popperRef, {
		modifiers: [{ name: 'arrow', options: { element: arrowRef } }],
		placement: 'auto-start',
	});
	const [showDropdown, setShowDropdown] = React.useState<boolean>(false);

	const handleChange = React.useCallback(
		(value: string) => {
			props.onChange(value);
		},
		[props.onChange]
	);

	React.useEffect(() => {
		const mouseUp = (event: any) => {
			if (mainRef.current && !mainRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		};

		document.addEventListener('mouseup', mouseUp);

		return () => {
			document.removeEventListener('mouseup', mouseUp);
		};
	}, [mainRef]);

	React.useEffect(() => {
		const focusIn = () => {
			setShowDropdown(true);
			if (props.onFocus) {
				props.onFocus();
			}
		};
		const focusOut = () => {
			if (props.onBlur) {
				props.onBlur();
			}
		};

		if (ref.current) {
			ref.current.addEventListener('focusin', focusIn);
			ref.current.addEventListener('focusout', focusOut);
		}

		return () => {
			if (ref.current) {
				ref.current.removeEventListener('focusin', focusIn);
				ref.current.removeEventListener('focusout', focusOut);
			}
		};
	}, [ref, props.onFocus, props.onBlur]);

	return (
		<div
			ref={mainRef}
			style={{
				zIndex: 1,
				position: 'relative',
				width: typeof width === 'number' ? width : 'initial',
			}}
		>
			<div ref={setContainerRef} className={styles.container}>
				<div
					style={props.style}
					className={`${styles.inputContainer} ${props.className ? props.className : ''}`}
				>
					<div
						onClick={() => {
							setShowDropdown(true);

							if (props.onClick) {
								props.onClick();
							}
						}}
						style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
					>
						<div className={styles.color} style={{ background: props.value }} />
					</div>
				</div>
			</div>
			<div
				className={`${styles.dropdownContainer} ${!showDropdown ? styles.hidden : ''}`}
				ref={setPopperRef}
				style={popperStyles.popper}
				{...attributes.popper}
			>
				{props.options.map((element) => (
					<div
						key={element}
						style={{ width: typeof width === 'number' ? width : 'initial' }}
						onClick={() => handleChange(element)}
						className={`${styles.dropdownItem} ${props.value === 'left' ? styles.dropdownItemActive : ''}`}
					>
						<div className={styles.color} style={{ background: element }} />
					</div>
				))}
				<div ref={setArrowRef} style={popperStyles.arrow} />
			</div>
		</div>
	);
};

export default ColorDropdown;
