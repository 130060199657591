import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Box from '../../lib/components/Box';
import Dropdown from '../../lib/components/Dropdown';
import Input from '../../lib/components/Input';
import Timepicker from '../../lib/components/Timepicker';
import Typography from '../../lib/components/Typography';
import { useUserState } from '../../store/selectors';
import validateEmail from '../../utils/validateEmail';
import ChevronLeftIcon from './../../img/ic-chevron-left.svg';
import ChevronRightIcon from './../../img/ic-chevron-right.svg';
import styles from './index.module.scss';

interface Props {
	timezones: Array<string>;
	recipientFirstName: string;
	recipientEmail: string;
	date: string;
	time: string;
	deliveryTimezone: string;
	deliveredAt: string | null;
	setRecipientFirstName: (value: string) => void;
	setRecipientEmail: (value: string) => void;
	setDate: (value: string) => void;
	setTime: (value: string) => void;
	setDeliveryTimezone: (value: string) => void;
}

const RecipientTab: React.FC<Props> = (props: Props) => {
	const [emailError, setEmailError] = React.useState<string>('');

	const userState = useUserState();

	const { width } = useWindowDimensions();

	const customHeader = React.useCallback(
		({ date, decreaseMonth, increaseMonth }) => (
			<div
				style={{
					margin: 10,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div onClick={decreaseMonth} style={{ cursor: 'pointer' }}>
					<img style={{ width: 8, height: 12 }} src={ChevronLeftIcon} alt="Left" />
				</div>
				<Typography variant="body1">
					{
						[
							'January',
							'February',
							'March',
							'April',
							'May',
							'June',
							'July',
							'August',
							'September',
							'October',
							'November',
							'December',
						][(date as Date).getMonth()]
					}{' '}
					{(date as Date).getFullYear()}
				</Typography>
				<div onClick={increaseMonth} style={{ cursor: 'pointer' }}>
					<img style={{ width: 8, height: 12 }} src={ChevronRightIcon} alt="Right" />
				</div>
			</div>
		),
		[]
	);

	const handleOnEmailBlur = React.useCallback(() => {
		if (props.recipientEmail !== '' && !validateEmail(props.recipientEmail)) {
			setEmailError('Invalid email address!');
			return;
		}

		if (props.recipientEmail.toLowerCase().trim() === userState.email.toLowerCase().trim()) {
			setEmailError('You could not use your own email address!');
			return;
		}

		setEmailError('');
	}, [props.recipientEmail, userState.email]);

	return (
		<div className={styles.formContainer}>
			<Typography variant="h6">Who is this card for?</Typography>
			<Box height={24} />
			<Input
				disabled={props.deliveredAt !== null}
				value={props.recipientFirstName}
				onChange={props.setRecipientFirstName}
				label="Recipient name"
			/>
			<Input
				disabled={props.deliveredAt !== null}
				error={emailError}
				value={props.recipientEmail}
				onChange={props.setRecipientEmail}
				onBlur={handleOnEmailBlur}
				label="Recipient email address"
				type="email"
			/>
			{width >= 992 && (
				<>
					<Typography variant="body2">
						By entering an email, the card will be sent directly to the recipient's inbox.
					</Typography>
					<Box height={16} />
					<Typography variant="body2">
						A unique URL, will also be created and sent to you, if you wish to share that instead.
					</Typography>
					<Box height={24} />
				</>
			)}
			<Typography variant="h6">When will the card be sent?</Typography>
			<Box height={24} />
			<div style={{ display: 'flex', flexDirection: 'row', flexShrink: 0 }}>
				<div style={{ flexGrow: 1 }}>
					<DatePicker
						disabled={props.deliveredAt !== null}
						filterDate={(date: Date) => moment(date).isAfter(moment())}
						selected={moment(props.date, 'DD/MM/YYYY').toDate()}
						onChange={(date: Date) => props.setDate(moment(date).format('DD/MM/YYYY'))}
						renderCustomHeader={customHeader}
						dateFormat="dd/MM/yyyy"
						customInput={<Input noError={true} label="Date" />}
					/>
				</div>
				<Box width={18} />
				<div style={{ flexGrow: 1 }}>
					<Timepicker disabled={props.deliveredAt !== null} value={props.time} onChange={props.setTime} />
				</div>
			</div>
			<Box height={12} />
			<Dropdown
				disabled={props.deliveredAt !== null}
				options={props.timezones}
				value={props.deliveryTimezone}
				onChange={props.setDeliveryTimezone}
				label="Timezone"
			/>
		</div>
	);
};

export default RecipientTab;
