import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import SocketContext from '../../context/SocketContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import Controls from '../../lib/components/Controls';
import CustomCircularProgress from '../../lib/components/CustomCircularProgress';
import EditableSignature from '../../lib/components/EditableSignature';
import Header from '../../lib/components/Header';
import Modal from '../../lib/components/Modal';
import RemoveMessageModal from '../../lib/components/RemoveMessageModal';
import Typography from '../../lib/components/Typography';
import Signature from '../../models/Signature';
import SignatureMeta from '../../models/SignatureMeta';
import SignaturePosition from '../../models/SignaturePosition';
import manageCardAsyncActions from '../../store/actions/manageCard.action';
import signACardAsyncActions from '../../store/actions/signACard.action';
import RequestManager from '../../store/request-manager';
import {
	useAuthenticationState,
	useManageCardState,
	useRequestState,
	useSessionState,
	useUserState,
} from '../../store/selectors';
import { manageCardActions } from '../../store/slices/manageCard.slice';
import { signACardActions } from '../../store/slices/signACard.slice';
import { uiActions } from '../../store/slices/ui.slice';
import checkIntersection from '../../utils/checkIntersection';
import checkRectIntersection from '../../utils/checkRectIntersection';
import { OverlappingTableEntry, TimeoutHandle } from '../SignACardScreen';
import ArrowLeftIcon from './../../img/ic-arrow-left.svg';
import ArrowRightIcon from './../../img/ic-arrow-right.svg';
import DownloadIcon from './../../img/ic-download.svg';
import EnvelopeIcon from './../../img/ic-envelope.svg';
import PencilIconDisabled from './../../img/ic-pencil-primary-disabled.svg';
import PencilIcon from './../../img/ic-pencil-primary.svg';
import CardBackPlaceholder from './../../img/il-card-back-placeholder.png';
import Logo from './../../logo.svg';
import styles from './index.module.scss';

const timeoutHandles: TimeoutHandle[] = [];

const ViewMessagesScreen: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();

	const { socket, socketConnected } = React.useContext(SocketContext);

	const sessionState = useSessionState();
	const authenticationState = useAuthenticationState();
	const userState = useUserState();
	const manageCardState = useManageCardState();
	const requestState = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(requestState.updatedAt);

	const cardContentRef = React.useRef<HTMLDivElement>(null);

	// const [signatures, setSignatures] = React.useState<Signature[]>([]);

	const { width } = useWindowDimensions();
	const [opacity, setOpacity] = React.useState<number>(1);
	const [zoom, setZoom] = React.useState<number>(1);
	const [page, setPage] = React.useState<number>(0);
	const [overlappingTable, setOverlappingTable] = React.useState<OverlappingTableEntry[]>([]);
	const [signatureId, setSignatureId] = React.useState<string>('');
	const [showRemoveMessageModal, setShowRemoveMessageModal] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState(false);

	const [atEnd, setAtEnd] = React.useState<boolean>(false);

	const filteredSignatures = React.useMemo<Array<Signature>>(() => {
		return manageCardState.signatures.filter((element) => element.page === page);
	}, [manageCardState.signatures, page]);

	const lastPage = React.useMemo<number>(() => {
		const newPage = Math.max(...manageCardState.signatures.map((element) => element.page));
		if (newPage < 1) {
			return 1;
		}

		return newPage + 1;
	}, [manageCardState.signatures]);

	const isCreator = React.useMemo<boolean>(
		() => manageCardState.creator.id === userState.id,
		[manageCardState.uuid, userState.id]
	);

	const hasAnyEditableOverlapping = React.useMemo<boolean>(() => {
		for (const signature of manageCardState.signatures) {
			const findIndex = overlappingTable.findIndex(
				(element) =>
					element.signatureOneId === parseInt(signature.id, 10) ||
					element.signatureTwoId === parseInt(signature.id, 10)
			);
			if (findIndex !== -1) {
				return true;
			}
		}

		return false;
	}, [overlappingTable, manageCardState.signatures]);

	const handleChangePage = React.useCallback(
		(newPage: number) => {
			if (newPage <= -2 || newPage > lastPage) {
				return;
			}

			if (page !== -1 && page !== lastPage) {
				if (cardContentRef.current && newPage > page && !atEnd && width < 992) {
					const scrollWidth = cardContentRef.current.scrollWidth;
					const width = cardContentRef.current.clientWidth;
					const maxScrollLeft = scrollWidth - width;
					cardContentRef.current.scrollLeft = maxScrollLeft > 0 ? maxScrollLeft : 0;
					return;
				} else if (
					cardContentRef.current &&
					newPage < page &&
					cardContentRef.current.scrollLeft !== 0 &&
					width < 992
				) {
					cardContentRef.current.scrollLeft = 0;
					return;
				}

				if (cardContentRef.current && newPage > page) {
					cardContentRef.current.scrollLeft = 0;
				}
			}

			setOpacity(0);
			setTimeout(() => {
				setPage(newPage);
				setTimeout(() => {
					setOpacity(1);
				}, 0);
			}, 250);
		},
		[lastPage, page, width, cardContentRef.current, atEnd]
	);

	const handleDownloadClick = React.useCallback(() => {
		const newWindow = window.open(
			`${process.env.REACT_APP_API_URL}/download-pdf/${manageCardState.id}`,
			'_blank',
			'noopener,noreferrer'
		);
		if (newWindow) newWindow.opener = null;
	}, [manageCardState]);

	const handleOnRemoveSignature = React.useCallback(() => {
		if (!isCreator || signatureId === '') {
			return;
		}

		dispatch(signACardActions.removeSignature(signatureId));
		dispatch(signACardAsyncActions.moderateRemoveSignature({ id: signatureId }));
		setSignatureId('');
		setShowRemoveMessageModal(false);
	}, [dispatch, isCreator, signatureId]);

	const handleRemoveSignature = React.useCallback((id: string) => {
		setSignatureId(id);
		setShowRemoveMessageModal(true);
	}, []);

	const checkIsOverlapping = React.useCallback(
		(id: string) =>
			overlappingTable.findIndex(
				(element) =>
					element.page === page &&
					(element.signatureOneId === parseInt(id, 10) || element.signatureTwoId === parseInt(id, 10))
			) !== -1,
		[overlappingTable, page]
	);

	const handleSetPosition = React.useCallback(
		(id: string, position: SignaturePosition) => {
			const findIndex = manageCardState.signatures.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(id, 10)
			);
			if (findIndex === -1) {
				return;
			}

			// Prevent from infinite loop.
			if (JSON.stringify(position) === JSON.stringify(manageCardState.signatures[findIndex].position)) {
				return;
			}

			dispatch(manageCardActions.moveSignature({ id, position }));

			if (checkIsOverlapping(id)) {
				return;
			}

			const findTimeoutHandle = timeoutHandles.findIndex((element) => element.id === parseInt(id, 10));
			if (findTimeoutHandle !== -1) {
				clearTimeout(timeoutHandles[findTimeoutHandle].timeoutId);
				timeoutHandles.splice(findTimeoutHandle, 1);
			}

			timeoutHandles.push({
				id: parseInt(id, 10),
				timeoutId: setTimeout(() => {
					dispatch(
						signACardAsyncActions.moderateMoveSignature({
							id,
							position,
						})
					);
				}, 1000),
			});
		},
		[
			authenticationState.isAuthenticated,
			manageCardState.id,
			manageCardState.signatures,
			overlappingTable,
			checkIsOverlapping,
		]
	);

	const revalidateSignatures = React.useCallback(() => {
		const newOverlappingTable: OverlappingTableEntry[] = [];

		for (const signature1 of manageCardState.signatures) {
			for (const signature2 of manageCardState.signatures) {
				const parsedId = parseInt(signature1.id, 10);
				const parsedId2 = parseInt(signature2.id, 10);

				if (parsedId === parsedId2) {
					continue;
				}

				if (signature1.page !== signature2.page) {
					continue;
				}

				if (
					checkIntersection(
						[
							{ x: signature1.position.x, y: signature1.position.y },
							{ x: signature1.position.x + signature1.position.width, y: signature1.position.y },
							{ x: signature1.position.x, y: signature1.position.y + signature1.position.height },
							{
								x: signature1.position.x + signature1.position.width,
								y: signature1.position.y + signature1.position.height,
							},
						],
						[
							{ x: signature2.position.x, y: signature2.position.y },
							{ x: signature2.position.x + signature2.position.width, y: signature2.position.y },
							{ x: signature2.position.x, y: signature2.position.y + signature2.position.height },
							{
								x: signature2.position.x + signature2.position.width,
								y: signature2.position.y + signature2.position.height,
							},
						]
					) ||
					checkRectIntersection(signature1.position, signature2.position)
				) {
					newOverlappingTable.push({
						page: signature1.page,
						signatureOneId: parsedId,
						signatureTwoId: parsedId2,
					});
				}
			}
		}

		setOverlappingTable(newOverlappingTable);
	}, [manageCardState.signatures]);

	React.useEffect(() => {
		dispatch(manageCardAsyncActions.show({ id }));
	}, [dispatch, id]);

	React.useEffect(() => {
		if (width <= 320) {
			setZoom(0.7);
		} else if (width < 576) {
			setZoom(0.8);
		} else {
			setZoom(1);
		}
	}, [width]);

	React.useEffect(() => {
		if (requestUpdatedAt === requestState.updatedAt) return;
		const RM = new RequestManager(requestState);

		if (
			RM.isPending(signACardAsyncActions.moderateMoveSignature.typePrefix) ||
			RM.isPending(signACardAsyncActions.moderateRemoveSignature.typePrefix)
		) {
			setIsLoading(true);
		}

		if (
			RM.isFinished(signACardAsyncActions.moderateMoveSignature.typePrefix) ||
			RM.isFinished(signACardAsyncActions.moderateRemoveSignature.typePrefix)
		) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(manageCardAsyncActions.show.typePrefix)) {
			RM.consume(manageCardAsyncActions.show.typePrefix);

			// setSignatures([...manageCardState.signatures]);
		}

		if (RM.isFulfilled(signACardAsyncActions.moderateRemoveSignature.typePrefix)) {
			RM.consume(signACardAsyncActions.moderateRemoveSignature.typePrefix);

			dispatch(manageCardAsyncActions.show({ id }));
		}

		if (RM.isFulfilled(signACardAsyncActions.moderateMoveSignature.typePrefix)) {
			RM.consume(signACardAsyncActions.moderateMoveSignature.typePrefix);

			// dispatch(manageCardAsyncActions.show({ id }));
		}
	}, [requestUpdatedAt, requestState.updatedAt]);

	React.useEffect(() => {
		revalidateSignatures();
	}, [manageCardState.signatures, page]);

	React.useEffect(() => {
		if (manageCardState.uuid !== id) {
			return;
		}

		if (socket === null || socket === undefined || !socketConnected) {
			return;
		}

		socket.emit('card:register', id);

		const onSignatureReceived = (signature: Signature, fromModerator = false) => {
			if (fromModerator) {
				return;
			}

			dispatch(manageCardActions.updateSignature(signature));
		};

		socket.on('signature:received', onSignatureReceived);

		const onSignatureMetaUpdate = (fromSessionId: string, signatureId: string, meta: SignatureMeta) => {
			if (fromSessionId === sessionState.sessionId) {
				return;
			}

			dispatch(
				manageCardActions.metaUpdateSignature({
					id: signatureId,
					meta,
				})
			);
		};

		socket.on('signature:meta-update', onSignatureMetaUpdate);

		const onSignatureMove = (
			fromSessionId: string,
			signatureId: string,
			position: SignaturePosition,
			fromModerator = false
		) => {
			if (fromSessionId === sessionState.sessionId || fromModerator) {
				return;
			}

			dispatch(
				manageCardActions.moveSignature({
					id: signatureId,
					position,
				})
			);
		};

		socket.on('signature:move', onSignatureMove);

		const onSignatureRemoved = (_: string, signatureId: string) => {
			dispatch(manageCardActions.socketRemoveSignature(signatureId));
		};

		socket.on('signature:removed', onSignatureRemoved);

		return () => {
			socket.off('signature:received', onSignatureReceived);
			socket.off('signature:meta-update', onSignatureMetaUpdate);
			socket.off('signature:move', onSignatureMove);
			socket.off('signature:removed', onSignatureRemoved);
			socket.emit('card:un-register', id);
		};
	}, [manageCardState.uuid, socket, socketConnected, sessionState.sessionId]);

	const handleScroll = React.useCallback(
		(e: any) => {
			const value = e.target.scrollWidth - e.target.scrollLeft === e.target.clientWidth;

			if (atEnd !== value) {
				setAtEnd(value);
			}
		},
		[atEnd]
	);

	// React.useEffect(() => setSignatures([...manageCardState.signatures]), [manageCardState.socketUpdatedAt]);

	if (manageCardState.uuid !== id) {
		return (
			<div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
				<CustomCircularProgress size={48} color={'#EE584B'} />
			</div>
		);
	}

	// if (isLoading) {
	// 	return (
	// 		<div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
	// 			<CustomCircularProgress size={48} color={'#EE584B'} />
	// 		</div>
	// 	);
	// }

	return (
		<>
			<Modal isVisible={showRemoveMessageModal} setIsVisible={setShowRemoveMessageModal}>
				<RemoveMessageModal onSubmit={handleOnRemoveSignature} />
			</Modal>
			<div className={styles.container}>
				<div className={styles.leftContainer}>
					<Header
						right={
							<>
								{manageCardState.deliveredAt !== null && width >= 992 && (
									<Button style={{ padding: '0 16px' }} type="raised" onClick={handleDownloadClick}>
										<img style={{ width: 18, height: 18 }} src={DownloadIcon} alt="" />
										{width > 576 && (
											<>
												<Box width={12} />
												Download
											</>
										)}
									</Button>
								)}
							</>
						}
						style={{ zIndex: 101 }}
						hideAuthentication
						title={<Typography variant="body2">View card messages for</Typography>}
						subTitle={
							<Typography variant="h5">
								{manageCardState.recipientFirstName} {manageCardState.recipientLastName}&nbsp;
							</Typography>
						}
						relative
					/>
					<div
						style={{
							flexShrink: 0,
							marginTop: 16,
							display: 'flex',
							alignItems: 'center',
							height: width < 576 ? 'calc(100% - 202px)' : 'calc(100% - 172px)',
						}}
					>
						<div
							ref={cardContentRef}
							className={`${
								page === -1 || page === lastPage ? styles.cardContainerCover : styles.cardContainer
							}`}
							style={{
								transform:
									width >= 992
										? `scale(${
												page === -1 || page === lastPage
													? width < 992
														? zoom * 1.5
														: zoom
													: zoom
										  }) translateZ(0)`
										: undefined,
							}}
							onScroll={handleScroll}
						>
							{page === -1 && <img src={manageCardState.image} alt={'Card Preview'} />}
							{page === lastPage && (
								<div
									style={{
										padding: width < 992 ? '0 16px' : '0 24px',
										boxSizing: 'border-box',
										display: 'flex',
										flexDirection: 'column',
										position: 'relative',
										width: '100%',
										height: '100%',
										alignItems: 'center',
										justifyContent: 'space-around',
									}}
								>
									<img
										src={CardBackPlaceholder}
										alt={'Card Preview'}
										style={{ position: 'absolute' }}
									/>
									<div
										style={{
											height: '100%',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<Typography
											variant={width < 992 ? 'h6' : 'h5'}
											style={{ fontWeight: 'bold', textAlign: 'center', position: 'relative' }}
										>
											We hope you’ve enjoyed using
										</Typography>
										<Box height={width < 992 ? 8 : 16} />
										<img
											style={{
												position: 'relative',
												width: width < 992 ? 42 : 98,
												height: width < 992 ? 58 : 140,
											}}
											src={Logo}
											alt={'Sign the card'}
										/>
										<Box height={width < 992 ? 8 : 16} />
										<Typography
											variant="body2"
											style={{
												fontSize: width < 992 ? '11px' : undefined,
												lineHeight: width < 992 ? '13px' : undefined,
												textAlign: 'center',
												position: 'relative',
											}}
										>
											Why not send someone else this warm fuzzy feeling too?
										</Typography>
										<Box height={width < 992 ? 8 : 16} />
										<Button
											onClick={() => history.replace('/?tab=0')}
											style={{
												fontSize: width < 992 ? '11px' : undefined,
												lineHeight: width < 992 ? '13px' : undefined,
												height: width < 992 ? 28 : undefined,
												position: 'relative',
												width: '80%',
												padding: width < 992 ? '0 3px' : '0 8px',
											}}
										>
											Create a card
										</Button>
									</div>
								</div>
							)}

							{page > -1 && page < lastPage && (
								<div
									style={{
										width: width < 992 ? 650 : undefined,
									}}
								>
									<div
										className={styles.cardContent}
										style={{
											transform:
												width < 992
													? `scale(${
															page === -1 || page === lastPage
																? width < 992
																	? zoom * 1.5
																	: zoom
																: zoom
													  }) translateZ(0)`
													: undefined,
										}}
									>
										<div className={styles.cardVerticalLine} />
										<div className={`${styles.cardHorizontalLine} ${styles.lineHidden}`} />
										{filteredSignatures.map((element) => (
											<EditableSignature
												key={element.id}
												cardId={id}
												disabled={false}
												style={{ transition: 'opacity ease 250ms', opacity }}
												mode="view"
												signatureId={element.id}
												name={
													element.user === null
														? element.name !== null
															? element.name
															: ''
														: `${element.user.firstName} ${element.user.lastName}`
												}
												inEditingMode={false}
												removeSignature={handleRemoveSignature}
												setPosition={handleSetPosition}
												zoom={zoom}
												overlappingTable={overlappingTable}
												revalidateSignatures={revalidateSignatures}
												moderate
											/>
										))}
									</div>
								</div>
							)}
							{width >= 992 && page === -1 && (
								<div
									onClick={() => handleChangePage(0)}
									className={styles.button}
									style={{ right: width < 992 ? -40 : -60 }}
								>
									<img src={ArrowRightIcon} alt="Right" />
								</div>
							)}
							{width >= 992 && page === lastPage && (
								<div
									onClick={() => handleChangePage(page - 1)}
									className={styles.button}
									style={{ left: width < 922 ? -40 : -60 }}
								>
									<img src={ArrowLeftIcon} alt="Left" />
								</div>
							)}
						</div>
					</div>
					{width < 992 && (
						<div
							style={{
								padding: '0 8px',
								fontSize: '1.5rem',
								display: 'flex',
								flexDirection: 'row',
								alignSelf: 'center',
								alignItems: 'center',
								marginBottom: 16,
							}}
						>
							<span>&lt;</span>
							<Button
								style={{ height: 32, margin: '3px 8px 0', padding: 0 }}
								onClick={() => history.replace('/?tab=1')}
								type={'text'}
							>
								Return to my profile
							</Button>
						</div>
					)}
					<Controls
						noZoom={width < 992}
						disableZoom={width < 992}
						page={page}
						zoom={zoom}
						setPage={handleChangePage}
						setZoom={setZoom}
						noPageLabel={width < 992}
					/>
					<Box height={32} />
				</div>
				<div className={styles.rightContainer}>
					<div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
						<Typography variant="h4">
							{manageCardState.recipientFirstName}
							{manageCardState.recipientLastName !== '' ? ` ${manageCardState.recipientLastName}` : ''}
							's Card
						</Typography>
						<Typography style={{ marginTop: 16 }} variant="h6">
							Recipient details
						</Typography>
						<Typography variant="body2">
							{manageCardState.recipientFirstName} {manageCardState.recipientLastName}
						</Typography>
						<Typography variant="body2">{manageCardState.recipientEmail}</Typography>
						<Typography style={{ marginTop: 16 }} variant="body2">
							Card {manageCardState.deliveredAt === null ? 'will be' : 'was'} delivered on{' '}
							<span style={{ fontWeight: 600 }}>
								{manageCardState.deliveryAt} ({manageCardState.deliveryTimezone})
							</span>
						</Typography>
						{manageCardState.isContributionEnabled && (
							<Typography style={{ marginTop: 16 }} variant="body2">
								<span style={{ fontWeight: 600 }}>{manageCardState.contributors.length}</span> people
								contributed to {manageCardState.recipientFirstName}'s gift of{' '}
								<span style={{ fontWeight: 600 }}>
									{manageCardState.contribution === -1
										? 'Unlimited'
										: `$${manageCardState.contribution.toFixed(2)}`}
								</span>{' '}
								so far
							</Typography>
						)}
					</div>
					<Box width={'100%'} height={1} style={{ background: '#D0D4DB' }} />
					<Typography variant="body2" style={{ fontStyle: 'italic', textAlign: 'center' }}>
						As the creator of this card, you are able to delete or move anything that contributors have
						added by simply selecting it. Deleting a message will also remove the associated media too.
					</Typography>
					<Box width={'100%'} height={1} style={{ background: '#D0D4DB' }} />
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							flexShrink: 0,
						}}
					>
						{hasAnyEditableOverlapping && (
							<>
								<Typography
									style={{ color: '#ee584b', fontWeight: 'bold', textAlign: 'center' }}
									variant="body2"
								>
									Error! You have overlapping elements.
									<br />
									Please adjust your layout before continuing.
								</Typography>
								<Box height={12} />
							</>
						)}
						{manageCardState.deliveredAt === null && (
							<>
								<Button
									disabled={hasAnyEditableOverlapping}
									style={{ padding: '0 8px', width: width < 992 ? '70%' : '60%' }}
									onClick={() => dispatch(uiActions.openShareModal({ noContributors: true }))}
								>
									<img src={EnvelopeIcon} alt="" />
									<Box width={12} />
									Invite Others to Sign
								</Button>
								<Box height={12} />
							</>
						)}
						{width >= 992 && manageCardState.deliveredAt === null && (
							<>
								<Button
									disabled={hasAnyEditableOverlapping}
									style={{
										padding: '0 8px',
										width: '60%',
										color: hasAnyEditableOverlapping ? '#fdebe9' : '#ee584b',
										backgroundColor: hasAnyEditableOverlapping ? '#FFFFFF' : undefined,
									}}
									className={styles.headerButton}
									type="outlined"
									onClick={() => history.replace(`/sign-a-card/${manageCardState.uuid}`)}
								>
									{hasAnyEditableOverlapping ? (
										<img src={PencilIconDisabled} alt="" />
									) : (
										<img src={PencilIcon} alt="" />
									)}
									<Box width={12} />
									Edit Message
								</Button>
								<Box height={12} />
							</>
						)}
						<Button
							disabled={hasAnyEditableOverlapping}
							type="text"
							style={{ padding: '0 16px', width: width < 992 ? '70%' : '60%' }}
							onClick={() => history.replace('/?tab=1')}
						>
							View my Profile
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewMessagesScreen;
