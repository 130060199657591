import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { API } from 'lib/util';
import { RootState } from '..';
import Card from '../../models/Card';
import ShowCardRequest from '../../network/requests/ShowCardRequest';
import ToggleCardContributorsRequest from '../../network/requests/ToggleCardContributorsRequest';
import UpdateCardRequest from '../../network/requests/UpdateCardRequest';
import { ManageCardState } from '../slices/manageCard.slice';
import { requestActions } from '../slices/request.slice';
import { SessionState } from '../slices/session.slice';

const show = createAsyncThunk('manage-card/show', async ({ id }: ShowCardRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(show.typePrefix));
	try {
		const sessionState: SessionState = (thunkApi.getState() as RootState).session;

		const response = await API.post<Omit<ShowCardRequest, 'id'>, AxiosResponse<Card>>(`/card/${id}`, {
			sessionId: sessionState.sessionId,
		});

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ error });
	}
});

const update = createAsyncThunk('manage-card/update', async (_, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	try {
		const card: ManageCardState = (thunkApi.getState() as RootState).manageCard;

		const response = await API.put<Omit<UpdateCardRequest, 'id'>, AxiosResponse<Card>>(`/card/${card.id}`, {
			recipientFirstName: card.recipientFirstName,
			recipientLastName: card.recipientLastName,
			recipientEmail: card.recipientEmail,
			image: card.image,
			isContributionEnabled: card.isContributionEnabled,
			contribution: card.contribution,
			deliveryAt: card.deliveryAt,
			deliveryTimezone: card.deliveryTimezone,
			isReminderEnabled: card.isReminderEnabled,
			reminderMessage: card.reminderMessage,
		});

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ error });
	}
});

const addContributors = createAsyncThunk(
	'manage-card/add-contributors',
	async ({ id, ...payload }: ToggleCardContributorsRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(addContributors.typePrefix));
		try {
			const response = await API.post<Omit<ToggleCardContributorsRequest, 'id'>, AxiosResponse<Card>>(
				`/card/${id}/add-contributors`,
				payload
			);

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const removeContributors = createAsyncThunk(
	'manage-card/remove-contributors',
	async ({ id, ...payload }: ToggleCardContributorsRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(removeContributors.typePrefix));
		try {
			const response = await API.post<Omit<ToggleCardContributorsRequest, 'id'>, AxiosResponse<Card>>(
				`/card/${id}/remove-contributors`,
				payload
			);

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const manageCardAsyncActions = {
	show,
	update,
	addContributors,
	removeContributors,
};

export default manageCardAsyncActions;
