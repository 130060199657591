import { Font } from '../declarations';

const getFonts = (): Array<Font> => {
	return [
		{
			name: 'Raleway',
			fontFamily: '"Raleway", sans-serif',
		},
		{
			name: 'Caveat',
			fontFamily: '"Caveat", cursive',
		},
		{
			name: 'Lobster',
			fontFamily: '"Lobster", cursive',
		},
		{
			name: 'Pacifico',
			fontFamily: '"Pacifico", cursive',
		},
		{
			name: 'Roboto',
			fontFamily: '"Roboto", sans-serif',
		},
		{
			name: 'Bree Serif',
			fontFamily: '"Bree Serif", serif',
		},
		{
			name: 'Arial',
			fontFamily: '"Arial", sans-serif',
		},
		{
			name: 'Comic Neue',
			fontFamily: '"Comic Neue", cursive',
		},
		{
			name: 'Open Sans',
			fontFamily: '"Open Sans", sans-serif',
		},
		{
			name: 'Serif',
			fontFamily: '"Source Serif Pro", serif',
		},
	];
};

export default getFonts;
