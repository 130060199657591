import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { API } from 'lib/util';
import { RootState } from '..';
import User from '../../models/User';
import FinalizePayPalTransactionRequest from '../../network/requests/FinalizePayPalTransactionRequest';
import InitializePayPalTransactionRequest from '../../network/requests/InitializePayPalTransactionRequest';
import OkResponse from '../../network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';
import { SessionState } from '../slices/session.slice';

const initialize = createAsyncThunk(
	'transactions/initialize',
	async (payload: InitializePayPalTransactionRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(initialize.typePrefix));
		try {
			const sessionState: SessionState = (thunkApi.getState() as RootState).session;

			const response = await API.post<
				InitializePayPalTransactionRequest & { sessionId: string },
				AxiosResponse<OkResponse>
			>('/transactions/initialize', {
				sessionId: sessionState.sessionId,
				...payload,
			});

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const finalize = createAsyncThunk(
	'transactions/finalize',
	async (payload: FinalizePayPalTransactionRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(finalize.typePrefix));
		try {
			const sessionState: SessionState = (thunkApi.getState() as RootState).session;

			const response = await API.post<
				FinalizePayPalTransactionRequest & { sessionId: string },
				AxiosResponse<User>
			>('/transactions/finalize', {
				sessionId: sessionState.sessionId,
				...payload,
			});

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const transactionsAsyncActions = {
	initialize,
	finalize,
};

export default transactionsAsyncActions;
