import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

// Inspired by the former Facebook spinners.
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			position: 'relative',
		},
		bottom: {
			color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
		},
		top: {
			color: '#FFFFFF',
			animationDuration: '550ms',
			position: 'absolute',
			left: 0,
		},
		circle: {
			strokeLinecap: 'round',
		},
	})
);

interface Props {
	size?: number;
	thickness?: number;
	color?: string;
}

const CustomCircularProgress: React.FC<Props> = ({ size = 20, thickness = 4, color = '#FFFFFF', ...props }: Props) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CircularProgress
				variant="determinate"
				className={classes.bottom}
				size={size}
				thickness={thickness}
				{...props}
				value={100}
			/>
			<CircularProgress
				variant="indeterminate"
				disableShrink
				className={classes.top}
				style={{ color }}
				classes={{
					circle: classes.circle,
				}}
				size={size}
				thickness={thickness}
				{...props}
			/>
		</div>
	);
};

export default CustomCircularProgress;
