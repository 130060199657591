import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import Header from '../../lib/components/Header';
import Separator from '../../lib/components/Separator';
import Typography from '../../lib/components/Typography';
import { uiActions } from '../../store/slices/ui.slice';
import ArrowLeft from './../../img/ic-chevron-left.svg';
import ArrowRight from './../../img/ic-chevron-right.svg';
import Onboarding1 from './../../img/il-onboarding-step-1.webp';
import Onboarding2 from './../../img/il-onboarding-step-2.webp';
import Onboarding3 from './../../img/il-onboarding-step-3.webp';
import Onboarding4 from './../../img/il-onboarding-step-4.webp';
import PayPalLogo from './../../img/il-paypal-logo.svg';
import styles from './index.module.scss';

const GuestScreen: React.FC = () => {
	const dispatch = useDispatch();
	const { width } = useWindowDimensions();
	const location = useLocation();
	const { code } = useParams<{ code: string }>();

	const CAROUSEL_PAGES = 4;
	const [carouselIndex, setCarouselIndex] = React.useState<number>(0);
	const setCarouselItem = React.useCallback((index: number) => {
		setCarouselIndex(index);
	}, []);

	const handleCreateCard = React.useCallback(() => {
		dispatch(uiActions.openSignUpModal({}));
	}, [dispatch]);

	const handleViewCards = React.useCallback(() => {
		dispatch(uiActions.openSignInModal({ redirectUrl: '/?tab=1' }));
	}, [dispatch]);

	React.useEffect(() => {
		if (location.pathname.includes('/about-us')) {
			dispatch(uiActions.openNavigationModal({ index: 0 }));
		}

		if (location.pathname.includes('/how-it-works')) {
			dispatch(uiActions.openNavigationModal({ index: 1 }));
		}

		if (location.pathname.includes('/faqs')) {
			dispatch(uiActions.openNavigationModal({ index: 2 }));
		}

		if (location.pathname.includes('/website-terms')) {
			dispatch(uiActions.openNavigationModal({ index: 3 }));
		}

		if (location.pathname.includes('/terms-of-use')) {
			dispatch(uiActions.openNavigationModal({ index: 4 }));
		}

		if (location.pathname.includes('/privacy-policy')) {
			dispatch(uiActions.openNavigationModal({ index: 5 }));
		}

		if (location.pathname.includes('/fees')) {
			dispatch(uiActions.openNavigationModal({ index: 6 }));
		}

		if (location.pathname.includes('/reset-password')) {
			dispatch(uiActions.openResetPasswordModal({ code }));
		}
	}, [dispatch, location, code]);

	return (
		<div style={{ height: '100%', overflow: 'hidden', overflowY: 'auto' }}>
			<section className={styles.container}>
				<div className={styles.backgroundLeft} />
				<div className={styles.backgroundRight} />
				<Header style={{ zIndex: 5, position: 'static', top: 0, padding: '16px 16px' }} />
				<div className={styles.mainContainer}>
					<section className={styles.leftContainer}>
						<div className={styles.carouselWrapper}>
							<div
								style={{ display: 'flex', flexDirection: 'row', height: '100%', alignItems: 'center' }}
							>
								<div
									className={`${styles.carouselArrow} ${styles.carouselArrowLeft}`}
									onClick={async () => {
										if (carouselIndex > 0) {
											setCarouselIndex(carouselIndex - 1);
										} else {
											setCarouselIndex(CAROUSEL_PAGES - 1);
										}
									}}
								>
									<img src={ArrowLeft} alt={'Left'} />
								</div>
								<div className={styles.carouselContainer}>
									<div
										className={`${styles.carouselItem} ${carouselIndex === 0 ? styles.active : ''}`}
									>
										<div>
											<img src={Onboarding1} alt="Onboarding 1" />
										</div>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Typography
												style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}
												variant="h1"
											>
												Step 1
											</Typography>
											<Typography
												style={{
													textAlign: 'center',
													fontSize: '2.667rem',
													lineHeight: '3.167rem',
													fontWeight: 'bold',
												}}
												variant="body1"
											>
												Create & Share
											</Typography>
											<Box height={18} />
											<Typography style={{ textAlign: 'center' }} variant="body1">
												Upload or select a card design, enter in the card recipient details, add
												a financial gift collection & share.
											</Typography>
										</div>
									</div>
									<div
										className={`${styles.carouselItem} ${carouselIndex === 1 ? styles.active : ''}`}
									>
										<div>
											<img src={Onboarding2} alt="Onboarding 2" />
										</div>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Typography
												style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}
												variant="h1"
											>
												Step 2
											</Typography>
											<Typography
												style={{
													textAlign: 'center',
													fontSize: '2.667rem',
													lineHeight: '3.167rem',
													fontWeight: 'bold',
												}}
												variant="body1"
											>
												Sign the Card
											</Typography>
											<Box height={18} />
											<Typography style={{ textAlign: 'center' }} variant="body1">
												Upload a personal written, picture or video message, or add a gif with
												Giphy!
											</Typography>
										</div>
									</div>
									<div
										className={`${styles.carouselItem} ${carouselIndex === 2 ? styles.active : ''}`}
									>
										<div>
											<img
												style={{ position: 'relative', bottom: '40px' }}
												src={Onboarding3}
												alt="Onboarding 3"
											/>
										</div>
										<div
											style={{
												position: 'relative',
												zIndex: 2,
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Typography
												style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}
												variant="h1"
											>
												Step 3
											</Typography>
											<Typography
												style={{
													textAlign: 'center',
													fontSize: '2.667rem',
													lineHeight: '3.167rem',
													fontWeight: 'bold',
												}}
												variant="body1"
											>
												Contribute
											</Typography>
											<Box height={18} />
											<Typography style={{ textAlign: 'center' }} variant="body1">
												Make a digital payment towards a gift collection securely with PayPal.
											</Typography>
										</div>
									</div>
									<div
										className={`${styles.carouselItem} ${carouselIndex === 3 ? styles.active : ''}`}
									>
										<div>
											<img src={Onboarding4} alt="Onboarding 4" />
										</div>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Typography
												style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}
												variant="h1"
											>
												Step 4
											</Typography>
											<Typography
												style={{
													textAlign: 'center',
													fontSize: '2.667rem',
													lineHeight: '3.167rem',
													fontWeight: 'bold',
												}}
												variant="body1"
											>
												Send & Receive
											</Typography>
											<Box height={18} />
											<Typography style={{ textAlign: 'center' }} variant="body1">
												A digital card is sent to the recipient, while the financial
												contributions can be withdrawn to purchase a personal gift.
											</Typography>
										</div>
									</div>
								</div>
								<div
									className={`${styles.carouselArrow} ${styles.carouselArrowRight}`}
									onClick={async () => {
										if (carouselIndex < CAROUSEL_PAGES - 1) {
											setCarouselIndex(carouselIndex + 1);
										} else {
											setCarouselIndex(0);
										}
									}}
								>
									<img src={ArrowRight} alt={'Right'} />
								</div>
							</div>
							<div className={styles.carouselControls}>
								<div
									onClick={() => setCarouselItem(0)}
									className={`${styles.carouselDot} ${carouselIndex === 0 ? styles.activeDot : ''}`}
								/>
								<div
									onClick={() => setCarouselItem(1)}
									className={`${styles.carouselDot} ${carouselIndex === 1 ? styles.activeDot : ''}`}
								/>
								<div
									onClick={() => setCarouselItem(2)}
									className={`${styles.carouselDot} ${carouselIndex === 2 ? styles.activeDot : ''}`}
								/>
								<div
									onClick={() => setCarouselItem(3)}
									className={`${styles.carouselDot} ${carouselIndex === 3 ? styles.activeDot : ''}`}
								/>
							</div>
						</div>
					</section>
					<section className={styles.rightContainer}>
						<Typography variant="body2" style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
							create, sign, contribute & send!
						</Typography>
						<Typography variant={width < 576 ? 'h2' : 'h1'} style={{ textAlign: 'center' }}>
							Sign a card
							<br />
							together
						</Typography>
						<Box height={'2rem'} />
						<Button onClick={handleCreateCard}>Create a card</Button>
						<Box height={'2rem'} />
						<Separator />
						<Box height={'1.75rem'} />
						<Button className={styles.button} onClick={handleViewCards} type={'text'}>
							View my profile
						</Button>
					</section>
				</div>
				<Box
					style={{
						position: 'relative',
						zIndex: 5,
						padding: width < 992 ? '16px 0' : '32px',
						textAlign: 'center',
						display: 'flex',
						flexDirection: width < 992 ? 'column' : 'row',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{width >= 992 && <Box style={{ flexGrow: 1 }} />}
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Typography variant="body1" style={{ fontSize: '0.917rem' }}>
							All card payments made securely with
						</Typography>
						<Box width={12} />
						<img style={{ height: width < 576 ? 20 : 25 }} src={PayPalLogo} alt={'PayPal'} />
					</div>
					{width >= 992 && <Box style={{ flexGrow: 1 }} />}
					{width < 992 && <Box height={16} />}
					<Typography variant="body1" style={{ fontSize: '0.917rem', flexShrink: 0 }}>
						&copy; 2021 Sign the Card
					</Typography>
				</Box>
			</section>
		</div>
	);
};

export default GuestScreen;
