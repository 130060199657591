import { toast } from 'react-hot-toast';
import ErrorResponse from '../network/responses/ErrorResponse';
import { requestActions } from './slices/request.slice';
import { uiActions } from './slices/ui.slice';
import { CPA } from './types';

const postErrorRequest = (state: any, action: CPA<ErrorResponse>, initialState: any) => {
	if (action.payload.error.list[0].msg !== 'unknown_signature') {
		toast.error(action.payload.error.list[0].msg);
	}

	if (action.payload.error.status === 401) {
		action.dispatch(uiActions.openSignInModal({}));
		state = initialState;
	}

	action.dispatch(
		requestActions.rejected({
			name: action.type,
			message: '',
			payload: {},
		})
	);

	return state;
};

export default postErrorRequest;
