import React from 'react';
import { useDispatch } from 'react-redux';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { useCardState } from '../../../store/selectors';
import { uiActions } from '../../../store/slices/ui.slice';
import Box from '../Box';
import Button from '../Button';
import Typography from '../Typography';
import EmailIcon from './../../../img/ic-envelope-primary.svg';
import CopyIcon from './../../../img/ic-link-black.svg';
import FacebookIcon from './../../../img/ic-social-facebook.svg';
import GoogleIcon from './../../../img/ic-social-google.svg';
import LinkedInIcon from './../../../img/ic-social-linkedin.svg';
import styles from './index.module.scss';

interface Props {}

const ShareRecipientModal: React.FC<Props> = () => {
	const dispatch = useDispatch();

	const { width } = useWindowDimensions();

	const cardState = useCardState();

	const copyLink = React.useCallback(() => {
		navigator.clipboard.writeText(`https://signthecard.com/received/${cardState.uuid}`);
		dispatch(uiActions.closeModals());
	}, [dispatch, cardState]);

	return (
		<div className={styles.container}>
			<Typography variant="h3" style={{ textAlign: 'center' }}>
				Show off your card
			</Typography>
			<Typography variant="body2" style={{ textAlign: 'center' }}>
				Share your card on your social channels.
			</Typography>
			<Box height={width < 992 ? 16 : 32} />
			<Button onClick={() => {}} style={{ width: 230, padding: '0 4px' }} type={'outlined'}>
				<img src={LinkedInIcon} alt={'LinkedIn'} />
				<div style={{ width: 8 }} />
				Share on LinkedIn
			</Button>
			<Box height={24} />
			<Button onClick={() => {}} style={{ width: 230, padding: '0 4px' }} type={'outlined'}>
				<img src={GoogleIcon} alt={'Google'} />
				<div style={{ width: 8 }} />
				Share on Google
			</Button>
			<Box height={24} />
			<Button onClick={() => {}} style={{ width: 230, padding: '0 4px' }} type={'outlined'}>
				<img src={FacebookIcon} alt={'Facebook'} />
				<div style={{ width: 8 }} />
				Share on Facebook
			</Button>
			<Box height={24} />
			<Button onClick={() => {}} style={{ width: 230, padding: '0 4px' }} type={'outlined'}>
				<img src={EmailIcon} alt={'Email'} />
				<div style={{ width: 8 }} />
				Share via Email
			</Button>
			<Box height={24} />
			<Button onClick={copyLink} style={{ width: 230, padding: '0 4px' }} type={'outlined'}>
				<img style={{ width: 18 }} src={CopyIcon} alt={'Copy Link'} />
				<div style={{ width: 8 }} />
				Copy Link
			</Button>
		</div>
	);
};

export default ShareRecipientModal;
