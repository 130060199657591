const getColors = (): Array<string> => {
	return [
		'#FFFFFF',
		'#FFF500',
		'#FFC600',
		'#EE584B',
		'#E6E6E6',
		'#C7E900',
		'#79D430',
		'#EE0062',
		'#808080',
		'#00D1D6',
		'#00A787',
		'#A300B2',
		'#1A1A1A',
		'#009CF7',
		'#4048B9',
		'#6E20BA',
	];
};

export default getColors;
