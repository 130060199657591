import SignaturePosition from '../models/SignaturePosition';

const checkRectIntersection = (position1: SignaturePosition, position2: SignaturePosition) => {
	const aLeftOfB = position1.x + position1.width < position2.x;
	const aRightOfB = position1.x > position2.x + position2.width;
	const aAboveB = position1.y > position2.y + position2.height;
	const aBelowB = position1.y + position1.height < position2.y;

	return !(aLeftOfB || aRightOfB || aAboveB || aBelowB);
};

export default checkRectIntersection;
