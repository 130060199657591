import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import Header from '../../lib/components/Header';
import Typography from '../../lib/components/Typography';
import { useCreateCardState } from '../../store/selectors';
import { createCardActions } from '../../store/slices/createCard.slice';
import { uiActions } from '../../store/slices/ui.slice';
import CheckIcon from './../../img/ic-check.svg';
import ShareIcon from './../../img/ic-share-primary.svg';
import styles from './index.module.scss';

const CardCreatedScreen: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const createCardState = useCreateCardState();

	React.useEffect(() => {
		return () => {
			dispatch(createCardActions.initializeCard());
		};
	}, []);

	React.useEffect(() => {
		if (createCardState.image === '' && createCardState.template === null) {
			history.replace('/');
		}
	}, []);

	return (
		<section className={styles.container}>
			<div className={styles.backgroundLeft} />
			<div className={styles.backgroundRight} />
			<Header />
			<div className={styles.cardPreviewContainer}>
				<img src={createCardState.image} alt={'Card Preview'} />
				<img className={`${styles.previewIcon}`} src={CheckIcon} alt="" />
			</div>
			<Box height={32} />
			<Typography variant="h3" style={{ textAlign: 'center' }}>
				Card successfully created!
			</Typography>
			<Box height={12} />
			<Typography variant="body1" style={{ textAlign: 'center' }}>
				{createCardState.recipientFirstName}’s card is now ready to be signed. Be the first to add a message, or
				share the card for others to sign
			</Typography>
			<Box height={32} />
			<Button
				onClick={() => history.replace(`/sign-a-card/${createCardState.uuid}`)}
				style={{ padding: '0 12px', width: 200 }}
			>
				Sign the Card
			</Button>
			<Box height={12} />
			<Button
				type="text"
				onClick={() => dispatch(uiActions.openShareModal({}))}
				style={{ padding: '0 12px', width: 200 }}
			>
				<img style={{ width: 24, height: 24 }} src={ShareIcon} alt="Share" />
				<Box width={12} />
				Share
			</Button>
		</section>
	);
};

export default CardCreatedScreen;
