import React from 'react';
import Typography from '../Typography';
import styles from './index.module.scss';
import CloseIcon from './../../../img/ic-circle-close.svg';

interface Props {
	isUploading: boolean;
	image: string | null;
	filename: string | null;
	progress: number;
	onCancel: () => void;
}

const FileUpload: React.FC<Props> = (props: Props) => {
	const [preview, setPreview] = React.useState<string | null>(null);

	const fileType = React.useMemo<string>(() => {
		if (props.filename) {
			if (
				props.filename.toLowerCase().includes('jpg') ||
				props.filename.toLowerCase().includes('png') ||
				props.filename.toLowerCase().includes('jpeg') ||
				props.filename.toLowerCase().includes('gif')
			) {
				return 'Image';
			} else if (
				props.filename.toLowerCase().includes('mp4') ||
				props.filename.toLowerCase().includes('mov') ||
				props.filename.toLowerCase().includes('3gp')
			) {
				return 'Video';
			}
		}

		return 'Unknown';
	}, [props.filename]);

	const fileName = React.useMemo<string>(() => {
		if (props.filename) {
			const name = props.filename.split('/').pop()?.split('?')[0];
			if (name) {
				return name;
			}
		}

		return 'Unknown';
	}, [props.filename]);

	React.useEffect(() => {
		setPreview(props.image);
	}, [props.image]);

	return (
		<div
			className={`${styles.container} ${!props.isUploading ? styles.idle : ''} ${
				styles[`progress-${props.progress}`]
			}`}
		>
			<div style={{ width: 48, height: 48, flexShrink: 0 }}>
				{preview !== null && (
					<>
						{fileType === 'Image' && (
							<img style={{ width: 48, height: 48, objectFit: 'cover' }} src={preview} alt="Preview" />
						)}
						{fileType === 'Video' && (
							<video controls style={{ width: 48, height: 48, objectFit: 'cover' }}>
								<source src={preview} />
							</video>
						)}
					</>
				)}
			</div>
			<div
				style={{
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					marginLeft: 12,
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					justifyContent: 'center',
				}}
			>
				<Typography variant="caption" style={{ color: 'rgba(18, 18, 18, .87)', textTransform: 'uppercase' }}>
					{fileType}
				</Typography>
				<Typography
					variant="body1"
					style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', color: 'rgba(0, 0, 0, .87)' }}
				>
					{fileName}
				</Typography>
			</div>
			<img
				onClick={props.onCancel}
				style={{ flexShrink: 0, width: 24, height: 24, cursor: 'pointer' }}
				src={CloseIcon}
				alt="Remove"
			/>
		</div>
	);
};

export default FileUpload;
