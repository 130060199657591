import { API } from 'lib/util';
import React from 'react';
import FileResponse from '../../../network/responses/FileResponse';
import Box from '../Box';
import Button from '../Button';
import ProgressBar from '../ProgressBar';
import Typography from '../Typography';
import AddIcon from './../../../img/ic-add.svg';
import LibraryPrimaryIcon from './../../../img/ic-library-primary.svg';
import LibraryIcon from './../../../img/ic-library.svg';
import CardUploadPlaceholder from './../../../img/il-card-upload-placeholder.png';
import styles from './index.module.scss';

interface Props {
	isActive?: boolean;
	onUpload: (value: string) => void;
}

const CardDesignUpload: React.FC<Props> = (props: Props) => {
	const ref = React.useRef<HTMLInputElement>(null);
	const [fileList, setFileList] = React.useState<FileList | null>(null);
	const [isUploading, setIsUploading] = React.useState<boolean>(false);
	const [isUploaded, setIsUploaded] = React.useState<boolean>(false);
	const [progress, setProgress] = React.useState<number>(0);
	const [image, setImage] = React.useState<string>(CardUploadPlaceholder);

	const handleClick = React.useCallback(() => {
		if (ref.current && !isUploading) {
			ref.current.click();
		}
	}, [isUploading, ref]);

	React.useEffect(() => {
		if (isUploading) {
			return;
		}

		const start = async () => {
			if (fileList) {
				setImage(CardUploadPlaceholder);
				setIsUploaded(false);
				setIsUploading(true);
				const formData = new FormData();
				formData.append('file', fileList[0]);

				const response = await API.post<any, FileResponse>('/upload', formData, {
					onUploadProgress: (data) => {
						setProgress(Math.round((100 * data.loaded) / data.total));
					},
				});

				setIsUploading(false);
				setIsUploaded(true);
				setImage(URL.createObjectURL(fileList[0]));
				setFileList(null);
				if (response.status === 200) {
					props.onUpload(response.data.path);
				}
			}
		};

		start();
	}, [fileList]);

	return (
		<div onClick={handleClick} className={`${styles.container} ${props.isActive ? styles.active : ''}`}>
			<input
				accept="image/png,image/jpg,image/jpeg,image/gif"
				onChange={(e) => setFileList(e.target.files)}
				ref={ref}
				type="file"
				style={{ display: 'none' }}
			/>
			<div className={styles.preview}>
				{!fileList && !isUploaded && (
					<div className={styles.addButton}>
						<img className={styles.libraryIcon} src={LibraryIcon} alt={'Library'} />
						<img className={styles.addIcon} src={AddIcon} alt={'Add Icon'} />
					</div>
				)}
				{isUploading && (
					<div className={styles.uploadBar}>
						<ProgressBar value={progress} />
					</div>
				)}
				{!isUploaded && (
					<>
						<Typography className={styles.captionText} variant="body1">
							Drag and drop here <br />
							(.png or .jpg)
						</Typography>
						<Button
							className={styles.button}
							onClick={() => {}}
							style={{ pointerEvents: 'none' }}
							type="text"
						>
							<img src={LibraryPrimaryIcon} alt={'Upload'} />
							<Box width={12} />
							Upload file
						</Button>
					</>
				)}
				<img className={isUploaded ? styles.uploaded : ''} src={image} alt={'Card Preview'} />
			</div>
		</div>
	);
};

export default CardDesignUpload;
