import React, { ReactNode } from 'react';
import Box from '../Box';
import Typography from '../Typography';
import ChevronIcon from './../../../img/ic-chevron-right-primary.svg';
import ChevronIconBlack from './../../../img/ic-chevron-right.svg';
import styles from './index.module.scss';

interface Props {
	open?: boolean;
	question: string;
	children: ReactNode;
	questionColor?: string;
	blackIcon?: boolean;
	noSpacer?: boolean;
}

const Accordion: React.FC<Props> = ({ questionColor = 'rgba(255, 255, 255, .87)', open = false, ...props }: Props) => {
	const [isOpen, setIsOpen] = React.useState<boolean>(open);

	const toggleOpen = React.useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.title} onClick={toggleOpen}>
					<img
						className={isOpen ? styles.chevronOpen : ''}
						src={props.blackIcon ? ChevronIconBlack : ChevronIcon}
						alt={''}
					/>
					<Typography style={{ fontWeight: 'bold', color: questionColor }} variant="body1">
						{props.question}
					</Typography>
				</div>
				{props.blackIcon && (
					<div
						style={{
							width: '100%',
							height: '2px',
							background: '#000000',
							marginBottom: 16,
						}}
					/>
				)}
				<Typography
					className={`${styles.content} ${isOpen ? styles.open : ''}`}
					style={{ color: 'rgba(255, 255, 255, .87)' }}
					variant="body1"
				>
					{props.children}
				</Typography>
			</div>
			{!props.noSpacer && <Box style={{ background: '#E1E1E1' }} height={1} />}
		</>
	);
};

export default Accordion;
