import React from 'react';
import Typography from '../Typography';
import styles from './index.module.scss';

interface Props {
	style?: React.CSSProperties;
}

const Separator: React.FC<Props> = (props: Props) => {
	return (
		<div className={styles.container} style={props.style}>
			<div className={styles.dashedLine} />
			<span className={styles.text}>
				<Typography variant="caption">or</Typography>
			</span>
			<div className={styles.dashedLine} />
		</div>
	);
};

export default Separator;
