import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import CardCategory from '../../models/CardCategory';
import ErrorResponse from '../../network/responses/ErrorResponse';
import authenticationAsyncActions from '../actions/authentication.action';
import cardCategoryAsyncActions from '../actions/cardCategory.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';
import { CPA } from '../types';

export interface CardCategoryState {
	list: CardCategory[];
	updatedAt: number;
}

const initialState: CardCategoryState = {
	list: [],
	updatedAt: moment().valueOf(),
};

const slice = createSlice({
	name: 'cardCategory',
	initialState,
	reducers: {},
	extraReducers: {
		[cardCategoryAsyncActions.index.fulfilled.type]: (state, action: CPA<CardCategory[]>) => {
			state.list = action.payload;
			state.updatedAt = moment().valueOf();

			postRequest(action);
		},
		[cardCategoryAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
	},
});

export const cardCategoryActions = slice.actions;

export default slice.reducer;
