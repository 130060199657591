import React from 'react';
import { usePopper } from 'react-popper';
import Box from '../Box';
import Input from '../Input';
import Typography from '../Typography';
import ChevronDownIcon from './../../../img/ic-chevron-down.svg';
import ChevronUpIcon from './../../../img/ic-chevron-up.svg';
import styles from './index.module.scss';

interface Props {
	value: string;
	onChange: (value: string) => void;
	disabled?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

const Timepicker: React.FC<Props> = (props: Props) => {
	const mainRef = React.useRef<HTMLDivElement | null>(null);
	const [hourRef, setHourRef] = React.useState<HTMLDivElement | null>(null);
	const [minuteRef, setMinuteRef] = React.useState<HTMLDivElement | null>(null);
	const [periodRef, setPeriodRef] = React.useState<HTMLDivElement | null>(null);
	const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);
	const [popperRef, setPopperRef] = React.useState<HTMLDivElement | null>(null);
	const [arrowRef, setArrowRef] = React.useState<HTMLDivElement | null>(null);
	const { styles: popperStyles, attributes } = usePopper(containerRef, popperRef, {
		modifiers: [{ name: 'arrow', options: { element: arrowRef } }],
		placement: 'bottom',
	});
	const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
	const [hour, setHour] = React.useState<string>();
	const [minute, setMinute] = React.useState<string>();
	const [period, setPeriod] = React.useState<string>();
	const [hours] = React.useState<Array<string>>([
		'01',
		'02',
		'03',
		'04',
		'05',
		'06',
		'07',
		'08',
		'09',
		'10',
		'11',
		'12',
	]);
	const [minutes] = React.useState<Array<string>>(['00', '15', '30', '45']);
	const [periods] = React.useState<Array<string>>(['AM', 'PM']);
	const [value, setValue] = React.useState<string>(props.value);

	const upArrow = React.useCallback(
		(
			callback: (value: string) => void,
			actualValue: string | undefined,
			list: Array<string>,
			ref: HTMLDivElement | null
		) => {
			const findIndex = list.findIndex((element) => element === actualValue);
			if (findIndex === -1 || findIndex === 0) {
				return;
			}

			if (ref) {
				const findIndexRef = list.findIndex((element) => element === list[findIndex - 1]);
				if (findIndexRef !== -1) {
					ref.scrollTo({
						top: 4 + findIndexRef * 24,
					});
				}
			}
			callback(list[findIndex - 1]);
		},
		[hours, minutes, periods]
	);

	const downArrow = React.useCallback(
		(
			callback: (value: string) => void,
			actualValue: string | undefined,
			list: Array<string>,
			ref: HTMLDivElement | null
		) => {
			const findIndex = list.findIndex((element) => element === actualValue);
			if (findIndex === -1 || findIndex === list.length - 1) {
				return;
			}

			if (ref) {
				const findIndexRef = list.findIndex((element) => element === list[findIndex + 1]);
				if (findIndexRef !== -1) {
					ref.scrollTo({
						top: 4 + findIndexRef * 24,
					});
				}
			}
			callback(list[findIndex + 1]);
		},
		[hours, minutes, periods]
	);

	React.useEffect(() => {
		if (hourRef) {
			const findIndex = hours.findIndex((element) => props.value.split(' ')[0].split(':')[0] === element);
			if (findIndex !== -1) {
				hourRef.scrollTo({
					top: 4 + findIndex * 24,
				});
			}
		}

		if (minuteRef) {
			const findIndex = minutes.findIndex((element) => props.value.split(' ')[0].split(':')[1] === element);
			if (findIndex !== -1) {
				minuteRef.scrollTo({
					top: 4 + findIndex * 24,
				});
			}
		}

		if (periodRef) {
			const findIndex = periods.findIndex((element) => props.value.split(' ')[1] === element);
			if (findIndex !== -1) {
				periodRef.scrollTo({
					top: 4 + findIndex * 24,
				});
			}
		}
	}, [props.value, hourRef, minuteRef, periodRef]);

	React.useEffect(() => {
		setHour(props.value.split(' ')[0].split(':')[0]);
		setMinute(props.value.split(' ')[0].split(':')[1]);
		setPeriod(props.value.split(' ')[1]);
		setValue(props.value);
	}, [props.value]);

	React.useEffect(() => {
		const mouseUp = (event: any) => {
			if (mainRef.current && !mainRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		};

		document.addEventListener('mouseup', mouseUp);

		return () => {
			document.removeEventListener('mouseup', mouseUp);
		};
	}, [mainRef]);

	return (
		<div ref={mainRef}>
			<div ref={setContainerRef} className={styles.container}>
				<div
					style={props.style}
					className={`${styles.inputContainer} ${props.className ? props.className : ''}`}
				>
					<div
						onClick={() => {
							setShowDropdown(true);
						}}
						style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
					>
						<Input
							className={props.disabled ? styles.disabled : styles.enabled}
							value={value}
							disabled
							type="text"
							label="Time"
						/>
					</div>
				</div>
			</div>
			<div
				className={`${styles.dropdownContainer} ${!showDropdown || props.disabled ? styles.hidden : ''}`}
				ref={setPopperRef}
				style={popperStyles.popper}
				{...attributes.popper}
			>
				<div className={styles.section}>
					<img
						onClick={() => upArrow(setHour, hour, hours, hourRef)}
						className={styles.controlIcon}
						src={ChevronUpIcon}
						alt="Up"
					/>
					<Box height={8} />
					<div
						ref={setHourRef}
						className={styles.list}
						onScroll={(e) => {
							if (
								// @ts-ignore
								(e.nativeEvent.target.scrollTop - 4) % 24 >= 0 &&
								// @ts-ignore
								(e.nativeEvent.target.scrollTop - 4) % 24 <= 3
							) {
								// @ts-ignore
								let count = e.nativeEvent.target.scrollTop - 4;
								let index = 0;
								while (count > 0) {
									count -= 24;
									index++;
								}

								if (index >= hours.length) {
									index = hours.length - 1;
								}

								setHour(hours[index]);
								props.onChange(`${hours[index]}:${minute} ${period}`);
							}
						}}
					>
						<div>
							<Typography variant="body1">&nbsp;</Typography>
							{hours.map((element) => (
								<Typography key={`hour-${element}`} variant="body1">
									{element}
								</Typography>
							))}
							<Typography variant="body1">&nbsp;</Typography>
						</div>
					</div>
					<Box height={8} />
					<img
						onClick={() => downArrow(setHour, hour, hours, hourRef)}
						className={styles.controlIcon}
						src={ChevronDownIcon}
						alt="Down"
					/>
				</div>
				<div className={styles.section}>
					<img
						onClick={() => upArrow(setMinute, minute, minutes, minuteRef)}
						className={styles.controlIcon}
						src={ChevronUpIcon}
						alt="Up"
					/>
					<Box height={8} />
					<div
						ref={setMinuteRef}
						className={styles.list}
						onScroll={(e) => {
							if (
								// @ts-ignore
								(e.nativeEvent.target.scrollTop - 4) % 24 >= 0 &&
								// @ts-ignore
								(e.nativeEvent.target.scrollTop - 4) % 24 <= 3
							) {
								// @ts-ignore
								let count = e.nativeEvent.target.scrollTop - 4;
								let index = 0;
								while (count > 0) {
									count -= 24;
									index++;
								}

								if (index >= minutes.length) {
									index = minutes.length - 1;
								}

								setMinute(minutes[index]);
								props.onChange(`${hour}:${minutes[index]} ${period}`);
							}
						}}
					>
						<div>
							<Typography variant="body1">&nbsp;</Typography>
							{minutes.map((element) => (
								<Typography key={`minute-${element}`} variant="body1">
									{element}
								</Typography>
							))}
							<Typography variant="body1">&nbsp;</Typography>
						</div>
					</div>
					<Box height={8} />
					<img
						onClick={() => downArrow(setMinute, minute, minutes, minuteRef)}
						className={styles.controlIcon}
						src={ChevronDownIcon}
						alt="Down"
					/>
				</div>
				<div className={styles.section}>
					<img
						onClick={() => upArrow(setPeriod, period, periods, periodRef)}
						className={styles.controlIcon}
						src={ChevronUpIcon}
						alt="Up"
					/>
					<Box height={8} />
					<div
						ref={setPeriodRef}
						className={styles.list}
						onScroll={(e) => {
							if (
								// @ts-ignore
								(e.nativeEvent.target.scrollTop - 4) % 24 >= 0 &&
								// @ts-ignore
								(e.nativeEvent.target.scrollTop - 4) % 24 <= 3
							) {
								// @ts-ignore
								let count = e.nativeEvent.target.scrollTop - 4;
								let index = 0;
								while (count > 0) {
									count -= 24;
									index++;
								}

								if (index >= periods.length) {
									index = periods.length - 1;
								}

								setPeriod(periods[index]);
								props.onChange(`${hour}:${minute} ${periods[index]}`);
							}
						}}
					>
						<div>
							<Typography variant="body1">&nbsp;</Typography>
							{periods.map((element) => (
								<Typography key={`period-${element}`} variant="body1">
									{element}
								</Typography>
							))}
							<Typography variant="body1">&nbsp;</Typography>
						</div>
					</div>
					<Box height={8} />
					<img
						onClick={() => downArrow(setPeriod, period, periods, periodRef)}
						className={styles.controlIcon}
						src={ChevronDownIcon}
						alt="Down"
					/>
				</div>
				<div ref={setArrowRef} style={popperStyles.arrow} />
			</div>
		</div>
	);
};

export default Timepicker;
