import React from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import authenticationAsyncActions from '../../../store/actions/authentication.action';
import { useAuthenticationState, useSettingsState, useUIState, useUserState } from '../../../store/selectors';
import { uiActions } from '../../../store/slices/ui.slice';
import Accordion from '../Accordion';
import Box from '../Box';
import Button from '../Button';
import Typography from '../Typography';
import CloseIcon from './../../../img/ic-modal-close.svg';
import Logo from './../../../img/il-alternate-logo.svg';
import styles from './index.module.scss';

interface Props {
	isVisible: boolean;
	setIsVisible: (value: boolean) => void;
}

const ModalMenu: React.FC<Props> = (props: Props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { width } = useWindowDimensions();

	const authenticationState = useAuthenticationState();
	const userState = useUserState();
	const uiState = useUIState();
	const settingsState = useSettingsState();

	const [index, setIndex] = React.useState<number>(uiState.navigationIndex);

	const handleSignIn = React.useCallback(() => {
		dispatch(uiActions.openSignInModal({}));
	}, [dispatch]);

	const handleSignUp = React.useCallback(() => {
		dispatch(uiActions.openSignUpModal({}));
	}, [dispatch]);

	const handleCreateCard = React.useCallback(() => {
		history.replace('/?tab=0');
		dispatch(uiActions.closeModals());
	}, [dispatch, history]);

	const handleSignOut = React.useCallback(() => {
		history.replace('/');
		dispatch(authenticationAsyncActions.signOut());
		dispatch(uiActions.closeModals());
	}, [dispatch, history]);

	const handleClose = React.useCallback(() => {
		props.setIsVisible(false);
	}, []);

	const handleClick = React.useCallback((newIndex: number) => {
		setIndex(newIndex);
	}, []);

	React.useEffect(() => {
		setIndex(uiState.navigationIndex);
	}, [uiState.navigationIndex]);

	if (!props.isVisible) {
		return null;
	}

	return createPortal(
		<div style={{ height: '100%', boxSizing: 'border-box' }}>
			<div className={styles.container}>
				<header className={styles.headerContainer}>
					<img src={Logo} alt={'Sign the card'} />
					<div style={{ flexGrow: 1 }} />
					{width > 576 && process.env.REACT_APP_APP_ENV !== 'maintenance' && (
						<>
							<Button onClick={handleCreateCard} style={{ padding: '0 12px' }}>
								Create a card
							</Button>
							<Box width={8} />
						</>
					)}
					{!authenticationState.isAuthenticated && process.env.REACT_APP_APP_ENV !== 'maintenance' && (
						<>
							<Button
								onClick={handleSignIn}
								style={{ padding: '0 4px', color: '#ffffff' }}
								className={styles.button}
							>
								Login
							</Button>
							<Button
								onClick={handleSignUp}
								style={{ padding: '0 4px', color: '#ffffff' }}
								className={styles.button}
							>
								Register
							</Button>
						</>
					)}
					{authenticationState.isAuthenticated && (
						<>
							<Button
								type="text"
								onClick={handleSignOut}
								style={{
									flexDirection: width < 576 ? 'column' : undefined,
									whiteSpace: width < 576 ? 'nowrap' : undefined,
									padding: '0 4px',
									color: '#ffffff',
									fontWeight: 'normal',
								}}
							>
								Log out
								<span style={{ marginLeft: width > 576 ? 8 : undefined, fontWeight: 'bold' }}>
									{userState.firstName}
								</span>
							</Button>
						</>
					)}
					<Box width={width < 576 ? 8 : 18} />
					<div onClick={handleClose} className={styles.button} style={{ padding: '0 0 0 0' }}>
						{width < 576 ? '' : 'Close '}
						<img src={CloseIcon} alt={'Close'} />
					</div>
				</header>
				<Box height={36} />
				<div className={styles.navigation}>
					<div className={styles.navigationContainer}>
						<button
							onClick={() => handleClick(0)}
							className={`${styles.button} ${styles.navigationButton} ${
								index === 0 ? styles.active : ''
							}`}
						>
							About Us
						</button>
						<button
							onClick={() => handleClick(1)}
							className={`${styles.button} ${styles.navigationButton} ${
								index === 1 ? styles.active : ''
							}`}
						>
							How it works
						</button>
						<button
							onClick={() => handleClick(2)}
							className={`${styles.button} ${styles.navigationButton} ${
								index === 2 ? styles.active : ''
							}`}
						>
							FAQS
						</button>
						<button
							onClick={() => handleClick(3)}
							className={`${styles.button} ${styles.navigationButton} ${
								index === 3 ? styles.active : ''
							}`}
						>
							Website Terms
						</button>
						<button
							onClick={() => handleClick(4)}
							className={`${styles.button} ${styles.navigationButton} ${
								index === 4 ? styles.active : ''
							}`}
						>
							Terms Of Use
						</button>
						<button
							onClick={() => handleClick(5)}
							className={`${styles.button} ${styles.navigationButton} ${
								index === 5 ? styles.active : ''
							}`}
						>
							Privacy Policy
						</button>
						<button
							onClick={() => handleClick(6)}
							className={`${styles.button} ${styles.navigationButton} ${
								index === 6 ? styles.active : ''
							}`}
						>
							Fees
						</button>
					</div>
				</div>
				<Box height={36} />
				{index === 0 && (
					<div style={{ flexGrow: 1, overflow: 'hidden', overflowY: 'auto' }}>
						<div>
							<Typography variant="h3" style={{ color: '#FFFFFF', textAlign: 'center' }}>
								About Us
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								For years, working at large global organisations, Dom and Frank (our co-founders and
								life long friends) would constantly see the disconnect and challenges in coordinating
								and delivering office greeting cards and gift collections amongst colleagues.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								So when Dom was handed his own wedding office greeting card to sign and contribute, by
								an unknown co-worker, he called Frank and{' '}
								<span style={{ fontWeight: 'bold' }}>‘Sign the Card’</span> was born.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								A digital experience, revolutionising how we{' '}
								<span style={{ fontWeight: 'bold' }}>create</span>, collectively{' '}
								<span style={{ fontWeight: 'bold' }}>sign and contribute</span>, to then{' '}
								<span style={{ fontWeight: 'bold' }}>send and receive</span> greetings and gifts. To
								anyone, from everyone, at any time.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Whether in your own special words, pictures or video, along with being able to digitally
								pay towards a gift collection,{' '}
								<span style={{ fontWeight: 'bold' }}>‘Sign the Card’</span> is the complete group card
								and gift collection experience, from coordination and contributing in seconds, to life
								long memories of appreciation and gratitude.
							</Typography>
						</div>
					</div>
				)}
				{index === 1 && (
					<div style={{ flexGrow: 1, overflow: 'hidden', overflowY: 'auto' }}>
						<div>
							<Typography variant="h3" style={{ color: '#FFFFFF', textAlign: 'center' }}>
								How it works
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								1. <span style={{ fontWeight: 'bold' }}>Create and Share</span>
								<span style={{ marginLeft: 12, display: 'block' }}>
									Step 1 - upload your own or select from one of our card designs
									<br />
									Step 2 – enter in the details for who the card is for, and when they will receive
									the card
									<br />
									Step 3 – add a financial gift collection for others to contribute
									<br />
									Step 4 – share via a unique URL or upload emails and we`ll send them a direct invite
								</span>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								2. <span style={{ fontWeight: 'bold' }}>Sign and Contribute</span>
								<span style={{ marginLeft: 12, display: 'block' }}>
									– upload a written, picture or video message to a card, along with securely making a
									digital payment towards a gift collection
								</span>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								3. <span style={{ fontWeight: 'bold' }}>Manage</span>
								<span style={{ marginLeft: 12, display: 'block' }}>
									– see messages being added, gift collection balance, change delivery dates and send
									new invites direct when you log in and view cards created
								</span>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								4. <span style={{ fontWeight: 'bold' }}>Send and Receive</span>
								<span style={{ marginLeft: 12, display: 'block' }}>
									– a URL to a digital card is automatically sent to the recipient and forever
									accessible.
								</span>
							</Typography>
						</div>
					</div>
				)}
				{index === 2 && (
					<div style={{ flexGrow: 1, overflow: 'hidden', overflowY: 'auto' }}>
						<div>
							<Typography variant="h3" style={{ color: '#FFFFFF', textAlign: 'center' }}>
								Frequently Asked Questions
							</Typography>
							<Box height={16} />
							<Typography variant="body2" style={{ color: '#FFFFFF', textAlign: 'center' }}>
								For any enquiries, please email Sign the Card at{' '}
								<a href="mailto:support@signthecard.com">support@signthecard.com</a>
							</Typography>
							<Box height={16} />
							<Box style={{ padding: width < 992 ? undefined : '0 25vw' }}>
								<Accordion open question="How do I create a card?">
									Once you have registered or logged in with Sign the Card, a page will appear to
									create a card where you can upload your own design, or select a card from the Sign
									the Card catalogue of card designs provided for your occasions.
									<br />
									<br />
									When uploading your own card design please ensure you load either a png or jpg image
									less than 10MB, at a 4:3 ratio. You can reduce your image at{' '}
									<a rel="noreferrer" href="https://tinyjpg.com/" target="_blank">
										https://tinyjpg.com/
									</a>{' '}
									to make uploading the card faster for all users.
									<br />
									<br />
									Once you have your chosen or uploaded a card design, you will be able to input the
									recipient's name and email address, set a date and time for when they will receive
									the card, add a financial contribution target, and invite others to sign the card by
									entering their email address or share the card’s unique URL that you can copy paste
									and send to others via email, SMS or via social media platforms.
								</Accordion>
								<Accordion question="How do I register a Sign the Card account?">
									Click the `Register` button on the home page (top right) and follow the simple
									instructions to set up an account using either an email address, Linkedin, Facebook
									or Google account.
								</Accordion>
								<Accordion question="How do I log in to my account?">
									Click the ‘Login’ button on the home page (top right) to log in via the email and
									password used when you initially registered, or log in via your Linkedin, Facebook
									or your Google account.
									<br />
									<br />
									To log out, click the menu icon on the top right of the page (3 horizontal bars),
									and select `Log out`.
									<br />
									<br />
									Once logged in you can ‘Create a Card’ or view ‘My Profile’.
								</Accordion>
								<Accordion question="What can I do from ‘My Profile’">
									Once you have logged in and select the ‘My Profile’ tab at the top of the page.
									<br />
									<br />
									Here you can see cards that you have either:
									<br />
									<br />
									<ol type="a">
										<li>
											a) Created - where can view and edit your own message on cards you’ve
											created, along with other messages from contributors and also change card
											details i.e. change delivery date, card image or recipient details. In
											addition, you can see the number of contributors and the total amount of a
											financial contribution, as well as withdraw the funds,
										</li>
										<li>
											b) Signed cards, which you can edit messages for cards that have been sent
											to you to contribute to, if they’re not delivered and,
										</li>
										<li>
											c) Received cards, which you can view and download, as well as send a
											message to all those that have signed the card and provided their contact
											details.
										</li>
									</ol>
									<br />
									You can also view the number of card you have remaining to create or buy more cards.
								</Accordion>
								<Accordion question="How do I retrieve a forgotten password?">
									Select the `Forgot password` button on the log in screen, and enter your email
									address. Reset password instructions will be sent to the nominated email address.
								</Accordion>
								<Accordion question="Purchasing a card and receipts">
									All card purchases with Sign the Card are made via PayPal, where payments can be
									made using a PayPal account or Debit/Credit Card.
									<br />
									<br />
									If you are setting up a gift collection, please note that Sign the Card will only
									pay out to the email address registered to a PayPal account – see ‘Create a Gift
									Collection’ below.
									<br />
									<br />
									Receipts will be provided at checkout with PayPal, and be available if you pay via
									your PayPal account. If you do not pay with a PayPal account, Sign the Card advises
									you keep a record of the receipt at checkout.
								</Accordion>
								<Accordion question="Can I buy more than one card and remaining cards">
									Yes, you can purchase one of our bulk card packages when prompted at time of
									purchase or anytime when in the ‘My Profile’ page, where you will also be able to
									see how many cards remaining to create.
								</Accordion>
								<Accordion question="Do you have corporate accounts">
									Yes, Sign the Card offer a range of corporate packages that allow employees or
									nominated people, to be able to send an unlimited or selected number of cards.
									Please contact{' '}
									<a rel="noreferrer" target="_blank" href="mailto:support@signthecard.com">
										support@signthecard.com
									</a>{' '}
									for more information.
								</Accordion>
								<Accordion question="How do I create a gift collection, and when do I withdraw it?">
									During the card creation process, there is an option to create a financial gift
									collection and also set a collection goal (This will not be displayed to
									contributors/signers).
									<br />
									<br />
									Your collection goal can be modified, or a gift collection enabled on a card if not
									selected at card creation. This can be managed by the creator of the card when
									logged in by visiting the My Cards tab on the card selection screen, and selecting
									the relevant Card.
									<br />
									<br />
									The card creator will have the ability to withdraw the financial gift collection
									within 48 hours of the card delivery date. However once collections have been
									withdrawn, Sign the Card will disable the gift collection option for all
									contributors. Contributors will be able to Sign a Card up until the card delivery
									cut off.
									<br />
									<br />
									Gift collections are paid out to the email address linked to a PayPal account. Sign
									the Card use PayPal; a global payments provider for Sign the Card user security and
									protection.
									<br />
									<br />
									Any payment or payout enquires must directed to PayPal{' '}
									<a
										rel="noreferrer"
										href="https://www.paypal.com/au/smarthelp/contact-us"
										target="_blank"
									>
										https://www.paypal.com/au/smarthelp/contact-us
									</a>{' '}
									or your relevant PayPal country provider if outside of Australia.
									<br />
									<br />
									Note – Sign the Card will hold collection for the period of 90 days after the card
									delivery date. Thereafter, Sign the Card will refund monies back to each card
									contributor the amount contributed minus Sign the Card fees – See Sign the Card Fees
									for more details.
								</Accordion>
								<Accordion question="How do I sign a card?">
									Once you have selected ‘Sign the Card’ from the card invitation or if the creator,
									you will be prompted once a card has been created. Write your message in the text
									field, select a font, font size, font style and colour. See your message on the card
									which you can then move around the page or place on a different page. Sign the Card
									does have overlapping rules that will apply and your message box will appear in red
									if a message is overlapping another message on the card. You will be unable to
									progress to the next screen until the overlapping issue is resolved.
									<br />
									You can increase and reduce the size of your message by clicking the corners of your
									message box when displayed on the card.
									<br />
									In addition to a written message, you can upload your own pictures or videos, or
									select a gif. Sign the Card advise to keep the file sizes of pictures and video
									loaded to be less than 20MB to avoid delays in loading cards.
									<br />
									All message will be displayed on the card and can be seen by all visitors (who need
									to register for security purposes) to the card.
								</Accordion>
								<Accordion question="Can the creator move or delete messages on a card">
									The Card Creator can log in, view the card messages and then select a message that
									can be either deleted or moved.
								</Accordion>
								<Accordion question="How can I delete my card?">
									Please contact{' '}
									<a rel="noreferrer" target="_blank" href="mailto:support@signthecard.com">
										support@signthecard.com
									</a>{' '}
									to delete a card you have created.
								</Accordion>
								<Accordion question="How do I share a card?">
									Click the 'Share this Card' or 'Invite others to sign' button to either add email
									addresses to invite people to Sign the Card or copy the unique card URL to share
									personally via email, SMS or on social media platforms.
								</Accordion>
								<Accordion question="Receiving a Card">
									When a card has been completed, an email will be sent to the card receiver with a
									link to their Card. Messages can be viewed and videos played via the link, along
									with the ability to download the card as a PDF. Videos and gifs will only be
									displayed as stills. The link for the card will always be accessible, unless
									otherwise advised by Sign the Card.
									<br />
									You can also send a message to all those that have signed the card and provided
									their contact details.
								</Accordion>
								<Accordion question="Where can I use Sign the Card?">
									Sign the Card is available to customers worldwide.
								</Accordion>
								<Accordion question="How do I change my email address linked to my account">
									Please contact{' '}
									<a rel="noreferrer" target="_blank" href="mailto:support@signthecard.com">
										support@signthecard.com
									</a>{' '}
									who can update your account details.
								</Accordion>
								<Accordion question="Photo or video isn’t uploading">
									Please contact{' '}
									<a rel="noreferrer" target="_blank" href="mailto:support@signthecard.com">
										support@signthecard.com
									</a>{' '}
									and send the file you are attempting to upload. Please note when uploading your own
									card design please ensure you load either a png or jpg image less than 10MB.
								</Accordion>
							</Box>
						</div>
					</div>
				)}
				{index === 3 && (
					<div style={{ flexGrow: 1, overflow: 'hidden', overflowY: 'auto' }}>
						<div>
							<Typography variant="h3" style={{ color: '#FFFFFF', textAlign: 'center' }}>
								Website Terms of Use
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Welcome to the website of Sign the Card (ABN 60 638 086 094) ("we", "us" or "STC"), an
								online seller for digital greeting cards and gift collection solutions.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								This website is located on the web via the domain{' '}
								<a target={'_blank'} rel={'noreferrer'} href={'https://signthecard.com'}>
									www.signthecard.com
								</a>{' '}
								and includes all of the files located in that domain ("this site").
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Agreement to these Website Terms of Use
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								By accessing this site, you agree to be bound by these terms of use ("Website Terms of
								Use").
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								These Website Terms of Use are a binding agreement between you and STC and apply to your
								use of this site.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Privacy Policy
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								As part of these Website Terms of Use, your use of this site is also subject to our
								Privacy Policy (located at{' '}
								<a href="#" onClick={() => handleClick(5)}>
									www.signthecard.com/privacy-policy
								</a>
								) which are incorporated by reference into these Website Terms of Use.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Legal capacity to transact
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If you are under 16 years of age, you cannot make purchases on our website.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								By using this site you represent and warrant to STC that you are over the age of 16
								years.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Should STC suffer any damage or other losses as a result of a transaction entered into
								by a minor, we reserve the right to seek compensation for such losses from his/her
								parents or guardians.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Restrictions on use
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Prohibited conduct
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Your use of this site is subject to the rules set out below.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								YOU MUST NOT:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										use any device, routine or software that interferes, or attempt to interfere,
										with the proper working of this site;
									</li>
									<li>
										engage in any action that requires, or may require, an unreasonable or
										excessively large load on our infrastructure;
									</li>
									<li>
										use this site to decipher passwords or security encryption codes, transmit any
										worms, viruses or Trojan horses, transfer or store illegal, threatening or
										obscene material or otherwise violate the security of any computer network;
									</li>
									<li>
										use this site to violate any applicable local, state, national or international
										law, to engage in any misleading or deceptive online marketing practices or for
										any fraudulent or malicious purposes;
									</li>
									<li>
										use any spider, robot or search/retrieval application or any screen scraping,
										data mining or similar data gathering device, process, program or means to
										access, retrieve or index any portion of this site;
									</li>
									<li>use this site by any automated means;</li>
									<li>
										use this site to transmit junk mail, spam or chain letters or pyramid schemes or
										engage in other flooding techniques or mass distribution of unsolicited email;
									</li>
									<li>
										access, retrieve or index any portion of this site for use in constructing or
										populating any database that is searchable online or for the purpose of
										soliciting or sharing reviews;
									</li>
									<li>
										interfere with the display of any advertisements appearing on or in connection
										with this site;
									</li>
									<li>
										reverse engineer, decompile, disassemble, adapt, modify, translate, frame or
										reformat any of the material contained on this site;
									</li>
									<li>
										reproduce, duplicate, copy or store any of the material appearing on this site
										other than for your own personal and non-commercial use;
									</li>
									<li>falsely imply that any other website is associated with this site;</li>
									<li>
										do anything that leads, or may lead, to a decrease in the value of STC's
										intellectual property rights in this site;
									</li>
									<li>
										use or exploit any of the material appearing on this site for, or in connection
										with, any business or enterprise (whether for profit or otherwise), including
										any business or enterprise that is in competition with this site;
									</li>
									<li>
										release to the public any news release, advertising material, promotional
										material or any other form of publicity or information relating to STC without
										STC's prior written consent; or
									</li>
									<li>
										use this site to transmit any information or material that is, or may reasonably
										be considered to be:
									</li>
								</ul>
								<ul style={{ listStyle: 'circle', paddingInlineStart: 50 }}>
									<li>
										abusive, threatening, harassing, harmful, obscene, indecent, lewd, inflammatory,
										violent, vulgar, profane, racially, ethnically or otherwise objectionable or
										offensive in any way;
									</li>
									<li>
										libellous, defamatory, pornographic, sexually explicit, unlawful or plagiarised;
									</li>
									<li>
										infringing upon or violating any copyright, trademark, patent or other
										intellectual property or proprietary right;
									</li>
									<li>
										in breach of any duty of confidentiality by which you are bound, whether by way
										of a fiduciary or contractual relationship;
									</li>
									<li>in breach of any person’s privacy or publicity rights;</li>
									<li>
										a misrepresentation of facts, including the impersonation of any person or
										entity or a misrepresentation of an affiliation with any person or entity
										(including any sponsorship or endorsement);
									</li>
									<li>
										in violation of any applicable law, statute, ordinance or regulation, or
										encouraging of others to do so;
									</li>
									<li>
										containing any political campaigning material, advertisements or solicitations;
										or
									</li>
									<li>likely to bring STC or any of its staff into disrepute.</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Violations of these Website Terms of Use
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Without limiting any other remedies available to STC at law or in equity, STC may:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										temporarily or indefinitely suspend, or terminate, your access to this site or
										refuse to provide products or services to you if:
									</li>
								</ul>
								<ul style={{ listStyle: 'circle', paddingInlineStart: 50 }}>
									<li>you breach any provision of these Website Terms of Use;</li>
									<li>
										STC is unable to verify or authenticate any information that you provide to us;
										or
									</li>
									<li>
										STC believes that your actions may cause damage and/or legal liability to STC,
										any of its customers or suppliers or any other person; and
									</li>
								</ul>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										remove or block access to any information and/or materials (in whole or in part)
										that STC, at its sole and absolute discretion, regards in any way to be
										objectionable or in violation of any applicable law, any person's intellectual
										property rights or these Website Terms of Use.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Indemnity
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You indemnify and hold harmless STC and its officers, employees, agents, consultants,
								licensors, partners and affiliates from and against any losses, liabilities, costs,
								expenses or damages (including actual, special, indirect and consequential losses or
								damages of every kind and nature, including all legal fees on a solicitor-client basis)
								suffered or incurred by any of them due to, arising out of, or in any way related to
								(directly or indirectly):
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										any material or information that you submit, post, transmit or otherwise make
										available through this site;
									</li>
									<li>your use of, or connection to, this site; or</li>
									<li>
										your negligence or misconduct, breach of these Website Terms of Use or violation
										of any law or the rights of any person.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Registration and account security
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Requirement for registration
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC reserves the right to make any parts of this site accessible only to users who have
								registered.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Username and password
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Upon registration with this site, you will create a username and password to access your
								account.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You are responsible for maintaining the security of your password for this site.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC will not be liable for any loss or damage arising from or in connection with your
								failure to comply with this security obligation.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You agree that STC will be entitled to assume that any person using this site with your
								username and password is you or your authorised representative.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You must notify STC immediately of any known or suspected unauthorised use of any
								password or any other breach of security.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								User information
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								In order to register an account with this site, you must agree to these Website Terms of
								Use and provide STC with:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>first and last name</li>
									<li>a valid email address;</li>
									<li>
										accurate billing and contact information (this may include your street address
										and the name and telephone number of your authorised billing contact and
										administrator); and
									</li>
									<li>accurate information for the card contributors and card recipients; and</li>
									<li>
										any other information that may be required by STC during the registration
										process.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You must promptly update this information to maintain its accuracy at all times.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You represent and warrant to STC that all information provided to STC by you, including
								the information provided by you through our account registration module or entered into
								your account profile, is true and not misleading and does not violate any applicable law
								or regulation or any person's intellectual property or other rights.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Automated account opening
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Accounts registered by "bots" or other automated methods are not permitted.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Approval of registrations
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC reserves the right to accept or reject any application for registration of an
								account with this site at its discretion.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Orders
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Order constitutes offer
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								By placing an order through this site, you make an irrevocable offer to us to purchase
								the products/services that you have selected pursuant to these Website Terms of Use.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Information contained in this site constitutes an invitation to treat only. No
								information in this site constitutes an offer by us to supply any products/services to
								you – however, STC will endeavour to supply your selected products/services to you.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We will not commence processing any order made through this site unless and until:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>payment for the order has been received by us in full; and</li>
									<li>
										the order has passed our internal validation procedures, which are undertaken in
										order to verify the bona fides of each order for the purpose of preventing
										credit card and other fraud.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We reserve the right at our discretion to:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										at any time prior to your order being accepted in accordance with these Website
										Terms of Use, cancel all or part of your order; and
									</li>
									<li>at any time:</li>
								</ul>
								<ul style={{ listStyle: 'circle', paddingInlineStart: 50 }}>
									<li>refuse to provide products or services to you;</li>
									<li>cancel a card before it is sent to the recipient;</li>
									<li>terminate your access to this site; and/or</li>
									<li>remove or edit any content on this site.</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Acceptance of orders
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Acceptance of each order will take place if and when STC:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										in the case of physical items, sends the requested items to you, at the time at
										which the items are despatched by STC, and title to, and risk in, the items will
										pass from STC to you at that time. After the items have been sent, we will send
										you an email confirming that shipment has taken place;
									</li>
									<li>in the case of digital items, either:</li>
								</ul>
								<ul style={{ listStyle: 'circle', paddingInlineStart: 50 }}>
									<li>
										sends the requested items to you, at the time at which the items are sent by
										STC; or
									</li>
									<li>
										notifies you in writing that the requested items are available for download by
										you, at the time at which such notification is sent by STC,
									</li>
									<li>
										and title to, and risk in, the items will pass from STC to you at that time; or
									</li>
								</ul>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>in the case of services, either:</li>
								</ul>
								<ul style={{ listStyle: 'circle', paddingInlineStart: 50 }}>
									<li>
										provides the services to you, at the time at which STC commences providing the
										services; or
									</li>
									<li>
										notifies you in writing that your order has been accepted, at the time at which
										such notification is sent by STC.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Prices
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC reserves the right to change the prices for products/services displayed or the fees
								for products/services displayed on this site at any time before you place an order.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Fees
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								There are fees associated with the items that you purchase on our website. Fees include
								merchant fees and also fees that we charge for providing STC products and services.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Fee details can be found at{' '}
								<a href="#" onClick={() => handleClick(6)}>
									www.signthecard.com/fees
								</a>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Shipping costs
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								For any physical products, shipping costs will depend upon the location for delivery and
								the items purchased and are shown separately during checkout.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								GST
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Unless otherwise expressly stated, all amounts payable through your use of this site are
								expressed to be inclusive of GST. For these purposes, the term "GST" has the meaning
								given to it in the A New Tax System (Goods and Services Tax) Act 1999 (Cth).
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Payment methods
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Payment for orders placed through this site may be made by credit card processed online
								using the secure PayPal payment gateway or using a PayPal account.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								PayPal secure payment gateway
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC uses the third-party PayPal payment gateway provided by PayPal Australia Pty Limited
								(ABN 93 111 195 389) for its secure online payment transactions.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Payments made through PayPal are subject to PayPal’s own terms and conditions and
								privacy policy, links to which are provided from the PayPal checkout pages.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								For more information about PayPal, see the PayPal website (
								<a rel="noreferrer" target="_blank" href="https://www.paypal.com.au">
									https://www.paypal.com.au
								</a>
								).
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Unless you expressly consent otherwise, we do not see or have access to any personal
								information that you may provide to PayPal, other than information that is required in
								order to process your order and deliver your purchased items to you (eg, your name,
								email address and billing/postal address).
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Credit and debit card payments
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								It is not necessary to have a PayPal account in order to make a purchase on this site.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								PayPal accepts all major credit and debit cards. Please note that we may be unable to
								accept credit cards issued by banks outside of Australia in some cases.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Additional transaction fees may apply if paying by credit card, in which case the
								additional charges will be specified at checkout.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Refunds and other remedies
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Except as expressly provided otherwise in these Website Terms of Use or other policies,
								all amounts paid through this site are non-refundable.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Further information on the steps that STC will take to remedy any breach of any
								non-excludable condition or warranty/guarantee is provided under the heading "Remedies
								limited" in these Website Terms of Use below.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								For more information about obtaining refunds from third party suppliers, see the
								paragraphs headed "Refunds from suppliers" in these Website Terms of Use below.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Security
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								While PayPal and our website hosting providers employ secure technology for transactions
								with our customers, we will not be responsible for any damages, including consequential
								losses (whether direct or indirect), that may be suffered by a customer whose credit or
								debit card or bank account information is used in a fraudulent or unauthorised manner by
								any person other than STC.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC may request further information from you, such as a copy of your credit card and/or
								other identification documentation, as part of our internal validation procedures.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								These procedures help protect bank and credit card account holders from online fraud.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Until your order has passed our internal fraud prevention checks, your order will remain
								on pending status.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If further information is requested and you do not provide the requested information
								within such time as STC considers appropriate at its discretion, your order will be
								cancelled and, if your payment has been received, it will be refunded back to you.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Dealings with third parties
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Content supplied by third parties
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								This site includes an online portal that allows third parties to advertise goods and/or
								services for sale to our users through this site and to upload information and other
								content directly to this site for our users to access.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We do not act as agent for any such third parties and we take no responsibility, and
								assume no liability, for:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										any such content that is, or may reasonably be considered to be, abusive,
										harassing, harmful, obscene, indecent, inflammatory, violent, profane, racially,
										ethnically or otherwise objectionable, libellous, defamatory, deceptive,
										pornographic, sexually explicit, unlawful or plagiarised;
									</li>
									<li>
										any of the information supplied by such third parties (including opinions,
										ideas, suggestions, comments, observations, text, photographs, videos, data,
										music, sounds, chat messages, files or any other material); and/or
									</li>
									<li>
										any loss or damage that results from any dealings that you may have with such
										third parties.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Third party goods/services and websites
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Unless otherwise stated, we do not recommend or endorse any third party goods or
								services that are listed, advertised or referred to in this site or the content of any
								third party websites.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We are not responsible for the content of linked third party websites, websites framed
								within this site or third party advertisements and we do not make any representations,
								or provide any guarantees or warranties, regarding their content or accuracy.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								These links may unintentionally connect with websites containing information that some
								users may find inappropriate or offensive. Your use of any third party websites is at
								your own risk and subject to their respective terms and conditions of use.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								User acknowledgements
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You acknowledge that STC does not:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										check the truth or currency of any of the material or information that third
										parties provide or make available through this site;
									</li>
									<li>
										control, endorse, approve or warrant to you the merchantability or fitness for
										any particular purpose of any of the goods or services of any third parties
										referred to in this site or whose identities become known to you through this
										site, including suppliers of content that is published or made available in or
										through this site;
									</li>
									<li>
										offer professional advice on the quality or suitability of any goods, services
										or information supplied by any such third parties; or
									</li>
									<li>
										endorse or recommend any third party supplier or any third party goods or
										services, including where details of the relevant supplier are provided by STC
										to you or otherwise become known to you through this site.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Role of STC
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The relevant supplier, and not STC, is:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>the supplier of the goods and/or services that you offer to purchase; and</li>
									<li>
										solely responsible for supplying you with those goods and/or services and for
										those goods/services themselves.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We do not act as agent for the supplier and we make no representation or warranty, and
								provide no guarantee, that the supplier will provide you with the goods and/or services
								that you offer to purchase through this site, or that those goods/services will meet
								your expectations.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You should satisfy yourself through your own enquiries as to the quality or suitability
								of any supplier listed on this site and any goods or services supplied, offered or
								recommended by or on behalf of a supplier.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Supplier’s terms and conditions
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Acceptance of an order creates a contract between you and the relevant supplier in
								respect of the provision of the goods and/or services that are the subject of that
								order. STC is not a party to that contract. That contract will be subject to the
								supplier’s own relevant terms and conditions of supply.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You will be responsible for investigating and reviewing the supplier’s terms and
								conditions of supply – including its policies on refunds, returns, cancellations and
								rescheduling, as applicable – prior to placing any order through this site.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Refunds from suppliers
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								As between you and STC, all amounts paid through this site are non-refundable unless
								otherwise stated.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If, pursuant to the terms and conditions of the contract that is formed between you and
								any supplier upon the acceptance of any offer that you make through this site, you are
								entitled to any refund, the relevant supplier is solely responsible for providing you
								with that refund.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC does not guarantee the provision of that refund to you and you must take action
								against the supplier directly, and not against STC, in order to enforce your entitlement
								to that refund.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Disputes between users and suppliers
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You are solely responsible for your interactions with suppliers listed on this site and
								STC is not a party to any transactions between you and such suppliers.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We reserve the right, but have no obligation, to monitor and take action regarding any
								disputes between users and suppliers.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If you believe that any supplier from which you have purchased any goods or services
								through this site has failed to provide those goods or services to you, or that those
								goods or services did not meet your expectations, please contact us to let us know so
								that we may take disciplinary action against that supplier if we consider it appropriate
								at our discretion to do so.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC will not, however, act on your behalf, or on behalf of any supplier, in respect of
								any dispute between you and a supplier.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Intellectual property
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Copyright
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								In these Website Terms of Use, the term "Proprietary Content" means:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>this site;</li>
									<li>
										all of its content (including all of the text, graphics, designs, software,
										data, sound and video files and other information contained in this site, and
										the selection and arrangement thereof); and
									</li>
									<li>
										all software, systems and other information owned or used by STC in connection
										with the products and services offered through this site (whether hosted on the
										same server as this site or otherwise).
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								All Proprietary Content is the property of STC or its licensors (as applicable) and is
								protected by Australian and international copyright laws.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You must not reproduce, transmit, republish or prepare derivative works from any of the
								Proprietary Content, except as expressly authorised by these Website Terms of Use or
								with the prior written consent of STC or other copyright owner (as applicable).
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You may download and print out content from this site only for your own personal and
								non-commercial use and provided that you do not remove or modify any copyright,
								trademark or other proprietary notices.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								User Content
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								In these Website Terms of Use, the term "User Content" means any and all content that is
								submitted, posted or otherwise added to this site by any user, such as comments, forum
								posts, chat room messages, reviews, ratings and feedback.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								This site contains some features that enable you and other users to upload User Content.
								STC reserves the right to display, refuse to display, remove and/or amend all or any
								part of any User Content at its absolute discretion. In respect of any User Content that
								you upload, you:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										represent and warranty to STC that your sharing of that User Content does not
										infringe any copyright or other legal right of any other person; and
									</li>
									<li>
										grant to STC a worldwide, non-exclusive, royalty-free, perpetual, irrevocable,
										sub-licensable and transferable license to use, reproduce, distribute, modify,
										adapt, prepare derivative works of, publicly display, publicly perform and
										otherwise exploit all or any part of that User Content in any way at STC’s
										absolute discretion.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Copyright claims
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If you believe that our site contains any material that infringes upon any copyright
								that you hold or control, or that users are directed through a link on this site to a
								third party website that you believe is infringing upon any copyright that you hold or
								control, you may send a notification of such alleged infringement to us in writing. Such
								notification should identify the works that are allegedly being infringed upon and the
								allegedly infringing material and give particulars of the alleged infringement.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								In response to such a notification, we will give a written notice of a claim of
								copyright infringement to the provider of the allegedly infringing material. If the
								provider of that material does not respond to us in writing denying the alleged
								infringement within 14 days after receipt of that notice, we will remove or block the
								allegedly infringing material as soon as is reasonably practicable.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If the provider of that material responds to us in writing denying the alleged
								infringement, we will, as soon as is reasonably practicable, send a copy of that
								response to the original notifying party.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If the original notifying party does not, within a further 14 days, file an action
								seeking a court order against the provider of the allegedly infringing material, we may
								restore any removed or blocked material at our discretion. If the original notifying
								party files such a legal action, we will remove or block the allegedly infringing
								material pending resolution of that legal action.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Disclaimer of warranties
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								TO THE MAXIMUM EXTENT PERMITTED BY LAW, STC AND ITS OFFICERS, EMPLOYEES, AGENTS,
								CONSULTANTS, LICENSORS, PARTNERS AND AFFILIATES EXPRESSLY DISCLAIM ALL CONDITIONS,
								REPRESENTATIONS AND WARRANTIES (WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE) IN
								RELATION TO THIS SITE AND ANY PRODUCTS AND/OR SERVICES PURCHASED OR OBTAINED THROUGH
								THIS SITE, INCLUDING ANY IMPLIED WARRANTY/GUARANTEE OF MERCHANTABILITY, FITNESS FOR A
								PARTICULAR PURPOSE OR NON-INFRINGEMENT.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								This site is provided strictly on an "as is" basis. To the maximum extent permitted by
								law, STC and its officers, employees, agents, consultants, licensors, partners and
								affiliates make no representation, warranty or guarantee as to the reliability,
								timeliness, quality, suitability, truth, availability, accuracy or completeness of this
								site or any of its content, and in particular do not represent, warrant or guarantee
								that:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										the use of this site will be secure, timely, uninterrupted or error-free or
										operate in combination with any other hardware, software, system or data;
									</li>
									<li>this site will meet your requirements or expectations;</li>
									<li>
										anything on this site, or on any third-party website referred or linked to in
										this site, is reliable, accurate, complete or up-to-date;
									</li>
									<li>
										the quality of any products, services, information or other material purchased
										or obtained through this site will meet any particular requirements or
										expectations;
									</li>
									<li>errors or defects will be corrected; or</li>
									<li>
										this site or the servers that make it available are free of viruses or other
										harmful components.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Limitation of liability
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Exclusion of liability
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								To the maximum extent permitted by law, STC and its officers, employees, agents,
								consultants, licensors, partners and affiliates exclude all liability to you or any
								other person for any loss, cost, expense, claim or damage (whether arising in contract,
								negligence, tort, equity, statute or otherwise, and for any loss, whether it be
								consequential, indirect, incidental, special, punitive, exemplary or otherwise,
								including any loss of profits, loss or corruption of data or loss of goodwill) arising
								directly or indirectly out of, or in connection with, these Website Terms of Use or the
								use of this site by you or any other person.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Remedies limited
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								To the maximum extent permitted by law, STC and its officers, employees, agents,
								consultants, licensors, partners and affiliates expressly limit their liability for
								breach of any non-excludable condition or warranty/guarantee implied by virtue of any
								legislation to the following remedies (the choice of which is to be at STC's sole
								discretion):
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>in the case of goods, to any of the following:</li>
								</ul>
								<ul style={{ listStyle: 'circle', paddingInlineStart: 50 }}>
									<li>the replacement of the goods or the supply of equivalent goods;</li>
									<li>the repair of the goods;</li>
									<li>
										the payment of the cost of replacing the goods or of acquiring equivalent goods;
										or
									</li>
									<li>the payment of the cost of having the goods repaired; and</li>
								</ul>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>in the case of services:</li>
								</ul>
								<ul style={{ listStyle: 'circle', paddingInlineStart: 50 }}>
									<li>the supply of the services again; or</li>
									<li>the payment of the cost of having the services supplied again.</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Release
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You agree that your use of this site is at your own discretion and risk. You agree to
								release STC and its officers, employees, agents, consultants, licensors, partners and
								affiliates from any claim, demand or cause of action that you may have against any of
								them arising from these Website Terms of Use or the use of this site by you or any other
								person. STC may plead this release as a bar and complete defence to any claims or
								proceedings.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Force majeure
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								To the maximum extent permitted by law, and without limiting any other provision of
								these Website Terms of Use, STC excludes liability for any delay in performing any of
								its obligations under these Website Terms of Use where such delay is caused by
								circumstances beyond the reasonable control of STC, and STC shall be entitled to a
								reasonable extension of time for the performance of such obligations.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								General
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Interpretation
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								In these Website Terms of Use, the following rules of interpretation apply:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										headings are for reference purposes only and in no way define, limit or describe
										the scope or extent of any provision in these Website Terms of Use;
									</li>
									<li>
										these Website Terms of Use may not be construed adversely against STC solely
										because STC prepared them;
									</li>
									<li>the singular includes the plural and vice-versa;</li>
									<li>
										a reference to a "person" includes an individual, a firm, a corporation, a body
										corporate, a partnership, an unincorporated body, an association, a government
										body or any other entity; and
									</li>
									<li>
										the meaning of general words is not limited by specific examples introduced by
										"including", "for example", "in particular" or similar expressions.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Notifications
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC may provide any notification for the purposes of these Website Terms of Use by email
								and/or by adding the notification into your user control panel.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Costs
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Except as specifically provided in these Website Terms of Use, each party must bear its
								own legal, accounting and other costs associated with these Website Terms of Use.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Assignment
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You may not assign, transfer or sub-contract any of your rights or obligations under
								these Website Terms of Use without STC's prior written consent. Your registration with
								this site is personal to you and may not be sold or otherwise transferred to any other
								person.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC may assign, transfer or sub-contract any of its rights or obligations under these
								Website Terms of Use at any time without notice to you.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								No waiver
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Waiver of any power or right under these Website Terms of Use must be in writing signed
								by the party entitled to the benefit of that power or right and is effective only to the
								extent set out in that written waiver. Any failure by STC to act with respect to a
								breach by you or others does not waive STC's right to act with respect to that breach or
								any subsequent or similar breaches.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Severability
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The provisions of these Website Terms of Use are severable and, if any provision of
								these Website Terms of Use is held to be illegal, invalid or unenforceable under present
								or future law, such provision may be removed and the remaining provisions shall be
								enforced.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Variation
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC reserves the right to amend these Website Terms of Use and any other policy on this
								site at any time in its sole discretion and any such changes will, unless otherwise
								noted, be effective immediately. Your continued usage of this site will mean you accept
								those amendments. We reserve the right, without notice and at our sole discretion, to
								change, suspend, discontinue or impose limits on any aspect or content of this site or
								the products/services offered through this site.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You may only vary or amend these Website Terms of Use by written agreement with STC.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Governing law and jurisdiction
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								These Website Terms of Use will be governed in all respects by the laws of New South
								Wales. The parties irrevocably submit to the non-exclusive jurisdiction of the courts of
								New South Wales and the courts of appeal from them.
							</Typography>
							<Box height={32} />
						</div>
					</div>
				)}
				{index === 4 && (
					<div style={{ flexGrow: 1, overflow: 'hidden', overflowY: 'auto' }}>
						<div>
							<Typography variant="h3" style={{ color: '#FFFFFF', textAlign: 'center' }}>
								Card & Gift Collection Terms
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Sign The Card (ABN 60 638 086 094) ("we", "us" or "STC") prides itself on providing
								products and services of the highest quality.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								These Card and Gift Collection Terms ("Card and Gift Terms") describe the terms for our
								card and gift collection offering. If you would like more information, please don't
								hesitate to contact us at{' '}
								<a rel="noreferrer" target="_blank" href="mailto:support@signthecard.com">
									support@signthecard.com
								</a>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								These Card and Gift Terms for part of, and are subject to the provisions of all our
								other policies including our Website Terms of Use (
								<a href="#" onClick={() => handleClick(3)}>
									https://signthecard.com/website-terms
								</a>
								).
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								How Card and Gift Collection works
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								A card creator will either use their own card design or a card design as provided by STC
								to purchase and create a digital card.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The card creator can also be a card contributor and card signer, but may only sign the
								card once under the email address registered.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The card creator can then invite others to sign the card and if they wish, also collect
								a financial contribution (“gift”) on behalf of the gift recipient.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC will never force a card signer to contribute a financial amount. A card signer can
								select ‘No thanks’ at the contribution page to progress with signing a card and not
								contribute a financial amount
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Once the card creator has invited all people they would like to sign and/or contribute
								to the card, the funds will be available for transfer to the card creator within 48
								hours of the card delivery date.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The card creator will need to initiate the withdrawal button to withdraw the gift. In
								the instance of a dormant gift collection account, see the section below “Dormant Gift
								Collection.”
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The minimum voluntary contribution by a card contributor is $5 AUD and the maximum total
								contribution is $250 AUD.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Card Creator
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The card creator is responsible for:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'lower-alpha', paddingInlineStart: 30 }}>
									<li>
										creating the card either using their own design or card design as provided by
										STC; and
									</li>
									<li>nominating the recipient; and</li>
									<li>
										supplying the correct name and email address of the recipient and any other
										required information; and
									</li>
									<li>setting and/or editing the delivery date of the card; and</li>
									<li>setting and/or editing the total contribution amount for the card; and</li>
									<li>
										not editing any other person's message posted on the card - our software does
										not allow this, also the card creator must not use any software to circumvent
										this software feature; and
									</li>
									<li>
										withdrawing the gift within 48 hours of the card delivery date. All monies will
										be transferred to the Paypal email address associated with the Paypal account.
										STC is not liable under any circumstance for the way in which gift collection
										amounts are used; STC does not control or monitor how the gift collection
										amounts are used after withdrawal and is not responsible for any use or misuse
										of this money.
									</li>
									<li>
										inviting others to sign the card by uploading an email address on STC’s site or
										sharing the unique URL created for the card;
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Card Contributor or Card Signer
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The card contributor or card signer is responsible for:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'lower-alpha', paddingInlineStart: 30 }}>
									<li>signing the card within the delivery time frame; and</li>
									<li>
										if the card creator has set up a gift collection, a card contributor can make a
										payment towards the gift, this is optional.
									</li>
									<li>
										signing or making a gift contribution before the card creator’s cut-off time or
										before the card creator has withdrawn the gift contribution amount. You will not
										be able to sign a card after the card delivery date or make a gift contribution
										after the card creator has withdrawn the gift contribution amount, which can be
										within 48 hours of the card delivery date. STC is not responsible under any
										circumstance for any loss or claim in relation to a missed opportunity to make a
										gift contribution.
									</li>
									<li>
										inviting others to sign the card by uploading an email address on STC’s site or
										sharing the unique URL created for the card; and
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Card Recipient
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The card recipient is responsible for:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'lower-alpha', paddingInlineStart: 30 }}>
									<li>creating an account to access the card; and</li>
									<li>viewing and downloading the card.</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The card link will be available indefinitely unless otherwise communicated by STC.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC will send any notice of changes to the card URL.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC is not able to share any personal details of the card contributors or creators for
								privacy reasons.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC does not control or monitor who receives the URL and therefore are not responsible
								for the misuse and ultimately all content on the card.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Allowable Refunds for Gift Collections
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Refunds are not available for the cost of the card purchase under any circumstance.
								Refunds are allowed for gift contributions and the contributions will be sent back to
								each individual contributor and not the card creator and the refund amount will be net
								of STC service fees. All fees can be found at{' '}
								<a href="#" onClick={() => handleClick(6)}>
									www.signthecard.com/fees
								</a>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Dormant Gift Collection
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If a card creator has collected money on behalf of a gift recipient but has not
								withdrawn the money, the money will be held by STC for a period of 90 days after the
								card delivery date. After this time, the money will be returned to each of the card
								contributors net of STC service fees. All fees can be found at{' '}
								<a href="#" onClick={() => handleClick(6)}>
									www.signthecard.com/fees
								</a>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Disclaimer
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								This disclaimer is in addition to the disclaimer in our Website Terms of Use and any
								other STC policies.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								To the fullest extent permitted by law, STC disclaims liability for the following:
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'lower-alpha', paddingInlineStart: 30 }}>
									<li>
										card contributor comments including any comments that are in breach of the law;
										and
									</li>
									<li>card contributor contributions or lack thereof; and</li>
									<li>any missed cut-off times for card signatures or comments; and</li>
									<li>
										any malfunction or decision by STC that results in a card being unavailable for
										a short or indefinite period of time; and
									</li>
									<li>
										prohibited user conduct as explained under our Website Terms of Use including
										but not limited to threats, harassment or discrimination of any kind; and
									</li>
									<li>
										any account suspension for prohibited conduct as described in our Website Terms
										of Use or any other policy.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 900,
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Indemnity
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You indemnify and hold harmless STC and its officers, employees, agents, consultants,
								licensors, partners and affiliates from and against any losses, liabilities, costs,
								expenses or damages (including actual, special, indirect and consequential losses or
								damages of every kind and nature, including all legal fees on a solicitor-client basis)
								suffered or incurred by any of them due to, arising out of, or in any way related to
								(directly or indirectly):
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										any material or information that you submit, post, transmit or otherwise make
										available through this site;
									</li>
									<li>your use of, or connection to, this site; or</li>
									<li>
										your negligence or misconduct, breach of these Gift Collection Terms, our
										Website Terms of Use, any other policies or violation of any law or the rights
										of any person.
									</li>
								</ul>
							</Typography>
							<Box height={32} />
						</div>
					</div>
				)}
				{index === 5 && (
					<div style={{ flexGrow: 1, overflow: 'hidden', overflowY: 'auto' }}>
						<div>
							<Typography variant="h3" style={{ color: '#FFFFFF', textAlign: 'center' }}>
								Privacy Policy
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Sign The Card (ABN 60 638 086 094) ("we", "us" or "STC") is committed to privacy
								protection. At{' '}
								<a rel="noreferrer" target="_blank" href="https://signthecard.com">
									https://signthecard.com
								</a>{' '}
								("this site"), we understand the importance of keeping personal information private and
								secure.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								This privacy policy ("Privacy Policy") describes generally how we manage personal
								information and safeguard privacy. If you would like more information, please don't
								hesitate to contact us.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								This Privacy Policy forms part of, and is subject to the provisions of, our Website
								Terms of Use (
								<a href="#" onClick={() => handleClick(4)}>
									https://signthecard.com/terms-of-use
								</a>
								).
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The Australian Privacy Principles
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We will treat all personal information in accordance with any and all obligations that
								are binding upon us under the Privacy Act 1988 (Cth) (“Privacy Act”).
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The Privacy Act lays down 13 key principles in relation to the collection and treatment
								of personal information, which are called the “Australian Privacy Principles”.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								What is "personal information"?
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Personal information held by STC may include your:
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>name and date of birth;</li>
									<li>
										residential and business postal addresses, telephone/mobile/fax numbers and
										email addresses;
									</li>
									<li>bank account and/or credit card details for agreed billing purposes;</li>
									<li>
										any information that you provided to us by you during your account creation
										process or added to your user profile;
									</li>
									<li>
										preferences and password for using this site and your computer and connection
										information; and
									</li>
									<li>any information that you otherwise share with us.</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Information provided to PayPal
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								All purchases that are made through this site are processed securely and externally by
								PayPal. Unless you expressly consent otherwise, we do not see or have access to any
								personal information that you may provide to PayPal, other than information that is
								required in order to process your order and deliver your purchased items to you (eg,
								your name, email address and billing/postal address).
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								How we may collect your personal information
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								At this site, we only collect personal information that is necessary for us to conduct
								our business as an online seller of digital greeting cards and gift collection
								solutions.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Information that you provide to us
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We may collect personal information that you provide to us about yourself when you:
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>use this site, including (without limitation) when you:</li>
								</ul>
								<ul style={{ listStyle: 'circle', paddingInlineStart: 50 }}>
									<li>create a user account;</li>
									<li>add information to your user profile;</li>
									<li>Create or contribute or receive a card;</li>
									<li>purchase any products and/or services through this site;</li>
									<li>
										add reviews, forum or chat room messages or comments in any elements of this
										site that permit user-generated content;
									</li>
									<li>
										register for access to premium content or request certain premium features; or
									</li>
									<li>complete an online contact form to contact us or any third party supplier;</li>
								</ul>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										provide information to us by telephone or through marketing or competition
										application forms; or
									</li>
									<li>send us an email or other communication.</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								IP addresses
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								This site may also collect Internet Protocol (IP) addresses. IP addresses are assigned
								to computers on the internet to uniquely identify them within the global network.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC collects and manages IP addresses as part of the service of providing internet
								session management and for security purposes. STC may also collect and use web log,
								computer and connection information for security purposes and to help prevent and detect
								any misuse of, or fraudulent activities involving, this site.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Cookies
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								This site uses "cookies" to help personalise your online experience. A cookie is a text
								file or a packet of information that is placed on your hard disk by a web page server to
								identify and interact more effectively with your computer.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								There are two types of cookies that may be used at this site: a persistent cookie and a
								session cookie.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								A persistent cookie is entered by your web browser into the "Cookies" folder on your
								computer and remains in that folder after you close your browser, and may be used by
								your browser on subsequent visits to this site.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								A session cookie is held temporarily in your computer’s memory and disappears after you
								close your browser or shut down your computer.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Cookies cannot be used to run programs. Cookies are uniquely assigned to you, and can
								only be read by a web server in the domain that issued the cookie to you. In some cases,
								cookies may collect and store personal information about you. STC extends the same
								privacy protection to your personal information, whether gathered via cookies or from
								other sources.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You can configure your internet browser to accept all cookies, reject all cookies or
								notify you when a cookie is sent.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Please refer to your internet browser’s instructions to learn more about these
								functions. Most web browsers automatically accept cookies, but you can usually modify
								your browser settings to decline cookies if you prefer.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If you choose to decline cookies, you may not be able to fully experience the
								interactive features of this site.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Why we use cookies
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								This site uses cookies in order to:
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>remember your preferences for using this site;</li>
									<li>manage the signup process when you create an account with us;</li>
									<li>
										recognise you as logged in while you remain so. This avoids your having to log
										in again every time you visit a new page;
									</li>
									<li>
										facilitate e-commerce transactions, to ensure that your order is remembered
										between pages during the checkout process;
									</li>
									<li>
										show relevant notifications to you (eg, notifications that are relevant only to
										users who have, or have not, created an account or subscribed to newsletters or
										email or other subscription services); and
									</li>
									<li>
										remember details of data that you choose to submit to us (eg, through online
										contact forms or by way of comments, forum posts, chat room messages, reviews,
										ratings, etc).
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Many of these cookies are removed or cleared when you log out but some may remain so
								that your preferences are remembered for future sessions.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Third party cookies
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								In some cases, third parties may place cookies through this site. For example:
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										Google Analytics, one of the most widespread and trusted website analytics
										solutions, may use cookies de-identified data about how long users spend on this
										site and the pages that they visit;
									</li>
									<li>
										Google AdSense, one of the most widespread and trusted website advertising
										solutions, may use cookies to serve more relevant advertisements across the web
										and limit the number of times that a particular advertisement is shown to you;
										and
									</li>
									<li>
										third party social media applications (eg, Facebook, Twitter, LinkedIn,
										Pinterest, YouTube, Instagram, etc) may use cookies in order to facilitate
										various social media buttons and/or plugins in this site.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								How we may use your personal information
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Your personal information may be used in order to:
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>verify your identity;</li>
									<li>assist you to place orders through this site;</li>
									<li>
										process any purchases of products and/or services that you may make through this
										site, including charging, billing and collecting debts and shipment of products
										to you;
									</li>
									<li>make changes to your account;</li>
									<li>respond to any queries or feedback that you may have;</li>
									<li>conduct appropriate checks for credit-worthiness and for fraud;</li>
									<li>
										prevent and detect any misuse of, or fraudulent activities involving, this site;
									</li>
									<li>
										conduct research and development in respect of our products and/or services;
									</li>
									<li>
										gain an understanding of your information and communication needs or obtain your
										feedback or views about our products and/or services in order for us to improve
										them; and/or
									</li>
									<li>
										maintain and develop our business systems and infrastructure, including testing
										and upgrading of these systems,
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								and for any other purpose reasonably considered necessary or desirable by STC in
								relation to the operation of our business.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								From time to time we may email our customers with news, information and offers relating
								to our own products/services or those of selected partners.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Your personal information may also be collected so that STC can promote and market
								products and services to you. This is to keep you informed of products, services, and
								special offers we believe you will find valuable and may continue after you cease
								acquiring products and services from us.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If you would prefer not to receive promotional or other material from us, please let us
								know and we will respect your request. You can unsubscribe from such communications at
								any time if you choose.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								When we may disclose your personal information
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Information provided to suppliers
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								When you acquire or access any other goods or services from a third party supplier
								through this site, we will provide to that supplier such information as is necessary to
								enable it to process and administer your order. Such information will include personal
								information about you, including (without limitation) your name and contact details.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Information provided to other organisations
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								In order to deliver the products/services you require or for the purposes set out above,
								STC may disclose your personal information to organisations outside STC.{' '}
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Your personal information may be disclosed to these organisations only in relation to
								this site, and STC takes reasonable steps to ensure that these organisations are bound
								by confidentiality and privacy obligations in relation to the protection of your
								personal information. These organisations may carry out or provide:
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>customer enquiries;</li>
									<li>mailing systems;</li>
									<li>billing and debt-recovery functions;</li>
									<li>information technology services;</li>
									<li>marketing, telemarketing and sales services;</li>
									<li>market research; and</li>
									<li>website usage analysis.</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								In addition, we may disclose your personal information to:
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										your authorised representatives or legal advisers (when requested by you to do
										so);
									</li>
									<li>credit-reporting and fraud-checking agencies;</li>
									<li>
										credit providers (for credit-related purposes such as creditworthiness, credit
										rating, credit provision and financing);
									</li>
									<li>our professional advisers, including our accountants, auditors and lawyers;</li>
									<li>
										government and regulatory authorities and other organisations, as required or
										authorised by law;
									</li>
									<li>
										organisations who manage our business strategies, including those involved in a
										transfer/sale of all or part of our assets or business (including accounts and
										trade receivables) and those involved in managing our business risk and funding
										functions; and
									</li>
									<li>
										the police or other appropriate persons where your communication suggests
										possible illegal activity or harm to others.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Contacting us about privacy
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If you would like more information about the way we manage personal information that we
								hold about you, or are concerned that we may have breached your privacy, please contact
								us by email to{' '}
								<a rel="noreferrer" target="_blank" href="mailto:support@signthecard.com">
									support@signthecard.com
								</a>
								.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Access to your personal information
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								In most cases, you may have access to personal information that we hold about you. We
								will handle requests for access to your personal information in accordance with the
								Australian Privacy Principles.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								All requests for access to your personal information must be directed to the Privacy
								Officer by email using the email address provided above or by writing to us at our
								postal address. We will deal with all requests for access to personal information as
								quickly as possible.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Requests for a large amount of information, or information that is not currently in use,
								may require further time before a response can be given.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We may charge you a fee for access if a cost is incurred by us in order to retrieve your
								information, but in no case will we charge you a fee for your application for access.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								In some cases, we may refuse to give you access to personal information that we hold
								about you. This may include circumstances where giving you access would:
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										be unlawful (eg, where a record that contains personal information about you is
										subject to a claim for legal professional privilege by one of our contractual
										counterparties);
									</li>
									<li>have an unreasonable impact on another person’s privacy; or</li>
									<li>prejudice an investigation of unlawful activity.</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We may also refuse access where the personal information relates to existing or
								anticipated legal proceedings, and the information would not be accessible by the
								process of discovery in those proceedings.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If we refuse to give you access, we will provide you with reasons for our refusal.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Correcting your personal information
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We will amend any personal information about you that is held by us and that is
								inaccurate, incomplete or out of date if you request us to do so.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If we disagree with your view about the accuracy, completeness or currency of a record
								of your personal information that is held by us, and you ask us to associate with that
								record a statement that you have a contrary view, we will take reasonable steps to do
								so.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Storage and security of your personal information
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We are committed to maintaining the confidentiality of the information that you provide
								us and we will take all reasonable precautions to protect your personal information from
								unauthorised use or alteration. In our business, personal information may be stored both
								electronically (on our computer systems and with our website hosting provider) and in
								hard-copy form.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Firewalls, anti-virus software and email filters, as well as passwords, protect all of
								our electronic information. Likewise, we take all reasonable measures to ensure the
								security of hard-copy information.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Third party websites
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You may click-through to third party websites from this site, in which case we recommend
								that you refer to the privacy statement of the websites you visit. This Privacy Policy
								applies to this site only and STC assumes no responsibility for the content of any third
								party websites.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Redirection to PayPal
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								In particular, when you submit an order to us, you may be automatically redirected to
								PayPal in order to complete the required payment. The payment page that is provided by
								PayPal is not part of this site. As noted above, we are not privy to any of the bank
								account, credit card or other personal information that you may provide to PayPal, other
								than information that is required in order to process your order and deliver your
								purchased items to you (eg, your name, email address and billing/postal address). We
								recommend that you refer to PayPal’s privacy statement if you would like more
								information about how PayPal collects and handles your personal information.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Re-marketing
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We may use the Google AdWords and/or Facebook re-marketing services to advertise on
								third party websites to previous visitors to this site based upon their activity on this
								site. This allows us to tailor our marketing to better suit your needs and to only
								display advertisements that are relevant to you. Such advertising may be displayed on a
								Google search results page or a website in the Google Display Network or inside
								Facebook. Google and Facebook may use cookies and/or pixel tags to achieve this. Any
								data so collected by Google and/or Facebook will be used in accordance with their own
								respective privacy policies. None of your personal Google and/or Facebook information is
								reported to us.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								You can set preferences for how Google advertises to you using the Google Ads Settings
								page (
								<a rel="noreferrer" target="_blank" href="https://www.google.com/settings/ads">
									https://www.google.com/settings/ads
								</a>
								). Facebook has enabled an AdChoices link that enables you to opt out of targeted
								advertising.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								GDPR
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC welcomes the General Data Protection Regulation (“GDPR”) of the European Union
								(“EU”) as an important step forward in streamlining data protection globally. We intend
								to comply with the data handling regime laid out in the GDPR in respect of any personal
								information of data subjects in the EU that we may obtain.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								GDPR rights
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The requirements of the GDPR are broadly similar to those set out in the Privacy Act and
								include the following rights:
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										you are entitled to request details of the information that we hold about you
										and how we process it. For EU residents, we will provide this information for no
										fee;
									</li>
									<li>you may also have a right to:</li>
								</ul>
								<ul style={{ listStyle: 'circle', paddingInlineStart: 50 }}>
									<li>have that information rectified or deleted;</li>
									<li>restrict our processing of that information;</li>
									<li>stop unauthorised transfers of your personal information to a third party;</li>
									<li>
										in some circumstances, have that information transferred to another
										organisation; and
									</li>
									<li>
										lodge a complaint in relation to our processing of your personal information
										with a local supervisory authority; and
									</li>
								</ul>
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										where we rely upon your consent as our legal basis for collecting and processing
										your data, you may withdraw that consent at any time.
									</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If you object to the processing of your personal information, or if you have provided
								your consent to processing and you later choose to withdraw it, we will respect that
								choice in accordance with our legal obligations. However, please be aware that:
								<ul style={{ listStyle: 'disc', paddingInlineStart: 30 }}>
									<li>
										such objection or withdrawal of consent could mean that we are unable to provide
										our services to you, and could unduly prevent us from legitimately providing our
										services to other customers/clients subject to appropriate confidentiality
										protections; and
									</li>
									<li>
										even after you have chosen to withdraw your consent, we may be able to continue
										to keep and process your personal information to the extent required or
										otherwise permitted by law, in particular:
									</li>
								</ul>
								<ul style={{ listStyle: 'circle', paddingInlineStart: 50 }}>
									<li>
										to pursue our legitimate interests in a way that might reasonably be expected as
										part of running our business and which does not materially impact on your
										rights, freedoms or interests; and
									</li>
									<li>in exercising and defending ou</li>
								</ul>
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Storage and processing by third parties
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Data that we collect about you may be stored or otherwise processed by third party
								services with data centres based outside the EU, such as Google Analytics, Microsoft
								Azure, Amazon Web Services, Apple, etc and online relationship management tools. We
								consider that the collection and such processing of this information is necessary to
								pursue our legitimate interests in a way that might reasonably be expected (eg, to
								analyse how our customers/clients use our services, develop our services and grow our
								business) and which does not materially impact your rights, freedom or interests.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC requires that all third parties that act as “data processors” for us provide
								sufficient guarantees and implement appropriate technical and organisational measures to
								secure your data, only process personal data for specified purposes and have committed
								themselves to confidentiality.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Duration of retention of your data
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								We will only keep your data for as long as is necessary for the purpose for which it was
								collected, subject to satisfying any legal, accounting or reporting requirements. At the
								end of any retention period, your data will either be deleted completely or anonymised
								(for example, by aggregation with other data so that it can be used in a
								non-identifiable way for statistical analysis and business planning). In some
								circumstances, you can ask us to delete your data.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Keeping your information up-to-date
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								To ensure that your personal information is accurate and up to date, please promptly
								advise us of any changes to your information by contacting our data protection officer
								by email at{' '}
								<a rel="noreferrer" target="_blank" href="mailto:support@signthecard.com">
									support@signthecard.com
								</a>
								.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Changes to this Privacy Policy
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								From time to time, it may be necessary for us to revise this Privacy Policy. Any changes
								will be in accordance with any applicable requirements under the Privacy Act and the
								Australian Privacy Principles. We may notify you about changes to this Privacy Policy by
								posting an updated version on this site.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								* * * *
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If you require any further information about the Privacy Act and the Australian Privacy
								Principles, you can visit the Federal Privacy Commissioner’s website (see{' '}
								<a rel="noreferrer" target="_blank" href="https://www.privacy.gov.au">
									https://www.privacy.gov.au
								</a>
								).
							</Typography>
							<Box height={32} />
						</div>
					</div>
				)}
				{index === 6 && (
					<div style={{ flexGrow: 1, overflow: 'hidden', overflowY: 'auto' }}>
						<div>
							<Typography variant="h3" style={{ color: '#FFFFFF', textAlign: 'center' }}>
								Fees
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Card creation
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Creating a card requires an upfront payment of ${settingsState.creationFee.toFixed(2)}
								AUD per card. That’s cheaper than a coffee, cheeseburger and most physical cards!
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Contribution and Withdrawal
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If a card creator is collecting money on behalf of a gift recipient, a STC Contribution
								Service Fee of {settingsState.withdrawalFee.toFixed(2)}% is applied upon withdrawal of
								the total amount collected to the PayPal email address of the creator.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								The minimum voluntary contribution by a card contributor is $5 AUD and the maximum total
								contribution is $250 AUD.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Dormant Gift Collection
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								If a card creator has collected money on behalf of a gift recipient but has not
								withdrawn the money, the money will be held by STC for a period of 90 days after the
								card delivery date. After this time, the money will be returned to each of the card
								contributors net of STC Contribution Service Fee of{' '}
								{settingsState.withdrawalFee.toFixed(2)}%.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								PayPal
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								For all payment enquiries please contact PayPal,{' '}
								<a rel="noreferrer" target="_blank" href="https://www.paypal.com.au">
									https://www.paypal.com.au
								</a>
								, who are the Sign the Card payment partner for card purchases and gift collections.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Remember – if you’re collecting monies for a gift to have a PayPal account set up. Sign
								the Card will deposit all monies to an email address linked to a nominated PayPal
								account and will be available to withdraw 48 hours before the card is scheduled to be
								delivered.
							</Typography>
							<Box height={16} />
							<Typography
								variant="body2"
								style={{
									fontWeight: 'bold',
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Allowable Refunds for Gift Collections
							</Typography>
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								Refunds are not available for the cost of the card purchase under any circumstance.
								Refunds are allowed for gift contributions and the contributions will be sent back to
								each individual contributor and not the card creator and the refund amount will be net
								of STC service fees.
							</Typography>
							<Box height={32} />
							<Typography
								variant="body2"
								style={{
									width: '100%',
									boxSizing: 'border-box',
									padding: width < 992 ? undefined : '0 25vw',
									color: '#FFFFFF',
								}}
							>
								STC reserves the right to change the prices for products/services displayed or the fees
								for products/services displayed on this site at any time before you place an order.
							</Typography>
							<Box height={32} />
						</div>
					</div>
				)}
			</div>
		</div>,
		document.body
	);
};

export default ModalMenu;
