import React from 'react';
import Box from '../../lib/components/Box';
import CopyLink from '../../lib/components/CopyLink';
import InvitationBox from '../../lib/components/InvitationBox';
import Typography from '../../lib/components/Typography';
import Contributor from '../../models/Contributor';
import styles from './index.module.scss';

interface Props {
	uuid: string;
	recipientFirstName: string;
	contributors: Array<Contributor>;
	isPaid: boolean;
	setContributors: (value: Array<Contributor>) => void;
}

const InviteTab: React.FC<Props> = (props: Props) => {
	return (
		<div className={styles.formContainer}>
			{props.isPaid && (
				<>
					<Typography variant="h4">Payment Successful!</Typography>
					<Box height={18} />
				</>
			)}
			<Typography variant="h6">Invite people to sign {props.recipientFirstName}'s card</Typography>
			<Box height={24} />
			<Typography variant="body2" style={{ fontStyle: 'italic' }}>
				Share the unique link below with people who would like to sign {props.recipientFirstName}’s card.
			</Typography>
			<Box height={24} />
			<CopyLink url={`signthecard.com/invitation/${props.uuid}`} />
			<Box height={18} />
			<Typography variant="body2" style={{ fontStyle: 'italic' }}>
				Or, add their email addresses and we'll send on invite for you.
			</Typography>
			<Box height={18} />
			<InvitationBox isCreating list={props.contributors} setList={props.setContributors} />
			<Box height={18} />
		</div>
	);
};

export default InviteTab;
