import React from 'react';
import CheckIcon from './../../../img/ic-check.svg';
import styles from './index.module.scss';

interface Props {
	value: boolean;
	onChange: (value: boolean) => void;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	label?: string;
	disabled?: boolean;
}

const Checkbox: React.FC<Props> = ({ disabled = false, ...props }: Props) => {
	const [isChecked, setIsChecked] = React.useState<boolean>(props.value);

	const handleChange = React.useCallback(() => {
		if (disabled) {
			return;
		}

		setIsChecked(!isChecked);
		props.onChange(!isChecked);
	}, [props.value, props.onChange, disabled, isChecked]);

	React.useEffect(() => {
		if (props.value !== isChecked) {
			setIsChecked(props.value);
		}
	}, [props.value]);

	return (
		<div style={props.style} onClick={handleChange} className={styles.container}>
			<div className={`${styles.checkbox} ${isChecked ? styles.active : ''} ${disabled ? styles.disabled : ''}`}>
				<img className={styles.icon} src={CheckIcon} alt={'Check'} />
			</div>
			{props.children !== undefined && <div className={styles.label}>{props.children}</div>}
			{props.children === undefined && props.label !== undefined && (
				<div className={styles.label}>{props.label}</div>
			)}
		</div>
	);
};

export default Checkbox;
