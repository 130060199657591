/* eslint-disable camelcase */
/**
 *
 * LoginSocialLinkedin
 *
 */
import axios from 'axios';
import React, { memo, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { TypeCrossFunction } from 'reactjs-social-login';

type objectType = {
	[key: string]: any;
};

type IResolveParams = {
	provider: string;
	data?: objectType;
};

interface Props {
	state?: string;
	scope?: string;
	client_id: string;
	className?: string;
	redirect_uri: string;
	response_type?: string;
	children?: React.ReactNode;
	onLoginStart?: () => void;
	onLogoutSuccess?: () => void;
	onReject: (reject: string | objectType) => void;
	onResolve: ({ provider, data }: IResolveParams) => void;
}

const LINKEDIN_URL: string = 'https://www.linkedin.com/oauth/v2';
const LINKEDIN_API_URL: string = 'https://api.linkedin.com';
const PREVENT_CORS_URL: string = 'https://cors.bridged.cc';

export const LoginSocialLinkedin = React.forwardRef(
	(
		{
			state = '',
			scope = 'r_liteprofile',
			client_id,
			className = '',
			redirect_uri,
			response_type = 'code',
			children,
			onLoginStart,
			onLogoutSuccess,
			onReject,
			onResolve,
		}: Props,
		ref: React.Ref<TypeCrossFunction>
	) => {
		const [isLogged, setIsLogged] = useState(false);
		const [isProcessing, setIsProcessing] = useState(false);

		useEffect(() => {
			const popupWindowURL = new URL(window.location.href);
			const code = popupWindowURL.searchParams.get('code');
			const state = popupWindowURL.searchParams.get('state');
			if (state?.includes('_linkedin') && code) {
				localStorage.setItem('linkedin', code);
				window.close();
			}
		}, []);

		const getProfile = useCallback(
			(code: string) => {
				axios
					.post(
						`${process.env.REACT_APP_API_URL}/linkedin/callback`,
						{
							code,
						},
						{
							headers: {
								'Content-Type': 'application/json',
							},
						}
					)
					.then((res) => {
						setIsLogged(true);
						setIsProcessing(false);
						onResolve({ provider: 'linkedin', data: res.data });
					})
					.catch((err) => {
						setIsProcessing(false);
						onReject(err);
					});
			},
			[onReject, onResolve]
		);

		const handlePostMessage = useCallback(
			async ({ type, code, provider }) => type === 'code' && provider === 'linkedin' && code && getProfile(code),
			[getProfile]
		);

		const onChangeLocalStorage = useCallback(() => {
			window.removeEventListener('storage', onChangeLocalStorage, false);
			const code = localStorage.getItem('linkedin');
			if (code) {
				setIsProcessing(true);
				handlePostMessage({ provider: 'linkedin', type: 'code', code });
				localStorage.removeItem('linkedin');
			}
		}, [handlePostMessage]);

		const onLogin = useCallback(() => {
			if (!isProcessing) {
				onLoginStart && onLoginStart();
				window.addEventListener('storage', onChangeLocalStorage, false);
				const oauthUrl = `${LINKEDIN_URL}/authorization?response_type=${response_type}&client_id=${client_id}&scope=${scope}&state=${
					state + '_linkedin'
				}&redirect_uri=${redirect_uri}`;
				const width = 450;
				const height = 730;
				const left = window.screen.width / 2 - width / 2;
				const top = window.screen.height / 2 - height / 2;
				window.open(
					oauthUrl,
					'Linkedin',
					'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
						width +
						', height=' +
						height +
						', top=' +
						top +
						', left=' +
						left
				);
			}
		}, [isProcessing, onLoginStart, onChangeLocalStorage, response_type, client_id, scope, state, redirect_uri]);

		useImperativeHandle(ref, () => ({
			onLogout: () => {
				if (isLogged) {
					setIsLogged(false);
					onLogoutSuccess && onLogoutSuccess();
				} else {
					console.log('You must login before logout.');
				}
			},
		}));

		return (
			<div className={className} onClick={onLogin}>
				{children}
			</div>
		);
	}
);
LoginSocialLinkedin.displayName = 'LoginSocialLinkedin';

export default memo(LoginSocialLinkedin);
