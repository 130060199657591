import React from 'react';
import { createPortal } from 'react-dom';
import CloseIcon from './../../../img/ic-modal-close.svg';
import styles from './index.module.scss';

interface Props {
	children: React.ReactNode;
	isVisible: boolean;
	setIsVisible: (value: boolean) => void;
	style?: React.CSSProperties;
}

const Modal: React.FC<Props> = (props: Props) => {
	const ref = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const mouseUp = (e: any) => {
			if (ref.current) {
				if (!ref.current.contains(e.target)) {
					props.setIsVisible(false);
				}
			}
		};

		document.addEventListener('mouseup', mouseUp);

		return () => {
			document.removeEventListener('mouseup', mouseUp);
		};
	}, [ref]);

	if (!props.isVisible) {
		return null;
	}

	return createPortal(
		<div style={{ zIndex: 1100, height: '100%', boxSizing: 'border-box' }}>
			<div className={styles.container}>
				<div style={props.style} className={styles.scrollable}>
					<div onClick={() => props.setIsVisible(false)} className={styles.button}>
						<img src={CloseIcon} alt={'Close'} />
					</div>
					<div ref={ref}>{props.children}</div>
				</div>
			</div>
		</div>,
		document.body
	);
};

export default Modal;
