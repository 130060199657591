import moment from 'moment';
import React from 'react';
import Contributor from '../../../models/Contributor';
import { useCreateCardState, useManageCardState, useUserState } from '../../../store/selectors';
import validateEmail from '../../../utils/validateEmail';
import Box from '../Box';
import Typography from '../Typography';
import CheckIcon from './../../../img/ic-check.svg';
import AddIcon from './../../../img/ic-circle-add.svg';
import CloseIcon from './../../../img/ic-circle-close.svg';
import DollarIcon from './../../../img/ic-dollar.svg';
import styles from './index.module.scss';

interface Props {
	list: Array<Contributor>;
	setList: (list: Array<Contributor>) => void;
	onAdd?: (contributor: Contributor) => void;
	onRemove?: (contributor: Contributor) => void;
	viewOnly?: boolean;
	height?: number;
	isCreating?: boolean;
}

const InvitationBox: React.FC<Props> = ({ onAdd = () => {}, onRemove = () => {}, height = 120, ...props }: Props) => {
	const userState = useUserState();
	const createCardState = useCreateCardState();
	const manageCardState = useManageCardState();

	const [index, setIndex] = React.useState<number>(0);
	const [value, setValue] = React.useState<string>('');
	const [error, setError] = React.useState<string>('');

	const canProceed = React.useMemo<boolean>(() => {
		if (props.isCreating) {
			return (
				value.toLowerCase().trim() !== userState.email.toLowerCase().trim() &&
				value.toLowerCase().trim() !== createCardState.recipientEmail.toLowerCase().trim()
			);
		} else {
			return (
				value.toLowerCase().trim() !== userState.email.toLowerCase().trim() &&
				value.toLowerCase().trim() !== manageCardState.recipientEmail.toLowerCase().trim()
			);
		}
	}, [props.isCreating, userState.email, createCardState.recipientEmail, manageCardState.recipientEmail, value]);

	const filteredList = React.useMemo<Array<Contributor>>(() => {
		if (index === 1) {
			const list = [...props.list];
			list.sort(function (a, b) {
				if (a.email < b.email) {
					return -1;
				}
				if (a.email > b.email) {
					return 1;
				}
				return 0;
			});

			return list;
		}

		return props.list;
	}, [props.list, index]);

	const handleAdd = React.useCallback(() => {
		if (!canProceed) {
			return;
		}

		if (value !== '' && !validateEmail(value) && value.length >= 6) {
			setError('Invalid email address!');
			return;
		} else {
			setError('');
		}

		const contributor = {
			email: value,
			isSigned: false,
			isContributed: false,
			contribution: 0,
			signedAt: null,
			contributedAt: null,
			createdAt: moment.utc().format('MM/DD/YYYY'),
		};

		const list = [...props.list];
		list.unshift(contributor);

		onAdd(contributor);

		setValue('');
		props.setList(list);
	}, [value, canProceed]);

	const handleRemove = React.useCallback(
		(value: Contributor) => {
			const list = [...props.list];
			const index = list.findIndex((element) => element.email === value.email);
			if (index !== -1) {
				list.splice(index, 1);
			}

			onRemove(value);

			props.setList(list);
		},
		[props.list]
	);

	const handleKeyUp = React.useCallback(
		(e) => {
			if (e.keyCode === 13) {
				handleAdd();
			}
		},
		[handleAdd]
	);

	const handleOnBlur = React.useCallback(() => {
		if (
			props.list.findIndex((element) => element.email.toLowerCase().trim() === value.toLowerCase().trim()) !== -1
		) {
			setError('Email address already added!');
			return;
		}

		if (value.toLowerCase().trim() === userState.email.toLowerCase().trim()) {
			setError('Cannot invite yourself.');
			return;
		}

		if (props.isCreating && value.toLowerCase().trim() === createCardState.recipientEmail.toLowerCase().trim()) {
			setError('Cannot invite recipient.');
			return;
		}

		if (!props.isCreating && value.toLowerCase().trim() === manageCardState.recipientEmail.toLowerCase().trim()) {
			setError('Cannot invite recipient.');
			return;
		}

		setError('');
	}, [value, props.isCreating, userState.email, createCardState.recipientEmail, manageCardState.recipientEmail]);

	return (
		<div className={styles.container}>
			{!props.viewOnly && (
				<>
					<div className={styles.inputContainer}>
						<input
							onKeyUp={handleKeyUp}
							value={value}
							onChange={(event) => setValue(event.target.value)}
							onBlur={handleOnBlur}
							placeholder="Add email addresses"
							type="email"
							className={styles.input}
						/>
						<img
							onClick={handleAdd}
							className={`${styles.icon} ${value === '' || error !== '' ? styles.iconDisabled : ''}`}
							src={AddIcon}
							alt="Add"
						/>
					</div>
					<Box height={4} />
					<span className={styles.errorText}>{error}&nbsp;</span>
				</>
			)}
			<div className={styles.tabbar}>
				<div style={{ display: 'flex', flexDirection: 'row', flexGrow: 2 }}>
					<div
						onClick={() => setIndex(0)}
						className={`${styles.item} ${index === 0 ? styles.itemActive : ''}`}
					>
						<Typography
							style={{ textAlign: 'center', textTransform: 'uppercase', fontWeight: 600 }}
							variant="overline"
						>
							Recently added
						</Typography>
					</div>
					<div
						onClick={() => setIndex(1)}
						className={`${styles.item} ${index === 1 ? styles.itemActive : ''}`}
					>
						<Typography
							style={{ textAlign: 'center', textTransform: 'uppercase', fontWeight: 600 }}
							variant="overline"
						>
							Alphabetical
						</Typography>
					</div>
				</div>
				<Typography
					style={{
						padding: '12px 0',
						flexGrow: 1,
						textAlign: 'right',
						textTransform: 'uppercase',
						fontWeight: 'bold',
					}}
					variant="overline"
				>
					{filteredList.length} invited
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: filteredList.length === 0 ? 'center' : 'flex-start',
					justifyContent: filteredList.length === 0 ? 'center' : 'flex-start',
					height,
					overflow: 'hidden',
					overflowY: 'auto',
				}}
			>
				{filteredList.length === 0 && (
					<Typography
						style={{ color: 'rgba(0, 0, 0, .38)', textAlign: 'center', fontWeight: 'normal' }}
						variant="body1"
					>
						Enter email addresses to get started
					</Typography>
				)}
				{filteredList.length !== 0 &&
					filteredList.map((element, index) => (
						<div key={index} className={styles.entryContainer}>
							<Typography style={{ flexGrow: 1 }} variant="body1">
								{element.email === '' ? 'Guest' : element.email}
							</Typography>
							{!props.viewOnly && (
								<img
									onClick={() => handleRemove(element)}
									className={`${styles.removeIcon}`}
									src={CloseIcon}
									alt="Remove"
								/>
							)}
							{props.viewOnly && (
								<>
									<img
										className={`${styles.previewIcon} ${
											element.isSigned ? styles.previewIconActive : ''
										}`}
										src={CheckIcon}
										alt="Signed"
									/>
									<Box width={8} />
									<img
										className={`${styles.previewIcon} ${
											element.isContributed ? styles.previewIconActive : ''
										}`}
										src={DollarIcon}
										alt="Paid"
									/>
								</>
							)}
						</div>
					))}
			</div>
		</div>
	);
};

export default InvitationBox;
