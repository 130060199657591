import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import Checkbox from '../../lib/components/Checkbox';
import Controls from '../../lib/components/Controls';
import Header from '../../lib/components/Header';
import { PayPalButton } from '../../lib/components/PayPalButton';
import Tabbar from '../../lib/components/Tabbar';
import Typography from '../../lib/components/Typography';
import CardPack from '../../models/CardPack';
import Contributor from '../../models/Contributor';
import createCardAsyncActions from '../../store/actions/createCard.action';
import transactionsAsyncActions from '../../store/actions/transactions.action';
import RequestManager from '../../store/request-manager';
import {
	useAuthenticationState,
	useCardPackState,
	useCreateCardState,
	useRequestState,
	useSettingsState,
	useUserState,
} from '../../store/selectors';
import { createCardActions } from '../../store/slices/createCard.slice';
import { uiActions } from '../../store/slices/ui.slice';
import getTimezones from '../../utils/getTimezones';
import PayPalTransactionTypes from '../../utils/PayPalTransactionTypes';
import validateEmail from '../../utils/validateEmail';
import ConfirmTab from './ConfirmTab';
import GiftDetailsTab from './GiftDetailsTab';
import styles from './index.module.scss';
import InviteTab from './InviteTab';
import RecipientTab from './RecipientTab';

const CreateCardScreen: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const authenticationState = useAuthenticationState();
	const userState = useUserState();
	const createCardState = useCreateCardState();
	const settingsState = useSettingsState();
	const cardPackState = useCardPackState();
	const requestState = useRequestState();

	const [requestUpdatedAt] = React.useState<number>(requestState.updatedAt);

	const { width } = useWindowDimensions();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [zoom, setZoom] = React.useState<number>(1);
	const [index, setIndex] = React.useState<number>(0);
	const [isPaid, setIsPaid] = React.useState<boolean>(false);
	const [cardPackId, setCardPackId] = React.useState<string>('-1');
	const [recipientFirstName, setRecipientFirstName] = React.useState<string>(
		`${createCardState.recipientFirstName} ${createCardState.recipientLastName}`.trim()
	);
	const [recipientLastName, setRecipientLastName] = React.useState<string>(createCardState.recipientLastName);
	const [recipientEmail, setRecipientEmail] = React.useState<string>(createCardState.recipientEmail);
	const [deliveryAt, setDeliveryAt] = React.useState<string>(createCardState.deliveryAt);
	const [deliveryTimezone, setDeliveryTimezone] = React.useState<string>(createCardState.deliveryTimezone);
	const [isContributionEnabled, setIsContributionEnabled] = React.useState<boolean>(
		createCardState.isContributionEnabled
	);
	const [contribution, setContribution] = React.useState<number>(createCardState.contribution);
	const [contributors, setContributors] = React.useState<Array<Contributor>>(createCardState.contributors);
	const [isReminderEnabled, setIsReminderEnabled] = React.useState<boolean>(createCardState.isReminderEnabled);
	const [reminderMessage, setReminderMessage] = React.useState<string>(createCardState.reminderMessage);
	const [timezones] = React.useState<Array<string>>(getTimezones());

	const [date, setDate] = React.useState<string>(
		moment(createCardState.deliveryAt, 'DD/MM/YYYY hh:mm A').format('DD/MM/YYYY')
	);
	const [time, setTime] = React.useState<string>(
		moment(createCardState.deliveryAt, 'DD/MM/YYYY hh:mm A').format('hh:mm A')
	);

	const [isPaymentMode, setIsPaymentMode] = React.useState<boolean>(false);

	const [showRightPanel] = React.useState<boolean>(true);

	const canProceed = React.useMemo<boolean>(() => {
		if (index === 0) {
			if (recipientEmail.toLowerCase().trim() === userState.email.toLowerCase().trim()) {
				return false;
			}

			return (
				recipientFirstName !== '' &&
				recipientEmail !== '' &&
				validateEmail(recipientEmail) &&
				deliveryAt !== '' &&
				timezones.some((element) => element === deliveryTimezone)
			);
		} else if (index === 1) {
			return (isContributionEnabled && (contribution === -1 || contribution >= 5)) || !isContributionEnabled;
		} else if (index === 2) {
			return userState.availableCards > 0;
		} else if (index === 3) {
			return reminderMessage !== '';
		}

		return false;
	}, [
		userState.updatedAt,
		index,
		timezones,
		recipientFirstName,
		recipientEmail,
		deliveryAt,
		deliveryTimezone,
		isContributionEnabled,
		contribution,
		contributors,
		reminderMessage,
		userState.availableCards,
	]);

	const cardPack = React.useMemo<CardPack | undefined>(
		() => cardPackState.list.find((element) => element.id === cardPackId),
		[cardPackState.list, cardPackId]
	);

	const handleNext = React.useCallback(async () => {
		if (index > 3 || !canProceed) {
			return;
		}

		if (index === 0) {
			dispatch(
				createCardActions.updateRecipient({
					recipientFirstName,
					recipientLastName,
					recipientEmail,
					deliveryAt,
					deliveryTimezone,
				})
			);
		} else if (index === 1) {
			dispatch(createCardActions.updateContribution({ isContributionEnabled, contribution }));
			if (userState.availableCards === 0) {
				setIsPaymentMode(true);
				return;
			}

			setIsLoading(true);
			dispatch(createCardAsyncActions.store());
			return;
		} else if (index === 2) {
			dispatch(createCardActions.updateContributors({ contributors }));

			setIsLoading(true);
			dispatch(createCardAsyncActions.store());
			return;
		} else if (index === 3) {
			dispatch(createCardActions.updateReminder({ isReminderEnabled, reminderMessage }));

			setIsLoading(true);
			dispatch(createCardAsyncActions.publish());
		}

		if (index < 3) {
			setIndex(index + 1);
		}
	}, [
		dispatch,
		index,
		canProceed,
		recipientFirstName,
		recipientLastName,
		recipientEmail,
		deliveryAt,
		deliveryTimezone,
		isContributionEnabled,
		contribution,
		userState.availableCards,
		contributors,
		isReminderEnabled,
		reminderMessage,
	]);

	const handleChangeDesign = React.useCallback(() => {
		if (index === 0) {
			dispatch(
				createCardActions.updateRecipient({
					recipientFirstName,
					recipientLastName,
					recipientEmail,
					deliveryAt,
					deliveryTimezone,
				})
			);
		} else if (index === 1) {
			dispatch(createCardActions.updateContribution({ isContributionEnabled, contribution }));
		} else if (index === 2) {
			dispatch(createCardActions.updateContributors({ contributors }));
		} else if (index === 3) {
			dispatch(createCardActions.updateReminder({ isReminderEnabled, reminderMessage }));
		}

		history.replace('/?action=create&redirectUri=/create-card');
	}, [
		dispatch,
		history,
		index,
		recipientFirstName,
		recipientLastName,
		recipientEmail,
		deliveryAt,
		deliveryTimezone,
		isContributionEnabled,
		contribution,
		contributors,
		isReminderEnabled,
		reminderMessage,
	]);

	const handleFeesClick = React.useCallback(() => {
		dispatch(uiActions.openNavigationModal({ index: 6 }));
	}, [dispatch]);

	const createOrder = React.useCallback(
		(data: any, actions: any) => {
			const order = actions.order.create({
				purchase_units: [
					{
						amount: {
							currency_code: 'AUD',
							value: cardPack
								? cardPack.pricePerCard + cardPack.cardsAmount * cardPack.pricePerCard
								: settingsState.creationFee,
						},
						description: `Payment for card creation for ${recipientFirstName}`,
					},
				],
			});

			order.then((transactionId: string) => {
				dispatch(
					transactionsAsyncActions.initialize({
						transactionId,
						objectId: cardPackId === '-1' ? null : cardPackId,
						typeId: PayPalTransactionTypes.CREATION,
						amount: cardPack
							? cardPack.pricePerCard + cardPack.cardsAmount * cardPack.pricePerCard
							: settingsState.creationFee,
					})
				);
			});

			return order;
		},
		[dispatch, cardPackId, cardPack, recipientFirstName, settingsState.creationFee]
	);

	const onApprove = React.useCallback(
		(data: any, actions: any) => {
			return actions.order.capture().then(() => {
				dispatch(
					transactionsAsyncActions.finalize({
						transactionId: data.orderID,
					})
				);
			});
		},
		[dispatch]
	);

	const toggleCardPack = React.useCallback(
		(id: string) => {
			if (cardPackId === id) {
				setCardPackId('-1');
			} else {
				setCardPackId(id);
			}
		},
		[cardPackId]
	);

	React.useEffect(() => {
		if (requestUpdatedAt === requestState.updatedAt) return;
		const RM = new RequestManager(requestState);

		if (RM.isPending(transactionsAsyncActions.finalize.typePrefix)) {
			setIsLoading(true);
		}

		if (RM.isFinished(transactionsAsyncActions.finalize.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(transactionsAsyncActions.finalize.typePrefix)) {
			RM.consume(transactionsAsyncActions.finalize.typePrefix);

			setIsPaymentMode(false);
			setIsPaid(true);

			setIsLoading(true);
			dispatch(createCardAsyncActions.store());
		}

		if (
			RM.isFinished(createCardAsyncActions.store.typePrefix) ||
			RM.isFinished(createCardAsyncActions.publish.typePrefix)
		) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(createCardAsyncActions.store.typePrefix)) {
			RM.consume(createCardAsyncActions.store.typePrefix);

			setIndex((prev) => prev + 1);
		}

		if (RM.isFulfilled(createCardAsyncActions.publish.typePrefix)) {
			RM.consume(createCardAsyncActions.publish.typePrefix);

			history.replace('/card-created');
		}
	}, [requestUpdatedAt, requestState.updatedAt]);

	React.useEffect(() => {
		if (index !== 3) {
			setReminderMessage(createCardState.reminderMessage);
		}
	}, [index, createCardState.reminderMessage]);

	React.useEffect(() => {
		setDeliveryAt(`${date} ${time}`);
	}, [date, time]);

	React.useEffect(() => {
		const parts = recipientFirstName.trim().split(' ');
		if (parts.length === 1) {
			setRecipientLastName('');
		} else {
			setRecipientLastName(parts.slice(1).join(' '));
		}
	}, [recipientFirstName]);

	React.useEffect(() => {
		if (!authenticationState.isAuthenticated) {
			dispatch(uiActions.openSignInModal({}));
		}
	}, [dispatch, authenticationState]);

	React.useEffect(() => {
		if (createCardState.image === '' && createCardState.template === null) {
			history.replace('/');
		}
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.leftContainer}>
				<Header
					title={<Typography variant="body2">Sign the Card for</Typography>}
					subTitle={
						<Typography variant="h5">
							{recipientFirstName}
							{recipientFirstName.includes(' ') ? '' : recipientLastName}&nbsp;
						</Typography>
					}
					relative
				/>
				<div style={{ marginTop: 16, display: 'flex', alignItems: 'center', height: '100%' }}>
					<div className={styles.cardContainer} style={{ transform: `scale(${zoom})` }}>
						<img src={createCardState.image} alt={'Card Preview'} />
					</div>
				</div>
				<Button onClick={handleChangeDesign} type="text">
					Change Design
				</Button>
				<Box height={12} />
				<Controls noPage zoom={zoom} setZoom={setZoom} />
				<Box height={32} />
			</div>
			{isPaymentMode && (
				<div className={`${styles.rightContainer} ${showRightPanel ? styles.rightContainerActive : ''}`}>
					<div style={{ width: '100%', boxSizing: 'border-box', paddingLeft: 32, paddingRight: 32 }}>
						<div
							style={{
								padding: '32px 8px 0',
								fontSize: '1.5rem',
								display: 'flex',
								flexDirection: 'row',
								alignSelf: 'flex-start',
								alignItems: 'center',
							}}
						>
							<span>&lt;</span>
							<Button
								style={{ height: 32, margin: '0 8px', padding: 0 }}
								className={styles.button}
								onClick={() => setIsPaymentMode(false)}
								type={'text'}
							>
								Return to gift details
							</Button>
						</div>
						<Box height={16} />
						<Typography variant="h3">Order Summary</Typography>
						<Box height={48} />
						<Box width={'100%'} height={1} style={{ background: '#D0D4DB' }} />
						<div className={styles.summaryEntry}>
							<Typography variant="body1" style={{ fontWeight: 'bold' }}>
								Single Card
							</Typography>
							<Typography variant="body1">
								${(cardPack ? cardPack.pricePerCard : settingsState.creationFee).toFixed(2)}
							</Typography>
						</div>
						<Typography variant="body1">Need to grab more cards?</Typography>
						<div className={styles.cardPacksContainer}>
							{cardPackState.list.map((element) => (
								<div key={element.id} className={styles.cardPack}>
									<Checkbox
										style={{ marginTop: 4 }}
										value={cardPackId === element.id}
										onChange={() => toggleCardPack(element.id)}
									/>
									<div onClick={() => toggleCardPack(element.id)} className={styles.cardPackDetails}>
										<Typography variant="body1" style={{ fontWeight: 'bold' }}>
											{element.cardsAmount} Pack
										</Typography>
										<Typography variant="caption" style={{ textTransform: 'none' }}>
											${element.pricePerCard.toFixed(2)} per card
										</Typography>
										<Typography variant="caption" style={{ textTransform: 'none' }}>
											{element.savingPercentageDescription}
										</Typography>
									</div>
								</div>
							))}
						</div>
						{/* <Box width={'100%'} height={1} style={{ background: '#D0D4DB' }} />
						<div className={styles.summaryEntry}>
							<Typography variant="body1" style={{ fontWeight: 'bold' }}>
								Subtotal
							</Typography>
							<Typography variant="body1">
								$
								{(cardPack
									? cardPack.pricePerCard + cardPack.cardsAmount * cardPack.pricePerCard
									: settingsState.creationFee
								).toFixed(2)}
							</Typography>
						</div> */}
						<Box width={'100%'} height={1} style={{ background: '#D0D4DB' }} />
						<div className={styles.summaryEntry}>
							<Typography variant="body1" style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
								Total
							</Typography>
							<Typography variant="body1">
								$
								{(cardPack
									? cardPack.pricePerCard + cardPack.cardsAmount * cardPack.pricePerCard
									: settingsState.creationFee
								).toFixed(2)}
							</Typography>
						</div>
						<Box height={32} />
						<Typography variant="h4">Select your payment method</Typography>
						<Box height={32} />
						<PayPalButton
							createOrder={createOrder}
							onApprove={onApprove}
							options={{
								clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID_CREATION,
								currency: 'AUD',
							}}
						/>
					</div>
				</div>
			)}
			{!isPaymentMode && (
				<div className={`${styles.rightContainer} ${showRightPanel ? styles.rightContainerActive : ''}`}>
					<Tabbar
						onClick={(newIndex) => {
							if (newIndex < index) {
								setIndex(newIndex);
							}
						}}
						tabs={['1. Recipient', '2. Gift Details', '3. Invite', '4. Confirm']}
						index={index}
					/>
					{index === 0 && (
						<RecipientTab
							timezones={timezones}
							recipientFirstName={recipientFirstName}
							recipientEmail={recipientEmail}
							date={date}
							time={time}
							deliveryTimezone={deliveryTimezone}
							deliveredAt={null}
							setRecipientFirstName={setRecipientFirstName}
							setRecipientEmail={setRecipientEmail}
							setDate={setDate}
							setTime={setTime}
							setDeliveryTimezone={setDeliveryTimezone}
						/>
					)}
					{index === 1 && (
						<GiftDetailsTab
							recipientFirstName={recipientFirstName.trim().split(' ')[0]}
							recipientLastName={recipientLastName}
							isContributionEnabled={isContributionEnabled}
							contribution={contribution}
							deliveredAt={null}
							setIsContributionEnabled={setIsContributionEnabled}
							setContribution={setContribution}
						/>
					)}
					{index === 2 && (
						<InviteTab
							uuid={createCardState.uuid}
							recipientFirstName={recipientFirstName}
							contributors={contributors}
							isPaid={isPaid}
							setContributors={setContributors}
						/>
					)}
					{index === 3 && (
						<ConfirmTab
							recipientFirstName={recipientFirstName}
							recipientEmail={recipientEmail}
							date={date}
							time={time}
							deliveryTimezone={deliveryTimezone}
							contribution={contribution}
							reminderMessage={reminderMessage}
							isReminderEnabled={isReminderEnabled}
							setReminderMessage={setReminderMessage}
							setIsReminderEnabled={setIsReminderEnabled}
						/>
					)}
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							flexShrink: 0,
						}}
					>
						{index === 1 && (
							<>
								<Typography
									style={{ textAlign: 'center', paddingLeft: 16, paddingRight: 16 }}
									variant="body2"
								>
									Please note a STC withdrawal fee will apply at the end of the total contribution
									collection. Visit the{' '}
									<div
										style={{
											textDecoration: 'underline',
											cursor: 'pointer',
											display: 'inline-block',
											color: '#ee584b',
										}}
										onClick={handleFeesClick}
									>
										Fees
									</div>{' '}
									section for further information.
								</Typography>
								<Box height={16} />
							</>
						)}
						{(index !== 1 || userState.availableCards > 0) && (
							<Button
								loading={isLoading}
								style={{ padding: '0 12px', width: width < 992 ? '70%' : '50%' }}
								disabled={!canProceed || isLoading}
								onClick={handleNext}
							>
								{index !== 3 ? 'Next Step' : 'Confirm & Send Invites'}
							</Button>
						)}
						{index === 1 && userState.availableCards === 0 && (
							<Button
								style={{
									width: width < 992 ? '80%' : '60%',
								}}
								disabled={!canProceed}
								onClick={handleNext}
							>
								<Typography style={{ color: '#FFFFFF' }} variant="button">
									Proceed to pay
								</Typography>
							</Button>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default CreateCardScreen;
