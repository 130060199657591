import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import SocketContext from '../../context/SocketContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import ComputeSignature from '../../lib/components/ComputeSignature';
import Controls from '../../lib/components/Controls';
import CustomCircularProgress from '../../lib/components/CustomCircularProgress';
import EditableSignature from '../../lib/components/EditableSignature';
import GuestProposeSign from '../../lib/components/GuestProposeSign';
import Header from '../../lib/components/Header';
import Modal from '../../lib/components/Modal';
import StaticSignature from '../../lib/components/StaticSignature';
import Tabbar from '../../lib/components/Tabbar';
import TopTimer from '../../lib/components/TopTimer';
import Typography from '../../lib/components/Typography';
import Signature from '../../models/Signature';
import SignatureMeta from '../../models/SignatureMeta';
import SignaturePosition from '../../models/SignaturePosition';
import CreateSignatureRequest from '../../network/requests/CreateSignatureRequest';
import signACardAsyncActions from '../../store/actions/signACard.action';
import transactionsAsyncActions from '../../store/actions/transactions.action';
import RequestManager from '../../store/request-manager';
import {
	useAuthenticationState,
	useRequestState,
	useSessionState,
	useSignACardState,
	useUserState,
} from '../../store/selectors';
import { manageCardActions } from '../../store/slices/manageCard.slice';
import { signACardActions } from '../../store/slices/signACard.slice';
import { uiActions } from '../../store/slices/ui.slice';
import checkIntersection from '../../utils/checkIntersection';
import checkRectIntersection from '../../utils/checkRectIntersection';
import SignatureType from '../../utils/SignatureType';
import ArrowLeftIcon from './../../img/ic-arrow-left.svg';
import ArrowRightIcon from './../../img/ic-arrow-right.svg';
import EnvelopeIcon from './../../img/ic-envelope.svg';
import PencilIcon from './../../img/ic-pencil-primary.svg';
import CardBackPlaceholder from './../../img/il-card-back-placeholder.png';
import Logo from './../../logo.svg';
import ContributeTab from './ContributeTab';
import GreetingTab from './GreetingTab';
import styles from './index.module.scss';
import WriteMessageTab from './WriteMessageTab';

export const CARD_WIDTH = 650;
const CARD_HEIGHT = 650 * 0.75;

const SIGNATURE_WIDTH = 150;
const SIGNATURE_HEIGHT = 100;

export interface TimeoutHandle {
	id: number;
	timeoutId: NodeJS.Timeout;
}

export interface OverlappingTableEntry {
	page: number;
	signatureOneId: number;
	signatureTwoId: number;
}

const timeoutHandles: TimeoutHandle[] = [];

const SignACardScreen: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { socket, socketConnected } = React.useContext(SocketContext);
	const { id } = useParams<{ id: string }>();

	const authenticationState = useAuthenticationState();
	const signACardState = useSignACardState();
	const userState = useUserState();
	const sessionState = useSessionState();
	const requestState = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(requestState.updatedAt);

	const cardContentRef = React.useRef<HTMLDivElement>(null);
	const { width } = useWindowDimensions();
	const ref = React.useRef<HTMLDivElement>(null);

	const [showGuestProposeSignModal, setShowGuestProposeSignModal] = React.useState<boolean>(false);

	// const [signatures, setSignatures] = React.useState<Signature[]>([]);

	const [opacity, setOpacity] = React.useState<number>(1);
	const [overlappingTable, setOverlappingTable] = React.useState<OverlappingTableEntry[]>([]);
	// const [lastUpdatedAt] = React.useState<number>(cardState.updatedAt);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isUploading, setIsUploading] = React.useState<boolean>(false);
	const [zoom, setZoom] = React.useState<number>(1);
	const [index, setIndex] = React.useState<number>(0);
	const [isContributionMode, setIsContributionMode] = React.useState<boolean>(false);

	const [initialLoad, setInitialLoad] = React.useState<boolean>(true);
	const [page, setPage] = React.useState<number>(0);

	const [contribution, setContribution] = React.useState<number>(0);

	const [name, setName] = React.useState<string>(`${userState.firstName} ${userState.lastName}`.trim());
	const [email, setEmail] = React.useState<string>(userState.email);

	const [atEnd, setAtEnd] = React.useState<boolean>(false);

	const alreadySigned = React.useMemo<boolean>(() => {
		return (
			signACardState.signatures.find(
				(element) =>
					((element.user && element.user.id === userState.id) ||
						element.sessionId === sessionState.sessionId) &&
					element.meta?.message !== 'Type your message here...'
			) !== undefined
		);
	}, [signACardState.updatedAt, userState.id]);

	const alreadyContributed = React.useMemo<boolean>(() => {
		return signACardState.yourContribution > 0;
	}, [signACardState.updatedAt]);

	const textSignature = React.useMemo<Signature | undefined>(() => {
		const list = signACardState.signatures.filter((element) => element.typeId === SignatureType.TEXT);

		return list.find(
			(element) =>
				(element.user && element.user.id === userState.id) || element.sessionId === sessionState.sessionId
		);
	}, [authenticationState.isAuthenticated, signACardState.signatures, userState.id]);

	const [onEditingMode, setOnEditingMode] = React.useState<boolean>(true);
	const [previewMessage, setPreviewMessage] = React.useState<boolean>(false);
	const [showRightPanel, setShowRightPanel] = React.useState<boolean>(alreadySigned ? true : false);

	const tabs = React.useMemo<Array<string>>(() => {
		if (signACardState.isContributionEnabled && signACardState.contribution !== 0) {
			return ['1. Edit your message', '2. Contribute to this card'];
		}

		return ['1. Edit your message'];
	}, [signACardState.updatedAt]);

	const canProceed = React.useMemo<boolean>(() => {
		if (index === 0) {
			return (
				textSignature !== undefined &&
				textSignature.meta!.message.trim() !== '' &&
				textSignature.meta!.message !== 'Type your message here...' &&
				name.trim().length >= 3
			);
		} else if (index === 1) {
			return contribution !== 0 && contribution + signACardState.yourContribution <= 250;
		}

		return false;
	}, [index, name, textSignature, contribution, signACardState.updatedAt]);

	const editableSignatures = React.useMemo<Signature[]>(() => {
		if (!authenticationState.isAuthenticated) {
			return signACardState.signatures.filter(
				(element) => element.sessionId === sessionState.sessionId && element.page === page
			);
		}

		return signACardState.signatures.filter(
			(element) =>
				(element.sessionId === sessionState.sessionId || (element.user && element.user.id === userState.id)) &&
				element.page === page
		);
	}, [authenticationState.isAuthenticated, userState.id, sessionState.sessionId, signACardState.signatures, page]);

	const staticSignatures = React.useMemo<Signature[]>(() => {
		if (!authenticationState.isAuthenticated) {
			return signACardState.signatures.filter(
				(element) => element.sessionId !== sessionState.sessionId && element.page === page
			);
		}

		return signACardState.signatures.filter(
			(element) => (!element.user || element.user.id !== userState.id) && element.page === page
		);
	}, [authenticationState.isAuthenticated, userState.id, sessionState.sessionId, signACardState.signatures, page]);

	const lastPage = React.useMemo<number>(() => {
		const newPageIndex = Math.max(...signACardState.signatures.map((element) => element.page));
		if (newPageIndex < 1) {
			return 1 + (onEditingMode ? 1 : 0); // Add new empty page as well as ending page.
		}

		return newPageIndex + 1 + (onEditingMode ? 1 : 0); // Add new empty page as well as ending page.
	}, [signACardState.signatures, onEditingMode]);

	const handleChangePage = React.useCallback(
		(newPage) => {
			let lastPage = 0;
			const sortedList = [...signACardState.signatures]
				// Exclude current user signatures
				.filter((element) => element.sessionId !== sessionState.sessionId)
				.sort((a, b) => b.page - a.page)
				.map((element) => element.page);
			if (sortedList.length > 0) {
				lastPage = sortedList[0] + 1; // Add new empty page as well as ending page.
			}

			// Allow to flip to one more page
			if (newPage > page && newPage >= lastPage + 1) {
				return;
			}

			if (cardContentRef.current && newPage > page && !atEnd && width < 992) {
				const scrollWidth = cardContentRef.current.scrollWidth;
				const width = cardContentRef.current.clientWidth;
				const maxScrollLeft = scrollWidth - width;
				cardContentRef.current.scrollLeft = maxScrollLeft > 0 ? maxScrollLeft : 0;
				return;
			} else if (
				cardContentRef.current &&
				newPage < page &&
				cardContentRef.current.scrollLeft !== 0 &&
				width < 992
			) {
				cardContentRef.current.scrollLeft = 0;
				return;
			}

			if (cardContentRef.current && newPage > page) {
				cardContentRef.current.scrollLeft = 0;
			}

			if (newPage === -1) {
				return;
			}

			// const newSignatures = [...signatures];
			if (onEditingMode && editableSignatures.length > 0) {
				// for (const signature of editableSignatures) {
				// 	const findIndex = newSignatures.findIndex((element) => element.id === signature.id);
				// 	if (findIndex !== -1) {
				// 		newSignatures.splice(findIndex, 1, {
				// 			...signature,
				// 			page: newPage,
				// 		});
				// 	}
				// }

				// const findTextSignatureIndex = newSignatures.findIndex(
				// 	(element) => parseInt(element.id, 10) === parseInt(textSignature!.id, 10)
				// );
				// if (findTextSignatureIndex !== -1) {
				// 	newSignatures.splice(findTextSignatureIndex, 1, {
				// 		...textSignature!,
				// 		page: newPage,
				// 	});
				// }

				dispatch(
					signACardActions.moveSignaturesToNewPage({
						ids: [...editableSignatures.map((element) => element.id), textSignature!.id],
						page: newPage,
					})
				);
				dispatch(
					signACardAsyncActions.moveSignaturesToNewPage({
						ids: editableSignatures.map((element) => element.id),
						page: newPage,
					})
				);

				const newOverlappingTable = [...overlappingTable];
				for (const signature of editableSignatures) {
					for (const overlappingEntry of newOverlappingTable) {
						const overlappingIndex = newOverlappingTable.indexOf(overlappingEntry);

						if (
							newOverlappingTable[overlappingIndex].signatureOneId === parseInt(signature.id, 10) ||
							newOverlappingTable[overlappingIndex].signatureTwoId === parseInt(signature.id, 10)
						) {
							newOverlappingTable.splice(overlappingIndex, 1, {
								...overlappingTable[overlappingIndex],
								page: newPage,
							});
						}
					}
				}

				setOverlappingTable(newOverlappingTable);
			}

			setOpacity(0);
			setTimeout(() => {
				// if (onEditingMode) {
				// 	setSignatures(newSignatures);
				// }

				setPage(newPage);
				setTimeout(() => {
					setOpacity(1);
				}, 0);
			}, 250);
		},
		[
			sessionState.sessionId,
			page,
			editableSignatures,
			signACardState.signatures,
			overlappingTable,
			onEditingMode,
			width,
			cardContentRef.current,
			atEnd,
		]
	);

	const checkIsOverlapping = React.useCallback(
		(id: string, newOverlappingTable: OverlappingTableEntry[] = []) =>
			(newOverlappingTable.length > 0 ? newOverlappingTable : overlappingTable).findIndex(
				(element) =>
					element.page === page &&
					(element.signatureOneId === parseInt(id, 10) || element.signatureTwoId === parseInt(id, 10))
			) !== -1,
		[overlappingTable, page]
	);

	const findEmptySpace = React.useCallback(
		(signatures: Signature[], forceSamePage = false, width = SIGNATURE_WIDTH, height = SIGNATURE_HEIGHT) => {
			let maxPages = 0;

			let newPageIndex = Math.max(...signatures.map((element) => element.page));
			if (newPageIndex < 1) {
				maxPages = 1 + (onEditingMode ? 1 : 0); // Add new empty page as well as ending page.
			} else {
				maxPages = newPageIndex + 1 + (onEditingMode ? 1 : 0); // Add new empty page as well as ending page.
			}

			let shouldForceSamePage = forceSamePage;
			let forcedPage = 0;
			if (!textSignature) {
				shouldForceSamePage = false;
			} else {
				forcedPage = textSignature!.page;
			}

			for (
				let i = shouldForceSamePage ? forcedPage : 0;
				i <= (shouldForceSamePage ? forcedPage : maxPages);
				i++
			) {
				// Top Left quadrant
				const firstQuadrant = {
					centerX: CARD_WIDTH / 4 - width / 2,
					centerY: CARD_HEIGHT / 4 - height / 2,
				};
				// Top Right quadrant
				const secondQuadrant = {
					centerX: CARD_WIDTH / 4 - width / 2 + CARD_WIDTH / 2,
					centerY: CARD_HEIGHT / 4 - height / 2,
				};
				// Bottom Left quadrant
				const thirdQuadrant = {
					centerX: CARD_WIDTH / 4 - width / 2,
					centerY: CARD_HEIGHT / 4 - height / 2 + CARD_HEIGHT / 2,
				};
				// Bottom Right quadrant
				const fourthQuadrant = {
					centerX: CARD_WIDTH / 4 - width / 2 + CARD_WIDTH / 2,
					centerY: CARD_HEIGHT / 4 - height / 2 + CARD_HEIGHT / 2,
				};

				let firstQuadrantIntersection = false;
				let secondQuadrantIntersection = false;
				let thirdQuadrantIntersection = false;
				let fourthQuadrantIntersection = false;

				const allSignatures = signatures.filter((element) => element.page === i);
				for (const signature of allSignatures) {
					if (
						checkIntersection(
							[
								{ x: firstQuadrant.centerX, y: firstQuadrant.centerY }, // Top Left
								{ x: firstQuadrant.centerX + width, y: firstQuadrant.centerY }, // Top Right
								{ x: firstQuadrant.centerX, y: firstQuadrant.centerY + height }, // Bottom Left
								{
									x: firstQuadrant.centerX + width,
									y: firstQuadrant.centerY + height,
								}, // Bottom Right
							],
							[
								{ x: signature.position.x, y: signature.position.y }, // Top Left
								{ x: signature.position.x + signature.position.width, y: signature.position.y }, // Top Right
								{ x: signature.position.x, y: signature.position.y + signature.position.height }, // Bottom Left
								{
									x: signature.position.x + signature.position.width,
									y: signature.position.y + signature.position.height,
								}, // Bottom Right
							]
						)
					) {
						firstQuadrantIntersection = true;
					}

					if (
						checkIntersection(
							[
								{ x: secondQuadrant.centerX, y: secondQuadrant.centerY }, // Top Left
								{ x: secondQuadrant.centerX + width, y: secondQuadrant.centerY }, // Top Right
								{ x: secondQuadrant.centerX, y: secondQuadrant.centerY + height }, // Bottom Left
								{
									x: secondQuadrant.centerX + width,
									y: secondQuadrant.centerY + height,
								}, // Bottom Right
							],
							[
								{ x: signature.position.x, y: signature.position.y }, // Top Left
								{ x: signature.position.x + signature.position.width, y: signature.position.y }, // Top Right
								{ x: signature.position.x, y: signature.position.y + signature.position.height }, // Bottom Left
								{
									x: signature.position.x + signature.position.width,
									y: signature.position.y + signature.position.height,
								}, // Bottom Right
							]
						)
					) {
						secondQuadrantIntersection = true;
					}

					if (
						checkIntersection(
							[
								{ x: thirdQuadrant.centerX, y: thirdQuadrant.centerY }, // Top Left
								{ x: thirdQuadrant.centerX + width, y: thirdQuadrant.centerY }, // Top Right
								{ x: thirdQuadrant.centerX, y: thirdQuadrant.centerY + height }, // Bottom Left
								{
									x: thirdQuadrant.centerX + width,
									y: thirdQuadrant.centerY + height,
								}, // Bottom Right
							],
							[
								{ x: signature.position.x, y: signature.position.y }, // Top Left
								{ x: signature.position.x + signature.position.width, y: signature.position.y }, // Top Right
								{ x: signature.position.x, y: signature.position.y + signature.position.height }, // Bottom Left
								{
									x: signature.position.x + signature.position.width,
									y: signature.position.y + signature.position.height,
								}, // Bottom Right
							]
						)
					) {
						thirdQuadrantIntersection = true;
					}

					if (
						checkIntersection(
							[
								{ x: fourthQuadrant.centerX, y: fourthQuadrant.centerY }, // Top Left
								{ x: fourthQuadrant.centerX + width, y: fourthQuadrant.centerY }, // Top Right
								{ x: fourthQuadrant.centerX, y: fourthQuadrant.centerY + height }, // Bottom Left
								{
									x: fourthQuadrant.centerX + width,
									y: fourthQuadrant.centerY + height,
								}, // Bottom Right
							],
							[
								{ x: signature.position.x, y: signature.position.y }, // Top Left
								{ x: signature.position.x + signature.position.width, y: signature.position.y }, // Top Right
								{ x: signature.position.x, y: signature.position.y + signature.position.height }, // Bottom Left
								{
									x: signature.position.x + signature.position.width,
									y: signature.position.y + signature.position.height,
								}, // Bottom Right
							]
						)
					) {
						fourthQuadrantIntersection = true;
					}
				}

				if (!firstQuadrantIntersection) {
					setPage(i);
					return {
						page: i,
						position: {
							width,
							height,
							x: firstQuadrant.centerX,
							y: firstQuadrant.centerY,
							rotation: 0,
						},
					};
				}

				if (!secondQuadrantIntersection) {
					setPage(i);
					return {
						page: i,
						position: {
							width,
							height,
							x: secondQuadrant.centerX,
							y: secondQuadrant.centerY,
							rotation: 0,
						},
					};
				}

				if (!thirdQuadrantIntersection) {
					setPage(i);
					return {
						page: i,
						position: {
							width,
							height,
							x: thirdQuadrant.centerX,
							y: thirdQuadrant.centerY,
							rotation: 0,
						},
					};
				}

				if (!fourthQuadrantIntersection) {
					setPage(i);
					return {
						page: i,
						position: {
							width,
							height,
							x: fourthQuadrant.centerX,
							y: fourthQuadrant.centerY,
							rotation: 0,
						},
					};
				}
			}

			return {
				page,
				position: {
					width,
					height,
					x: 10,
					y: 10,
					rotation: 0,
				},
			};
		},
		[page, textSignature, onEditingMode]
	);

	const addSignature = React.useCallback(
		(typeId: SignatureType, message: string, width: number, height: number) => {
			let additionalProps: Pick<CreateSignatureRequest, 'name' | 'email'> = {
				name: null,
				email: null,
			};
			if (!authenticationState.isAuthenticated) {
				additionalProps = {
					email,
					name,
				};
			}

			let signatureWidth = 150;
			let signatureHeight = 100;

			if (typeId === SignatureType.EMOJI) {
				signatureWidth = 150;
				signatureHeight = 150;
			}

			if (typeId === SignatureType.GIF || typeId === SignatureType.MEDIA) {
				const isPortrait = width < height;
				if (isPortrait) {
					signatureHeight = Math.round(150 * (4 / 3));
				} else {
					signatureHeight = 150 * 0.75;
				}
			}

			const { page, position } = findEmptySpace(signACardState.signatures, true, signatureWidth, signatureHeight);

			dispatch(
				signACardAsyncActions.createSignature({
					cardId: signACardState.id,
					...additionalProps,
					page,
					position,
					typeId,
					message,
					width,
					height,
				})
			);
		},
		[
			authenticationState.isAuthenticated,
			textSignature,
			signACardState.signatures,
			email,
			name,
			signACardState.id,
			page,
			findEmptySpace,
		]
	);

	const removeSignature = React.useCallback(
		(id: string) => {
			dispatch(signACardActions.removeSignature(id));
			dispatch(signACardAsyncActions.removeSignature({ id }));

			const newTable = [...overlappingTable].filter(
				(element) => element.signatureOneId !== parseInt(id, 10) && element.signatureTwoId !== parseInt(id, 10)
			);
			setOverlappingTable(newTable);
		},
		[overlappingTable]
	);

	const setTextSignature = React.useCallback(
		(signature: Signature) => {
			// const newSignatures = [...signatures];

			const findIndex = signACardState.signatures.findIndex(
				(element) =>
					(element.sessionId === sessionState.sessionId ||
						(element.user && element.user.id === userState.id)) &&
					element.typeId === SignatureType.TEXT
			);
			if (findIndex === -1) {
				return;
			}

			// newSignatures.splice(findIndex, 1, signature);

			// setSignatures(newSignatures);

			dispatch(signACardActions.updateSignature(signature));
			if (socket && socketConnected) {
				socket.emit('signature:meta-update', id, sessionState.sessionId, signature.id, signature.meta);
			}

			if (signature.isPublished) {
				return;
			}

			// const findTimeoutHandle = timeoutHandles.findIndex((element) => element.id === parseInt(signature.id, 10));
			// if (findTimeoutHandle !== -1) {
			// 	clearTimeout(timeoutHandles[findTimeoutHandle].timeoutId);
			// 	timeoutHandles.splice(findTimeoutHandle, 1);
			// }

			// TODO: This.
			// timeoutHandles.push({
			// 	id: parseInt(signature.id, 10),
			// 	timeoutId: setTimeout(() => {
			// 		let additionalProps: Pick<UpdateSignatureRequest, 'name' | 'email'> = {
			// 			name: null,
			// 			email: null,
			// 		};
			// 		if (!authenticationState.isAuthenticated) {
			// 			additionalProps = {
			// 				email,
			// 				name,
			// 			};
			// 		}

			// 		dispatch(
			// 			signACardAsyncActions.updateSignatures({
			// 				id: signature.id,
			// 				...additionalProps,
			// 				page,
			// 				position: signature.position,
			// 				typeId: signature.typeId,
			// 				meta: signature.meta,
			// 			})
			// 		);
			// 	}, 1000),
			// });
		},
		[userState.id, authenticationState.isAuthenticated, name, email, page, signACardState.signatures]
	);

	const revalidateSignatures = React.useCallback(
		(
			id?: string,
			page?: number,
			x?: number,
			y?: number,
			width?: number,
			height?: number,
			recentSignatures: Signature[] = []
		) => {
			const signaturesToCheck = recentSignatures.length > 0 ? recentSignatures : signACardState.signatures;

			const parsedOverlappingTable = JSON.stringify(overlappingTable);

			const newOverlappingTable: OverlappingTableEntry[] = [];

			if (
				id !== undefined &&
				page !== undefined &&
				x !== undefined &&
				y !== undefined &&
				width !== undefined &&
				height !== undefined
			) {
				for (const signature1 of signaturesToCheck) {
					const parsedId = parseInt(signature1.id, 10);
					const parsedId2 = parseInt(id, 10);

					if (parsedId === parsedId2) {
						continue;
					}

					if (signature1.page !== page) {
						continue;
					}

					if (
						checkIntersection(
							[
								{ x: signature1.position.x, y: signature1.position.y },
								{ x: signature1.position.x + signature1.position.width, y: signature1.position.y },
								{ x: signature1.position.x, y: signature1.position.y + signature1.position.height },
								{
									x: signature1.position.x + signature1.position.width,
									y: signature1.position.y + signature1.position.height,
								},
							],
							[
								{ x, y },
								{ x: x + width, y },
								{ x, y: y + height },
								{
									x: x + width,
									y: y + height,
								},
							]
						) ||
						checkRectIntersection(signature1.position, { x, y, width, height, rotation: 0 })
					) {
						newOverlappingTable.push({
							page: signature1.page,
							signatureOneId: parsedId,
							signatureTwoId: parsedId2,
						});
					}
				}

				if (JSON.stringify(newOverlappingTable) === parsedOverlappingTable) {
					return newOverlappingTable;
				}

				setOverlappingTable(newOverlappingTable);
				return newOverlappingTable;
			}

			for (const signature1 of signaturesToCheck) {
				for (const signature2 of signaturesToCheck) {
					const parsedId = parseInt(signature1.id, 10);
					const parsedId2 = parseInt(signature2.id, 10);

					if (parsedId === parsedId2) {
						continue;
					}

					if (signature1.page !== signature2.page) {
						continue;
					}

					if (
						checkIntersection(
							[
								{ x: signature1.position.x, y: signature1.position.y },
								{ x: signature1.position.x + signature1.position.width, y: signature1.position.y },
								{ x: signature1.position.x, y: signature1.position.y + signature1.position.height },
								{
									x: signature1.position.x + signature1.position.width,
									y: signature1.position.y + signature1.position.height,
								},
							],
							[
								{ x: signature2.position.x, y: signature2.position.y },
								{ x: signature2.position.x + signature2.position.width, y: signature2.position.y },
								{ x: signature2.position.x, y: signature2.position.y + signature2.position.height },
								{
									x: signature2.position.x + signature2.position.width,
									y: signature2.position.y + signature2.position.height,
								},
							]
						) ||
						checkRectIntersection(signature1.position, signature2.position)
					) {
						newOverlappingTable.push({
							page: signature1.page,
							signatureOneId: parsedId,
							signatureTwoId: parsedId2,
						});
					}
				}
			}

			if (JSON.stringify(newOverlappingTable) === parsedOverlappingTable) {
				return newOverlappingTable;
			}

			setOverlappingTable(newOverlappingTable);

			return newOverlappingTable;
		},
		[signACardState.signatures, overlappingTable]
	);

	const handleSetPosition = React.useCallback(
		(signatureId: string, position: SignaturePosition) => {
			// const newSignatures = [...signatures];

			const findIndex = signACardState.signatures.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(signatureId, 10)
			);
			if (findIndex === -1) {
				return;
			}

			// Prevent from infinite loop.
			if (JSON.stringify(position) === JSON.stringify(signACardState.signatures[findIndex].position)) {
				return;
			}

			// newSignatures.splice(findIndex, 1, {
			// 	...newSignatures[findIndex],
			// 	position,
			// });

			// setSignatures(newSignatures);
			dispatch(signACardActions.moveSignature({ id: signatureId, position }));

			if (socket && socketConnected) {
				socket.emit('signature:move', id, sessionState.sessionId, signatureId, position);
			}

			// const newOverlappingTable = revalidateSignatures(
			// 	id,
			// 	page,
			// 	position.x,
			// 	position.y,
			// 	position.width,
			// 	position.height,
			// 	newSignatures
			// );

			if (signACardState.signatures[findIndex].isPublished && checkIsOverlapping(signatureId)) {
				return;
			}

			const findTimeoutHandle = timeoutHandles.findIndex((element) => element.id === parseInt(signatureId, 10));
			if (findTimeoutHandle !== -1) {
				clearTimeout(timeoutHandles[findTimeoutHandle].timeoutId);
				timeoutHandles.splice(findTimeoutHandle, 1);
			}

			// TODO: This.
			// timeoutHandles.push({
			// 	id: parseInt(id, 10),
			// 	timeoutId: setTimeout(() => {
			// 		let additionalProps: Pick<UpdateSignatureRequest['signatures'][0], 'name' | 'email'> = {
			// 			name: null,
			// 			email: null,
			// 		};
			// 		if (!authenticationState.isAuthenticated) {
			// 			additionalProps = {
			// 				email,
			// 				name,
			// 			};
			// 		}

			// 		dispatch(
			// 			signACardAsyncActions.updateSignatures({
			// 				...additionalProps,
			// 				page,
			// 				position,
			// 				typeId: signACardState.signatures[findIndex].typeId,
			// 				meta: signACardState.signatures[findIndex].meta,
			// 			})
			// 		);
			// 	}, 1000),
			// });
		},
		[
			authenticationState.isAuthenticated,
			email,
			name,
			signACardState.id,
			signACardState.signatures,
			page,
			overlappingTable,
			revalidateSignatures,
			checkIsOverlapping,
		]
	);

	const hasAnyEditableOverlapping = React.useMemo<boolean>(() => {
		for (const signature of editableSignatures) {
			const findIndex = overlappingTable.findIndex(
				(element) =>
					element.signatureOneId === parseInt(signature.id, 10) ||
					element.signatureTwoId === parseInt(signature.id, 10)
			);
			if (findIndex !== -1) {
				return true;
			}
		}

		return false;
	}, [overlappingTable, editableSignatures]);

	const handleNext = React.useCallback(async () => {
		if (index >= 2 || !canProceed || !textSignature) {
			return;
		}

		if (index === 0) {
			if (hasAnyEditableOverlapping && width >= 992) {
				return;
			}

			if (width < 992 && alreadySigned && !onEditingMode) {
				// setShowRightPanel(false);
				return;
			}

			if (width < 992 && !previewMessage) {
				setPreviewMessage(true);
				setShowRightPanel(false);
				return;
			}

			if (!authenticationState.isAuthenticated && !showGuestProposeSignModal) {
				setShowGuestProposeSignModal(true);
				return;
			}

			setShowGuestProposeSignModal(false);

			// setIsLoading(true);
			dispatch(signACardAsyncActions.updateSignatures({ signatures: [...editableSignatures, textSignature!] }));
			// TODO: This.
			// dispatch(
			// 	signACardAsyncActions.publishSignatures({
			// 		id: textSignature.id,
			// 		name,
			// 		email,
			// 	})
			// );

			setOnEditingMode(false);
			// if (socket) {
			// 	socket.emit('signature:force-refresh');
			// }
		} else if (index === 1) {
			setIsContributionMode(true);
			// setShowPayPalModal(true);
			return;
		}

		if (tabs.length === 1) {
			setIndex(2);
			return;
		}

		if (signACardState.yourContribution > 0) {
			setIndex(2);
			return;
		}

		setIndex(index + 1);
	}, [
		dispatch,
		hasAnyEditableOverlapping,
		// socket,
		signACardState.yourContribution,
		authenticationState.isAuthenticated,
		textSignature,
		previewMessage,
		overlappingTable,
		alreadySigned,
		width,
		tabs,
		id,
		index,
		canProceed,
		name,
		email,
		page,
		showGuestProposeSignModal,
	]);

	const handleSubmitMessage = React.useCallback(async () => {
		if (!canProceed || hasAnyEditableOverlapping || !textSignature) {
			return;
		}

		setShowRightPanel(true);
		if (!authenticationState.isAuthenticated && !showGuestProposeSignModal) {
			setShowGuestProposeSignModal(true);
			return;
		}

		// setIsLoading(true);
		dispatch(signACardAsyncActions.updateSignatures({ signatures: [...editableSignatures, textSignature!] }));
		// dispatch(
		// 	signACardAsyncActions.publishSignatures({
		// 		id: textSignature.id,
		// 		name,
		// 		email,
		// 	})
		// );
		setIndex(signACardState.isContributionEnabled ? (alreadyContributed ? 2 : 1) : 2);
	}, [
		dispatch,
		authenticationState.isAuthenticated,
		showGuestProposeSignModal,
		hasAnyEditableOverlapping,
		// socket,
		textSignature,
		signACardState.isContributionEnabled,
		id,
		canProceed,
		overlappingTable,
		name,
		email,
		page,
		alreadyContributed,
	]);

	React.useEffect(() => {
		if (signACardState.uuid === id && signACardState.deliveredAt) {
			setIndex(2);
		}
	}, [signACardState.uuid, signACardState.deliveredAt]);

	React.useEffect(() => {
		if (signACardState.uuid !== id) {
			return;
		}

		if (socket === null || socket === undefined || !socketConnected) {
			return;
		}

		socket.emit('card:register', id);

		const onSignatureReceived = (signature: Signature) => {
			if (!editableSignatures.map((element) => parseInt(element.id, 10)).includes(parseInt(signature.id, 10))) {
				dispatch(signACardActions.socketUpdateSignature(signature));
			}
		};

		socket.on('signature:received', onSignatureReceived);

		const onSignatureMetaUpdate = (fromSessionId: string, signatureId: string, meta: SignatureMeta) => {
			if (
				!editableSignatures.map((element) => parseInt(element.id, 10)).includes(parseInt(signatureId, 10)) ||
				fromSessionId !== sessionState.sessionId
			) {
				// if (
				// 	!editableSignatures
				// 		.map((element) => parseInt(element.id, 10))
				// 		.includes(parseInt(signatureId, 10)) &&
				// 	textSignature &&
				// 	!textSignature.id
				// ) {
				// 	return;
				// }

				console.log('here');
				dispatch(
					signACardActions.socketMetaUpdateSignature({
						id: signatureId,
						meta,
					})
				);
			}
		};

		socket.on('signature:meta-update', onSignatureMetaUpdate);

		const onSignatureMove = (fromSessionId: string, signatureId: string, position: SignaturePosition) => {
			if (
				!editableSignatures.map((element) => parseInt(element.id, 10)).includes(parseInt(signatureId, 10)) ||
				fromSessionId !== sessionState.sessionId
			) {
				// if (
				// 	!editableSignatures
				// 		.map((element) => parseInt(element.id, 10))
				// 		.includes(parseInt(signatureId, 10)) &&
				// 	textSignature &&
				// 	!textSignature.id
				// ) {
				// 	return;
				// }

				dispatch(
					signACardActions.socketMoveSignature({
						id: signatureId,
						position,
					})
				);
			}
		};

		socket.on('signature:move', onSignatureMove);

		const onSignatureRemoved = (sessionId: string, signatureId: string) => {
			if (sessionId !== sessionState.sessionId) {
				dispatch(signACardActions.socketRemoveSignature(signatureId));
			}
		};

		socket.on('signature:removed', onSignatureRemoved);

		return () => {
			socket.off('signature:received', onSignatureReceived);
			socket.off('signature:meta-update', onSignatureMetaUpdate);
			socket.off('signature:move', onSignatureMove);
			socket.off('signature:removed', onSignatureRemoved);
			socket.emit('card:un-register', id);
		};
	}, [signACardState.uuid, socket, socketConnected, editableSignatures, textSignature?.id]);

	// React.useEffect(() => {
	// 	setSignatures([...signACardState.signatures]);
	// }, [signACardState.socketUpdatedAt]);

	React.useEffect(() => {
		if (width <= 320) {
			setZoom(0.6);
		} else if (width < 576) {
			setZoom(0.8);
		} else {
			setZoom(1);
		}
	}, [width]);

	React.useEffect(() => {
		if (name === '' && userState.firstName !== '') {
			setName(`${userState.firstName} ${userState.lastName}`);
		}
		if (email === '') {
			setEmail(userState.email);
		}
	}, [userState.updatedAt]);

	React.useEffect(() => {
		if (!initialLoad || !textSignature) {
			return;
		}

		if (cardContentRef.current) {
			const left = textSignature.position.x > 50 ? textSignature.position.x - 50 : textSignature.position.x;
			cardContentRef.current.scrollTo({
				behavior: 'auto',
				top: 0,
				left,
			});

			setInitialLoad(false);
		}
	}, [cardContentRef.current, textSignature]);

	React.useEffect(() => {
		if (requestUpdatedAt === requestState.updatedAt) return;
		const RM = new RequestManager(requestState);

		if (
			RM.isPending(signACardAsyncActions.updateSignatures.typePrefix) ||
			RM.isPending(signACardAsyncActions.publishSignatures.typePrefix)
		) {
			setIsLoading(true);
		}

		if (
			RM.isFinished(signACardAsyncActions.updateSignatures.typePrefix) ||
			RM.isFinished(signACardAsyncActions.publishSignatures.typePrefix)
		) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(signACardAsyncActions.show.typePrefix)) {
			RM.consume(signACardAsyncActions.show.typePrefix);

			// setSignatures([...signACardState.signatures]);
			if (signACardState.deliveredAt !== null) {
				setIndex(2);
			}

			const list = [...signACardState.signatures].filter((element) => element.typeId === SignatureType.TEXT);

			const signature = list.find(
				(element) =>
					(element.user && element.user.id === userState.id) || element.sessionId === sessionState.sessionId
			);

			if (!signature) {
				let additionalProps: Pick<CreateSignatureRequest, 'name' | 'email'> = {
					name: null,
					email: null,
				};
				if (!authenticationState.isAuthenticated) {
					additionalProps = {
						email,
						name,
					};
				}

				const { page, position } = findEmptySpace(signACardState.signatures);

				dispatch(
					signACardAsyncActions.createSignature({
						cardId: signACardState.id,
						...additionalProps,
						message: 'Type your message here...',
						width: null,
						height: null,
						page,
						position,
						typeId: SignatureType.TEXT,
					})
				);
			} else {
				setPage(signature.page);
			}
		}

		if (
			RM.isPending(signACardAsyncActions.removeSignature.typePrefix) ||
			RM.isFulfilled(signACardAsyncActions.createSignature.typePrefix)
		) {
			if (RM.isFulfilled(signACardAsyncActions.createSignature.typePrefix)) {
				const request = RM.getRequest(signACardAsyncActions.createSignature.typePrefix);

				if (socket && request && request.payload) {
					socket.emit('signature:created', request.payload.sessionId, request.payload.id);
				}

				RM.consume(signACardAsyncActions.createSignature.typePrefix);
			}

			// setSignatures([...signACardState.signatures]);
		}

		if (RM.isFulfilled(signACardAsyncActions.updateSignatures.typePrefix)) {
			RM.consume(signACardAsyncActions.updateSignatures.typePrefix);

			if (textSignature) {
				dispatch(
					signACardAsyncActions.publishSignatures({
						id: textSignature.id,
						name,
						email,
					})
				);
			}
			// setSignatures([...signACardState.signatures]);
		}

		if (RM.isFulfilled(signACardAsyncActions.moveSignaturesToNewPage.typePrefix)) {
			RM.consume(signACardAsyncActions.moveSignaturesToNewPage.typePrefix);

			// setSignatures([...signACardState.signatures]);
		}

		if (RM.isFinished(signACardAsyncActions.publishSignatures.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(signACardAsyncActions.publishSignatures.typePrefix)) {
			RM.consume(signACardAsyncActions.publishSignatures.typePrefix);
		}

		// Contribute logic.
		if (RM.isPending(transactionsAsyncActions.finalize.typePrefix)) {
			setIsLoading(true);
		}

		if (RM.isFinished(transactionsAsyncActions.finalize.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(transactionsAsyncActions.finalize.typePrefix)) {
			RM.consume(transactionsAsyncActions.finalize.typePrefix);

			setIsContributionMode(false);
			setIndex(2);
		}
	}, [requestUpdatedAt, requestState.updatedAt]);

	React.useEffect(() => {
		dispatch(signACardActions.reset());
		setTimeout(() => {
			dispatch(signACardAsyncActions.show({ id }));
		});
	}, [dispatch, id, authenticationState.isAuthenticated]);

	React.useEffect(() => {
		dispatch(manageCardActions.reset());

		return () => {
			if (socket && socketConnected) {
				socket.emit('signature:exit', id, sessionState.sessionId);
			}
			dispatch(signACardActions.reset());
		};
	}, []);

	React.useEffect(() => {
		if (!textSignature) {
			return;
		}

		if (textSignature.name && name === '') {
			setName(textSignature.name);
		}
		if (textSignature.email && email === '') {
			setEmail(textSignature.email);
		}
	}, [textSignature]);

	React.useEffect(() => {
		revalidateSignatures();
	}, [signACardState.signatures, page]);

	const handleScroll = React.useCallback(
		(e: any) => {
			const value = e.target.scrollWidth - e.target.scrollLeft === e.target.clientWidth;

			if (atEnd !== value) {
				setAtEnd(value);
			}
		},
		[atEnd]
	);

	return React.useMemo(() => {
		if (signACardState.uuid !== id || !textSignature) {
			return (
				<div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
					<CustomCircularProgress size={48} color={'#EE584B'} />
				</div>
			);
		}

		return (
			<>
				{textSignature !== undefined && (
					<ComputeSignature
						style={{ position: 'absolute', opacity: 0, pointerEvents: 'none' }}
						key={`${textSignature.id}-${zoom}`}
						cardId={id}
						mode="sign"
						signatureId={textSignature.id}
						name={name}
						inEditingMode={onEditingMode}
						zoom={zoom}
						setPosition={handleSetPosition}
					/>
				)}
				<Modal
					isVisible={showGuestProposeSignModal}
					setIsVisible={(value) => {
						if (!value) {
							handleNext();
						}
						setShowGuestProposeSignModal(value);
					}}
				>
					<GuestProposeSign handleNext={handleNext} />
				</Modal>
				<div className={styles.container}>
					<TopTimer
						style={{ zIndex: 5 }}
						deliveryAt={signACardState.deliveryAt}
						deliveryTimezone={signACardState.deliveryTimezone}
						deliveredAt={signACardState.deliveredAt}
					/>
					<div className={styles.leftContainer}>
						<Header
							style={{ zIndex: 5 }}
							title={<Typography variant="body2">Sign the Card for</Typography>}
							hideAuthentication={width < 992}
							subTitle={
								<Typography variant="h5">
									{signACardState.recipientFirstName} {signACardState.recipientLastName}&nbsp;
								</Typography>
							}
							relative
						/>
						<div
							ref={ref}
							style={{
								position: 'relative',
								flexShrink: 0,
								marginTop: 16,
								display: 'flex',
								alignItems: 'center',
								height: width < 576 ? 'calc(100% - 172px)' : 'calc(100% - 172px)',
							}}
						>
							<div
								ref={cardContentRef}
								className={page === lastPage ? styles.cardContainerCover : styles.cardContainer}
								style={{ transform: width >= 992 ? `scale(${zoom}) translateZ(0)` : undefined }}
								onScroll={handleScroll}
							>
								{page !== lastPage && (
									<div
										style={{
											width: width < 992 ? 650 : undefined,
										}}
									>
										<div
											className={styles.cardContent}
											style={{
												transform: width < 992 ? `scale(${zoom}) translateZ(0)` : undefined,
											}}
										>
											<div className={styles.cardVerticalLine} />
											<div className={`${styles.cardHorizontalLine} ${styles.lineHidden}`} />
											{editableSignatures.map((element) => (
												<EditableSignature
													key={`${element.id}-${zoom}`}
													cardId={id}
													disabled={index !== 0 || !onEditingMode}
													style={{ transition: 'opacity ease 250ms', opacity }}
													mode="sign"
													signatureId={element.id}
													name={name}
													inEditingMode={onEditingMode}
													removeSignature={removeSignature}
													setPosition={handleSetPosition}
													hideRemoveButton={element.typeId === SignatureType.TEXT}
													zoom={zoom}
													overlappingTable={overlappingTable}
													revalidateSignatures={revalidateSignatures}
												/>
											))}
											{staticSignatures.map((element) => (
												<StaticSignature
													key={element.id}
													style={{ transition: 'opacity ease 250ms', opacity }}
													signature={element}
													zoom={zoom}
													withControls={false}
												/>
											))}
										</div>
									</div>
								)}
								{page === lastPage && (
									<div
										style={{
											padding: width < 992 ? '0 16px' : '0 90px',
											boxSizing: 'border-box',
											display: 'flex',
											flexDirection: 'column',
											position: 'relative',
											width: '100%',
											height: '100%',
											alignItems: 'center',
											justifyContent: 'space-around',
										}}
									>
										<img
											src={CardBackPlaceholder}
											alt={'Card Preview'}
											style={{ position: 'absolute' }}
										/>
										<div
											style={{
												height: '100%',
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Typography
												variant={width < 992 ? 'h6' : 'h5'}
												style={{
													fontWeight: 'bold',
													textAlign: 'center',
													position: 'relative',
												}}
											>
												We hope you’ve enjoyed using
											</Typography>
											<Box height={width < 992 ? 8 : 16} />
											<img
												style={{
													position: 'relative',
													width: width < 992 ? 42 : 128,
													height: width < 992 ? 58 : 180,
												}}
												src={Logo}
												alt={'Sign the card'}
											/>
											<Box height={width < 992 ? 8 : 16} />
											<Typography
												variant="body2"
												style={{
													fontSize: width < 992 ? '11px' : undefined,
													lineHeight: width < 992 ? '13px' : undefined,
													textAlign: 'center',
													position: 'relative',
												}}
											>
												Why not create a card for someone you know?
											</Typography>
											<Box height={width < 992 ? 8 : 16} />
											<Button
												onClick={() => history.replace('/?tab=0')}
												style={{
													fontSize: width < 992 ? '11px' : undefined,
													lineHeight: width < 992 ? '13px' : undefined,
													height: width < 992 ? 28 : undefined,
													position: 'relative',
													width: '80%',
													padding: width < 992 ? '0 3px' : '0 8px',
												}}
											>
												Create a card
											</Button>
										</div>
									</div>
								)}
								{width >= 992 && page !== lastPage && (
									<div
										onClick={() => handleChangePage(page + 1)}
										className={styles.button}
										style={{ right: width < 992 ? -40 : -60 }}
									>
										<img src={ArrowRightIcon} alt="Right" />
									</div>
								)}
								{width >= 992 && page > 0 && (
									<div
										onClick={() => handleChangePage(page - 1)}
										className={styles.button}
										style={{ left: width < 922 ? -40 : -60 }}
									>
										<img src={ArrowLeftIcon} alt="Left" />
									</div>
								)}
							</div>
							<canvas
								width={CARD_WIDTH}
								height={CARD_HEIGHT}
								className={styles.cardContainerCanvas}
							></canvas>
						</div>
						<Controls
							noZoom={width < 992}
							disableZoom={width < 992}
							page={page}
							zoom={zoom}
							setPage={handleChangePage}
							setZoom={setZoom}
						/>
						{width < 992 && !alreadySigned && !previewMessage && (
							<>
								<Box height={16} />
								<Button
									style={{ width: 200, flexShrink: 0, padding: '0 12px' }}
									onClick={() => setShowRightPanel(true)}
								>
									Add your Message
								</Button>
							</>
						)}
						{width < 992 && !alreadySigned && previewMessage && (
							<>
								<Box height={16} />
								<div style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
									<Button
										type="outlined"
										style={{
											background: 'transparent',
											color: '#ee584b',
											flexGrow: 1,
											flexBasis: '50%',
											padding: '0 4px',
										}}
										onClick={() => {
											setOnEditingMode(true);
											setPreviewMessage(false);
											setShowRightPanel(true);
										}}
									>
										Edit Message
									</Button>
									<Box width={12} />
									<Button
										disabled={!canProceed || isLoading || hasAnyEditableOverlapping}
										style={{ flexGrow: 1, flexBasis: '50%', padding: '0 12px' }}
										onClick={handleSubmitMessage}
									>
										Confirm
									</Button>
								</div>
							</>
						)}
						{width < 992 && alreadySigned && (
							<>
								<Box height={16} />
								<div style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
									<Button
										type="outlined"
										style={{
											background: 'transparent',
											color: '#ee584b',
											flexGrow: 1,
											flexBasis: '50%',
											padding: '0 4px',
										}}
										onClick={() => {
											setOnEditingMode(true);
											setPreviewMessage(false);
											setShowRightPanel(true);
											setIndex(0);
										}}
									>
										Edit Message
									</Button>
									<Box width={12} />
									<Button
										disabled={!canProceed || isLoading || hasAnyEditableOverlapping}
										style={{ flexGrow: 1, flexBasis: '50%', padding: '0 12px' }}
										onClick={handleSubmitMessage}
									>
										Confirm
									</Button>
								</div>
							</>
						)}
						<Box height={width < 992 ? 24 : 32} />
					</div>
					<div
						className={`${styles.rightContainer} ${showRightPanel ? styles.rightContainerActive : ''}`}
						style={{
							justifyContent: isContributionMode ? 'flex-start' : undefined,
						}}
					>
						{index !== 2 && (
							<Tabbar
								onClick={(newIndex) => {
									if (!alreadySigned && newIndex > index) {
										return;
									}

									setIndex(newIndex);
								}}
								tabs={tabs}
								index={index}
							/>
						)}
						{index === 0 && (
							<WriteMessageTab
								name={name}
								email={email}
								recipientFirstName={signACardState.recipientFirstName}
								recipientLastName={signACardState.recipientLastName}
								textSignature={textSignature}
								addSignature={addSignature}
								isUploading={isUploading}
								setTextSignature={setTextSignature}
								setName={setName}
								setEmail={setEmail}
								setIsUploading={setIsUploading}
							/>
						)}
						{index === 1 && (
							<ContributeTab
								isContributionMode={isContributionMode}
								contribution={contribution}
								setIsContributionMode={setIsContributionMode}
								setContribution={setContribution}
							/>
						)}
						{index === 2 && (
							<GreetingTab
								recipientFirstName={signACardState.recipientFirstName}
								recipientLastName={signACardState.recipientLastName}
							/>
						)}
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								flexShrink: 0,
							}}
						>
							{index === 0 && hasAnyEditableOverlapping && (
								<>
									<Typography
										style={{ color: '#ee584b', fontWeight: 'bold', textAlign: 'center' }}
										variant="body2"
									>
										Error! You have overlapping elements.
										<br />
										Please adjust your layout before continuing.
									</Typography>
									<Box height={12} />
								</>
							)}
							{index === 0 && !hasAnyEditableOverlapping && signACardState.unsavedChanges && (
								<>
									<Typography
										style={{ color: '#ee584b', fontWeight: 'bold', textAlign: 'center' }}
										variant="body2"
									>
										You have unsaved changes.
									</Typography>
									<Box height={12} />
								</>
							)}
							{index < 2 && !isContributionMode && (
								<>
									<Button
										loading={isLoading || isUploading}
										style={{ padding: '0 16px', width: width < 992 ? '70%' : '50%' }}
										disabled={
											!canProceed ||
											isLoading ||
											isUploading ||
											(index !== 0 && contribution < 5) ||
											(width >= 992 && hasAnyEditableOverlapping)
										}
										onClick={handleNext}
									>
										{index === 0
											? width < 992
												? 'Preview Message'
												: 'Confirm Message'
											: 'Contribute Now'}
									</Button>
								</>
							)}
							{index === 2 && (
								<>
									{signACardState.deliveredAt === null && (
										<Button
											style={{ padding: '0 8px', width: width < 992 ? '70%' : '50%' }}
											onClick={() => dispatch(uiActions.openShareModal({ noContributors: true }))}
										>
											<img src={EnvelopeIcon} alt="" />
											<Box width={12} />
											Invite Others to Sign
										</Button>
									)}
									<Box height={12} />
									{width >= 992 && signACardState.deliveredAt === null && (
										<>
											<Button
												style={{ padding: '0 8px', width: width < 992 ? '70%' : '50%' }}
												className={styles.headerButton}
												type="outlined"
												onClick={() => {
													setOnEditingMode(true);
													setIndex(0);
												}}
											>
												<img src={PencilIcon} alt="" />
												<Box width={12} />
												Edit Message
											</Button>
											<Box height={12} />
										</>
									)}
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											width: 300,
											justifyContent: 'center',
										}}
									>
										{width < 992 && (
											<>
												<Button
													type="outlined"
													style={{
														background: 'transparent',
														color: '#ee584b',
														padding: '0 16px',
														width: width < 992 ? '70%' : '50%',
													}}
													onClick={() => {
														setOnEditingMode(true);
														setPreviewMessage(false);
														setShowRightPanel(true);
														setIndex(0);
													}}
												>
													Edit Message
												</Button>
												<Box width={12} />
											</>
										)}
										<Button
											type="text"
											style={{ padding: '0 16px', width: width < 992 ? '70%' : '50%' }}
											onClick={() => history.replace('/?tab=1')}
										>
											View my Profile
										</Button>
									</div>
								</>
							)}
							{index === 1 && !isContributionMode && (
								<Button
									type="text"
									style={{ padding: '0 16px', width: width < 992 ? '70%' : '50%', color: '#000000' }}
									onClick={() => setIndex(2)}
								>
									No Thanks
								</Button>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}, [
		onEditingMode,
		socket,
		overlappingTable,
		isContributionMode,
		index,
		width,
		isLoading,
		isUploading,
		editableSignatures,
		staticSignatures,
		textSignature,
		signACardState,
		name,
		email,
		contribution,
		canProceed,
		alreadySigned,
		previewMessage,
		showRightPanel,
		signACardState.signatures,
		zoom,
		page,
		lastPage,
		cardContentRef,
		opacity,
		atEnd,
		addSignature,
		handleNext,
		handleChangePage,
		revalidateSignatures,
		hasAnyEditableOverlapping,
		showGuestProposeSignModal,
	]);
};

export default SignACardScreen;
