import React from 'react';
import Typography from '../Typography';
import styles from './index.module.scss';

interface Props {
	title: string;
	suffix?: React.ReactNode;
	alternate?: boolean;
}

const TitledContainer: React.FC<Props> = (props: Props) => {
	return (
		<>
			<div className={styles.container}>
				<Typography className={styles.title} variant="button">
					{props.title}
				</Typography>
				{!props.alternate && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>{props.suffix}</div>}
			</div>
			{props.alternate && (
				<div style={{ marginTop: 8, display: 'flex', justifyContent: 'flex-end' }}>{props.suffix}</div>
			)}
		</>
	);
};

export default TitledContainer;
