import React from 'react';
import { useDispatch } from 'react-redux';
import { IResolveParams, LoginSocialFacebook } from 'reactjs-social-login';
import authenticationAsyncActions from '../../../store/actions/authentication.action';
import { useAuthenticationState, useUIState } from '../../../store/selectors';
import { uiActions } from '../../../store/slices/ui.slice';
import { SocialTypeId } from '../../../utils/SocialTypeId';
import validateEmail from '../../../utils/validateEmail';
import Box from '../Box';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Input from '../Input';
import LoginSocialGoogle from '../LoginSocialGoogle';
import LoginSocialLinkedIn from '../LoginSocialLinkedIn';
import Typography from '../Typography';
import FacebookIcon from './../../../img/ic-social-facebook.svg';
import GoogleIcon from './../../../img/ic-social-google.svg';
import LinkedInIcon from './../../../img/ic-social-linkedin.svg';
import styles from './index.module.scss';

const SignUp: React.FC = () => {
	const dispatch = useDispatch();

	const authenticationState = useAuthenticationState();
	const uiState = useUIState();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [typeId, setTypeId] = React.useState<SocialTypeId>();
	const [token, setToken] = React.useState<string>('');
	const [userId, setUserId] = React.useState<string>('');
	const [firstName, setFirstName] = React.useState<string>('');
	const [lastName, setLastName] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');
	const [acceptedRules, setAcceptedRules] = React.useState<boolean>(false);
	const [emailError, setEmailError] = React.useState<string>('');
	const [passwordError, setPasswordError] = React.useState<string>('');

	const canProceed = React.useMemo<boolean>(() => {
		if (typeId !== undefined) {
			return email !== '' && validateEmail(email) && firstName !== '' && lastName !== '' && acceptedRules;
		}

		return (
			email !== '' &&
			validateEmail(email) &&
			password !== '' &&
			password.length >= 6 &&
			firstName !== '' &&
			lastName !== '' &&
			acceptedRules
		);
	}, [typeId, firstName, lastName, email, password, acceptedRules]);

	const title = React.useMemo<string>(() => {
		if (typeId === SocialTypeId.FACEBOOK) {
			return 'Facebook';
		} else if (typeId === SocialTypeId.GOOGLE) {
			return 'Google';
		} else if (typeId === SocialTypeId.LINKEDIN) {
			return 'LinkedIn';
		}

		return 'email';
	}, [typeId]);

	const handleSocialSignUp = React.useCallback(async () => {
		if (!canProceed || isLoading || !acceptedRules || typeId === undefined) {
			return;
		}

		setIsLoading(true);

		await dispatch(
			authenticationAsyncActions.signUpWithSocial({ firstName, lastName, email, token, typeId, userId })
		);
		setIsLoading(false);
	}, [dispatch, canProceed, isLoading, token, typeId, userId, firstName, lastName, email, acceptedRules]);

	const handleSignUp = React.useCallback(async () => {
		if (!canProceed || isLoading || !acceptedRules) {
			return;
		}

		if (token !== '' && typeId !== undefined) {
			handleSocialSignUp();
			return;
		}

		setIsLoading(true);
		await dispatch(authenticationAsyncActions.signUp({ firstName, lastName, email, password }));
		setIsLoading(false);
	}, [
		dispatch,
		handleSocialSignUp,
		canProceed,
		isLoading,
		token,
		typeId,
		firstName,
		lastName,
		email,
		password,
		acceptedRules,
	]);

	const handleWebsiteTermsClick = React.useCallback(() => {
		dispatch(uiActions.openNavigationModal({ index: 3 }));
	}, [dispatch]);

	const handleTermsOfUseClick = React.useCallback(() => {
		dispatch(uiActions.openNavigationModal({ index: 4 }));
	}, [dispatch]);

	const handlePolicyClick = React.useCallback(() => {
		dispatch(uiActions.openNavigationModal({ index: 5 }));
	}, [dispatch]);

	React.useEffect(() => {
		if (email && !validateEmail(email)) {
			setEmailError('Invalid Email address.');
		} else {
			setEmailError('');
		}
	}, [email]);

	React.useEffect(() => {
		if (password && password.length < 6) {
			setPasswordError('Too weak password.');
		} else {
			setPasswordError('');
		}
	}, [password]);

	React.useEffect(() => {
		setEmailError(authenticationState.emailError || '');
		setPasswordError(authenticationState.passwordError || '');
	}, [authenticationState.updatedAt]);

	return (
		<div className={styles.container}>
			<div className={styles.leftContainer}>
				<p className={styles.paragraph}>Get started today</p>
				<h3 className={styles.h3}>Create an account</h3>
				<Box height={36} />
				<div style={{ cursor: 'pointer' }}>
					<LoginSocialLinkedIn
						scope={'r_liteprofile,r_emailaddress'}
						redirect_uri={process.env.REACT_APP_BASE_URL!}
						client_id={process.env.REACT_APP_LINKEDIN_CLIENT_ID!}
						onResolve={async (response: IResolveParams) => {
							if (!response.data) {
								return;
							}

							setFirstName(response.data.firstName);
							setLastName(response.data.lastName);
							setEmail(response.data.email);
							setToken(response.data.accessToken);
							setTypeId(SocialTypeId.LINKEDIN);
							setUserId(response.data.id);
						}}
						onReject={(error: any) => {
							console.error(error);
						}}
					>
						<Button style={{ pointerEvents: 'none', width: 230, padding: '0 4px' }} type={'outlined'}>
							<img src={LinkedInIcon} alt={'LinkedIn'} />
							<div style={{ width: 8 }} />
							Continue with LinkedIn
						</Button>
					</LoginSocialLinkedIn>
				</div>
				<Box height={24} />
				<div style={{ cursor: 'pointer' }}>
					<LoginSocialGoogle
						scope="openid email profile"
						access_type="offline"
						redirect_uri={process.env.REACT_APP_BASE_URL!}
						client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID!}
						onResolve={async (response: IResolveParams) => {
							if (!response.data) {
								return;
							}

							setFirstName(response.data.firstName);
							setLastName(response.data.lastName);
							setEmail(response.data.email);
							setToken(response.data.idToken);
							setTypeId(SocialTypeId.GOOGLE);
							setUserId(response.data.id);
						}}
						onReject={(error: any) => {
							console.error(error);
						}}
					>
						<Button style={{ pointerEvents: 'none', width: 230, padding: '0 4px' }} type={'outlined'}>
							<img src={GoogleIcon} alt={'Google'} />
							<div style={{ width: 8 }} />
							Continue with Google
						</Button>
					</LoginSocialGoogle>
				</div>
				<Box height={24} />
				<div style={{ cursor: 'pointer' }}>
					<LoginSocialFacebook
						fieldsProfile={'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email'}
						redirect_uri={process.env.REACT_APP_BASE_URL!}
						appId={process.env.REACT_APP_FACEBOOK_APP_ID!}
						onResolve={(response: IResolveParams) => {
							if (response.data!.name) {
								if (response.data!.name.includes(' ')) {
									setFirstName(response.data!.name.split(' ')[0]);
									setLastName(response.data!.name.split(' ')[1]);
								} else {
									setFirstName(response.data!.name);
								}
							}

							setEmail(response.data!.email);
							setToken(response.data!.accessToken);
							setTypeId(SocialTypeId.FACEBOOK);
							setUserId(response.data!.userID);
						}}
						onReject={(error: any) => {
							console.error(error);
						}}
					>
						<Button style={{ pointerEvents: 'none', width: 230, padding: '0 4px' }} type={'outlined'}>
							<img src={FacebookIcon} alt={'Facebook'} />
							<div style={{ width: 8 }} />
							Continue with Facebook
						</Button>
					</LoginSocialFacebook>
				</div>
			</div>
			<div className={styles.rightContainer}>
				<div className={styles.scrollable}>
					<Box height={16} />
					<Typography variant="h5">Sign up with {title}</Typography>
					<Box height={32} />
					<Input
						style={{ width: '100%' }}
						name="first-name"
						label={'First name'}
						value={firstName}
						onChange={setFirstName}
					/>
					<Box height={16} />
					<Input
						style={{ width: '100%' }}
						name="last-name"
						label={'Surname'}
						value={lastName}
						onChange={setLastName}
					/>
					<Box height={16} />
					<Input
						error={emailError}
						style={{ width: '100%' }}
						name="email"
						label={'Email address'}
						value={email}
						onChange={setEmail}
						type="email"
					/>
					{typeId === undefined && (
						<>
							<Box height={16} />
							<Input
								error={passwordError}
								style={{ width: '100%' }}
								name="password"
								label={'Password'}
								value={password}
								onChange={setPassword}
								type="password"
							/>
						</>
					)}
					<Box height={16} />
					<Checkbox value={acceptedRules} onChange={setAcceptedRules}>
						I have read and agree to the Sign the Card{' '}
						<div
							style={{ textDecoration: 'underline', cursor: 'pointer' }}
							onClick={handleWebsiteTermsClick}
						>
							Website Terms
						</div>
						,{' '}
						<div style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleTermsOfUseClick}>
							Terms of Use
						</div>{' '}
						and{' '}
						<div style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handlePolicyClick}>
							Privacy Policy
						</div>
					</Checkbox>
					<Box height={32} />
					<Button loading={isLoading} disabled={!canProceed || isLoading} onClick={handleSignUp}>
						Sign Up
					</Button>
					<Box height={8} />
					<Button
						onClick={() => dispatch(uiActions.openSignInModal({ redirectUrl: uiState.redirectUrl }))}
						style={{ padding: '16px 0' }}
						type="text"
					>
						Already with us? Log in
					</Button>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
