import React from 'react';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import Input from '../../lib/components/Input';
import { PayPalButton } from '../../lib/components/PayPalButton';
import Separator from '../../lib/components/Separator';
import Typography from '../../lib/components/Typography';
import { useAppDispatch } from '../../store';
import transactionsAsyncActions from '../../store/actions/transactions.action';
import { useSignACardState } from '../../store/selectors';
import PayPalTransactionTypes from '../../utils/PayPalTransactionTypes';
import ContributeIcon from './../../img/ic-contribute.svg';
import styles from './index.module.scss';

interface Props {
	isContributionMode: boolean;
	contribution: number;
	setIsContributionMode: React.Dispatch<React.SetStateAction<boolean>>;
	setContribution: React.Dispatch<React.SetStateAction<number>>;
}

const ContributeTab: React.FC<Props> = (props: Props) => {
	const dispatch = useAppDispatch();

	const signACardState = useSignACardState();

	const [contributionError, setContributionError] = React.useState<string>('');
	const [contribution, setContribution] = React.useState<number>(props.contribution);

	const createOrder = React.useCallback(
		(data: any, actions: any) => {
			const order = actions.order.create({
				purchase_units: [
					{
						amount: {
							currency_code: 'AUD',
							value: contribution,
						},
						description: `Contribution for ${signACardState.recipientFirstName} ${signACardState.recipientLastName} card.`,
					},
				],
			});

			order.then((transactionId: string) => {
				dispatch(
					transactionsAsyncActions.initialize({
						transactionId,
						objectId: signACardState.id,
						typeId: PayPalTransactionTypes.CONTRIBUTION,
						amount: contribution,
					})
				);
			});

			return order;
		},
		[dispatch, signACardState.id, signACardState.recipientFirstName, signACardState.recipientLastName, contribution]
	);

	const onApprove = React.useCallback(
		(data: any, actions: any) => {
			return actions.order.capture().then(() => {
				dispatch(
					transactionsAsyncActions.finalize({
						transactionId: data.orderID,
					})
				);
			});
		},
		[dispatch]
	);

	React.useEffect(() => {
		if (contribution < 5) {
			setContributionError('Minimum contribution amount is $5');
		}

		if (contribution + signACardState.yourContribution > 250) {
			setContributionError('Maximum contribution allowed is $250');
		} else if (contribution >= 5 && contribution + signACardState.yourContribution <= 250) {
			setContributionError('');
		}

		props.setContribution(contribution);
	}, [contribution]);

	if (props.isContributionMode) {
		return (
			<div style={{ width: '100%', boxSizing: 'border-box', paddingLeft: 32, paddingRight: 32 }}>
				<div
					style={{
						padding: '0 8px',
						fontSize: '1.5rem',
						display: 'flex',
						flexDirection: 'row',
						alignSelf: 'flex-start',
						alignItems: 'center',
					}}
				>
					<span>&lt;</span>
					<Button
						style={{ height: 32, margin: '3px 8px 0', padding: 0 }}
						onClick={() => props.setIsContributionMode(false)}
						type={'text'}
					>
						Return to contribution details
					</Button>
				</div>
				<Box height={16} />
				<Typography variant="h3">Order Summary</Typography>
				<Box height={48} />
				<Box width={'100%'} height={1} style={{ background: '#D0D4DB' }} />
				<div className={styles.summaryEntry}>
					<Typography variant="button" style={{ textTransform: 'none' }}>
						{signACardState.recipientFirstName} {signACardState.recipientLastName} gift contribution
					</Typography>
					<Typography variant="button" style={{ fontWeight: 300 }}>
						${contribution.toFixed(2)}
					</Typography>
				</div>
				<Box width={'100%'} height={1} style={{ background: '#D0D4DB' }} />
				<div className={styles.summaryEntry}>
					<Typography variant="button">Total</Typography>
					<Typography variant="button" style={{ fontWeight: 300 }}>
						${contribution.toFixed(2)}
					</Typography>
				</div>
				<Box height={32} />
				<Typography variant="h4">Select your payment method</Typography>
				<Box height={32} />
				<PayPalButton
					createOrder={createOrder}
					onApprove={onApprove}
					options={{
						clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
						currency: 'AUD',
					}}
				/>
			</div>
		);
	}

	return (
		<div className={styles.formContainer}>
			<img style={{ width: 134, height: 128, alignSelf: 'center' }} src={ContributeIcon} alt={'Contribute'} />
			<Box height={12} />
			<Typography style={{ textAlign: 'center' }} variant="h3">
				Ask people to contribute to this card
			</Typography>
			<Box height={12} />
			<Typography style={{ textAlign: 'center', fontStyle: 'italic' }} variant="body1">
				Select or enter how much you’d like to contribute to {signACardState.recipientFirstName}’s gift
			</Typography>
			<Box height={24} />
			<div className={styles.pills}>
				<div
					onClick={() => setContribution(5)}
					className={`${styles.pill} ${contribution === 5 ? styles.pillActive : ''}`}
				>
					<Typography style={{ fontWeight: 800 }} variant="body1">
						$5
					</Typography>
				</div>
				<div
					onClick={() => setContribution(10)}
					className={`${styles.pill} ${contribution === 10 ? styles.pillActive : ''}`}
				>
					<Typography style={{ fontWeight: 800 }} variant="body1">
						$10
					</Typography>
				</div>
				<div
					onClick={() => setContribution(15)}
					className={`${styles.pill} ${contribution === 15 ? styles.pillActive : ''}`}
				>
					<Typography style={{ fontWeight: 800 }} variant="body1">
						$15
					</Typography>
				</div>
				<div
					onClick={() => setContribution(20)}
					className={`${styles.pill} ${contribution === 20 ? styles.pillActive : ''}`}
				>
					<Typography style={{ fontWeight: 800 }} variant="body1">
						$20
					</Typography>
				</div>
			</div>
			<Box height={24} />
			<Separator style={{ alignSelf: 'center' }} />
			<Box height={24} />
			<Input
				min={5}
				prefix="$"
				error={contributionError}
				value={contribution.toFixed(0)}
				onChange={(value: string) => setContribution(parseInt(value))}
				label="Enter amount"
				type="number"
			/>
			<Box height={24} />
		</div>
	);
};

export default ContributeTab;
