import React, { ReactNode } from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Box from '../Box';
import Button from '../Button';
import Typography from '../Typography';
import ChevronIcon from './../../../img/ic-chevron-right-primary.svg';
import ChevronIconBlack from './../../../img/ic-chevron-right.svg';
import styles from './index.module.scss';

interface Props {
	open?: boolean;
	question: string;
	children: ReactNode;
	questionColor?: string;
	blackIcon?: boolean;
	noSpacer?: boolean;
}

const CategoryAccordion: React.FC<Props> = ({
	questionColor = 'rgba(255, 255, 255, .87)',
	open = false,
	...props
}: Props) => {
	const { width } = useWindowDimensions();

	const [isOpen, setIsOpen] = React.useState<boolean>(open);

	const toggleOpen = React.useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.title} onClick={toggleOpen}>
					<img
						className={isOpen ? styles.chevronOpen : ''}
						src={props.blackIcon ? ChevronIconBlack : ChevronIcon}
						alt={''}
					/>
					<Typography style={{ fontWeight: 'bold', color: questionColor }} variant="body1">
						{props.question}
					</Typography>
				</div>
				{props.blackIcon && (
					<div
						style={{
							width: '100%',
							height: '1px',
							background: '#000000',
							marginBottom: width < 992 ? 12 : 16,
						}}
					/>
				)}
				<div className={`${styles.contentContainer} ${isOpen ? styles.contentOpen : ''}`}>
					<Typography
						className={`${styles.content} ${isOpen ? styles.open : ''}`}
						style={{ color: 'rgba(255, 255, 255, .87)' }}
						variant="body1"
					>
						{props.children}
					</Typography>
					<Button
						onClick={toggleOpen}
						style={{
							position: 'sticky',
							bottom: 0,
							transform: 'translateX(-16px)',
							width: 'calc(100% + 16px * 2)',
							borderRadius: 0,
						}}
						type="raised"
					>
						Filter
					</Button>
				</div>
			</div>
			{!props.noSpacer && <Box style={{ background: '#E1E1E1' }} height={1} />}
		</>
	);
};

export default CategoryAccordion;
