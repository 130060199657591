import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import CardPack from '../../models/CardPack';
import ErrorResponse from '../../network/responses/ErrorResponse';
import authenticationAsyncActions from '../actions/authentication.action';
import cardPackAsyncActions from '../actions/cardPack.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';
import { CPA } from '../types';

export interface CardPackState {
	list: CardPack[];
	updatedAt: number;
}

const initialState: CardPackState = {
	list: [],
	updatedAt: moment().valueOf(),
};

const slice = createSlice({
	name: 'cardPack',
	initialState,
	reducers: {},
	extraReducers: {
		[cardPackAsyncActions.index.fulfilled.type]: (state, action: CPA<CardPack[]>) => {
			state.list = action.payload;
			state.updatedAt = moment().valueOf();

			postRequest(action);
		},
		[cardPackAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
	},
});

export const cardPackActions = slice.actions;

export default slice.reducer;
