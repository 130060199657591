import React from 'react';

interface Props {
	children?: React.ReactNode;
	width?: number | string;
	height?: number | string;
	style?: React.CSSProperties;
	className?: string;
}

const Box: React.FC<Props> = ({ style = {}, ...props }: Props) => {
	return (
		<div
			className={props.className}
			style={Object.assign({ flexShrink: 0, width: props.width, height: props.height }, style)}
		>
			{props.children}
		</div>
	);
};

export default Box;
