import React from 'react';
import { useDispatch } from 'react-redux';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { uiActions } from '../../../store/slices/ui.slice';
import Box from '../Box';
import Button from '../Button';
import Typography from '../Typography';
import styles from './index.module.scss';

interface Props {
	handleNext: () => void;
}

const GuestProposeSign: React.FC<Props> = (props: Props) => {
	const dispatch = useDispatch();

	const { width } = useWindowDimensions();

	return (
		<div className={styles.container}>
			<div className={styles.leftContainer}>
				{width < 992 && (
					<>
						<h4 className={styles.h3} style={{ paddingBottom: 0 }}>
							Thank you for using
						</h4>
						<h4 className={styles.h3} style={{ paddingTop: 0, color: '#EE584B' }}>
							Sign the Card
						</h4>
					</>
				)}
				{width >= 992 && (
					<>
						<h3 className={styles.h3} style={{ paddingBottom: 0 }}>
							Thank you for using
						</h3>
						<h3 className={styles.h3} style={{ paddingTop: 0, color: '#EE584B' }}>
							Sign the Card
						</h3>
					</>
				)}
			</div>
			<div className={styles.rightContainer}>
				<h5 className={styles.h5}>
					{width < 992 ? 'Create an account' : 'Would you like to create an account?'}
				</h5>
				<Box height={36} />
				<Typography style={{ textAlign: 'center' }} variant="body2">
					Creating an account on <span style={{ fontWeight: 700 }}>Sign the Card</span> is free and enables
					some great features including:
				</Typography>
				<ul className={styles.ul}>
					<li>
						<Typography variant="body2">editing messages before the card is delivered</Typography>
					</li>
					<li>
						<Typography variant="body2">see all cards you have signed and created</Typography>
					</li>
					<li>
						<Typography variant="body2">be the first to see new designs and exclusive offers</Typography>
					</li>
				</ul>
				<Box height={16} />
				<Button
					type="raised"
					onClick={() => {
						props.handleNext();
						dispatch(uiActions.openSignUpModal({}));
					}}
				>
					Create Account
				</Button>
				<Box height={8} />
				<Button onClick={props.handleNext} type="text">
					Continue as guest
				</Button>
			</div>
		</div>
	);
};

export default GuestProposeSign;
