import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { API } from 'lib/util';
import { RootState } from '..';
import Card from '../../models/Card';
import ShowCardRequest from '../../network/requests/ShowCardRequest';
import { ManageCardState } from '../slices/manageCard.slice';
import { requestActions } from '../slices/request.slice';
import { SessionState } from '../slices/session.slice';

const cardThankYou = createAsyncThunk('card/thank-you', async (payload: { id: string; message: string }, thunkApi) => {
	thunkApi.dispatch(requestActions.started(cardThankYou.typePrefix));
	try {
		const response = await API.post(`/card/${payload.id}/thank-you`, { message: payload.message });

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const withdrawRequest = createAsyncThunk('card/withdraw-request', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(withdrawRequest.typePrefix));
	try {
		const manageCardState: ManageCardState = (thunkApi.getState() as RootState).manageCard;

		const response = await API.get(`/card/${manageCardState.uuid}/withdraw`);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const show = createAsyncThunk('card/show', async (payload: Pick<ShowCardRequest, 'id'>, thunkApi) => {
	thunkApi.dispatch(requestActions.started(show.typePrefix));
	try {
		const sessionState: SessionState = (thunkApi.getState() as RootState).session;

		const response = await API.post<Omit<ShowCardRequest, 'id'>, AxiosResponse<Card>>(`/card/${payload.id}`, {
			sessionId: sessionState.sessionId,
		});

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const refreshSignatures = createAsyncThunk('card/refresh-signatures', async (payload: { id: string }, thunkApi) => {
	thunkApi.dispatch(requestActions.started(refreshSignatures.typePrefix));
	try {
		const response = await API.get(`/card/${payload.id}/refresh`);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const cardAsyncActions = {
	cardThankYou,
	withdrawRequest,
	show,
	refreshSignatures,
};

export default cardAsyncActions;
