import React from 'react';
import { useDispatch } from 'react-redux';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Contributor from '../../../models/Contributor';
import manageCardAsyncActions from '../../../store/actions/manageCard.action';
import RequestManager from '../../../store/request-manager';
import {
	useCreateCardState,
	useManageCardState,
	useRequestState,
	useSignACardState,
	useUIState,
} from '../../../store/selectors';
import { uiActions } from '../../../store/slices/ui.slice';
import Box from '../Box';
import Button from '../Button';
import CopyLink from '../CopyLink';
import InvitationBox from '../InvitationBox';
import Typography from '../Typography';
import styles from './index.module.scss';

interface Props {
	viewOnly?: boolean;
}

const ShareModal: React.FC<Props> = (props: Props) => {
	const dispatch = useDispatch();

	const { width } = useWindowDimensions();

	const uiState = useUIState();
	const createCardState = useCreateCardState();
	const manageCardState = useManageCardState();
	const signACardState = useSignACardState();
	const requestState = useRequestState();

	const [requestUpdatedAt] = React.useState<number>(requestState.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [contributorsToRemove, setContributorsToRemove] = React.useState<string[]>([]);
	const [contributorsToAdd, setContributorsToAdd] = React.useState<string[]>([]);
	const [contributors, setContributors] = React.useState<Array<Contributor>>(manageCardState.contributors);

	const handleClick = React.useCallback(async () => {
		if (isLoading) {
			return;
		}

		setIsLoading(true);
		if (contributorsToAdd.length > 0) {
			dispatch(
				manageCardAsyncActions.addContributors({ id: manageCardState.id, contributors: contributorsToAdd })
			);
		}
		if (contributorsToRemove.length > 0) {
			dispatch(
				manageCardAsyncActions.removeContributors({
					id: manageCardState.id,
					contributors: contributorsToRemove,
				})
			);
		}
	}, [dispatch, manageCardState.id, isLoading, contributorsToAdd, contributorsToRemove]);

	const handleOnAdd = React.useCallback(
		(contributor: Contributor) => {
			const list = [...contributorsToAdd];
			list.push(contributor.email);

			setContributorsToAdd(list);
		},
		[contributorsToAdd]
	);

	const handleOnRemove = React.useCallback(
		(contributor: Contributor) => {
			const list = [...contributorsToRemove];
			list.push(contributor.email);

			setContributorsToRemove(list);
		},
		[contributorsToRemove]
	);

	React.useEffect(() => {
		if (requestUpdatedAt === requestState.updatedAt) return;
		const RM = new RequestManager(requestState);

		if (
			RM.isFinished(manageCardAsyncActions.addContributors.typePrefix) ||
			RM.isFinished(manageCardAsyncActions.removeContributors.typePrefix)
		) {
			setIsLoading(false);

			dispatch(uiActions.closeModals());
		}

		if (RM.isFulfilled(manageCardAsyncActions.addContributors.typePrefix)) {
			RM.consume(manageCardAsyncActions.addContributors.typePrefix);

			setContributorsToAdd([]);
		}

		if (RM.isFulfilled(manageCardAsyncActions.removeContributors.typePrefix)) {
			RM.consume(manageCardAsyncActions.removeContributors.typePrefix);

			setContributorsToRemove([]);
		}
	}, [requestUpdatedAt, requestState.updatedAt]);

	React.useEffect(() => {
		if (uiState.noContributors) {
			setContributors([]);
		}
	}, [uiState.noContributors]);

	return (
		<div className={styles.container}>
			<Typography variant="h3" style={{ textAlign: 'center' }}>
				Invite Others to Sign
			</Typography>
			<Box height={width < 992 ? 16 : 32} />
			<Typography variant="body2" style={{ textAlign: 'center' }}>
				Copy the link below to share{' '}
				{createCardState.uuid !== '' ? createCardState.recipientFirstName : manageCardState.recipientFirstName}
				’s card, or invite people by entering their email address.
			</Typography>
			<Box height={32} />
			<CopyLink
				url={`signthecard.com/invitation/${
					createCardState.uuid !== ''
						? createCardState.uuid
						: signACardState.uuid !== ''
						? signACardState.uuid
						: manageCardState.uuid
				}`}
			/>
			<Box height={width < 992 ? 16 : 32} />
			<div style={{ width: '100%', height: '100%', overflow: 'hidden', overflowX: 'auto', overflowY: 'auto' }}>
				<InvitationBox
					list={contributors}
					setList={setContributors}
					onAdd={handleOnAdd}
					onRemove={handleOnRemove}
				/>
			</div>
			{!props.viewOnly && (
				<>
					<Box height={12} />
					<Button loading={isLoading} disabled={isLoading} style={{ flexShrink: 0 }} onClick={handleClick}>
						Confirm & Send Invites
					</Button>
				</>
			)}
		</div>
	);
};

export default ShareModal;
