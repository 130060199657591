import React from 'react';
import { Rnd } from 'react-rnd';
import { Font } from '../../../declarations';
import Signature from '../../../models/Signature';
import SignaturePosition from '../../../models/SignaturePosition';
import getFonts from '../../../utils/getFonts';
import SignatureType from '../../../utils/SignatureType';
import Box from '../Box';
import { SIGNATURE_EMOJI_MINIMUM_WIDTH, SIGNATURE_TEXT_MINIMUM_HEIGHT } from '../EditableSignature';
import styles from './index.module.scss';

interface Props {
	signature: Signature;
	style?: React.CSSProperties;
	withControls?: boolean;
	noSignature?: boolean;
	zoom: number;
}

const CARD_WIDTH = 650;

export const SIGNATURE_MINIMUM_WIDTH = 150;
export const SIGNATURE_MINIMUM_HEIGHT = 100;
export const SIGNATURE_MAXIMUM_WIDTH = CARD_WIDTH / 2 - 2;
export const SIGNATURE_MAXIMUM_HEIGHT = CARD_WIDTH * 0.75 - 10;

const StaticSignature: React.FC<Props> = (props: Props) => {
	const [fonts] = React.useState<Array<Font>>(getFonts());
	const [fontFamily, setFontFamily] = React.useState<Font>(
		props.signature.meta
			? getFonts().find((element) => element.name === props.signature.meta!.fontFamily)!
			: getFonts()[0]
	);

	const [emojiFontSize, setEmojiFontSize] = React.useState<number>(props.signature.position.width);
	const [position, setPosition] = React.useState<SignaturePosition>(props.signature.position);

	const mediaType = React.useMemo<string>(() => {
		if (props.signature.media) {
			if (
				props.signature.media.url.split('.').pop()!.toLowerCase().includes('jpg') ||
				props.signature.media.url.split('.').pop()!.toLowerCase().includes('png') ||
				props.signature.media.url.split('.').pop()!.toLowerCase().includes('jpeg') ||
				props.signature.media.url.split('.').pop()!.toLowerCase().includes('gif')
			) {
				return 'image';
			} else if (
				props.signature.media.url.split('.').pop()!.toLowerCase().includes('mp4') ||
				props.signature.media.url.split('.').pop()!.toLowerCase().includes('mov') ||
				props.signature.media.url.split('.').pop()!.toLowerCase().includes('3gp')
			) {
				return 'video';
			}
		}

		return 'unknown';
	}, [props.signature.media]);

	React.useEffect(() => {
		if (props.signature.meta) {
			setFontFamily(fonts.find((element) => element.name === props.signature.meta!.fontFamily)!);
		}
	}, [fonts, props.signature.meta]);

	React.useEffect(() => {
		setEmojiFontSize(props.signature.position.width);
		setPosition(props.signature.position);
	}, [props.signature.position, props.zoom]);

	let message = '';
	if (props.signature.typeId === SignatureType.TEXT) {
		message = `${props.signature.meta ? props.signature.meta.message : ''}\n\n${
			props.signature.user === null
				? props.noSignature
					? ''
					: props.signature.name ?? ''
				: `${props.signature.user.firstName} ${props.signature.user.lastName}`
		}`.trim();
	} else if (props.signature.typeId === SignatureType.EMOJI) {
		message = props.signature.media ? props.signature.media.url : '';
	}

	return (
		<Rnd
			style={props.style}
			bounds="parent"
			size={{ width: position.width, height: position.height }}
			scale={props.zoom}
			position={{ x: position.x, y: position.y }}
			lockAspectRatio={props.signature.typeId !== SignatureType.TEXT}
			minWidth={
				props.signature.typeId === SignatureType.EMOJI ? SIGNATURE_EMOJI_MINIMUM_WIDTH : SIGNATURE_MINIMUM_WIDTH
			}
			minHeight={
				props.signature.typeId === SignatureType.EMOJI
					? undefined
					: props.signature.typeId === SignatureType.TEXT
					? SIGNATURE_TEXT_MINIMUM_HEIGHT
					: SIGNATURE_MINIMUM_HEIGHT
			}
			default={{
				width: position.width,
				height: position.height,
				x: position.x,
				y: position.y,
			}}
			disableDragging={true}
			enableResizing={false}
		>
			<div className={`${styles.container} ${props.withControls ? styles.containerVisible : ''}`}>
				{props.withControls && props.signature.typeId !== SignatureType.EMOJI && props.signature.user !== null && (
					<div style={{ userSelect: 'none' }} className={styles.header}>
						{`${props.signature.user.firstName} ${props.signature.user.lastName}`}
					</div>
				)}
				{props.signature.typeId === SignatureType.TEXT && <Box height={8} />}
				<div className={styles.content}>
					{props.signature.typeId !== SignatureType.TEXT && props.signature.typeId !== SignatureType.EMOJI && (
						<>
							{mediaType === 'image' && (
								<img
									style={{
										pointerEvents: 'none',
										width: '100%',
										height: '100%',
										objectFit: 'contain',
									}}
									src={props.signature.media!.url}
									alt={'Media'}
								/>
							)}
							{mediaType === 'video' && (
								<video
									controls
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'contain',
									}}
								>
									<source src={props.signature.media!.url} />
								</video>
							)}
						</>
					)}
					{(props.signature.typeId === SignatureType.TEXT ||
						props.signature.typeId === SignatureType.EMOJI) && (
						<div
							style={{
								alignSelf: undefined,
								pointerEvents: 'none',
								overflow: 'hidden',
								width: '100%',
								whiteSpace: 'pre-wrap',
								fontFamily: fontFamily.fontFamily,
								...(props.signature.typeId === SignatureType.EMOJI
									? {
											fontSize: `${emojiFontSize}px`,
											lineHeight: `${emojiFontSize}px`,
									  }
									: {}),
								...(props.signature.meta
									? {
											fontSize: `${props.signature.meta.fontSize}px`,
											lineHeight: `${props.signature.meta.fontSize + 4}px`,
											fontWeight: props.signature.meta.isBold ? 'bold' : 'normal',
											fontStyle: props.signature.meta.isItalic ? 'italic' : 'normal',
											textDecoration: props.signature.meta.isUnderline ? 'underline' : 'initial',
											color: props.signature.meta.color,
											textAlign: props.signature.meta.alignment,
									  }
									: {}),
							}}
						>
							{message}
						</div>
					)}
				</div>
			</div>
		</Rnd>
	);
};

export default StaticSignature;
