import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Card from '../../../models/Card';
import { useUserState } from '../../../store/selectors';
import Box from '../Box';
import Button from '../Button';
import Typography from '../Typography';
import CheckIcon from './../../../img/ic-check.svg';
import DollarIcon from './../../../img/ic-dollar.svg';
import styles from './index.module.scss';

interface Props {
	onClick: (id: string) => void;
	card: Card;
	isActive: boolean;
	setShowWithdrawModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardTableEntry: React.FC<Props> = (props: Props) => {
	const { width } = useWindowDimensions();
	const history = useHistory();

	const [timer, setTimer] = React.useState<string>('');

	const userState = useUserState();

	const alreadySigned = React.useMemo<boolean>(() => {
		return props.card.signatures.find((element) => element.user && element.user.id === userState.id) !== undefined;
	}, [userState.id, props.card]);

	const alreadyContributed = React.useMemo<boolean>(() => {
		if (props.card) {
			const findCard = userState.cards.find((element) => element.id === props.card!.id);
			if (findCard) {
				return findCard.yourContribution > 0;
			}
		}

		return false;
	}, [userState.cards, props.card]);

	const isCreator = React.useMemo<boolean>(() => {
		return props.card !== undefined && props.card.creator.id === userState.id;
	}, [props.card, userState.id, userState.cards]);

	const canWithdraw = React.useMemo<boolean>(() => {
		const cardDate = moment.utc(`${props.card.deliveryAt}`, 'DD/MM/YYYY hh:mm A');
		cardDate.utcOffset(props.card.deliveryTimezone.split(' ')[0].replace(/UTC|/, ''), true);

		const now = moment.utc();

		return cardDate.diff(now, 'hour') <= 48;
	}, [props.card]);

	React.useEffect(() => {
		if (!props.card) {
			return;
		}

		const cardDate = moment.utc(`${props.card.deliveryAt}`, 'DD/MM/YYYY hh:mm A');
		cardDate.utcOffset(props.card.deliveryTimezone.split(' ')[0].replace(/UTC|/, ''), true);

		const interval = setInterval(() => {
			const now = moment().valueOf();
			const distance = cardDate.valueOf() - now;
			const days = Math.floor(distance / (1000 * 60 * 60 * 24));
			const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

			if (distance < 0) {
				clearTimeout(interval);
			} else {
				setTimer(
					(days < 10 ? `0${days}` : days.toString()) +
						' days ' +
						(hours < 10 ? `0${hours}` : hours.toString()) +
						' hours.'
				);
			}
		}, 1000);

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [props.card]);

	if (width < 992) {
		return (
			<tr
				onClick={() => {
					if (isCreator) {
						props.onClick(props.card.uuid);
					}
				}}
			>
				<td>
					<div className={styles.smallContainer}>
						<div className={styles.cardContainer}>
							{alreadySigned && (
								<img
									style={{ zIndex: 1, right: -6 }}
									className={styles.cardIcon}
									src={CheckIcon}
									alt=""
								/>
							)}
							{alreadyContributed && (
								<img
									style={{ zIndex: 1, right: 14 }}
									className={styles.cardIcon}
									src={DollarIcon}
									alt=""
								/>
							)}
							<img src={props.card.image} />
						</div>
						<div style={{ marginLeft: 12 }} className={styles.contentContainer}>
							<Typography
								style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
								className={styles.title}
								variant="button"
							>
								{props.card.recipientFirstName} {props.card.recipientLastName}
							</Typography>
							<div style={{ display: 'flex', flexDirection: 'row', margin: '12px 0' }}>
								{timer !== '' && (
									<>
										<Typography
											style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
											className={styles.title}
											variant="caption"
										>
											Time left to sign:
										</Typography>
										<Box width={4} />
										<Typography
											style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
											className={styles.title}
											variant="button"
										>
											{timer}
										</Typography>
									</>
								)}
								{props.card.deliveredAt !== null && (
									<>
										<Typography
											style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
											className={styles.title}
											variant="caption"
										>
											Delivered on:
										</Typography>
										<Box width={4} />
										<Typography
											style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
											className={styles.title}
											variant="button"
										>
											{props.card.deliveredAt}
										</Typography>
									</>
								)}
							</div>
							<Typography
								style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
								className={styles.title}
								variant="caption"
							>
								{props.card.contributors.filter((element) => element.isSigned).length} contributors
							</Typography>
							{isCreator && props.card.isContributionEnabled && (
								<>
									<Typography
										style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
										className={styles.title}
										variant="caption"
									>
										${props.card.collectedAmount.toFixed(2)}
										{props.card.contribution !== -1
											? ` of \$${props.card.contribution.toFixed(2)} collected`
											: ' collected'}
									</Typography>
									{canWithdraw && (
										<Button
											style={{ margin: 0, padding: 0 }}
											disabled={props.card.withdrawalLocked}
											className={styles.button}
											onClick={() => {
												props.onClick(props.card.uuid);
												props.setShowWithdrawModal(true);
											}}
											type={'text'}
										>
											Withdraw funds
										</Button>
									)}
								</>
							)}
						</div>
					</div>
					<div
						style={{
							padding: '16px 0',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						{props.card.deliveredAt === null && (
							<Button
								style={{ margin: 0, padding: 0 }}
								className={styles.button}
								onClick={() => history.replace(`/sign-a-card/${props.card.uuid}`)}
								type={'text'}
							>
								{alreadySigned ? 'Edit your message' : 'Sign a card'}
							</Button>
						)}
						{props.card.deliveredAt !== null && (
							<Button
								style={{ padding: 0 }}
								className={styles.button}
								onClick={() => history.replace(`/received/${props.card.uuid}`)}
								type={'text'}
							>
								View card
							</Button>
						)}
						{isCreator && (
							<>
								<div style={{ width: 2, height: 16, background: '#000000' }} />
								<Button
									style={{ margin: 0, padding: 0 }}
									className={styles.button}
									onClick={() => history.push(`/messages/${props.card.uuid}`)}
									type={'text'}
								>
									Manage messages
								</Button>
							</>
						)}
						<div style={{ width: 2, height: 16, background: '#000000' }} />
						{isCreator && (
							<Button
								style={{ margin: 0, padding: 0 }}
								className={styles.button}
								onClick={() => {
									if (props.card.recipientEmail === userState.email) {
										history.push(`/received/${props.card.uuid}`);
									} else {
										props.onClick(props.card.uuid);
										// history.push(`/messages/${props.card.id}`);
									}
								}}
								type={'text'}
							>
								View card details
							</Button>
						)}
					</div>
				</td>
			</tr>
		);
	}

	return (
		<tr
			className={props.isActive ? styles.active : ''}
			onClick={() => {
				if (isCreator) {
					props.onClick(props.card.uuid);
				}
			}}
		>
			<td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
				<div className={styles.cardContainer}>
					{alreadySigned && (
						<img style={{ zIndex: 1, right: -6 }} className={styles.cardIcon} src={CheckIcon} alt="" />
					)}
					{alreadyContributed && (
						<img style={{ zIndex: 1, right: 14 }} className={styles.cardIcon} src={DollarIcon} alt="" />
					)}
					<img src={props.card.image} />
				</div>
			</td>
			<td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
				<Typography
					style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
					className={styles.title}
					variant="button"
				>
					{props.card.recipientFirstName} {props.card.recipientLastName}
				</Typography>
			</td>
			<td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
				{timer !== '' && (
					<>
						<Typography
							style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
							className={styles.title}
							variant="caption"
						>
							Time left to sign:
						</Typography>
						<Typography
							style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
							className={styles.title}
							variant="button"
						>
							{timer}
						</Typography>
					</>
				)}
				{props.card.deliveredAt !== null && (
					<>
						<Typography
							style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
							className={styles.title}
							variant="caption"
						>
							Delivered on:
						</Typography>
						<Typography
							style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
							className={styles.title}
							variant="button"
						>
							{props.card.deliveredAt}
						</Typography>
					</>
				)}
			</td>
			<td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
				<Typography
					style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
					className={styles.title}
					variant="button"
				>
					{props.card.contributors.filter((element) => element.isSigned).length}
				</Typography>
				<Typography
					style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
					className={styles.title}
					variant="caption"
				>
					contributors
				</Typography>
			</td>
			<td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
				{isCreator && props.card.isContributionEnabled && (
					<>
						<Typography
							style={{ color: props.card.deliveredAt !== null ? '#9E9E9E' : undefined }}
							className={styles.title}
							variant="caption"
						>
							${props.card.collectedAmount.toFixed(2)}
							{props.card.contribution !== -1
								? ` of \$${props.card.contribution.toFixed(2)} collected`
								: ' collected'}
						</Typography>
						{canWithdraw && (
							<Button
								style={{ padding: 0 }}
								disabled={props.card.withdrawalLocked}
								className={styles.button}
								onClick={() => {
									props.onClick(props.card.uuid);
									props.setShowWithdrawModal(true);
								}}
								type={'text'}
							>
								Withdraw funds
							</Button>
						)}
					</>
				)}
			</td>
			<td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
				{props.card.deliveredAt === null && (
					<Button
						style={{ padding: 0 }}
						className={styles.button}
						onClick={() => history.replace(`/sign-a-card/${props.card.uuid}`)}
						type={'text'}
					>
						{alreadySigned ? 'Edit your message' : 'Sign a card'}
					</Button>
				)}
				{props.card.deliveredAt !== null && (
					<Button
						style={{ padding: 0 }}
						className={styles.button}
						onClick={() => history.replace(`/received/${props.card.uuid}`)}
						type={'text'}
					>
						View card
					</Button>
				)}
				{isCreator && (
					<>
						<Button
							style={{ padding: 0 }}
							className={styles.button}
							onClick={() => history.push(`/messages/${props.card.uuid}`)}
							type={'text'}
						>
							Manage messages
						</Button>
						<Button
							style={{ padding: 0 }}
							className={styles.button}
							onClick={() => {
								if (props.card.recipientEmail === userState.email) {
									history.push(`/received/${props.card.uuid}`);
								} else {
									props.onClick(props.card.uuid);
									// history.push(`/messages/${props.card.uuid}`);
								}
							}}
							type={'text'}
						>
							View card details
						</Button>
					</>
				)}
			</td>
		</tr>
	);
};

export default CardTableEntry;
