import React from 'react';
import { usePopper } from 'react-popper';
import Typography from '../Typography';
import CaretDownIcon from './../../../img/ic-caret-down.svg';
import CaretUpIcon from './../../../img/ic-caret-up.svg';
import styles from './index.module.scss';

interface Props {
	className?: string;
	options: Array<string>;
	value?: string;
	name?: string;
	inputStyle?: React.CSSProperties;
	style?: React.CSSProperties;
	containerStyle?: React.CSSProperties;
	label?: string;
	error?: string;
	type?: 'text' | 'email' | 'tel' | 'password';
	noError?: boolean;
	noSearch?: boolean;
	withArrows?: boolean;
	width?: number | 'auto' | 'full';
	disabled?: boolean;
	onChange?: (value: string) => void;
	onClick?: () => void;
	onFocus?: () => void;
	onBlur?: () => void;
}

const Dropdown: React.FC<Props> = ({
	noSearch = false,
	withArrows = false,
	type = 'text',
	width = 'full',
	onChange = () => {},
	containerStyle = {},
	...props
}: Props) => {
	const mainRef = React.useRef<HTMLDivElement | null>(null);
	const ref = React.useRef<HTMLInputElement>(null);
	const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);
	const [popperRef, setPopperRef] = React.useState<HTMLDivElement | null>(null);
	const [arrowRef, setArrowRef] = React.useState<HTMLDivElement | null>(null);
	const { styles: popperStyles, attributes } = usePopper(containerRef, popperRef, {
		modifiers: [{ name: 'arrow', options: { element: arrowRef } }],
		placement: 'auto-start',
	});
	const [isActive, setIsActive] = React.useState<boolean>(false);
	const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
	const [tempValue, setTempValue] = React.useState<string>(props.value || '');

	const handleSelect = React.useCallback((element: string) => {
		setShowDropdown(false);
		onChange(element);
		setTempValue(element);
	}, []);

	const handleArrowUp = React.useCallback(() => {
		if (!withArrows || props.options.length === 0) {
			return;
		}

		const findIndex = props.options.findIndex((element) => element === props.value);
		if (findIndex !== -1) {
			if (findIndex === 0) {
				onChange(props.options[props.options.length - 1]);
				setTempValue(props.options[props.options.length - 1]);
			} else {
				onChange(props.options[findIndex - 1]);
				setTempValue(props.options[findIndex - 1]);
			}
		}
	}, [props.options, props.value, withArrows]);

	const handleArrowDown = React.useCallback(() => {
		if (props.options.length === 0) {
			return;
		}

		if (!withArrows) {
			setShowDropdown(true);

			if (props.onClick) {
				props.onClick();
			}

			return;
		}

		const findIndex = props.options.findIndex((element) => element === props.value);
		if (findIndex !== -1) {
			if (findIndex === props.options.length - 1) {
				onChange(props.options[0]);
				setTempValue(props.options[0]);
			} else {
				onChange(props.options[findIndex + 1]);
				setTempValue(props.options[findIndex + 1]);
			}
		}
	}, [props.options, props.value, withArrows]);

	React.useEffect(() => {
		const mouseUp = (event: any) => {
			if (mainRef.current && !mainRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		};

		document.addEventListener('mouseup', mouseUp);

		return () => {
			document.removeEventListener('mouseup', mouseUp);
		};
	}, [mainRef]);

	React.useEffect(() => {
		const focusIn = () => {
			setIsActive(true);
			setShowDropdown(true);
			if (props.onFocus) {
				props.onFocus();
			}
		};
		const focusOut = () => {
			if (!props.value || !props.label) {
				setIsActive(false);
			}
			if (props.onBlur) {
				props.onBlur();
			}
		};

		if (ref.current) {
			ref.current.addEventListener('focusin', focusIn);
			ref.current.addEventListener('focusout', focusOut);
		}

		return () => {
			if (ref.current) {
				ref.current.removeEventListener('focusin', focusIn);
				ref.current.removeEventListener('focusout', focusOut);
			}
		};
	}, [ref, props.value, props.label, props.onFocus, props.onBlur]);

	React.useEffect(() => {
		if (props.value && props.label) {
			setIsActive(true);
		}
	}, [props.value, props.label]);

	React.useEffect(() => {
		if (props.value) {
			setTempValue(props.value);

			if (popperRef) {
				const findIndex = props.options.findIndex((element) => element === props.value);
				if (findIndex === -1) {
					return;
				}

				popperRef.scrollTo({
					top: findIndex * 44,
				});
			}
		}
	}, [props.value, popperRef]);

	return (
		<div
			ref={mainRef}
			style={Object.assign(
				{ position: 'relative', width: typeof width === 'number' ? width : 'initial' },
				containerStyle
			)}
		>
			<div ref={setContainerRef} className={`${styles.container} ${props.disabled ? styles.disabled : ''}`}>
				<div
					style={props.style}
					className={`${styles.inputContainer} ${props.error ? styles.error : ''} ${
						props.className ? props.className : ''
					}`}
				>
					<div
						onClick={() => {
							if (noSearch) {
								setShowDropdown(true);
							}

							if (props.onClick) {
								props.onClick();
							}
						}}
						style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
					>
						{props.label ? (
							<label
								className={`${styles.label} ${isActive ? styles.activeLabel : ''} ${
									props.error ? styles.errorLabel : ''
								}`}
							>
								{props.label}
							</label>
						) : null}
						<input
							style={props.inputStyle}
							disabled={noSearch || props.disabled}
							name={props.name}
							value={tempValue}
							onChange={(e) => setTempValue(e.target.value)}
							className={styles.input}
							ref={ref}
							type={type}
						/>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{withArrows && <img onClick={handleArrowUp} className={styles.icon} src={CaretUpIcon} alt="" />}
						<img onClick={handleArrowDown} className={styles.icon} src={CaretDownIcon} alt="" />
					</div>
				</div>
			</div>
			<div
				className={`${styles.dropdownContainer} ${
					width === 'auto' || typeof width === 'number' ? styles.dropdownContainerAuto : ''
				} ${!showDropdown || props.disabled ? styles.hidden : ''}`}
				ref={setPopperRef}
				style={popperStyles.popper}
				{...attributes.popper}
			>
				{props.options
					.filter(
						(element) =>
							element.toLowerCase().includes(tempValue.toLowerCase()) ||
							props.options.some((element) => element === tempValue)
					)
					.map((element) => (
						<div
							style={{ width: typeof width === 'number' ? width : 'initial' }}
							key={element}
							onClick={() => handleSelect(element)}
							className={`${styles.dropdownItem} ${
								tempValue.includes(element) ? styles.dropdownItemActive : ''
							}`}
						>
							<Typography style={{ fontWeight: 'normal' }} variant="body2">
								{element}
							</Typography>
						</div>
					))}
				<div ref={setArrowRef} style={popperStyles.arrow} />
			</div>
		</div>
	);
};

export default Dropdown;
