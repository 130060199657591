import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import Controls from '../../lib/components/Controls';
import Header from '../../lib/components/Header';
import Typography from '../../lib/components/Typography';
import cardAsyncActions from '../../store/actions/card.action';
import { useAuthenticationState, useCardState } from '../../store/selectors';
import SignIcon from './../../img/ic-sign.svg';
import styles from './index.module.scss';

const InvitationScreen: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();

	const authenticationState = useAuthenticationState();
	const cardState = useCardState();

	const { width } = useWindowDimensions();
	const [zoom, setZoom] = React.useState<number>(1);

	const [showRightPanel] = React.useState<boolean>(true);

	const handleNext = React.useCallback(async () => {
		if (cardState.deliveredAt !== null) {
			history.replace(`/received/${cardState.uuid}`);
		} else {
			history.replace(`/sign-a-card/${cardState.uuid}`);
		}
	}, [dispatch, history, cardState.uuid, cardState.deliveredAt]);

	React.useEffect(() => {
		if (cardState.uuid === id) {
			return;
		}

		dispatch(cardAsyncActions.show({ id }));
	}, [dispatch, cardState.uuid, authenticationState.isAuthenticated]);

	if (cardState.uuid !== id) {
		return null;
	}

	return (
		<div className={styles.container}>
			<div className={styles.leftContainer}>
				<Header
					hideAuthentication={width < 992}
					style={{ zIndex: 6 }}
					title={<Typography variant="body2">Sign the Card for</Typography>}
					subTitle={
						<Typography variant="h5">
							{cardState.recipientFirstName} {cardState.recipientLastName}&nbsp;
						</Typography>
					}
					relative
				/>
				<div style={{ marginTop: 16, display: 'flex', alignItems: 'center', height: '100%' }}>
					<div className={styles.cardContainer} style={{ transform: `scale(${zoom})` }}>
						<img src={cardState.image} alt={'Card Preview'} />
					</div>
				</div>
				<Controls noPage zoom={zoom} setZoom={setZoom} />
				<Box height={32} />
			</div>
			<div className={`${styles.rightContainer} ${showRightPanel ? styles.rightContainerActive : ''}`}>
				<Box style={{ flexGrow: 1 }} />
				{width >= 992 && <img style={{ width: 101, height: 72 }} src={SignIcon} alt="" />}
				{width < 992 && (
					<>
						<Box height={48} />
						<div className={styles.invitationCardContainer}>
							<img
								style={{
									borderRadius: '8px',
									boxShadow:
										'0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
									// width: width < 992 ? 'calc(40vh * .75)' : 'calc(40vh * .75)',
									// height: width < 992 ? '40vh' : '40vh'
									width: '100%',
									height: '100%',
									objectFit: 'cover',
								}}
								src={cardState.image}
								alt={'Card Preview'}
							/>
						</div>
					</>
				)}
				<Box height={52} />
				{width >= 992 && (
					<>
						<Typography variant="h3" style={{ textAlign: 'center' }}>
							Sign {cardState.recipientFirstName} {cardState.recipientLastName}’s card.
						</Typography>
						<Box height={20} />
					</>
				)}
				<Typography variant="body1" style={{ fontStyle: 'italic', textAlign: 'center' }}>
					{cardState.reminderMessage}
				</Typography>
				<Box height={52} />
				<div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Button style={{ padding: '0 12px', width: width < 992 ? '70%' : '50%' }} onClick={handleNext}>
						{cardState.deliveredAt === null ? 'Sign the Card' : 'View the Card'}
					</Button>
					{/* <Button type='text' style={{ width: width < 992 ? '70%' : '50%', padding: '0 12px' }} onClick={handleSignInNext}>Login & edit message</Button> */}
				</div>
				<Box style={{ flexGrow: 1 }} />
			</div>
		</div>
	);
};

export default InvitationScreen;
