import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import moment from 'moment';
import Card from '../../models/Card';
import SignatureDraft from '../../models/SignatureDraft';
import getTimezones from '../../utils/getTimezones';
import authenticationAsyncActions from '../actions/authentication.action';
import cardAsyncActions from '../actions/card.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';

export interface CardState extends Omit<Card, 'creator' | 'template'> {
	withdrawError: string;
	isPaid: boolean;
	updatedAt: number;
}

const initialState: CardState = {
	isPaid: false,
	id: 'temporary',
	uuid: '',
	recipientFirstName: '',
	recipientLastName: '',
	recipientEmail: '',
	image: '',
	isContributionEnabled: true,
	contribution: 0,
	collectedAmount: 0,
	deliveryAt: moment().add(1, 'day').format('DD/MM/YYYY') + '12:00 PM',
	deliveryTimezone: '',
	isReminderEnabled: true,
	reminderMessage: '',
	contributors: [],
	signatures: [],
	deliveredAt: null,
	yourContribution: 0,
	thankYouMessageSent: false,
	withdrawalLocked: false,
	withdrawError: '',
	updatedAt: moment().valueOf(),
};

const slice = createSlice({
	name: 'card',
	initialState,
	reducers: {
		initializeCard: (state: WritableDraft<typeof initialState>, action: PayloadAction<Pick<Card, 'image'>>) => {
			state.id = 'temporary';
			state.image = action.payload.image;
			state.uuid = '';
			state.recipientFirstName = '';
			state.recipientLastName = '';
			state.recipientEmail = '';
			state.deliveryAt = moment().add(1, 'day').format('DD/MM/YYYY') + '12:00 PM';

			const timeZones = getTimezones();
			const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const parts = timeZone.split('/');
			let city = parts[parts.length - 1];
			city = city.replace('_', ' ');
			const findIndex = timeZones.findIndex((element) => element.toLowerCase().includes(city.toLowerCase()));
			if (findIndex !== -1) {
				state.deliveryTimezone = timeZones[findIndex];
			} else {
				state.deliveryTimezone = '';
			}
			state.isReminderEnabled = true;
			state.reminderMessage = '';
			state.contribution = 0;
			state.collectedAmount = 0;
			state.isContributionEnabled = true;
			state.contributors = [];
			state.signatures = [];
			state.deliveredAt = null;
			state.yourContribution = 0;
			state.thankYouMessageSent = false;
			state.withdrawalLocked = false;
			state.withdrawError = '';
			state.updatedAt = moment().valueOf();
		},
		changeCover: (state: WritableDraft<typeof initialState>, action: PayloadAction<Pick<Card, 'image'>>) => {
			state.image = action.payload.image;
			state.updatedAt = moment().valueOf();
		},
		updateRecipient: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<
				Pick<
					Card,
					'recipientFirstName' | 'recipientLastName' | 'recipientEmail' | 'deliveryAt' | 'deliveryTimezone'
				>
			>
		) => {
			state.recipientFirstName = action.payload.recipientFirstName;
			state.recipientLastName = action.payload.recipientLastName;
			state.recipientEmail = action.payload.recipientEmail;
			state.deliveryAt = action.payload.deliveryAt;
			state.deliveryTimezone = action.payload.deliveryTimezone;

			if (state.reminderMessage === '') {
				state.reminderMessage = `Hi there. You have been invited to Sign the Card for ${action.payload.recipientFirstName} ${action.payload.recipientLastName}.`;
			}
			state.updatedAt = moment().valueOf();
		},
		updateContribution: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Card, 'contribution' | 'isContributionEnabled'>>
		) => {
			state.contribution = action.payload.contribution;
			state.isContributionEnabled = action.payload.isContributionEnabled;
			state.updatedAt = moment().valueOf();
		},
		updateContributors: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Card, 'contributors'>>
		) => {
			state.contributors = action.payload.contributors;
			state.updatedAt = moment().valueOf();
		},
		updateReminder: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Card, 'isReminderEnabled' | 'reminderMessage'>>
		) => {
			state.isReminderEnabled = action.payload.isReminderEnabled;
			state.reminderMessage = action.payload.reminderMessage;
			state.updatedAt = moment().valueOf();
		},
		updateListOfSignatures: (state: WritableDraft<typeof initialState>, action: PayloadAction<SignatureDraft>) => {
			if (action.payload.cardId !== state.id) {
				return;
			}

			// const toHide = state.signatures.findIndex(
			// 	(element) => element.user.id === action.payload.userId && state.id === action.payload.cardId
			// );
			// if (toHide !== -1) {
			// 	state.signatures[toHide].hidden = true;
			// }

			// const findIndex = state.signatures.findIndex((element) => element.id === action.payload.id);
			// if (findIndex === -1) {
			// 	state.signatures.push(action.payload);
			// } else {
			// 	state.signatures.splice(findIndex, 1, action.payload);
			// }

			state.updatedAt = moment().valueOf();
		},
		removeFromSignaturesList: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<SignatureDraft, 'id' | 'userId' | 'cardId'>>
		) => {
			// const toHide = state.signatures.findIndex(
			// 	(element) => element.user.id === action.payload.userId && state.id === action.payload.cardId
			// );
			// if (toHide !== -1) {
			// 	state.signatures[toHide].hidden = false;
			// }

			const findIndex = state.signatures.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.signatures.splice(findIndex, 1);
			}

			state.updatedAt = moment().valueOf();
		},
	},
	extraReducers: {
		[cardAsyncActions.refreshSignatures.fulfilled.type]: (state, action) => {
			for (const signature of action.payload) {
				const findIndex = state.signatures.findIndex((element) => element.id === signature.id);
				if (findIndex !== -1) {
					state.signatures.splice(findIndex, 1, signature);
				} else {
					state.signatures.push(signature);
				}
			}

			state.updatedAt = moment().valueOf();

			postRequest(action);
		},
		[cardAsyncActions.withdrawRequest.fulfilled.type]: (state, action) => {
			state.withdrawError = '';
			state.updatedAt = moment().valueOf();

			postRequest(action);
		},
		[cardAsyncActions.withdrawRequest.rejected.type]: (state, action) =>
			postErrorRequest(state, action, initialState),
		[cardAsyncActions.show.fulfilled.type]: (state, action) => {
			state.id = action.payload.id;
			state.image = action.payload.image;
			state.uuid = action.payload.uuid;
			state.recipientFirstName = action.payload.recipientFirstName;
			state.recipientLastName = action.payload.recipientLastName;
			state.recipientEmail = action.payload.recipientEmail;
			state.deliveryAt = action.payload.deliveryAt;
			state.deliveryTimezone = action.payload.deliveryTimezone;
			state.contribution = action.payload.contribution;
			state.collectedAmount = action.payload.collectedAmount;
			state.isContributionEnabled = action.payload.isContributionEnabled;
			state.contributors = action.payload.contributors;
			state.signatures = action.payload.signatures;
			state.isReminderEnabled = action.payload.isReminderEnabled;
			state.reminderMessage = action.payload.reminderMessage;
			state.deliveredAt = action.payload.deliveredAt;
			state.yourContribution = action.payload.yourContribution;
			state.thankYouMessageSent = action.payload.thankYouMessageSent;
			state.withdrawalLocked = action.payload.withdrawalLocked;
			state.withdrawError = '';
			state.updatedAt = moment().valueOf();

			postRequest(action);
		},
		[cardAsyncActions.show.rejected.type]: (state, action) => postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signIn.fulfilled.type]: (state, action) => {
			state.isPaid = action.payload.user.unusedPayment;
		},
		[authenticationAsyncActions.signUp.fulfilled.type]: (state, action) => {
			state.isPaid = action.payload.user.unusedPayment;
		},
		[authenticationAsyncActions.resetPassword.fulfilled.type]: (state, action) => {
			state.isPaid = action.payload.user.unusedPayment;
		},
		[cardAsyncActions.cardThankYou.fulfilled.type]: (state) => {
			state.thankYouMessageSent = true;
		},
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
	},
});

export const cardActions = slice.actions;

export default slice.reducer;
