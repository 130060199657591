import axios from 'axios';
import React from 'react';
import { Font } from '../../declarations';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import AlignmentDropdown from '../../lib/components/AlignmentDropdown';
import Box from '../../lib/components/Box';
import Button from '../../lib/components/Button';
import ColorDropdown from '../../lib/components/ColorDropdown';
import EmojiDropdown from '../../lib/components/EmojiDropdown';
import FileUpload from '../../lib/components/FileUpload';
import FontDropdown from '../../lib/components/FontDropdown';
import FontSizeDropdown from '../../lib/components/FontSizeDropdown';
import FontStyleDropdown from '../../lib/components/FontStyleDropdown';
import Giphy from '../../lib/components/Giphy';
import Input from '../../lib/components/Input';
import Modal from '../../lib/components/Modal';
import Typography from '../../lib/components/Typography';
import { API } from '../../lib/util';
import Signature from '../../models/Signature';
import FileResponse from '../../network/responses/FileResponse';
import { useAuthenticationState } from '../../store/selectors';
import getColors from '../../utils/getColors';
import getFonts from '../../utils/getFonts';
import SignatureType from '../../utils/SignatureType';
import GiphyIcon from './../../img/ic-giphy.jpg';
import LibraryIcon from './../../img/ic-library-black.svg';
import styles from './index.module.scss';

interface Props {
	name: string;
	email: string;
	recipientFirstName: string;
	recipientLastName: string;
	textSignature: Signature;
	addSignature: (typeId: SignatureType, value: string, width: number, height: number) => void;
	isUploading: boolean;
	setName: React.Dispatch<React.SetStateAction<string>>;
	setEmail: React.Dispatch<React.SetStateAction<string>>;
	setIsUploading: (value: boolean) => void;
	setTextSignature: (signature: Signature) => void;
}

const WriteMessageTab: React.FC<Props> = (props: Props) => {
	const { width } = useWindowDimensions();
	const ref = React.useRef<HTMLInputElement>(null);

	const authenticationState = useAuthenticationState();

	const [cancelToken] = React.useState(axios.CancelToken.source());
	const [fonts] = React.useState<Array<Font>>(getFonts());
	const [colors] = React.useState<Array<string>>(getColors());
	const [tempFontFamily, setTempFontFamily] = React.useState<Font>(
		getFonts().find((element) => element.name === props.textSignature.meta!.fontFamily)!
	);
	const [fileList, setFileList] = React.useState<FileList | null>(null);
	const [progress, setProgress] = React.useState<number>(0);
	const [filename, setFilename] = React.useState<string | null>(null);
	// 	props.media ? props.media.url.split('/').pop()! : null
	// );
	const [media, setMedia] = React.useState<string | null>(null);
	const [showGiphyModal, setShowGiphyModal] = React.useState<boolean>(false);
	const [isFocused, setIsFocued] = React.useState<boolean>(false);

	const videoRef = React.useRef<HTMLVideoElement>(null);
	const [videoSrc, setVideoSrc] = React.useState<string | null>(null);

	const handleClick = React.useCallback(() => {
		if (ref.current && !props.isUploading) {
			ref.current.click();
		}
	}, [props.isUploading, ref]);

	const handleGifSelect = React.useCallback(
		(url: string, width: number, height: number) => {
			setShowGiphyModal(false);
			props.addSignature(SignatureType.GIF, url, width, height);
		},
		[props.addSignature]
	);

	const handleEmojiSelect = React.useCallback(
		(value: string) => {
			setShowGiphyModal(false);
			props.addSignature(SignatureType.EMOJI, value, 150, 150);
		},
		[props.addSignature]
	);

	// React.useEffect(() => {
	// 	if (props.media) {
	// 		setMedia(props.media.url);
	// 		setFilename(props.media.url.split('/').pop()!);
	// 	}
	// }, [props.media]);

	React.useEffect(() => {
		if (props.isUploading) {
			return;
		}

		const start = () => {
			if (fileList) {
				props.setIsUploading(true);
				const formData = new FormData();
				formData.append('file', fileList[0]);

				setFilename(fileList[0].name);
				setMedia(URL.createObjectURL(fileList[0]));

				const fileReader = new FileReader();
				fileReader.onload = () => {
					if (fileList[0].type.includes('image/')) {
						const image = new Image();
						image.onload = async () => {
							formData.append('width', image.width.toString());
							formData.append('height', image.height.toString());

							const response = await API.post<any, FileResponse>('/upload', formData, {
								onUploadProgress: (data) => {
									setProgress(Math.round((100 * data.loaded) / data.total));
								},
								cancelToken: cancelToken.token,
							});

							props.setIsUploading(false);
							setFileList(null);
							if (response.status === 200) {
								props.addSignature(
									SignatureType.MEDIA,
									response.data.path,
									response.data.width!,
									response.data.height!
								);
								setMedia(null);
							}
						};

						image.src = fileReader.result as string;
					} else {
						setVideoSrc(URL.createObjectURL(fileList[0]));
					}
				};

				fileReader.readAsDataURL(fileList[0]);
			}
		};

		start();
	}, [fileList]);

	React.useEffect(() => {
		const start = async () => {
			if (videoRef.current && fileList && videoSrc) {
				const formData = new FormData();
				formData.append('file', fileList[0]);
				formData.append('width', videoRef.current.width.toString());
				formData.append('height', videoRef.current.height.toString());

				const response = await API.post<any, FileResponse>('/upload', formData, {
					onUploadProgress: (data) => {
						setProgress(Math.round((100 * data.loaded) / data.total));
					},
				});

				props.setIsUploading(false);
				setFileList(null);
				if (response.status === 200) {
					props.addSignature(
						SignatureType.MEDIA,
						response.data.path,
						response.data.width!,
						response.data.height!
					);
					setMedia(null);
				}
			}
		};

		start();
	}, [videoRef, videoSrc]);

	React.useEffect(() => {
		setTempFontFamily(fonts.find((element) => element.name === props.textSignature.meta!.fontFamily)!);
	}, [fonts, props.textSignature.meta!.fontFamily]);

	return (
		<>
			<Modal isVisible={showGiphyModal} setIsVisible={setShowGiphyModal}>
				<Giphy onSelect={handleGifSelect} />
			</Modal>
			{videoSrc !== null && (
				<video
					ref={videoRef}
					style={{ position: 'absolute', pointerEvents: 'none', visibility: 'hidden' }}
					src={videoSrc}
				/>
			)}
			<input
				accept="image/png,image/jpg,image/jpeg,video/mp4,video/quicktime,video/3gpp"
				onChange={(e) => setFileList(e.target.files)}
				ref={ref}
				type="file"
				style={{ display: 'none' }}
			/>
			<div className={styles.formContainer}>
				<Typography style={{ textAlign: 'center' }} variant="h3">
					Write a message to {props.recipientFirstName} {props.recipientLastName}.
				</Typography>
				<Box height={24} />
				<div className={styles.editorContainer}>
					<FontDropdown
						containerStyle={{
							width: width < 576 ? 80 : undefined,
							flexShrink: width < 576 ? 0 : 'initial',
							flexGrow: 1,
						}}
						value={tempFontFamily}
						onChange={(value) =>
							props.setTextSignature({
								...props.textSignature,
								meta: {
									...props.textSignature.meta!,
									fontFamily: value.name,
								},
							})
						}
						className={styles.dropdown}
						options={fonts}
					/>
					<Box height={48} width={1} className={styles.horizontalSeparator} />
					<FontSizeDropdown
						containerStyle={{
							width: width < 576 ? 40 : undefined,
							flexShrink: width < 576 ? 0 : 'initial',
							flexGrow: 1,
						}}
						withArrows
						value={props.textSignature.meta!.fontSize.toString()}
						onChange={(value) =>
							props.setTextSignature({
								...props.textSignature,
								meta: {
									...props.textSignature.meta!,
									fontSize: parseInt(value),
								},
							})
						}
						className={styles.dropdown}
						noSearch={true}
						options={['4', '6', '8', '10', '12', '14', '16', '18', '20', '22', '24']}
					/>
					<Box height={48} width={1} className={styles.horizontalSeparator} />
					<div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
						<FontStyleDropdown
							width={40}
							className={styles.dropdown}
							isBold={props.textSignature.meta!.isBold}
							isItalic={props.textSignature.meta!.isItalic}
							isUnderline={props.textSignature.meta!.isUnderline}
							setIsBold={(value) =>
								props.setTextSignature({
									...props.textSignature,
									meta: {
										...props.textSignature.meta!,
										isBold: value,
									},
								})
							}
							setIsItalic={(value) =>
								props.setTextSignature({
									...props.textSignature,
									meta: {
										...props.textSignature.meta!,
										isItalic: value,
									},
								})
							}
							setIsUnderline={(value) =>
								props.setTextSignature({
									...props.textSignature,
									meta: {
										...props.textSignature.meta!,
										isUnderline: value,
									},
								})
							}
						/>
						<AlignmentDropdown
							width={40}
							className={styles.dropdown}
							value={props.textSignature.meta!.alignment}
							onChange={(value) =>
								props.setTextSignature({
									...props.textSignature,
									meta: {
										...props.textSignature.meta!,
										alignment: value,
									},
								})
							}
						/>
						<ColorDropdown
							options={colors}
							width={40}
							className={styles.dropdown}
							value={props.textSignature.meta!.color}
							onChange={(value) =>
								props.setTextSignature({
									...props.textSignature,
									meta: {
										...props.textSignature.meta!,
										color: value,
									},
								})
							}
						/>
					</div>
				</div>
				<Box height={16} />
				<textarea
					style={{
						flexShrink: 0,
						fontFamily: props.textSignature.meta!.fontFamily,
						fontSize: `${props.textSignature.meta!.fontSize}px`,
						lineHeight: `${props.textSignature.meta!.fontSize + 4}px`,
						fontWeight: props.textSignature.meta!.isBold ? 'bold' : 'normal',
						fontStyle: props.textSignature.meta!.isItalic ? 'italic' : 'normal',
						textDecoration: props.textSignature.meta!.isUnderline ? 'underline' : 'initial',
						color: props.textSignature.meta!.color,
						textAlign: props.textSignature.meta!.alignment,
					}}
					className={styles.textarea}
					value={
						props.textSignature.meta!.message.trim().toLowerCase() === 'type your message here...' &&
						isFocused
							? ''
							: props.textSignature.meta!.message
					}
					onFocus={() => setIsFocued(true)}
					onBlur={() => {
						setIsFocued(false);
						if (props.textSignature.meta!.message.trim() === '') {
							props.setTextSignature({
								...props.textSignature,
								meta: {
									...props.textSignature.meta!,
									message: 'Type your message here...',
								},
							});
						}
					}}
					onChange={(e) => {
						if (e.target.value.trim() === '') {
							props.setTextSignature({
								...props.textSignature,
								meta: {
									...props.textSignature.meta!,
									message: 'Type your message here...',
								},
							});
						} else {
							props.setTextSignature({
								...props.textSignature,
								meta: {
									...props.textSignature.meta!,
									message: e.target.value,
								},
							});
						}
					}}
				/>
				{!authenticationState.isAuthenticated && (
					<>
						<Box height={16} />
						<Input value={props.name} onChange={props.setName} label="Name (required)" />
						<Input
							value={props.email}
							onChange={props.setEmail}
							label="Email address (optional)"
							type="email"
						/>
					</>
				)}
				<Box height={32} />
				{media !== null && (
					<>
						<FileUpload
							isUploading={props.isUploading}
							filename={filename}
							image={media}
							progress={progress}
							onCancel={() => {
								props.setIsUploading(false);
								cancelToken.cancel();
								setFileList(null);
								setMedia(null);
							}}
						/>
						<Box height={32} />
					</>
				)}
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						style={{ padding: '0 16px', alignSelf: 'center', marginBottom: 16 }}
						type="outlined"
						onClick={handleClick}
					>
						<img style={{ objectFit: 'contain', width: 24, height: 24 }} src={LibraryIcon} alt="" />
						<Box width={12} />
						Add Photo/Video
					</Button>
					<Box width={16} />
					<Button
						style={{ padding: '0 16px', alignSelf: 'center', marginBottom: 16 }}
						type="outlined"
						onClick={() => setShowGiphyModal(true)}
					>
						<img style={{ objectFit: 'contain', width: 24, height: 24 }} src={GiphyIcon} alt="" />
						<Box width={12} />
						Add Giphy
					</Button>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<EmojiDropdown onChange={handleEmojiSelect} />
				</div>
				{/* )} */}

				<Box height={24} />
			</div>
		</>
	);
};

export default WriteMessageTab;
