import React from 'react';
import { useDispatch } from 'react-redux';
import authenticationAsyncActions from '../../../store/actions/authentication.action';
import { useAuthenticationState, useUIState } from '../../../store/selectors';
import { uiActions } from '../../../store/slices/ui.slice';
import validateEmail from '../../../utils/validateEmail';
import Button from '../Button';
import Input from '../Input';
import Typography from '../Typography';
import styles from './index.module.scss';

const ForgotPassword: React.FC = () => {
	const dispatch = useDispatch();

	const authenticationState = useAuthenticationState();
	const uiState = useUIState();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [email, setEmail] = React.useState<string>('');
	const [emailError, setEmailError] = React.useState<string>('');

	const canProceed = React.useMemo<boolean>(() => {
		return email !== '' && validateEmail(email);
	}, [email]);

	const handleForgotPassword = React.useCallback(async () => {
		if (!canProceed || isLoading) {
			return;
		}

		setIsLoading(true);
		await dispatch(authenticationAsyncActions.forgotPassword({ email }));
		setIsLoading(false);
		dispatch(uiActions.openResetPasswordModal({}));
	}, [dispatch, canProceed, isLoading, email]);

	React.useEffect(() => {
		if (email && !validateEmail(email)) {
			setEmailError('Invalid Email address.');
		} else {
			setEmailError('');
		}
	}, [email]);

	React.useEffect(() => {
		setEmailError(authenticationState.emailError || '');
	}, [authenticationState.updatedAt]);

	return (
		<div className={styles.container}>
			<div className={styles.leftContainer}>
				<Typography variant="h3" style={{ color: '#ffffff', textAlign: 'center' }}>
					Forgot
					<br />
					<span style={{ color: '#ee584b' }}>password</span>
				</Typography>
			</div>
			<div className={styles.rightContainer}>
				<Typography variant="h5" style={{ textAlign: 'center' }}>
					Forgotten your password?
				</Typography>
				<div style={{ height: 24 }} />
				<Typography variant="body1" style={{ textAlign: 'center' }}>
					Just enter your email address you used to create an account and we'll send you instructions to
					change your password.
				</Typography>
				<div style={{ height: 36 }} />
				<Input
					error={emailError}
					style={{ width: '100%' }}
					name="email"
					label={'Email address'}
					value={email}
					onChange={setEmail}
					type="email"
				/>
				<div style={{ height: 36 }} />
				<Button loading={isLoading} disabled={!canProceed || isLoading} onClick={handleForgotPassword}>
					Recover Password
				</Button>
				<div style={{ height: 8 }} />
				<Button
					onClick={() => dispatch(uiActions.openResetPasswordModal({}))}
					style={{ padding: '16px 0', textAlign: 'center' }}
					type="text"
				>
					You already have code? Click here
				</Button>
				<Button
					onClick={() => dispatch(uiActions.openSignInModal({ redirectUrl: uiState.redirectUrl }))}
					style={{ padding: '16px 0' }}
					type="text"
				>
					I want to Sign In
				</Button>
			</div>
		</div>
	);
};

export default ForgotPassword;
