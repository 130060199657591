import Request from '../lib/util/request';
import { requestActions } from './slices/request.slice';
import { CPA } from './types';

const postRequest = (action: CPA<any>, payload: Request.Payload = {}) => {
	action.dispatch(
		requestActions.fulfilled({
			name: action.type,
			message: '',
			payload,
		})
	);
};

export default postRequest;
