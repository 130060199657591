import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import authenticationAsyncActions from '../actions/authentication.action';
import transactionsAsyncActions from '../actions/transactions.action';
import postRequest from '../postRequest';
import { requestActions } from './request.slice';

export interface TransactionsState {}

const initialState: TransactionsState = {};

const slice = createSlice({
	name: 'transactions',
	initialState,
	reducers: {},
	extraReducers: {
		[transactionsAsyncActions.finalize.fulfilled.type]: (_, action) => {
			// toast('Transaction fulfilled.', {
			// 	type: 'success',
			// 	position: 'top-right',
			// 	autoClose: 5000,
			// 	hideProgressBar: false,
			// 	closeOnClick: true,
			// 	pauseOnHover: true,
			// });

			postRequest(action);
		},
		[transactionsAsyncActions.finalize.rejected.type]: (_, action) => {
			toast('Something went wrong, please contact support.', {
				type: 'error',
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
			});

			action.dispatch(
				requestActions.rejected({
					name: action.type,
					message: '',
					payload: {},
				})
			);
		},
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
	},
});

export const transactionsActions = slice.actions;

export default slice.reducer;
