import React from 'react';
import Box from '../Box';
import Typography from '../Typography';
import LeftArrowIcon from './../../../img/ic-arrow-left-black.svg';
import RightArrowIcon from './../../../img/ic-arrow-right-black.svg';
import MinusIcon from './../../../img/ic-minus.svg';
import PlusIcon from './../../../img/ic-plus.svg';
import styles from './index.module.scss';

interface Props {
	page?: number;
	zoom: number;
	setPage?: (value: number) => void;
	setZoom: (value: number) => void;
	disableZoom?: boolean;
	noPage?: boolean;
	noZoom?: boolean;
	noPageLabel?: boolean;
	style?: React.CSSProperties;
}

const Controls: React.FC<Props> = ({ page = 0, setPage = () => {}, disableZoom = false, ...props }: Props) => {
	const zoomPercentage = React.useMemo<number>(() => props.zoom * 100, [props.zoom]);

	const handlePrevious = React.useCallback(() => {
		if (page === -1) {
			return;
		}

		setPage(page - 1);
	}, [page, setPage]);

	const handleNext = React.useCallback(() => {
		// if (page === 0) {
		// return;
		// }

		setPage(page + 1);
	}, [page, setPage]);

	const handleMinus = React.useCallback(() => {
		if (disableZoom) {
			return;
		}

		if (props.zoom <= 0.5) {
			props.setZoom(0.5);
		} else {
			props.setZoom(props.zoom - 0.5);
		}
	}, [props.zoom, disableZoom]);

	const handlePlus = React.useCallback(() => {
		if (disableZoom) {
			return;
		}

		if (props.zoom >= 2) {
			props.setZoom(2);
		} else {
			props.setZoom(props.zoom + 0.5);
		}
	}, [props.zoom, disableZoom]);

	return (
		<div style={props.style} className={styles.container}>
			{!props.noPage && (
				<div className={styles.section}>
					{!props.noPageLabel && (
						<>
							<Typography variant="overline" style={{ fontWeight: 600, textTransform: 'uppercase' }}>
								page
							</Typography>
							<Box width={8} />
						</>
					)}
					<img onClick={handlePrevious} className={styles.button} src={LeftArrowIcon} alt={'Previous'} />
					<Typography
						variant="body1"
						style={{ width: 20, textAlign: 'center', margin: '0 8px', fontWeight: 'bold' }}
					>
						{page + 1}
					</Typography>
					<img onClick={handleNext} className={styles.button} src={RightArrowIcon} alt={'Next'} />
				</div>
			)}
			{!props.noZoom && !props.noPage && <div className={styles.separator} />}
			{!props.noZoom && (
				<div className={styles.section}>
					<Typography variant="overline" style={{ fontWeight: 600, textTransform: 'uppercase' }}>
						zoom
					</Typography>
					<Box width={8} />
					<img
						onClick={handleMinus}
						className={`${styles.button} ${props.zoom === 0.5 || disableZoom ? styles.disabled : ''}`}
						src={MinusIcon}
						alt={'Minus'}
					/>
					<Typography variant="body1" style={{ margin: '0 8px', fontWeight: 'bold' }}>
						{zoomPercentage}%
					</Typography>
					<img
						onClick={handlePlus}
						className={`${styles.button} ${props.zoom === 2 || disableZoom ? styles.disabled : ''}`}
						src={PlusIcon}
						alt={'Plus'}
					/>
				</div>
			)}
		</div>
	);
};

export default Controls;
